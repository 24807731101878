.select2-container--default .select2-results__option[aria-disabled=true] {
  display: none;
}

.toip {

  @extend %paddington;
  @extend %pko-clearfix;

  select + .select2-container {
    margin-top: 5px;
  }

  &__header {
    margin-top: 0;
  }

  &__accordion-single {
    display: none;
  }

  &__main-filter {
    @extend %pko-clearfix;
    list-style: none;
    margin-top: 15px;
    padding: 0;

    &-element {
      width: 100%;
      float: left;
      clear: left;
      padding-bottom: 16px;
      @include breakpoint(max-width $brake-md) { // todo zamienić na $brake-md-max
        &:last-child {
          padding-bottom: 27px;
        }
      }
    }
  }

  &__lvl1 {
    &-prefix {
      display: inline-block;
      width: 75px;
      color: $color-gray-second;
      @include pko-font-size($font-size-t1);
      float: left;
    }
    &-name {
      @include pko-font-size($font-size-h4);
      float: left;
      clear: left;
      width: 100%;
    }
    &-single {
      @extend %h3;
      margin-top: -3px;
      margin-bottom: 13px;
      display: block;
    }
  }

  &__lvl2 {
    @include pko-font-size($font-size-t2);
    list-style: none;
    padding: 0;
    width: 100%;
    li {
      padding: 15px 50px 12px 0;
      border-top: 1px solid $color-gray-fifth;
      cursor: pointer;
      min-height: 53px;
    }
    &-prefix {
      width: 100%;
      display: block;
      color: $color-gray-second;
    }
    &-name {
      float: left;
      clear: left;
      width: 100%;
    }
  }

  .accordion--toip {
    margin-bottom: 27px;

    @include breakpoint($brake-sm) {
      &:first-child {
        margin-top: -51px;
      }
    }
    
    &:last-child {
      margin-bottom: 0;
    }
    .accordion__body {
      padding: 0;

      ul {
        margin: 0;
      }
    }
  }

  @include breakpoint($brake-sm-max) {
    &__lvl1 {
      &-single {
        border-bottom: 1px solid $border-color;
        margin-bottom: 29px;
        padding-bottom: 23px;
      }
      &-prefix:empty {
        display: none;
      }
    }
    &__header {
      margin-bottom: 13px;
    }

    h3 {
      margin: 0 0 15px;
    }
  }

  @include breakpoint($brake-sm) {
    &-header {
      margin-bottom: 45px;
    }
    &__lvl1 {
      &-prefix {
        width: 75px;
        min-height: 1px;
      }
      &-name {
        clear: none;
        width: 80%;
      }
    }
    &__lvl2 {
      &-name {
        clear: none;
        width: 80%;
      }
    }
    .accordion--toip {
      margin-bottom: 45px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include breakpoint($brake-md) {

    .accordion--toip {
      .accordion__header {
        padding: 16px 50px 14px 0;
      }
    }

    &__main-filter {

      &-element {
        width: 297px;
        clear: none;
        padding-bottom: 45px;
        margin-right: 15px;
      }
    }
    &__lvl1 {
      &-prefix {
        width: 100px;
        line-height: 24px;
      }
      &-name {
        width: 80%;
        clear: none;
      }
    }
    &__lvl2 {
      padding-left: 100px;
      width: 95%;
      &-prefix {
        width: 75px;
        display: inline-block;
        float: left;
      }
    }
  }
}
