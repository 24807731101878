@media print {

  html,
  body {
    height: 99% !important;
    page-break-after: avoid;
    border: 1px solid transparent;
    background: transparent;
  }

  // -----------------------------------------------------------------
  // W widoku stron do druku pomijane są następujące elementy serwisu:
  // -----------------------------------------------------------------

  // belki nawigacyjne na stronach produktowych i na stronach kategorii
  .product-menu,
  .site-nav__sub-list.sticky,

  // filtrownice (jeżeli występują),
  .filters-wrapper-news,
  .ui-datepicker,

  // kontrolka "Przewiń do dołu/Przewiń do góry"
  .scrollie,

  // menu segmentów (menu I rzędu)
  // menu kategorii (menu II rzędu)
  .header__inner,

  // panel zakładek (taby)
  .site-tabs-select-wrapper,
  .js-tabs-nav,

  // prawa kolumna strony
  .site-content__additional,

  // zawartość instancji snippetu typu "Formularz"
  .form,

  // zawartość instancji snippetu typu "Kalkulator"
  .calc,

  // zawartość instancji snippetu typu "Placement"

  // zawartość instancji snippetu typu "Placement modal"

  // zawartość instancji snippetu typu "Sekwencja" - tylko wariant "Karuzela grafik"
  .card-carousel,

  // Stopka
  .footer,

  // inne
  .js-ajax-overlay,
  .modal,
  .pagination,
  .mobile-button,
  .cookie,
  .page-tools,
  .accordion__header-icon {
    display: none !important;
  }

  // ------------------------------------------------------------------------------
  // Przy generowaniu widoku strony do druku uwzględniane są następujące założenia:
  // ------------------------------------------------------------------------------

  // zawartość drukowanej strony z CMS zajmuje pełną szerokość wydruku
  .site-content__main {
    width: 100% !important;
  }

  // w nagłówku drukowanej strony wstawiana jest wartość taga <title> z sekcji <head> drukowanej treści z CMS

  // w widoku pierwszej drukowanej strony prezentowana jest wartość pola "Nagłówek H1" oraz logo PKO BP
  //

  // treść wszystkich zakładek jest prezentowana kolejno, jedna pod drugą
  .site-tabs-content.js-tabs-content > * {
    display: block !important;
    padding-top: 20px !important;
  }

  // jeżeli drukowana treść zawiera akordeon, to - w wersji strony do druku - każda belka przyjmuje stan "rozwinięta"
  .accordion__body.hidden {
    display: block !important;
  }

  // odsuwamy z prawej, żeby tekst nie zachodził na logo #66849
  .banner--short .banner__title,
  .banner__title {
    margin-right: 260px;
    .pko & {
      margin-right: 80px;
    }
    @include breakpoint($brake-sm) {
      margin-right: 0;
    }
  }

  .table-wrapper {
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    overflow: visible !important;
  }

}
