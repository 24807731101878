/* =================
   Moduł kalkulatora miniratki
   ================= */

.calc {
  &--loan-installment {
    .calc__options {
      margin-bottom: 18px;
      @include breakpoint($brake-sm) {
        margin-bottom: 40px;
      }

      &__list-outer {
        @extend %pko-clearfix;
      }

      &__list {
        @extend %break-from-paddington;
        margin-bottom: -4px;
        @include breakpoint($brake-sm) {
          padding-top: 4px;
        }

        &-item {
          margin-top: 10px;
          @extend %paddington;
          @include breakpoint($brake-sm) {
            @include span(6);
            margin: -4px 0 4px;
          }
          @include breakpoint($brake-md) {
            @include span(6);
            &:nth-child(2n-1) {
              clear: left;
            }
          }

          &--wrapped {
            white-space: normal;
            height: auto;
          }
        }
      }
    }
  }
}
