.contact-item {
  @extend %paddington;

  &__title {
    @extend %h4;
    margin: 0;
  }

  &__small-title {
    @include pko-font-size($font-size-t2);
    color: $text-color;
    margin: 10px 0 5px;
  }

  &--wide {
    h3 {
      & + h3 {
        margin-top: 13px;
        margin-bottom: 13px;
      }
      a {
        color: $color-blue;
      }
    }
  }

  &__p {
    margin-top: 0;
    @include pko-font-size($font-size-t2-mobile);
  }

  &__info {
    @include pko-font-size($font-size-t2-mobile);
    margin-top: 10px;
    color: $color-gray-second;
    margin-bottom: 24px;
  }

  &__call-center-logo {
    float: right;
    margin-right: 30px;
    height: 55px;
  }

  @include breakpoint($brake-sm) {

    padding-top: 30px;
    @include span(6);

    &--wide {
      @include span(12);
    }

    &:nth-child(1),
    &:nth-child(2) {
      padding-top: 0;
    }

    &__header {
      padding: 0;
    }

    &__p {
      @include pko-font-size($font-size-t2);
    }

    &__info {
      @include pko-font-size($font-size-t2);
      margin-bottom: 15px;
    }

    &__call-center-logo {
      margin-right: 0;
      height: 70px;
    }
  }

  @include breakpoint($brake-md) {
    &__call-center-logo {
      margin-right: 30px;
    }
  }

}

.accordion.accordion--mobile-only-contact {
  border-bottom: none;
  margin-bottom: -30px;
  @include breakpoint($brake-sm) {
    margin-bottom: -10px;
    margin-top: -5px;
  }
}
