.calc-results {
  padding: 40px 30px 25px;
  border-radius: 4px;

  &__content {
    display: flex;
    flex-wrap: wrap;

    dl {
      margin-top: 0;
      margin-bottom: 30px;
      dt {
        margin-bottom: 10px;
      }
      dd {
        margin-left: 0;
        @include pko-font-size(24);
      }

      &.specified {
        font-weight: bold;

        dt {
          @include pko-font-size(18);
          margin-bottom: 15px;
        }
        dd {
          @include pko-font-size(38);
        }
      }
      &:not(.specified) {
        @media all and (min-width: 360px) {
          width: 50%;
          padding-right: 20px;
        }
        @include breakpoint($brake-sm) {
          width: 100%;
          padding-right: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
