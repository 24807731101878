/*
* Styl 1 - bombelki po lewej
*/

.bubbles {
  $root: &;

  &--1 {
    $style: &;

    margin-bottom: 25px;

    @include breakpoint($brake-sm) {
      margin-bottom: 45px;
    }

    #{$root}__title {
      @include pko-text-size($font-size-h3-mobile, $line-height-h3-mobile, $font-size-h3, $line-height-h3);
      margin-bottom: 20px;
      color: $text-color-head;

      @include breakpoint($brake-sm) {
        margin-bottom: 40px;
      }
    }

    #{$root}__bubbles-container {
      display: block;
      position: relative;

      @include breakpoint($brake-sm) {
        display: inline-block;
        width: 50%;
        vertical-align: top;
      }

      &:before {
        content: " ";
        display: inline-block;
        width: 100%;
        padding-bottom: 100%;
      }
    }

    &#{$root}--right #{$root}__bubbles-container {
      float: right;
    }

    #{$root}__content {
      display: block;

      @include breakpoint($brake-sm) {
        display: inline-block;
        width: 50%;
        vertical-align: top;
      }
    }

    #{$root}__bubble {
      position: absolute;

      &--1 {
        top: 23.7%;
        left: 29.7%;
      }

      &--2 {
        top: 39.1%;
        left: 68.1%;
      }

      &--3 {
        top: 60.1%;
        left: 45%;
      }
    }


    #{$root}__description {
      margin-bottom: 25px;
      color: $text-color;

      @include breakpoint($brake-sm) {
        padding-left: 33px;
        margin-bottom: 45px;
      }
    }

    #{$root}__articles {
      @include breakpoint($brake-sm) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    #{$root}__article {
      position: relative;
      display: block;
      padding-left: 30px;
      margin-bottom: 25px;

      @include breakpoint($brake-sm) {
        flex: 0 0 50%;
        display: inline-block;
        padding-left: 56px;
        margin-bottom: 45px;
        vertical-align: top;
      }
    }

    #{$root}__article-image {
      position: absolute;
      top: 2px;
      left: 0;
      width: 13px;
      height: 13px;

      @include breakpoint($brake-sm) {
        left: 26px;
      }

      &:before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        vertical-align: top;
        background-image: pko-image('redesigncareer/arrow-right.png');
      }

      & > img {
        display: none;
      }
    }

    #{$root}__article-title {
      @include pko-text-size($font-size-h4-mobile, $line-height-h4-mobile, $font-size-h4, $line-height-h4);
      margin-bottom: 7px;
      color: $text-color-head;
    }

    #{$root}__article-description {
      @include pko-text-size($font-size-t2-mobile, $line-height-t2-mobile, $font-size-t2, $line-height-t2);
      color: $text-color;
    }
  }
}