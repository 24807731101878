.fees-and-interests {
  $root: &;

  :not(.about-product-under) > & {
    @extend %paddington;
  }

  //for scrolling from product menu
  &__header::before {
    content: '';
    display: block;
    height: 80px;
    margin-top: -80px;
  }

  &__section {
    overflow-anchor: none;
  }

  &__button {
    @extend %btn-white;

    .icon-arrow-down {
      transform: translateY(2px) scale(0.8);
      transform-origin: center;

      @include breakpoint($brake-sm-max) {
        float: none;
        vertical-align: top;
      }
    }

    &--open {
      .icon-arrow-down {
        transform: scale(-0.8);
        transform-origin: center;
      }
    }

    @include breakpoint($brake-sm-max) {
      width: 100%;
    }
  }

  & &__less, & &__more {
    margin: 18px 0 21px;
  }

  // More text show hide
  &__less {
    display: block;
  }
  &__more {
    display: none;
  }

  &__section.open &__less {
    display: none;
  }
  &__section.open &__more {
    display: block;
  }

  // Button show hide
  &__button {
    display: inline-block;

    &--open {
      display: none;
    }
  }

  &__section.open {
    #{$root}__button {
      display: none;
      &--open {
        display: inline-block;
      }
    }
  }
}