.product-table {
  $root: &;

  @include pko-visible-from($brake-sm, table);
  @include pko-font-size($font-size-t2);

  color: $text-color;
  table-layout: fixed;
  border-collapse: collapse;

  &__cell {
    width: (100 / 3) * 1%;
    padding: 10px 25px;
    border-left: 1px solid $color-gray-fifth;
    border-bottom: 1px solid $color-gray-fifth;
    vertical-align: top;

    &:first-child {
      border-left: none;
      padding-left: 0;
    }

    * {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__head,
  &__footer,
  &__row--no-border {
    #{$root}__cell {
      border-bottom: none;
    }
  }

  &__head {
    text-align: left;
  }

  &__footer {
    #{$root}__cell {
      padding-top: 22px;
      padding-bottom: 0;
    }
  }

  &__title {
    margin-top: 0;
  }

  img {
    max-width: 100%;
  }

  .infotip-trigger {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
  }

}
