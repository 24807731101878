.definition-list {
  dl {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;

    dt {
      font-weight: bold;
      color: $color-black;
    }
    dd {
      margin-left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint($brake-sm) {
      flex-flow: row nowrap;
      dt {
        flex: 0 0 25%;
        padding-right: 50px;
      }
    }
  }
}
