.banks-wrapper {
  .course {
    height: 270px;
    @include breakpoint($brake-sm) {
      margin-bottom: 93px;
    }

    &__title {
      margin-bottom: -5px;
    }

    &__info {
      margin-top: 19px;

      p {
        margin: 8px 0 11px;
      }
    }
  }
  &__description {
    @extend %paddington;
    @extend %text-default-2;
    color: $color-gray-second;
    margin: #{$vertical-space-mobile} 0;
    @include breakpoint($brake-sm) {
      margin: #{$vertical-space-desktop - 15px} 0 43px;
    }
  }
}

.banks-container {
  @extend %pko-clearfix;
  position: relative;

  @include breakpoint($brake-sm) {
    &::before {
      content: '';
      display: block;
      background: $color-white;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 80px;
      @include pko-z-index(default);
    }
  }
}

.banks-filters {
  margin-top: -10px;

  .courses-hour-select .select-mobile-wrapper {
    width: 175px;
    line-height: 1.4;
  }
  @include breakpoint($brake-sm) {

    .courses-hour-select .select-mobile-wrapper {
      font-size: 20px;
      width: 222px;
      margin-top: 1px;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
      @include pko-font-size(20);
    }
    .courses-hour-select-label {
      @include pko-font-size(20);
      float: left;
      margin: 2px 4px 0 0;
    }
  }
}
