.feature-section {

  $root: &;

  margin-bottom: 20px;
 // padding-bottom: 50px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    height: 1px;
    display: block;
    background: $border-color;
  }

  &--inline-block &__item.text-box {
    display: inline-block;
    float: none;
    width: 33%;
    vertical-align: top;
  }

  &__item {
    @extend %paddington;
    &.text-box {
      padding-bottom: 45px;

      @include breakpoint($brake-sm-max) {
        padding-bottom: 0;
      }
    }
  }
  &__header {
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
  &__description,
  &__header {
    padding-left: 0;
    padding-right: 0;
  }
  &__description {
    @include pko-font-size($font-size-t2-mobile);
  }

  .owl-dots {
    bottom: 25px;
  }

  @include breakpoint($brake-sm) {
    padding-bottom: 50px;

    &::before {
      display: none;
    }

    &__item {
      @include span(4);
      &.image {
        display: none;
        padding-bottom: 40px;
        img {
          display: inline-block;
          max-width: 100%;
        }
      }
      &.big {
        @include span(6);
        padding-bottom: #{$vertical-space-desktop - 10};
      }
      &.big + &.small {
        clear: left;
      }
      &.small {
        margin-top: 50px;
      }
      &.small:nth-child(3n-2) {
        clear: left;
      }
      .js-features-slider &.small:nth-child(3n-2) {
        clear: none;
      }
      .js-features-slider &.small:nth-child(3n-1) {
        clear: left;
      }
    }
    &__item.big &-item__header {
      margin-top: 40px;
      margin-bottom: 20px;
    }
    &__item.small &-item__description {
      @include pko-font-size($font-size-t2);
      p {
        margin: 5px 0;
      }
    }
    &__item.big &-item__description {
      p {
        @include pko-font-size($font-size-t1);//Bug #63164
        margin: 17px 0; //Bug #63164
      }
    }
  }

  &--with-bb {
    margin-bottom: 5px;
    @include breakpoint($brake-sm-max) {
      padding-bottom: 0;
    }
    &::before {
      display: none;
    }
  }
  &--with-bb &__item {
    @include breakpoint($brake-sm-max) {
      padding-bottom: 20px;
    }
  }

  &--under-banner {
    @extend %break-from-paddington;
    margin-bottom: 0;

    .breakline {
      bottom: auto;
      top: 0;
    }

    #{$root}__item {
      margin-top: 20px;
    }

    @include breakpoint($brake-sm) {
      padding-top: 5px;

      .breakline {
        display: none;
      }

      #{$root}__item {
        margin-top: 40px;
      }
    }

    @include breakpoint($brake-sm-max) {
      padding-bottom: 35px;
      padding-top: 10px;
      &::before {
        bottom: auto;
        top: 0;
      }
    }
  }

  @include breakpoint($brake-md) {
    &__item {
      &.image,
      &.big {
        @include span(4);
        display: block;

        .feature-section-item__title {
          margin-bottom: 18px; //Bug #63164 - nie wiem jak inaczej to zrobić...
        }
      }
      &.big {
        padding-top: 26px; //Bug #63164
      }
    }
  }
}

.feature-section-item {
  @include pko-text-item;

  &__title {
    padding-left: 0;
    padding-right: 0;
  }
}
