.sitemap {

  $root: &;

  @extend %pko-clearfix;

  &__item {
    @extend %paddington;

    clear: right;

    @include breakpoint($brake-sm) {
      @include span(6);

      clear: none;
      &:nth-child(2n+1) {
        clear: left;
      }
    }
    @include breakpoint($brake-md) {
      @include span(4);

      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }

  &__title {
    @include pko-font-size($font-size-t1);
    @include pko-normalcase-in-uppercase;
  }

  &__link {
    @include pko-font-size($font-size-t2-mobile);
    display: block;
  }
}
