.mobile-button {

  @extend %paddington;
  @extend %h3;
  position: relative;
  display: block;
  color: $color-black;
  padding-top: 15px;
  padding-bottom: 15px;

  & + & {
    padding-top: 15px;
  }

  &:first-child {
    margin-top: -20px;
  }

  &__icon {
    display: inline-block;
    width: 20px;
    height: 30px;
    margin-right: 13px;
    margin-top: -3px;
    float: left;

    &-inbox-indicator {
      @include new-message-alert(10, 0, -6);
    }

    &--iko {
      @include pko-font-size(32);
      color: $icon-color;
      &::before {
        position: relative;
        right: 3px;
      }
    }

    &--pin {
      @include pko-font-size(44);
      color: $icon-color;
      &::before {
        position: relative;
        left: -11px;
        top: -7px;
      }
    }

    &--phone {
      @include pko-font-size(38);
      color: $icon-color;
      &::before {
        position: relative;
        left: -8px;
        top: -4px;
      }
    }

    &--inbox {
      @include pko-font-size(22);
      color: $icon-color;
      
      position: relative;
      top: 3px;
    }
  }

  @include breakpoint($brake-sm) {
    display: none;
  }
}
