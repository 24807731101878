// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

// todo może nazwa
.home {
  &-2-col {
    @extend %pko-clearfix;

    &__box {
      @include breakpoint($brake-sm) {
        @include span(4);
        margin-bottom: 20px;

        &:first-child {
          @include span(8);
        }
      }
    }

    // np. leasing strona główna
    .form {
      @include pko-form-padding;
    }
  }
}

.homepage {
  .site-content__module--loan-calc-snippet {
    padding-bottom: 0;
  }
}

