.footer-bottom.simplified {
  margin-bottom: 20px;
  padding-top: 20px;
  position: relative;

  .breakline {
    bottom: auto;
    top: 0;
  }

  @include breakpoint($brake-sm) {
    margin-top: -30px;
    margin-bottom: 30px;

    .breakline {
      display: none;
    }
  }

  @include breakpoint($brake-md) {
    .footer-bottom__info {
      margin: -7px 0;
    }

    margin-top: -20px;
  }

}

