/* =================
   Cześć składowa modułu kalkulatorów
   Kalkulator kredytu hipotecznego - Zaplanuj budżet (gospodarstwa itp.)
   ================= */

%table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $color-gray-fifth;
}

%table--mod {
  @extend %table;

  td,
  th {
    @extend %text-default-2;
    padding: 10px 20px 5px;
    border: 1px solid $color-gray-fifth;
    text-align: right;
    font-weight: normal;
  }

  th {
    &:first-child {
      text-align: left;
      background: $color-gray-sixth;
    }
  }
}

.households {
  &__bottom-controls {
    padding: 18px 0 30px;

    .results-stage & {
      border-top: 1px solid $color-gray-fifth;
    }
  }

  &__button {
    &-submit {
      float: right;

      .results-stage & {
        display: none;
      }
    }

    &-back {
      display: none;

      .results-stage & {
        display: inline;
      }
    }

    &-add {
      .results-stage & {
        display: none;
      }
    }
  }

  &__summary {
    display: none;
    margin: 46px 0 70px;

    .results-stage & {
      display: block;
    }

    &-table {
      @extend %table--mod;
      table-layout: fixed;

      .icon-infotip {
        @extend %icon-infotip;
      }
    }
    &-val {
      @extend %h4;
      color: $color-black;
      white-space: nowrap;
    }
  }

  &__charts {
    display: none;

    .results-stage & {
      display: block;
    }

    &__main {
      margin-bottom: $vertical-space-mobile;
      @include breakpoint($brake-sm) {
        margin-bottom: $vertical-space-desktop;
      }
    }

    &__sub {
      width: 100%;
      margin-bottom: $vertical-space-mobile;

      @include breakpoint($brake-sm) {
        float: left;
        width: 50%;
        margin-bottom: $vertical-space-desktop;
      }
    }
  }

  &__errors {
    padding-left: 20px;
  }
}

.household {
  margin: 18px 0 40px;

  @include breakpoint($brake-sm) {
    margin: 46px 0 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .select2,
  .select-mobile-wrapper {
    margin: 6px 0 8px;
  }

  .error {
    @extend %text-default-3;
    color: $color-red;
    margin: 0;
    clear: both;
  }

  .summary {
    clear: both;
  }

  .summary-block {
    float: none;
    font-size: 28px;
    line-height: 25px;
    margin: 0;
    padding: 0;
    background: none;
    border: none;

    &::before {
      float: left;
    }

    &.active {
      .calc__blocked-info {
        display: inline-block;
      }
    }

    .calc__blocked-info {
      display: none;
      font-family: $text-font-stack;
      vertical-align: top;
      margin-top: 5px;
    }
  }

  &__button {
    @extend %text-default-2;
    background: none;
    border: none;
    color: $color-blue;
    padding: 0;
    position: relative;
    top: 7px;

    &-add {
      @extend %text-default-2;
    }

    &-delete {
      margin-right: 20px;

      @include breakpoint($brake-sm) {
        margin-right: 0;
        margin-left: 20px;
        float: right;
      }
    }

    &-clear {
      margin-right: 20px;
      top: 0;

      .household--closed & {
        display: none;
      }

      @include breakpoint($brake-sm) {
        top: 7px;
        margin-right: 0;
        margin-left: 20px;
        float: right;
      }
    }

    &-edit {
      display: none;
      margin-right: 20px;
      top: 0;

      .household--closed & {
        display: inline;
      }

      @include breakpoint($brake-sm) {
        top: 7px;
        margin-right: 0;
        margin-left: 20px;
        float: right;
      }
    }
  }

  &__header {
    margin: 0 0 12px;

    @include breakpoint($brake-sm) {
      float: left;
      margin-top: 0;
      margin-bottom: initial;
    }

    &-wrapper {
      padding-bottom: 10px;
    }
    //&-wrapper {
    //  height: 75px;
    //
    //  @include breakpoint($brake-sm) {
    //    height: 53px;
    //    border-bottom: none;
    //  }
    //}

  }

  &__group {
    margin-bottom: 7px;

    &-title {
      float: left;
      margin-top: 0;
      margin-bottom: initial;
    }

    &-subtitle {
      @include pko-font-size($font-size-t1-mobile);
      margin: 15px 0 22px;

      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-t1);
        margin: 2px 0 22px;
      }
    }

    &-total {
      @include pko-text-size($font-size-t2, $line-height-t2, $font-size-h4, $line-height-h4);
      color: $color-black;
      position: absolute;
      right: 30px;
      top: 18px;

      @include breakpoint($brake-sm) {
        right: 0;
        top: auto;
      }
    }
  }

  &__list {
    @extend %break-from-paddington;
    margin-bottom: 18px;

    .household__list {
      margin: 0;
    }

    &-item {
      @extend %paddington;
      position: relative;
      margin-bottom: 5px;

      .calc--wide & {
        @include breakpoint($brake-lg) {
          @include span(3);
        }
      }

      @include breakpoint($brake-md) {
        @include span(6);
        margin-bottom: 15px;
      }
    }
  }

  &__recap {
    display: none;

    .household--closed & {
      display: block;
      margin-top: 12px;
    }

    &-table {
      @extend %table;
      display: none;

      @include breakpoint($brake-sm) {
        display: table;
      }

      tr {
        &:nth-child(odd) {
          background: $color-gray-sixth;
        }
      }

      td,
      th {
        padding: 10px;
        border: 1px solid $color-gray-fifth;
        text-align: center;
      }

      th {
        @extend %text-default-2;
        font-weight: normal;
      }

      td {
        @extend %h4;
      }
    }

    &-table--mobile {
      @extend %table--mod;

      @include breakpoint($brake-sm) {
        display: none;
      }
    }
  }

  .income {
    &__group {
      &-delete {
        margin-left: 20px;
        font-size: 13px;
        float: right;
        position: absolute;
        right: 28px;
        top: 3px;
      }
    }

    &__add-btn {
      margin: 0 0 10px;
    }

    &--mod {
      .income-group__second-item,
      .income-group__third-item {
        @include breakpoint($brake-md) {
          @include span(3);
        }
      }
    }
  }

  .accordion {
    border-bottom: 1px solid $color-gray-fifth;

    &__body {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__header {
      border-top: 1px solid $color-gray-fifth;
      padding: 16px 110px 16px 0;

      &.active .error {
        display: none;
      }

      @include breakpoint($brake-sm) {
        padding: 27px 110px 15px 0;
        .error {
          display: none;
        }
      }
    }

    &__elem {
      &:first-child {
        .accordion__header {
          @include breakpoint($brake-sm) {
            border-top: none;
          }
        }
      }
    }
  }
}
