.landing-content {

  /*
   -------------    -------------    -------------
  |    TEXT     |  |    IMAGE    |  |   SNIPPET   |
   -------------    -------------    -------------
  */

  $root: &;

  // tablet and up
  @include breakpoint($brake-sm) {
    &--v3 &__info {
      @include span(7);
    }

    &--v3 &__snippet {
      @include span(5);
    }
  }

  // laptop screen and up
  @include breakpoint($brake-md) {
    &--v3 &__info {
      @include span(8);
    }

    &--v3 &__copy {
      @include span(5);
      width: $large-text-width;
    }

    &--v3 &__image {
      @include span(7);
      width: $large-image-width;
    }

    &--v3 &__snippet {
      @include span(4);
    }

    &--v3 {
      #{$root}__snippet--Z #{$root}__snippet-inner,
      #{$root}__snippet--S #{$root}__snippet-inner,
      #{$root}__snippet--G #{$root}__snippet-inner {
        margin-top: -30px !important;
      }
    }
  }

}
