.form {

  $root: &;

  &--sidebar-filters {
    //
    // scss-lint:disable QualifyingElement
    label.input-radio {
      @extend %text-default-2;
      margin: 0 0 5px;
      display: block;
      padding-left: 32px;
      &::before {
        width: 21px;
        height: 21px;
        margin-top: -11px;
      }
      &::after {
        left: 6px;
      }
    }
    label.input-checkbox {
      @extend %text-default-2;
      margin: 0 0 5px;
      display: block;
      padding-left: 32px;
      &::before {
        width: 21px;
        height: 21px;
      }
      &::after {
        left: -5px;
      }
    }

    .note {
      @extend %text-default-3;
      margin-top: 20px;
    }

    #{$root}__filter-fieldset {
      margin: 0;
      padding: 0 0 16px;
      border: none;

      @include breakpoint($brake-sm) {
        padding: 0 0 26px;
      }
    }
    @include breakpoint($brake-sm) {
      label.input-radio {
        margin: 0 0 8px;
      }
    }
    .form__title {
      margin: 0 0 8px;

      @include breakpoint($brake-sm) {
        margin: -5px 0 22px;
      }
    }
    // scss-lint:enable QualifyingElement
  }

  &--filters {
    &-switchable {
      .form__sidebar-filters-mobile {
        display: block;

        @include breakpoint($brake-sm) {
          display: none;
        }
      }

      .form__filter-radio-inputs {
        display: none;

        @include breakpoint($brake-sm) {
          display: block;
        }
      }
    }
  }

  .form__filter-button-container {
    margin: 4px 0 30px 0;
  }
}

.form__filter-check-wrap {
  display: block;
  margin-bottom: 4px;
}

.filters-wrapper-sort {
  @extend %paddington;
  @include breakpoint($brake-sm-max) {
    display: block;
    padding: 30px 0 20px;
  }
}
