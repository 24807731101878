.calc-combo-LP {
  @import "calc-combo/calc-badges";
  @import "calc-combo/calc-bar";
  @import "calc-combo/calc-carousel";
  @import "calc-combo/calc-form";
  @import "calc-combo/calc-header";
  @import "calc-combo/calc-options";
  @import "calc-combo/calc-results";
  @import "calc-combo/calc-sliders";
  @import "calc-combo/calc-summary";
  @import "calc-combo/calc-tooltip";

  // taki dziwny zamysł, by podłożyć tło pod kalkulator na wersji LP
  // poprzez ustawienie w snippecie, a nie w edycji strony
  padding: 1px 15px;
  @include breakpoint($brake-md) {
    padding-right: 30px;
    padding-left: 30px;
    margin: 0 -30px -10px -20px;
  }

  h5 {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .calc__carousel-header--small {
    font-size: 15px;
    margin: 20px 0 5px;
  }
  .calc__carousel-installment-nav {
    vertical-align: text-bottom;
  }

  // inputy kalkulatora
  .calc-input {
    .input-text__append {
      font-size: 14px;
      line-height: 40px;
      top: 0;
      right: calc(50% - 90px);
      padding: 0;
    }
    &__header {
      font-size: 15px;
      margin-bottom: 5px;
    }
    &__error {
      font-size: 11px;
      line-height: 15px;
      color: $color-gray-main;
    }

    @include breakpoint($brake-xs-sm) {
      margin-left: 40px;

      &:first-child {
        margin-left: 0;
      }
      &__header {
        text-align: left;
      }
    }
    @include breakpoint($brake-sm) {
      margin-left: 10%;
    }
    @include breakpoint($brake-md) {
      margin-left: 28px;
    }
    @include breakpoint($brake-md-lg) {
      margin-left: 70px;
    }
  }
  .input-text__wrapper {
    text-align: center;

    .input-text {
      height: 40px;
      font-size: 19px;
      line-height: 40px;
      text-align: center;
      background-color: $color-white;
      font-weight: 400;
      border-radius: 3px;
      margin: 0;
      width: 80%;

      &--right-space {
        padding-right: 15px;
      }
    }

    @include breakpoint($brake-xs-sm) {
      text-align: left;
      .input-text {
        width: calc(100% - 30px);
        max-width: 260px;

        & + & {
          margin-left: 40px;
        }
      }
    }

    @include breakpoint($brake-sm) {
      .input-text {
        & + & {
          margin-left: 80px;
        }
      }
    }
    @include breakpoint($brake-md) {
      max-width: 150px;
    }
  }

  // inne ustawienia
  .calc__fieldset-main-container {
    flex-direction: column;
  }
  .calc__fieldset-first-subcontainer {
    padding-right: 0;
    width: 100%;
  }
  .calc-combo__sliders__row {
    margin-bottom: 10px;
  }

  .calc__results {
    width: 100%;
    border: 0 none;
    border-top: 1px solid $color-gray-fifth;
    padding: 20px 0 0;
    margin: 0;

    &-decoration {
      display: none;
    }
    &-details-container {
      margin-bottom: 0;

      @include breakpoint($brake-md) {
        flex-direction: row;
      }
    }
    &-details-label {
      font-size: 15px;

      .icon-infotip {
        top: -5px;
      }
    }
    &-details-item {
      margin-right: 70px;
      h3 {
        font-size: 28px;
        span {
          font-size: 17px;
        }
      }
      &:first-child {
        margin-bottom: 0;
      }
    }
    @include breakpoint($brake-md) {
      align-items: flex-start;
      .cta {
        width: auto;
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
}
