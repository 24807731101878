.breakline {
  @extend %paddington;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  &::after {
    background: $border-color;
    content: '';
    height: 1px;
    display: block;
    width: 100%;
  }
  &--inline {
    position: relative;
    top: 5px;
    clear: both;
  }
  &--no-padd {
    padding: 0;
  }
  &--no-mobile {
    display: none;
    @include breakpoint($brake-sm) {
      display: block;
    }
  }

  &--iko-hp,
  &--loan-installment-calc-hp {
    position: relative;
    margin-bottom: $vertical-space-mobile;

    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
    }
  }
}
