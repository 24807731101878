.random-recomendation {
  @extend %paddington;
}

.flag-en {
  $root: &;

  &--small {
    display: inline;
    @include pko-flag-svg-small;
  }

  &__red {
    transition: fill 250ms;
  }
  &__blue {
    transition: fill 250ms;
  }

  @at-root .site-nav__link:not(:hover), #{$root}--gray {
    #{$root}__red {
      fill: $flag-en-red-gray;
    }

    #{$root}__blue {
      fill: $flag-en-blue-gray;
    }
  }
}

.flag-uk, .flag-ua {
  $root: &;

  &--gray {
    #{$root}__blue {
      fill: $flag-ua-blue-gray;
    }

    #{$root}__yellow {
      fill: $flag-ua-yellow-gray;
    }
  }
}

.flag-pl {
  $root: &;

  &--gray {
    #{$root}__white {
      fill: $flag-pl-white-gray;
    }

    #{$root}__red {
      fill: $flag-pl-red-gray;
    }
  }
}

.flag-ru {
  $root: &;

  &--gray {
    #{$root}__white {
      fill: $flag-ru-white-gray;
    }

    #{$root}__blue {
      fill: $flag-ru-blue-gray;
    }

    #{$root}__red {
      fill: $flag-ru-red-gray;
    }
  }
}
