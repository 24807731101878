.slider-table {
  $root: &;
  $anim-transition: all 0.5s ease; // equal for sliderDefaults.animationTime in sliderTable.js

  &--with-border {
    border-top: 1px solid $border-color;
    padding-top: 44px;
  }

  .last-visible {
    .slider-table__arrows {
      right: 0;
    }
  }

  &__sidebar {
    float: left;
    width: 20%;

    .product-table__head:first-child .product-table__cell {
      padding-top: 0;
    }
  }

  &__frame {
    float: left;
    width: 80%;
    overflow: hidden;
    position: relative;

    &--gold-version {
      @include breakpoint($brake-sm) {
        overflow: initial;
      }
    }

    .product-table {
      border-spacing: 0;
      border-collapse: collapse;
      border: 1px solid transparent;

      .cta {
        vertical-align: bottom;
      }

      &__cell {
        border-left: 1px solid $border-color;

        &:first-child {
          padding-left: 25px;
          #{$root}__left-arrow {
            display: none;
          }
        }
        &:last-child {
          padding-right: 25px;
          #{$root}__right-arrow {
            display: none;
          }
        }
      }

      &__head {
        &:first-child {
          .product-table__cell {
            border-top: none;
            border-bottom: none;
            padding-top: 0;
          }
        }
      }

      &__footer {
        &:last-child {
          .product-table__cell {
            border-bottom: none;
          }
        }
      }

      &__row {
        &:first-child {
          .product-table__cell {
            border-top: none;
          }
        }

        &--no-border {
          .product-table__cell {
            border-bottom: none;
          }
        }

      }
    }

    &::after {
      content: '';
      height: 100%;
      width: 3px;
      background: $color-white;
      position: absolute;
      top: 0;
      display: block;
      right: 0;
    }
  }

  &__holder {
    position: relative;
    .product-table__title {
      &::before {
        content: '';
        float: right;
        display: inline-block;
        width: 45px;
        height: 20px;
      }
    }
  }

  .product-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 1px;
    border: 1px solid transparent;

    &__cell {
      width: 25%;
      transition: none;
      position: relative;
      text-align: left;

      &.to-left {
        transform: translateX(-100%);
      }
      &.to-right {
        transform: translateX(100%);
      }
      &.to-left,
      &.to-right {
        transition: $anim-transition;
      }
    }
  }

  &__arrows {
    display: none;
    position: absolute;
    right: 25px;
    top: 5px;
    font-size: 20px;
    transition: 100ms right;

    &.hidden {
      display: none;
    }

    @include breakpoint($brake-sm) {
      display: block;
    }
  }

  &__left-arrow,
  &__right-arrow {
    float: right;
    margin-top: 0;
    cursor: pointer;
    color: $color-blue;
    font-weight: bold;
    transition: $trans-hover;

    &:hover {
      color: $color-gray-main;
    }

    &::before,
    &::after {
      transition: $trans-hover;
    }
  }

  &__right-arrow {
    margin-right: -3px;
    margin-left: 10px;
  }

  &-mobile {
    @extend %break-from-paddington;
    padding: 0 5px;
    position: relative;

    .slider-table-mobile__cell.first-visible,
    .last-visible + .slider-table-mobile__cell {
      border-left: 1px solid transparent;
    }

    table {
      border-collapse: collapse;
    }

    &::after,
    &::before {
      content: '';
      height: 100%;
      width: 3px;
      background: $color-white;
      position: absolute;
      top: 0;
      display: block;
      @include pko-z-index(below);
    }

    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }

    @include breakpoint($brake-sm) {
      display: none;
    }

    &__slideable-row {
      overflow: hidden;
      table {
        position: relative;
        box-sizing: content-box;
        table-layout: fixed;
        border: 1px solid transparent;
      }
      td {
        @extend %text-default-2;
        padding: 10px;
        position: relative;
        width: 100px; // iphone td width fix.
      }
    }
    &__slideable-footer-row {
      .slider-table-mobile__cell {
        padding-bottom: 0;

        .cta {
          vertical-align: bottom;
        }

        *:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__header-row {
      &.fixed {
        padding: 0 20px;
        @include pko-z-index(default);
        position: fixed;
        top: 103px;
        width: 100%;
        left: 0;
        background: $color-gray-sixth;
        border-bottom: 1px solid $border-color;

        &::after,
        &::before {
          content: '';
          height: 100%;
          width: 25px;
          background: $color-gray-sixth;
          position: absolute;
          top: 0;
          display: block;
        }

        &::before {
          left: 0;
        }
        &::after {
          right: 0;
        }
      }
    }
    &__header-row &__cell {
      @extend %h4;
    }
    &__fixed-row {
      @extend %text-default-2;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      padding: 5px 0;
      margin: 0 11px;
      color: $color-black;

      &--gold-version {
        margin: 0;
      }

      &:empty {
        padding: 0;
        border: none;
        border-bottom: 1px solid $border-color;
      }
    }
    &__cell {
      vertical-align: top;
      //width: 100px;

      &:not(:first-child) {
        border-left: 1px solid $border-color;
      }
    }
    &__header-row:not(.fixed) &__cell {
      padding-top: 0;
    }

    &__header-row--gold-version:not(.fixed) &__cell {
      padding-top: 10px;
    }
  }

}

// https://redmine.k2.pl/issues/106070
// /klienci-indywidualni/konta/konta-osobiste/porownanie-wielu-kont/
.compare-accounts-table {
  $goldBorderColor: #ac8121;
  $goldBorder: 2px solid $goldBorderColor;

  &__head {
    border-bottom: 1px solid $color-gray-fifth;
  }

  &__cell {
    &--align-center,
    .compare-accounts-table &--align-center { // Zwiększenie Specificity
      text-align: center;
    }

    &--vertical-middle {
      vertical-align: middle;

      padding-top: 5px;
      padding-bottom: 5px;

      @include pko-font-size(13);
    }

    &--vertical-top {
      vertical-align: top;
    }

    &--vertical-bottom {
      vertical-align: bottom;
    }

    &--small-padding-horizontal,
    .compare-accounts-table &--small-padding-horizontal:first-child { // Zwiększenie Specificity
      padding-left: 10px;
      padding-right: 10px;
    }

    &--small-padding-right {
      padding-right: 10px;
    }

    &--without-border-bottom {
      border-bottom: none;
    }

    &--border-top-invisible {
      border-top: 2px solid transparent;
    }

    .compare-accounts-table .slider-table-mobile &--gold-border {
      border: 1px solid $goldBorderColor;
      padding: 10px;
      position: absolute;
      height: calc(100% - 20px);
      width: calc(100% - 21px);
      left: -1px;
      top: -1px;
    }

    .compare-accounts-table .slider-table-mobile__slideable-row.fixed &--gold-border {
      border-color: $border-color;
      border-bottom: none;
      border-right: none;
    }

    .compare-accounts-table .slider-table__frame .product-table__head:first-child &--gold-border-without-bottom {
      border: $goldBorder;
      border-bottom: none;
    }

    .compare-accounts-table .slider-table__frame &--gold-border-left-right {
      border-right: $goldBorder;
      border-left: $goldBorder;
    }

    .compare-accounts-table .slider-table__frame .product-table__footer:last-child &--gold-border-bottom {
      border-bottom: $goldBorder;
    }

    &--margin-bottom-above-xl {
      margin-bottom: 0;

      @include breakpoint(max-width $max-width) {
        margin-bottom: 20px;
      }

      @include breakpoint($brake-xl) {
        margin-bottom: 20px;
      }
    }
  }

  &__important-text {
    @include pko-font-size(17);

    &--align-right {
      text-align: right;
    }
  }

  &__important-header {
    @include pko-font-size(23);
    text-align: center;
  }

  &__cta {
    @include breakpoint($brake-sm) {
      width: 80%;
    }

    &--small-width {
      @include breakpoint($brake-sm) {
        width: 60%;
      }
    }

    &--gold {
      background-color: $goldBorderColor;
    }
  }

  .icon-table-check {
    color: $text-color;
    @include pko-font-size(22);
  }

  .icon-table-uncheck {
    color: $color-gray-third;
    @include pko-font-size(16);
  }

  .infotip-trigger {
    bottom: 5px;
  }

  &__legend {
    display: none;

    &--gold-version {
      @include breakpoint($brake-sm) {
        padding-top: $vertical-space-desktop + 35px;
      }
    }

    @include pko-font-size(14);

    @extend %paddington;
    clear: both;
    padding-top: $vertical-space-desktop;

    @include breakpoint($brake-sm) {
      display: block;
      padding-right: 0;
    }

    .icon-table-check,
    .icon-table-uncheck {
      position: relative;
      top: 5px;
    }

    .icon-table-uncheck {
      top: 2px;
      right: 4px;
    }

    .icon-table-check {
      margin-left: -6px;
    }

    p {
      margin: 0 0 5px;
    }

    small {
      font-size: 64%;
    }
  }

  &__additional-description {
    position: absolute;
    text-transform: uppercase;
    color: $goldBorderColor;
    @include pko-font-size(17);

    bottom: -55px;
    left: 0;
    right: 0;

    @include breakpoint(798px) {
      bottom: -35px;
    }

    &--fixed {
      position: relative;
      bottom: 0;
    }
  }

  &__tooltip {
    display: inline-block;

    .icon-infotip {
      @include pko-font-size(20);

      position: relative;
      top: 2px;

      cursor: pointer;
    }
  }
}

.slider-table-mobile__slideable-row ~ .compare-accounts-table__legend {
  display: block;
}
