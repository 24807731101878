
.withdrawn-products {
  .accordion--three-columns-wrap {
    padding-top: 40px;
  }
}

.silver-privatebanking {
  @extend %text-default-2;
  clear: both;
  height: 21px;
  line-height: 21px;
  padding: 0 4px;
  color: $color-white;
  display: inline-block;
  margin: 0 0 45px;
  background: $privatebanking-gradient;

  @include breakpoint($brake-sm-max) {
    // #66650
    line-height: 22px;
    margin-bottom: 2px;
  }

  .banner & {
    margin: 0 0 35px;
  }
  .banner.banner--with-tabs & {
    margin: 20px 0 10px;
  }
}

.hidden-linked-news {
  padding-top: 25px;
  @include breakpoint($brake-sm) {
    padding-top: 45px;
  }
}

.site-content__module {
  &.product-tab-description {
    margin-bottom: 0;
    padding-bottom: 0;
    p,
    ul,
    ol,
    table,
    div {
      &:first-child {
        margin-top: 0;
        padding-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &:empty {
      display: none;
      @include breakpoint($brake-sm) {
        + .accordion .accordion__elem:first-child .accordion__header {
          margin-top: -15px;
          border-top: none;
        }
      }
      @include breakpoint($brake-md) {
        + .accordion .accordion__elem:first-child .accordion__header {
          margin-top: -20px;
        }
      }
    }
  }

  &.product-under-tabs {
    padding-top: $vertical-space-mobile;
    &--visual {
      padding-top: 0;
    }
    .feature-section-item {
      margin-bottom: $vertical-space-mobile;
    }
    &:last-child {
      padding-bottom: 5px;
    }
    .text-boxes__header,
    .site-content__module-header {
      margin-top: 0;
    }
  }
  @include breakpoint($brake-sm) {
    .text-boxes__header {
      margin-bottom: 36px;
    }
    &.product-under-tabs,
    &.product-under-tabs:last-child {
      padding-top: 60px;
      padding-bottom: 46px;
      .feature-section-item {
        margin-bottom: 0;
      }
    }
    &.product-under-tabs.product-under-tabs--visual {
      padding-top: 0;
    }
  }
}

@include breakpoint($brake-sm-max) {
  .product-tab-accordion.accordion {
    border-bottom: none;
    margin-bottom: -30px;
  }

  // ze względu na skalowane tabele i ich navigacje na mobilkach
  .site-content__module {
    &.product-tab-description {
      .table-wrapper-scrollable:first-child {
        margin-top: 40px;
      }
    }
  }
}

.product-menu-section {
  > .snippet-wysiwyg {
    // #66697 - nota prawna w dziwnym miejscu przez wysywyg, musi miec klasę .site-content__module
    @extend %paddington;
  }

  // formularz kontaktowy na całą stronę
  .ccform:not(.calc-loan__popup-form) {
    padding-bottom: 60px;
    
    @include breakpoint($brake-sm) {
      padding-bottom: 80px;
    }

    @include pko-form-padding;

    &.form-narrow {
      .form__row {
        padding-right: 0;
      }
    }
  }

  form:not(.calc-loan__popup-form) {
    .form__title {
      @include pko-font-size($font-size-h3-mobile);
      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-h2);
      }
    }
  }
}
