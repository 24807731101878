/*
* Styl 2 - Trzy kolumny linków
*/

.bubbles {
  $root: &;

  &--2 {
    $style: &;

    margin-bottom: 25px;

    @include breakpoint($brake-sm) {
      margin-bottom: 45px;
    }

    #{$root}__title {
      @include pko-text-size($font-size-h3-mobile, $line-height-h3-mobile, $font-size-h3, $line-height-h3);
      margin-bottom: 40px;
      color: $text-color-head;
    }

    #{$root}__bubbles-container {
      display: none;
    }

    #{$root}__content {
      display: block;
    }

    #{$root}__description {
      margin-bottom: 45px;
      color: $text-color;
    }

    #{$root}__articles {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;

      @extend %break-from-paddington;

      @include breakpoint($brake-sm) {
        margin-left: 0;
        margin-right: 0;
      }

      @include breakpoint($brake-sm) {
        flex-wrap: wrap;
        justify-content: center;
        overflow-x: hidden;
      }
    }

    #{$root}__article {
      position: relative;
      display: inline-block;
      padding-top: 106px;
      min-width: 60vw;

      @include breakpoint($brake-xs-sm) {
        min-width: 40vw;
      }

      @include breakpoint($brake-sm) {
        width: 33.333333%;
        flex: 0 0 auto;
        display: inline-block;
        margin-bottom: 50px;
        vertical-align: top;
        min-width: auto;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    #{$root}__article-image {
      position: absolute;
      top: 20px;
      left: 50%;
      width: 100px;
      height: 70px;
      text-align: center;
      transform: translateX(-50%);

      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    #{$root}__article-title {
      @include pko-text-size($font-size-t1-mobile, $line-height-t1-mobile, $font-size-t1, $line-height-t1);
      color: $text-color-head;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;

      @include breakpoint($brake-sm) {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    #{$root}__article-description {
      @include pko-text-size($font-size-t2-mobile, $line-height-t2-mobile, $font-size-t2, $line-height-t2);
      color: $text-color;
      text-align: center;
    }
  }
}