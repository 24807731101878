// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix,
.cf {
  @extend %pko-clearfix;
}

.hidden {
  display: none;
}

.hide-on-mobile {
  @include breakpoint($brake-sm-max) {
    display: none;
  }
}

.hide-on-desktop {
  @include breakpoint($brake-sm) {
    display: none;
  }
}

.hide-after-767-px{
  @media screen and (min-width: 768px){
    display:none;
  }
}

.show-on-android {
  display: none;
}
.hide-on-android {
  display: none;
}

.display {
  &-inline-block {
    display: inline-block;
  }
  &-flex{
    display:flex;
  }
}

.clear {
  &-left {
    clear: left;
  }
  &-right {
    clear: right;
  }
  &-both {
    clear: both;
  }
}

.float {
  &-left {
    float: left;
  }
  &-right {
    float: right;
  }
  &-none {
    float: none;
  }
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0 !important;
  position: absolute;
  width: 1px;
}

.documentation-paddington-helper {
  @extend %paddington;
  background: $red-with-opacity;
}

.documentation-block-helper {
  padding-top: 10px;
  padding-bottom: 10px;
  background: $red-with-opacity;
  color: $color-white;
}

.documentation-break-from-paddington-helper {
  @extend %break-from-paddington;
}

.documentation-text-item-helper {
  @include pko-text-item;
}

.no {
  &wrap {
    white-space: nowrap;
  }

  &-border {
    border: 0;
  }
}

.border-left {
  border-left: 1px solid $border-color;
}

.position {
  &-relative {
    position: relative;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.min {
  &-height {
    &-128 {
      min-height: 128px;
    }
    &-256 {
      min-height: 256px;
    }
    &-512 {
      min-height: 512px;
    }
  }
}

.react {
  &-hidden {
    transition: opacity 250ms, visibility 250ms;
    opacity: 0;
    visibility: hidden;

    &--show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.pointer-events {
  &-none {
    pointer-events: none;
  }
}

.text {
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }

  &--black {
    color: $color-black;
  }

  &--red {
    color: $color-red;
  }
  &--gray {
    color: $color-gray-second;
  }

  &--bold {
    font-weight: bold;
  }
  &--small {
    @extend small;
  }
}
