.rating {
  $root: &;

  @media (max-width: #{$brake-sm - 0.01em}) {
    .mobile-button + & {
      padding-top: 25px;
    }
  }

  &__box {
    & > & {
      @extend %paddington;
      @include span(12);
    }
  }
  &__box--chart {
    display: none;
  }
  &__quotations {
    display: none;
  }
  &__header {
    margin: 0;
    color: $color-black;
  }
  &__date {
    @extend %paddington;
  }
  &__list {
    @include pko-text-size($font-size-t2-mobile, 22, $font-size-t2, 25);
    @extend %pko-clearfix;
    margin: 10px 0;

    &--no-data-title {
      @extend %text-default-1;
      color: $color-red;
      margin-top: -40px;
    }

    &-row {
      //@extend %pko-clearfix;
      color: $color-black;
      position: relative;
      display: flex;

      @include pko-rate-arrows;

      &--head {
        background: none;
        color: $text-color;
      }

      > span {
        display: inline-block;
        text-align: right;

        &:nth-child(1) {
          @include span(4);
          text-align: left;
        }
        &:nth-child(2) {
          @include span(2);
        }
        &:nth-child(3) {
          @include span(3);
          margin-left: 20px;
        }
      }
    }
  }

  .chart {
    position: relative;
    @extend %break-from-sidebar-paddington;
    &__empty {
      @extend %paddington;
    }
    &__empty-title {
      @extend %paddington;
      @extend %h3;
      color: $color-black;
      margin-top: -50px;
    }
  }

  @include breakpoint($brake-sm) {
    padding-bottom: $vertical-space-desktop;

    &__quotations {
      display: block;
    }
    &__box {
      @include span(6);
      & > & {
        @include span(12);
      }
    }
    &__list {
      margin: 55px 0 20px;
      position: relative;

      &-row {
        &--up::before,
        &--down::before {
          right: 40px;
        }

        &--head {
          position: absolute;
          bottom: 100%;
          margin-bottom: 10px;
          right: 0;
          width: 100%;
        }

        > span {
          display: inline-block;
          text-align: right;

          &:nth-child(1),
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            float: none;
          }
        }
      }
    }

    .chart {
      margin-right: 0;
      height: 250px;
      margin-top: 50px;
      &__empty {
        color: $color-red;
        margin-top: 30px;
      }
    }
  }

  @include breakpoint($brake-md) {
    &__box {
      @include span(4);
      &--chart {
        display: block;
        @include span(8);
      }
      &--chart > & {
        @include span(6);
      }
    }
  }

}
