// -----------------------------------------------------------------------------
// breakpoints
// -----------------------------------------------------------------------------

// todo refactor name brake -> break
// main
$brake-xs: pko-em-size(0); // 0em
$brake-sm: pko-em-size(768); // 48em
$brake-sm-max: max-width pko-em-size(767); // max-width 47.9375em
$brake-md: pko-em-size(1024); // 64em
$brake-md-max: max-width pko-em-size(1023); // max-width 63.9375em
$brake-lg: pko-em-size(1280); // 80em
$brake-xl: pko-em-size(1440); // 90em

// todo refactor name brake -> break
// additional
$brake-xxs-max: max-width pko-em-size(375);
$brake-xs-sm: pko-em-size(480); // 30em
$brake-md-lg: pko-em-size(1120); // 70em
$brake-lg-xl: pko-em-size(1340); // 83.75em - potrzebuje tego scrollie

// -----------------------------------------------------------------------------
// susy config
// -----------------------------------------------------------------------------

$susy: (
  global-box-sizing: border-box,
  columns: 12,
  gutters: 1,
  math: fluid,
  output: float,
  gutter-position: inside,
  gutter-override: no-gutter
);

// -----------------------------------------------------------------------------
// max-width
// -----------------------------------------------------------------------------

$max-width: 1242px;

// -----------------------------------------------------------------------------
// paddings for grid
// -----------------------------------------------------------------------------

$padding-xs: (30 / 2) * 1px;

$padding-sm: (25 / 2) * 1px;

$padding-md: (30 / 2) * 1px;

$padding-md-lg: (40 / 2) * 1px;

$padding-lg: (44 / 2) * 1px;

//$padding-xl: (50 / 2) * 1px;

$vertical-space-desktop: 50px;
$vertical-space-mobile: 30px;

// -----------------------------------------------------------------------------
// colors - podstawowe
// -----------------------------------------------------------------------------

$color-black: #000;
$color-white: #fff;
$color-transparent: transparent;

// cta
$color-red: #e4202c;
$color-pink: #fef3f4;
$color-orange: #db912c;
$color-orange-dark: #e84a06;

// error
$color-error-border: rgba($color-red, 0.3);
$color-error-background: $color-pink;

// error na niebieskim tle
$color-error-special: #f27f8a;

// link, cta:hover
$color-blue: #004c9a;
$color-blue-light: #e6f8fa;
$color-blue-light2: $color-blue-light; // zły merge

// text
$color-gray-main: #585858;

// footer-header, poi facility text
$color-gray-second: #8d8d8d;

// scrollie gray
$scrollie-gray: #918f8f;

// ikony szare
$color-gray-third: #bababa;

// tło przycisku ios app store
$color-black-ios: #070909;

// todo potrzebny - radio, checkbox, input
//$color-gray-fourth: #bdbdbd; wypadł - zmieniony na gray-third

// accordion, ...
$color-gray-fifth: #dedede;

$color-gray-middle: #676767;
$color-gray-sixth: #f9f9f9;
$color-gray-seventh: #dadada;
$color-gray-eight: #f0f0f0;

$color-gray-icon: #666;
$color-green: #689a19;

$color-gray-disabled: $color-gray-sixth;
$color-gray-readonly: $color-gray-sixth;
$hotpink: #ff69bf;

$color-light-blue: #5897fb;
$color-mid-blue: #0069dc;
$color-dark-blue: #003574;

// lp
$gold-dark: #ae8326;
$gold-light: #e2c674;
$lp-gradient-blue-1: #004c9a;
$lp-gradient-blue-2: #070016;

// card-generator
$color-gray-background: #FAFAFA;
$color-gray-track: #D5D5D5;

// wifi
$color-gray-wifi: #c0c0c0;

// callmebutton border
$color-gray-callme: #cecece;

//private banking
$private-bank-stop1: #d0d7da;
$private-bank-stop2: #686c70;
$color-gold: #c8941e;

$privatebanking-gradient: linear-gradient(to right, $private-bank-stop1 0%, $private-bank-stop2 100%);

//career (for snippets)
$color-red-career: #CA231B;
$color-gray-athens: #f1f1f2;
$color-gray-iron: #dbdcde;
$color-gray-alto: #dedede;
$color-gray-boulder: #747474;
$color-blue-anakiwa: #96ccfc;
$color-blue-congress: #054c8e;
$color-yellow-salomie: #fad384;

$transition-bubbles: 0.5s;

//colors with opacity
$full-opacity: rgba($color-white, 0);
$red-with-opacity: rgba(255, 0, 0, 0.3);
$black-shadow-with-opacity: rgba($color-black, 0.05);

//properties
$property-gradient-red-1: #e3202c;
$property-gradient-red-2: #7a202c;
$property-gradient-grey-1: #f1f1f1;
$property-gradient-grey-2: #e4e4e4;
$transparent: rgba($color-white, 0);

// flag-en
$flag-en-blue-gray-pre: #00247d;
$flag-en-blue-gray: grayscale($flag-en-blue-gray-pre);
$flag-en-red-gray-pre: #cf142b;
$flag-en-red-gray: grayscale($flag-en-red-gray-pre);

// flag ua/uk
$flag-ua-blue-gray-pre: #005bbb;
$flag-ua-blue-gray: grayscale($flag-ua-blue-gray-pre);
$flag-ua-yellow-gray-pre: #ffd500;
$flag-ua-yellow-gray: grayscale($flag-ua-yellow-gray-pre);

// flag pl
$flag-pl-white-gray-pre: #f0f0f0;
$flag-pl-white-gray: grayscale($flag-pl-white-gray-pre);
$flag-pl-red-gray-pre: #d4213d;
$flag-pl-red-gray: grayscale($flag-pl-red-gray-pre);

// flag ru
$flag-ru-white-gray-pre: #ffffff;
$flag-ru-white-gray: grayscale($flag-ru-white-gray-pre);
$flag-ru-blue-gray-pre: #0039a6;
$flag-ru-blue-gray: grayscale($flag-ru-blue-gray-pre);
$flag-ru-red-gray-pre: #d52b1e;
$flag-ru-red-gray: grayscale($flag-ru-red-gray-pre);

// -----------------------------------------------------------------------------
// colors - tutaj tworzymy nazwy dla popularnych waznych,
// na posdstawie tych wyzej, nie przesadzamy
// -----------------------------------------------------------------------------

$text-color: $color-gray-main;
$text-color-head: $color-black;
$text-color-bold: $color-black;
$link-color: $color-blue;
$link-color-hover: $color-black;
$icon-color: $color-blue;
$input-icon-color: $color-blue;
$bg-color: $color-white;
$overlay-color: $color-white;
$border-color: $color-gray-fifth;
$border-color-hover: $color-blue;
$color-blind: $color-transparent;
$button-red-background-color: $color-red;
$arrow-color: $color-dark-blue;

// -----------------------------------------------------------------------------
// base url for assets
// -----------------------------------------------------------------------------

$base-url: '../' !default;

// -----------------------------------------------------------------------------
// transition
// -----------------------------------------------------------------------------

$trans-hover: 250ms;

// -----------------------------------------------------------------------------
// font size and line-height
// -----------------------------------------------------------------------------

$font-size-h1-mobile: 36;
$font-size-h2-mobile: 26;
$font-size-h3-mobile: 21;
$font-size-h4-mobile: 18;

$line-height-h1-mobile: 40;
$line-height-h2-mobile: 31;
$line-height-h3-mobile: 26;
$line-height-h4-mobile: 23;

$font-size-h1: 48;
$font-size-h2: 32;
$font-size-h3: 24;
$font-size-h4: 20;

$line-height-h1: 54;
$line-height-h2: 38;
$line-height-h3: 30;
$line-height-h4: 26;

$font-size-t1-mobile: 15;
$font-size-t2-mobile: 13;
$font-size-t3-mobile: 11;

$line-height-t1-mobile: 20;
$line-height-t2-mobile: 18;
$line-height-t3-mobile: 16;

$font-size-t1: 17;
$font-size-t2: 15;
$font-size-t3: 13;
$font-size-t4: 11;

$line-height-t1: 24;
$line-height-t2: 21;
$line-height-t3: 18;
$line-height-t4: 16;

// -----------------------------------------------------------------------------
// font stack
// -----------------------------------------------------------------------------

$text-font-stack: pkobp, Tahoma, sans-serif;
$icon-font-stack: icomoon;
$text-font-stack-ukr: 'FF Dax Pro', pkobp, Tahoma, sans-serif;

// -----------------------------------------------------------------------------
// ikony todo - ile ich bedzie, czcionka tylko wybrane
// -----------------------------------------------------------------------------

$icon-blockchain: "\e900";
$icon-blockchain-full: "\e901";
$icon-sign: "\e932";
$icon-exclamation-badge: "\e931";
$icon-mail-alt: "\e930";
$icon-mail: "\e92d";
$icon-nk: "\e92e";
$icon-star: "\e92f";
$icon-iko-logo: "\e927";
$icon-umbrella: "\e90f";
$icon-compare: "\e907";
$icon-play: "\e90e";
$icon-goldenline: "\e928";
$icon-linkedin: "\e929";
$icon-file-zip: "\e92a";
$icon-file-xls: "\e92b";
$icon-file-png: "\e93c";
$icon-file-jpg: "\e93b";
$icon-file-jpeg: "\e93b";
$icon-file-default: "\e92c";
$icon-print: "\e902";
$icon-phone: "\e926";
$icon-reload: "\e90d";
$icon-handicapped: "\e906";
$icon-block: "\e910";
$icon-check2: "\e911";
$icon-file-doc: "\e912";
$icon-home: "\e913";
$icon-infotip: "\e914";
$icon-calendar: "\e915";
$icon-localize: "\e916";
$icon-localize-2: "\e917";
$icon-file-pdf: "\e918";
$icon-map-pin: "\e919";
$icon-drop-down: "\e91a";
$icon-drop-up: "\e91b";
$icon-arrow-left: "\e91c";
$icon-arrow-right: "\e91d";
$icon-arrow-top: "\e91e";
$icon-arrow-down: "\e91f";
$icon-arrow-double: "\e920";
$icon-arrow-left-small: "\e921";
$icon-arrow-right-small: "\e922";
$icon-chart: "\e923";
$icon-file-xml: "\e924";
$icon-close: "\e925";
$icon-padlock: "\e903";
$icon-menu: "\e904";
$icon-minus: "\e905";
$icon-plus: "\e908";
$icon-search: "\e909";
$icon-spinner6: "\e90a";
$icon-facebook: "\e90b";
$icon-instagram: "\ea92";
$icon-twitter: "\e90c";
$icon-rss: "\e933";
$icon-youtube2: "\e934";
$icon-phone-list: "\e939";
$icon-bank: "\e93a";
$icon-customer: "\e937";
$icon-sign-agreement: "\e936";
$icon-payment-day: "\e938";
$icon-pozyczka-iko: "\e935";
$icon-external-link: "\e93d";

// -----------------------------------------------------------------------------
// z-indexy
// -----------------------------------------------------------------------------

// @include pko-z-index(name)
$z-indexes: (
  'cosmos': 8000,
  'cookie': 6000,
  'modal': 777,
  'smart-banner': 700,
  'call-me-btn-mobile': 700,
  'ajax-overlay-top': 666,
  'call-me-btn': 660,
  'warning': 200,
  'fixed-paginator': 100,
  'card-carousel-layer': 99,
  'product-menu': 10,
  'hamburger': 9,
  'actionsnav': 8,
  'stickynav': 7,
  'sitenav': 6,
  'menu-overlay': 5,
  'iko-float-box': 4,
  'sticky-table-nav': 4,
  'ajax-overlay-default': 3,
  'banner': 2,
  'select-dropdown': 1,
  'default': 1,
  'zero' : 0,
  'below': -1,
);

// -----------------------------------------------------------------------------
// others
// -----------------------------------------------------------------------------

$overlay-parent-class: '.has-overlay';
