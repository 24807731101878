/* moduł zdarzeń korporacyjnych */

#js-corporate-events {
  .corporate-events__form {

    // general
    .display-flex {
      align-items: center;
    }

    .input-checkbox.hidden {
      display: none;
    }

    .form__row {
      flex-wrap: wrap;

      .form__item {
        &.display-on-smaller-screens {
          display: none;
        }
      }
    }

    // by arrangement
    & > .form__row:first-of-type { // row of input, dates and submit
      justify-content: space-between;

      & > .form__item:first-of-type { // item of input, dates
        & > .form__row {
          margin: 0;

          & > .form__item:nth-of-type(1) { // item of input
            margin-right: 15px;

            input#corporate-events__text-input {
              max-width: 200px;
            }
          }

          & > .form__item:nth-of-type(2) { // item of dates
            margin-right: 15px;

            .datepicker-wrapper {
              flex-wrap: wrap;

              .datepicker-wrap {
                margin-left: 3px;
              }
            }
          }
        }
      }
    }

    & > .form__row:nth-of-type(2) { // row of checkboxes and select
      margin-top: 25px;
      justify-content: space-between;

      & > .form__item:first-of-type { // item of checkboxes
        flex-wrap: nowrap;
        margin-right: 15px;
        margin-bottom: 8px;

        input[type='checkbox'] {
          visibility: hidden;
        }
      }

      & > .form__item:nth-of-type(2) { // item of select
        flex-wrap: nowrap;

        .filters-wrapper-simple, .filter {
          margin-bottom: 0;
          padding: 0;
          align-items: center;

          .select2-selection--single, .select2-selection__rendered {
            background: transparent;
          }

          .select2-selection--single {
            padding-right: 20px;
          }

          .select2-selection__arrow {
            width: 20px;
          }

          label, .select2-selection__rendered {
            font-size: inherit
          }

          label {
            color: #585858
          }
        }
      }
    }

    // media queries

    @media screen and (max-width: 520px) {

      & > .form__row:first-of-type { // change input width and margins
        & > .form__item:first-of-type {
          & > .form__row {
            & > .form__item:nth-of-type(1) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 5px;

              input#corporate-events__text-input {
                max-width: none;
              }
            }
          }
        }
      }

      .dont-display-on-smaller-screens {
        display: none !important;
      }
      .display-on-smaller-screens {
        display: flex !important;
      }

    }

    @media screen and (min-width: 768px) and (max-width: 950px) {

      & > .form__row:first-of-type { // change input width and margins
        & > .form__item:first-of-type {
          & > .form__row {
            & > .form__item:nth-of-type(1) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 5px;

              input#corporate-events__text-input {
                max-width: none;
              }
            }
          }
        }
      }

      .dont-display-on-smaller-screens {
        display: none !important;
      }
      .display-on-smaller-screens {
        display: flex !important;
      }
    }
  }

  table.corporate-events__table {

    td.corporate-events__toggle-btn {
      cursor: pointer;

      .icon-arrow {
        color: $color-dark-blue;
        font-weight: bold;
      }
    }

    tr.expanded-parent {
      border: 2px solid $color-gray-fifth;
      border-bottom: none;
      background: #f5f5f5
    }

    tr.expanded {
      border: 2px solid $color-gray-fifth;
      border-top: none;
      background: #f5f5f5;

      table {
        background: #fff;
      }
    }

    td {
      word-break: break-word;

      .icon-external-link {
        font-size: 24px;
        color: $color-dark-blue;
      }
    }

    @media screen and (max-width: 767px) {
      td.corporate-events__toggle-btn {
        display: none;
      }
    }

    @media screen and (min-width: 768px) {
      .td-link {
        max-width: 300px;
      }
    }
  }
}