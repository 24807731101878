/* stylelint-disable */
/* Ion.RangeSlider
// css version 2.0.3
// © 2013-2014 Denis Ineshin | IonDen.com
// ===================================================================================================================*/

/* =====================================================================================================================
// RangeSlider */

.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-line-left, .irs-line-mid, .irs-line-right {
  position: absolute;
  display: block;
  top: 0;
}

.irs-line-left {
  left: 0;
  width: 11%;
}

.irs-line-mid {
  left: 9%;
  width: 82%;
}

.irs-line-right {
  right: 0;
  width: 11%;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-bar-edge {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-slider {
  position: absolute;
  display: block;
  cursor: default;
  z-index: 1;
}

.irs-slider.single {

}

.irs-slider.from {

}

.irs-slider.to {

}

.irs-slider.type_last {
  z-index: 2;
}

.irs-min {
  position: absolute;
  display: block;
  left: 0;
  cursor: default;
}

.irs-max {
  position: absolute;
  display: block;
  right: 0;
  cursor: default;
}

.irs-from, .irs-to, .irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: -33px;
  left: 0;
  width: 24px;
  height: 24px;
  margin-left: -11px;
  background: #e1e4e9;
  border-radius: 50%;
  pointer-events: none;

  @include breakpoint($brake-sm) {
    top: -25px;
  }
}

.irs-grid-pol.small {
  display: none;
  height: 4px;
}

.irs-grid-pol.active {
  background: #004c9a;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 13px;
  line-height: 13px;
  padding: 0 3px;
  color: #8d8d8d;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0.0);
  z-index: 2;
}

.irs-disabled {
  opacity: 0.4;
}

.lt-ie9 .irs-disabled {
  filter: alpha(opacity=40);
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

/* Ion.RangeSlider, Flat UI Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
  //background: url(../img/sprite-skin-flat.png) repeat-x;
}

.irs {
  height: 40px;
}

.irs-with-grid {
  height: 60px;
}

.irs-line {
  height: 3px;
  top: 25px;
  background: $color-gray-fifth;
  overflow: visible;
}

.irs-line-left {
  height: 3px;
  background-position: 0 -30px;
}

.irs-line-mid {
  height: 3px;
  background-position: 0 0;
}

.irs-line-right {
  height: 3px;
  background-position: 100% -30px;
}

.irs-bar {
  height: 3px;
  top: 25px;
  background-position: 0 -60px;
  background: $color-blue;
}

.irs-bar-edge {
  top: 25px;
  height: 3px;
  width: 12px;
  background: $color-blue;
}

.irs-shadow {
  height: 3px;
  top: 34px;
  background: #000;
  opacity: 0.25;
}

.lt-ie9 .irs-shadow {
  filter: alpha(opacity=25);
}

.irs-slider {
  width: 24px;
  height: 24px;
  top: 15px;
  background: $color-blue;
  border-radius: 50%;

  &:before {
    display: block;
    cursor: pointer;
    transition: transform $trans-hover ease;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $color-blue;
    border-radius: 50%;
    content: '';
  }
}

//.irs-slider.state_hover, .irs-slider:hover {
//  background-position: 0 -150px;
//}

.irs-min, .irs-max {
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: #e1e4e9;
  //-moz-border-radius: 4px;
  border-radius: 4px;
}

.irs-from, .irs-to, .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background: #ed5565;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.irs-from:after, .irs-to:after, .irs-single:after {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ed5565;
}

.irs-grid-pol {
  background: #e1e4e9;
}

.irs-grid-text {
  color: #999;
}

//.irs-disabled {
//}

.irs-single,
.irs-min,
.irs-max {
  display: none;
}

.no-touch .irs-slider {
  &:hover,
  &:active,
  &:focus {
    &:before {
      transform: scale(1.5);
    }
  }
}


.irs-value {
  &.input-text {
    padding: 3px 5px 0 0;
    display: inline-block;
    height: 31px;
    text-align: right;
  }

  &:before {
    content: attr(data-unit);
    float: right;
    line-height: 28px;
  }

  input {
    font-size: 19px;
    float: right;
    text-align: right;
    margin-right: 5px;
    border: none;
    color: $color-black;
    font-weight: bold;
    background: transparent;
    @include breakpoint($brake-sm-max) {
      line-height: 1.4;
      height: 26px;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.irs-block {
  font-size: 28px;
  color: $color-gray-third;
  cursor: pointer;
  &.active {
    color: $color-red;
  }
}

@media (min-width: 768px) {
  .irs-value {
    &.input-text {
      padding-right: 15px;
      height: 48px;
    }
    &:before {
      line-height: 54px;
      font-size: #{$font-size-t1}px;
    }
    input {
      font-size: #{$font-size-h2}px;
    }
  }
  .irs-block {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .irs-line,
  .irs-bar,
  .irs-bar-edge {
    top: 18px;
  }
  .irs-slider {
    top: 7px;
  }
}

/*doc
---
title: Range slider
name: range-slider
category: Modules
---

```<span class="icon-block irs-block js-slider-block" data-slider="repayment-time-slider"></span>```<br/>
```<span class="js-slider-value input-text irs-value" data-unit="mc" data-slider="repayment-time-slider"><input type="text"/></span>```<br/>
```<input type="text" class="js-slider-range" id="repayment-time-slider" ```<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;```name="repayment-time-slider" value="100" data-min="10" data-max="1000"/>```

<span class="icon-block irs-block js-slider-block" data-slider="repayment-time-slider"></span>
<span class="js-slider-value input-text irs-value" data-unit="mc" data-slider="repayment-time-slider"><input type="text"/></span>
<input type="text" class="js-slider-range" id="repayment-time-slider" name="repayment-time-slider" value="100" data-min="10" data-max="1000"/>

<p>Do inputa <em>.js-slider-range</em> nalezy dodac opcje zwiazane z zakresem slidera za pomoca atrybutów <em>data-min, data-max, value.</em></p>
<p>W kontenerze <em>.js-slider-value</em> wyswietla sie wartosc slidera z jednostka podana w atrybucie <em>data-unit.</em></p>

*/
