.calc {
  $calc: &;

  &-loan--full-width {
    $calc-irs-class: '.irs';
    $icomoon-font: icomoon;
    $color-results-background: #f2f2f2;
    $color-border-slider: #bcbcbc;

    #{$calc}__header {
      h2 {
        margin: 30px 0;
        @include pko-font-size(21);
        font-weight: bold;

        @include breakpoint($brake-md) {
          @include pko-font-size(24);
          margin-bottom: 40px;
        }
      }
    }

    #{$calc}__fieldset-main-container {
      position: relative;
      @include breakpoint($brake-md) {
        flex-direction: row;
      }
    }

    #{$calc}__badges {
      &-container {
        display: none;
        color: $color-black;

        @include breakpoint($brake-md) {
          display: flex;
          margin-bottom: 45px;
          flex-direction: row;
        }
      }

      &-item {
        display: flex;
        @include pko-font-size(20);

        @include breakpoint($brake-md) {
          margin-right: 65px;

          &:last-child {
            margin-right: 0;
          }
        }

        .icon-check2 {
          display: inline-block;
          color: $color-blue;
          @include pko-font-size(36);
          position: relative;
          top: -7px;
          margin-right: 10px;
        }
      }
    }

    #{$calc}__sliders {
      &-amount-labels {
        @include breakpoint($brake-md) {
          display: inline-block;
          position: absolute;
          @include pko-font-size(17);
          color: $color-gray-main;
          bottom: -25px;

          &:first-child {
            left: calc(16px + 36px);
          }

          &:last-child {
            left: initial;
            right: calc(16px + 36px);
          }
        }
      }

      &-header {
        color: $color-black;
        text-align: center;
      }
      #{$calc-irs-class}-labels-container {
        display: block;
        width: 100%;
      }

      #{$calc-irs-class}-wrapper {
        @include breakpoint($brake-sm) {
          width: 100%;
        }
      }

      #{$calc-irs-class}-wrapper-row {
        @include breakpoint($brake-sm-max) {
          display: block;
        }
      }

      #{$calc-irs-class}-slider-wrapper {
        display: inline-block;
        float: none;
        width: calc(100% - 2 * 16px - 2 * 32px);

        @include breakpoint($brake-md) {
          width: calc(100% - 2 * 16px - 2 * 36px);
        }
      }

      #{$calc-irs-class}-controls {
        width: 100%;
        bottom: 0;
        margin: 0 0 30px;
        float: none;

        @include breakpoint($brake-md) {
          margin-bottom: 40px;
        }

        #{$calc-irs-class}-value {
          display: block;
          float: none;
          width: calc(100% - 25px);
          height: 54px;
          margin: 0;
          padding: 0;
          position: relative;

          @include breakpoint($brake-md) {
            width: 266px;
            height: 64px;
            margin: 0 auto;
          }

          &::before {
            right: -25px;
            line-height: 52px;

            @include breakpoint($brake-md) {
              line-height: 62px;
              right: -45px;
            }
          }

          input {
            float: none;
            display: block;
            height: 52px;
            padding: 0;
            margin: 0;
            width: 100%;
            text-align: center;
            @include pko-font-size(22);
            line-height: 52px;

            ::-ms-clear,
            &::-ms-clear {
              display: none;
            }

            @include breakpoint($brake-md) {
              height: 62px;
              @include pko-font-size(24);
              line-height: 62px;
            }
          }
        }
      }

      #{$calc-irs-class}-value {
        width: calc(100% - 25px);
        height: 52px;

        @include breakpoint($brake-md) {
          width: 266px;
          height: 62px;
          margin: 0 auto;
        }

        &::before {
          right: -25px;
          @include pko-font-size(22);
          line-height: 52px;

          @include breakpoint($brake-md) {
            @include pko-font-size(24);
            line-height: 62px;
            right: -45px;
            padding-left: 20px;
          }
        }

        input {
          height: 52px;
          line-height: 52px;

          @include breakpoint($brake-md) {
            height: 62px;
            @include pko-font-size(24);
            line-height: 62px;
          }
        }
      }

      &-installment {
        margin: 0;

        &-slider {
          width: calc(100% - 2 * 16px - 2 * 32px);

          &-item {
            width: 48px;
            height: 48px;
            line-height: 48px;
            top: 0;
            border-radius: 3px;

            &--active {
              height: 48px;
              line-height: 48px;
              background-color: $color-blue;
              color: $color-white;
            }

            @include breakpoint($brake-md) {
              width: 100px;
              height: 64px;
              line-height: 64px;
              @include pko-font-size(22);

              &--active {
                height: 64px;
                line-height: 64px;
              }
            }
          }

          @include breakpoint($brake-md) {
            width: calc(100% - 2 * 16px - 2 * 36px);
          }
        }

        &-nav {
          border-radius: 50%;
          background-color: $color-blue;

          @include breakpoint($brake-md) {
            width: 36px;
            height: 36px;
            bottom: 13px;
          }

          &:first-child {
            margin-right: 16px;
          }

          &:last-child {
            margin-left: 16px;
          }
        }
      }
    }

    #{$calc}__options {
      margin-bottom: 36px;

      &__list {
        @extend %break-from-paddington;

        &-item {
          margin: 0;
          @include breakpoint($brake-md) {
            @include span(6);

            &:nth-child(2n-1) {
              clear: left;
            }
          }
        }
      }
    }

    #{$calc}__results {
      margin: 0 -30px;
      padding: 40px 30px 25px;
      //background-color: $color-results-background;
      border-top: 1px solid $color-gray-fifth;
      border-bottom: 1px solid $color-gray-fifth;

      &-decoration {
        width: 28.28px;
        height: #{(28.28/2)}px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -1px;
        overflow: hidden;

        @include breakpoint($brake-md) {
          right: auto;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 28.28px;
          width: #{(28.28/2)}px;
          left: -1px;
        }

        &::after {
          content: '';
          width: 20px;
          height: 20px;
          background-color: $color-white;
          border: 1px solid $color-gray-fifth;
          transform: rotate(45deg);
          display: block;
          margin: 0 auto;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 4px;

          @include breakpoint($brake-md) {
            margin: 0 auto;
            bottom: 0;
            top: 0;
            left: -19px;
          }
        }
      }

      &-show-details {
        border-bottom: 0 none;
      }

      &--hidden-rrso {
        @include breakpoint($brake-md) {
          min-height: 326px;
        }
      }

      &-show-details {
        @include pko-font-size(14);

        &::after {
          content: $icon-arrow-right;
          font-family: $icomoon-font;
          font-weight: 700;
          position: relative;
          top: 2px;
        }

        &--mobile {
          @include breakpoint($brake-md) {
            display: none;
          }
        }

        &--desktop {
          display: none;

          @include breakpoint($brake-md) {
            display: inline-block;
            margin-bottom: 36px;
            @include pko-font-size(17);
          }
        }
      }

      &-details {
        &-container {
          margin: 30px 0;
        }

        &-item {
          h3 {
            margin: 0;
            @include pko-font-size(24);
            font-weight: 700;

            @include breakpoint($brake-md) {
              @include pko-font-size(38);
            }
          }
        }

        &-label {
          @include pko-font-size(14);
          color: $color-gray-second;
        }
      }

      .cta {
        width: 100%;
        text-align: center;
        @include pko-font-size(17);
      }
      @include breakpoint($brake-sm) {
        margin: 0;
      }

      @include breakpoint($brake-md) {
        margin: 0;
        border: 1px solid $color-gray-fifth;
        padding: 40px;
        width: 32%;

        &-details {
          &-container {
            flex-direction: column;
            margin-top: 0;
          }

          &-item {
            margin-right: 0;
            &:first-child {
              margin-bottom: 30px;
            }
          }

          &-label {
            @include pko-font-size(18);
          }
        }
      }

      #{$calc}-loan__popup {
        &__details {
          &-container {
            .column {
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
            }
            @include breakpoint($brake-md) {
              display: flex;
              justify-content: space-between;

              .column {
                width: 50%;
                margin-bottom: 0;
              }
            }
          }
        }
        &__tooltip {
          right: -5px;

          .icon-infotip {
            &:before {
              font-size: 22px;
            }
          }

          @include breakpoint($brake-md) {
            top: 0;
            right: 25px;
          }
        }
      }
    }
  }
}
