.bubble {
  $root: &;
  position: relative;
  min-width: 100px;
  border-radius: 50%;
  opacity: 0.8;
  
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

  .bubbles__bubbles-container--visible & {
    transform: translate3d(-50%, -50%, 0);
    opacity: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
 
  &:before {
    content: " ";
    display: inline-block;
    width: 100%;
    padding-bottom: 100%;
  }

  &--1 {
    width: 43%;
    background: #E35A49;
    background-size: cover;
    z-index: 1;
    transition-duration: $transition-bubbles * 2;
    transform: translate3d(-50%, -35%, 0);
  }

  &--2 {
    width: 34%;
    background: #919191;
    background-size: cover;
    z-index: 1;
    transition-duration: $transition-bubbles * 2.3;
    transform: translate3d(-50%, -60%, 0);
  }

  &--3 {
    width: 47%;
    background: #295BB5;
    background-size: cover;
    transition-duration: $transition-bubbles * 2.5;
    transform: translate3d(-50%, -45%, 0);
  }

  &__text-big {
    position: absolute;
    top: 30%;
    width: 100%;
    color: $color-white;
    font-size: 6.7vw;
    text-align: center;

    @include breakpoint($brake-sm) {
      font-size: calc(7.5vw / 2);
    }

    @include breakpoint($brake-lg) {
      font-size: 50px;
    }

    #{$root}--2 & {
      top: 26%;
    }
  }

  &__text-small {
    position: absolute;
    top: 54%;
    width: 100%;
    color: $color-white;
    font-size: 3.3vw;
    text-align: center;

    @include breakpoint($brake-sm) {
      font-size: calc(3.6vw / 2);
    }

    @include breakpoint($brake-lg) {
      font-size: 24px;
    }

    #{$root}--2 & {
      top: 56%;
    }
  }
}