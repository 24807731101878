.warning {

  @extend %text-default-2;
  @extend %sidebar-paddington;
  @extend %break-from-paddington;

  position: relative;

  &:not(.visible) {
    display: none;
  }

  &__close {
    color: $color-blue;
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 32px;
    cursor: pointer;
    transition: color $trans-hover;
    @include pko-on-event() {
      color: $color-gray-main;
    }
  }

  @include breakpoint($brake-sm-max) {
    background: $color-gray-sixth;
    border-bottom: 1px solid $border-color;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 50px;
  }

  @include breakpoint($brake-sm) {
    background: $color-white;
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 25px 50px 25px 25px;
    max-width: 210px;
    margin: 0 0 0 20px;
    float: right;
    box-shadow: 3px 3px 0 $black-shadow-with-opacity;

    &-wrapper {
      @include pko-z-index('warning');
      position: fixed;
      right: 20px;
      bottom: 230px;

      &.universal {
        bottom: 280px;
      }
    }
    &__link {
      display: block;
      margin-top: 5px;
    }
    &__close {
      right: 5px;
    }
  }
  @include breakpoint($brake-sm-max) {
    &-wrapper {
      &.universal {
        padding: 0 15px;
      }
    }
  }
}


