.author {
  &__title {
    @extend %h3;
  }
  &__photo {
    border-radius: 50%;
    background: $color-gray-third;
    width: 120px;
    height: 120px;
    display: block;
  }
  &__name {
    @extend %h4;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  &__desc {
    @extend %text-default-2;
  }
  &__more {
    @extend %text-default-2;
    margin-top: 20px;
    display: block;
  }
}
