.banner {
  $root: &;

  position: relative;
  top: 0;
  padding-bottom: $vertical-space-desktop;
  min-height: 150px;
  margin-bottom: 0;

  .leasing & {
    margin-top: 10px;
  }

  .wifi & {
    margin-top: 0;
  }

  &__image-mobile {
    display: block;
    width: 90%;
    margin-bottom: -20px;
    margin-top: 10px;
    zoom: 1;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      margin-top: -20px;
      padding-bottom: 15px;
    }
  }

  &__link-mobile {
    font-size: 0.8125rem;
    display: inline-block;
    float: left;
    margin-top: 22px;
    margin-left: 14px;
  }

  &__category-title {
    margin-top: 0;
  }

  &__slide {
    @extend %paddington;
    table-layout: fixed;
  }

  .owl-dots {
    bottom: 5px;
    padding-bottom: 5px;
  }

  .icon-leaf {
    display: inline-block;
    width: auto;
    height: 1em;
    padding: 0.05em;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__disclaimer {
    color: $text-color;
    @include pko-on-event() {
      color: $color-blue;
    }
  }

  &__description {
    padding-top: 0;
    dl {
      margin: 15px 0 20px;
      dt,
      dd {
        margin: 0;
        padding: 0;
      }
      dt {
        @include pko-font-size($font-size-h2);
        clear: left;
        float: left;
        color: $color-red;
        margin: 2px 15px 0 0;
      }
      dd {
        @include pko-line-height(17, 22);
        margin-bottom: 10px;
        min-height: 43px;
      }
    }
    ul:not(.form__body) {
      margin: 10px 0;
      padding: 0 0 0 15px;
    }

    // Zwiększenie odstępu między CTA a formularzem w headerze na longpage
    // https://redmine.k2.pl/issues/105821
    &-form-container {
      margin-top: $vertical-space-mobile;

      @include breakpoint($brake-sm) {
        margin-top: $vertical-space-desktop;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__title {
    @include pko-normalcase-in-uppercase;
    a {
      color: inherit;
      @include pko-on-event() {
        color: inherit;
      }
    }

    &--recommended {
      position: relative;
      margin-top: 30px !important;

      @include breakpoint($brake-sm) {
        margin-top: 40px !important;
        .course & {
          margin-top: 0 !important;
        }
      }

      @extend %data-recommended;
    }

    &--submit {
      padding: 0;
      background: none;
      border: none;
      text-align: inherit;
      color: inherit;
      font-family: inherit;
      font: inherit;
      line-height: inherit;
      text-transform: inherit;
      cursor: pointer;
    }
  }

  @include breakpoint($brake-sm-max) {
    padding-bottom: 0;
    &.owl-loaded {
      &.banner--smaller-title {
        margin-bottom: -28px;
      }
    }

    .owl-item {
      overflow: hidden;
    }
    .calc .owl-item {
      overflow: visible;
    }

    // Dodatkowa konfiguracja --> h1TitleLow z wartością 1 powoduje dodanie klasy
    &--low-height {
      min-height: 60px;
    }
  }
  @include breakpoint($brake-sm) {
    padding-bottom: 0;
    top: -50px;
    margin-bottom: -80px;

    .wifi & {
      top: 0;
    }

    .owl-dots {
      bottom: 100px;
      text-align: left;
    }
  }
  @include breakpoint($brake-md-max) {
    hr {
      display: none;
    }
  }

  .banner {
    // This nesting is for distinction of banner slider elements (with images)
    // and header features (under slider, moved to .header-features container).
    // (Only on desktop. On mobile .banner contain all slides and they look the same way.)

    // zwiększenie specyficzności poprzez dodanie .baner, np. .banner .banner__slide

    &__slide {
      display: table;
      width: 100%;
    }
    &__image {
      img {
        display: none;
      }

      form {
        width: 100%;
        height: 100%;
      }

      &--submit {
        width: 100%;
        height: 100%;
        padding: 0;
        background: none;
        border: none;
        text-align: inherit;
        color: inherit;
        font-family: inherit;
        font: inherit;
        line-height: inherit;
        text-transform: inherit;
        cursor: pointer;
      }
    }

    &__image-wifi {
      display: block;
      width: 70%;
      margin-bottom: -20px;
      position: relative;
      zoom: 1;
      img {
        display: block;
        width: 100%;
        @include breakpoint($brake-sm) {
          display: none;
        }
      }
    }
    &__title {
      @include pko-text-size(
        $font-size-h2-mobile,
        $line-height-h2-mobile,
        $font-size-h1,
        $line-height-h1
      );
      @include pko-line-height($font-size-h2-mobile, 28);

      // #66804 - taryfa... i reszta strony
      &--small {
        @include pko-font-size($font-size-h3);
        @include pko-line-height($font-size-h3, 24);
        @include breakpoint($brake-md-lg) {
          @include pko-font-size($font-size-h2);
          @include pko-line-height($font-size-h2, 36);
        }
      }

      @include breakpoint($brake-sm-max) {
        .wifi & {
          @include pko-font-size(19);
        }
      }

      margin: 10px 0 15px;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      @include pko-z-index(default);

      &::before {
        position: absolute;
        left: 2px;
        @include breakpoint($brake-xl) {
          left: 4px; // visually align to header edge,
          // when header font-size is much bigger than pseudoelement
        }
      }
    }

    &__description {
      @include pko-text-size(15, 19.5, $font-size-t1, $line-height-t1);
      @extend %pko-clearfix;
    }

    &__button {
      @extend %cta;
      clear: left;
      float: left;
      @include breakpoint($brake-sm) {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }

    &__link {
      @include pko-font-size($font-size-t2-mobile);
      display: block;
      float: left;
      clear: left;
    }

    &__below {
      @extend %paddington;
      &-big {
        margin-top: $vertical-space-mobile;
        margin-bottom: $vertical-space-mobile;
      }
    }

    &__link,
    &__button {
      margin-top: 14px;
    }

    &__calc {
      .ccform {
        padding: $vertical-space-mobile 20px;
      }
      .calc .overlay {
        margin: -35px -15px;
        width: calc(100% + 30px);
        height: calc(100% + 81px);
      }
    }

    @include breakpoint($brake-md-max) {
      &__calc {
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 15px;
        padding-right: 15px;

        &:not(.bg-white) {
          padding-top: 1px;
        }

        .calc .overlay {
          margin: -40px -20px;
          width: calc(100% + 50px);
          height: calc(100% + 96px);
        }
      }
    }
    @include breakpoint($brake-sm) {
      &__link,
      &__button {
        margin-top: 15px;
      }
      &__text {
        @include span(4);
        float: none;
        display: table-cell;
        vertical-align: middle;
        //height: 100%; // bug fix #144694
        padding: 140px 0 $vertical-space-desktop;
        transform: translateZ(0); // fix renderowania na iOS
        will-change: transform;
        &--on-top {
          // case z produktami na LP bez headera
          .banner__title {
            padding: 60px 0;
            margin: 0;
          }
        }
      }

      &__image {
        @include pko-z-index('below');
        @include span(8);
        float: none;
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        // Bugfix 160341
        // scss-lint:disable IdSelector
        // #product & {
        //   vertical-align: top;
        // }
        // scss-lint:enable IdSelector

        img {
          display: inline-block;
          max-width: 100%;
          max-height: 80vh;
          //
          // scss-lint:disable IdSelector
          #product & {
            max-height: 87vh;
          }
          // scss-lint:enable IdSelector
          width: auto;

          // Bugfix 160341
          margin: auto;
          vertical-align: middle;
        }
      }

      &__link {
        @include pko-font-size($font-size-t2);
      }

      &__below {
        margin-top: 0;
        &-big {
          margin-top: #{$vertical-space-desktop - 5};
          margin-bottom: #{$vertical-space-desktop - 10};
        }
      }
    }
    @include breakpoint($brake-md) {
      &__below {
        &-big {
          margin-top: #{-$vertical-space-desktop - 25};
        }
      }

      &__calc {
        padding: 5px 30px 10px 20px;
        margin: 110px 150px 50px 70px;

        .ccform {
          padding: 40px 30px;
        }

        .calc .overlay {
          margin: -40px -20px;
          width: calc(100% + 50px);
          height: calc(100% + 96px);
        }
      }
    }
  }

  // variants //////////////////////////////////////////////////////

  &--short {
    // Only title, without image. /////////
    ///////////////////////////////////////

    padding-bottom: $vertical-space-mobile / 2;

    #{$root}__title {
      margin-bottom: 0;
    }

    @include breakpoint($brake-sm) {
      #{$root}__title {
        @include span(8);
        .pko &--small {
          // #66804 - dodałem więcej miejsca
          @include span(10);
        }
        float: none;
      }
    }

    @include breakpoint($brake-md) {
      min-height: 300px;
      padding-bottom: 0;

      #{$root}__text {
        padding-bottom: 40px;
        vertical-align: bottom;
        height: 300px;
      }
    }
  }

  &--static {
    // Not loaded from ads, on product pages.
    /////////////////////////////////////////

    //padding-bottom: 40px;

    #{$root}__image {
      img {
        display: block;
        max-width: 100%;
      }
    }

    @include breakpoint($brake-sm-max) {
      #{$root}__slide {
        display: flex;
        flex-direction: column;
      }
      #{$root}__image {
        order: -1;
      }

      #{$root}__calc {
        margin-top: 40px;
      }
    }

    @include breakpoint($brake-sm) {
      #{$root}__text {
        vertical-align: top;
      }
    }
  }

  &--smaller-title {
    // With injected category title and
    // smaller slide title, on category pages.
    /////////////////////////////////////////

    #{$root}__category-title {
      @extend %h2;
      text-transform: uppercase;
      @include pko-normalcase-in-uppercase;
      margin: 0;
      font-weight: bold;
    }
    #{$root}__title {
      @include pko-font-size($font-size-h3-mobile);
      text-transform: none;
      font-weight: normal;
    }

    @include breakpoint($brake-sm) {
      margin-bottom: -140px;
      #{$root}__category-title {
        @include pko-font-size($font-size-h1);
      }
      #{$root}__title {
        @include pko-font-size($font-size-h2);
      }
      #{$root}__text {
        vertical-align: top;
      }
      #{$root}__table {
        height: 100%;
        display: table;
      }
      #{$root}__cell {
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        padding-bottom: 100px;
        padding-bottom: 12vh;
      }
    }

    @include breakpoint($brake-sm-max) {
      #{$root}__cell {
        padding-bottom: 0;
      }
    }

    @include breakpoint($brake-sm) {
      #{$root}__category-title {
        white-space: nowrap;
        max-width: 30px;
        @include pko-z-index(default);
        position: relative;
      }
    }

    @include breakpoint($brake-md) {
      #{$root}__text {
        padding: 140px 0 #{$vertical-space-desktop};
      }
    }
  }

  &--with-tabs {
    // Lower bottom line because of tabs ////
    /////////////////////////////////////////
    min-height: initial;
    @include breakpoint($brake-sm) {
      //#{$root}__text {
      //  padding-bottom: 40px + 42px; // + tabs height
      //}

      + .breakline {
        bottom: -40px;
      }
    }
  }

  &--error {
    // Lower bottom line because of tabs ////
    /////////////////////////////////////////

    margin-top: 50px;

    .cta,
    &.banner .banner__button {
      margin-top: 0;
    }

    @include breakpoint($brake-sm) {
      &.banner .banner__text {
        padding-bottom: 20px;
      }
      &.banner .banner__title {
        @include pko-font-size(36);
      }
    }
    @include breakpoint($brake-md) {
      &.banner .banner__text {
        padding-bottom: 20px;
      }
      &.banner .banner__title {
        @include pko-font-size(48);
      }
    }
  }

  &--tablet-wide {
    .banner {
      &__text {
        width: 100%;
        display: block;
      }
    }
    @include breakpoint($brake-md) {
      .banner {
        &__text {
          @include span(4);
          float: none;
          display: table-cell;
        }
      }
    }
  }

  &--medium-title {
    @include breakpoint($brake-md-lg) {
      #{$root}__title {
        @include pko-font-size($font-size-h1-mobile);
      }
    }
  }

  &--with-red-text {
    // Red text before header - BIG and SMALL NEON  ////
    ///////////////////////////////////////////////////
    #{$root}__title {
      &::before {
        color: $color-red;
      }
    }
    .header-features & {
      #{$root}__title {
        &::before {
          color: $color-red;
        }
      }
    }
  }

  // full height on screen ////////////////////////////////////////////////
  // (only for <= 900px resolution) ///////////////////////////////////////

  @include breakpoint(($brake-sm) (max-height 900px) (orientation landscape)) {
    // if over 900px window height, banner__slider has calculated height by JS
    //
    // (because with autoheight display-table-based content centering don't working,
    // and we don't use absolute & transform centering because it fail when content is too high
    // and should stretch the slide height)

    &:not(#{$root}--short) {
      min-height: 80vh;

      #{$root}__slide {
        height: 80%;
      }
    }
  }

  @include breakpoint($brake-sm) {
    &__image {
      img {
        min-height: 470px;
      }
    }
  }

  // header features ////////////////////////////////////////////////////////

  .header-features & {
    @include pko-text-item;

    &__main-link {
      @include pko-on-event() {
        .banner__title {
          color: $color-blue;
        }
      }
    }
  }
  .header-features &__description {
    ul {
      margin: 0;
    }
    li {
      margin: 5px 0;
    }
  }
  .header-features &__category-title {
    @include breakpoint($brake-sm) {
      display: none;
    }
  }
  .header-features &__slide {
    @include breakpoint($brake-sm) {
      @include span(4);
    }
    margin-bottom: -5px;
  }
}

.course .banner {
  @include pko-text-item;

  &__slide {
    padding-left: 0;
    padding-right: 0;
  }
}

.smart-app-banner--show {
  .banner {
    &__image {
      img {
        //fix gdy smart banner obecny na stronie
        max-height: 70vh;
      }
    }
  }
}

/*
 * style dla nagłowka przed załadowaniem się JS
 */
.banner__category-title {
  //@extend %paddington; #65623
  margin-top: 200px;
}

.banner__content {
  color: $text-color;
  @include breakpoint($brake-sm-max) {
    p {
      margin-bottom: 13px;
    }
  }

  &--submit {
    padding: 0;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
  }
}

// ie hack
@media screen and (max-width: 767px) and (-ms-high-contrast: active),
  screen and (max-width: 767px) and (-ms-high-contrast: none) {
  .banner--static .banner__image {
    //margin: -20% 0 -30%;
    max-height: 90vw;
  }
}

/*doc
---
title: Banner
name: banner
category: Components
---

<div class="banner" >
<div class="banner__slide">
<div class="banner__text">
<h1 class="banner__title banner__title--recommended"> <a href="#">3 miesiace wakacji kredytowych</a> </h1>
<div class="banner__description text">
<p>Do 23 000 zl (wraz z kredytowanymi kosztami) bez zaswiadczen o dochodach od pracodawcy.</p>
 <a href="#" class="banner__button" title="Wiecej">Wiecej</a>
 </div>
 </div>
  <div class="banner__image">  <a href="#" tabindex="-1"> <img src="../img/duzyslider.jpg" alt=""> </a>
   </div> </div></div>

<p><strong>Warianty bannera</strong><br/>
- na stronie glównej - z zawartoscia pobierana dynamicznie z AdServera, z trzema dodatkowymi tekstowymi elementami pod spodem,<br/>
- na stronie kategorii (.banner--smaller-title) - równiez pobierany z AdServera, ale z zaincludowanym z site'a tytulem kategorii,<br/>
- na stronach produktowych z visualem (.banner--static) - zawiera opis produktu z site'a,<br/>
- na pozostalych stronach serwisu (.banner--short) - zawiera tylko tytul. </p>

<p><strong>Slider na mobile</strong><br/>
W zwiazku z tym, ze na stronach glównych i stronach kategorii banner:
<br/>a) na mobile'u ma rotowac glówny slajd równorzednie z elementami tekstowymi,<br/>
b) na desktopie sa to zupelnie inne elementy i nie ma zadnego slidera,
<br/> przy resizie tworzymy/niszczymy slider
i przemieszczamy elementy tekstowe do kontenera .header-features pod bannerem (dekstop), lub wkladamy je do bannera (mobile).
</p>

<p><strong>Skalowanie</strong><br/>
Zalozeniem ze strony designu bylo, zeby banner w miare mozliwosci skalowal sie wzgledem ekranu, aby na stronach glównych i stronach kategorii na desktopie:<br/>
a) uzytkownik widzial od razu trzy elementy spod bannera
 i nie musial w tym celu scrollowac strony (jezeli ekran nie jest niesamowicie niski),<br/>
b) natomiast nie widzial dalszej zawartosci strony (jezeli ekran nie jest niesamowicie wysoki).<br/>
Stad w CSSie media queries dotyczace min-height, relatywne jednostki w stylu vh.<br/>
</p>

<p><strong>Centrowanie</strong><br/>
Zawartosc bannera centruje sie w pionie na podstawie display: table i vertical-align: middle. <br/>Na stronach kategorii mamy
sytuacje wyjatkowa, gdzie tytul kategorii ma byc wyrównany do góry, a reszta zawartosci tekstowej wycentrowana miedzy tymze
tytulem i dolna krawedzia bannera, a w zwiazku z nawarstwieniem poprzednich regul (np. skalowaniem opisanym wyzej)
i brakiem prostej mozliwosci rozwiazania tego w CSSie - powstalo duzo kombinacji, aby taki efekt osiagnac, m.in.
zmienne, relatywne paddingi oraz funkcja centerCategoryBanner() w JS.
</p>



*/

