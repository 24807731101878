.tender {

  &__files {
    padding-top: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      padding-top: $vertical-space-desktop;
    }
  }

  .news__item,
  .pagination-wrapper,
  .filters-wrapper-simple {
    @extend %break-from-paddington;
  }

  //
  // scss-lint:disable IdSelector
  #tender-mobile-filters {
    padding-top: $vertical-space-mobile;
  }
  // scss-lint:enabled IdSelector

  &__mobile-filters {
    margin-bottom: $vertical-space-mobile;
    display: block;
    @include breakpoint($brake-sm) {
      display: none;
    }
  }

  .news {
    &__title {
      margin-bottom: 4px;
      @include breakpoint($brake-sm) {
        margin-bottom: 4px;
      }
    }

    &__item {
      clear: both;
      &-image {
        margin-bottom: 20px;
        @include breakpoint($brake-sm) {
          margin-bottom: 0;
          margin-right: 47px;
        }

        img {
          @include breakpoint($brake-sm) {
            margin-bottom: $vertical-space-desktop;
          }
        }

      }
    }

    &__main-link {
      display: block;
    }

    &__link {
      display: block;
      margin-top: 10px;
      @include breakpoint($brake-md) {
        margin-top: 19px;
      }
    }
  }

}
