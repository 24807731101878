.landing-content {

  /*
   -------------    -------------
  |    TEXT     |  |             |
   -------------   |    IMAGE    |
   -------------   |             |
  |   SNIPPET   |  |             |
   -------------    -------------
  */

  $root: &;

  &--v5 &__image {
    @extend %paddington;
  }

  // tablet and up
  @include breakpoint($brake-sm) {

    &--v5 &__info {
      @include span(12);
    }

    &--v5 &__copy {
      @include span(6);
    }

    &--v5 &__image {
      @include span(6);
      position: relative;
      img {
        //position: absolute;
        max-width: 100%;
        right: 0;
      }
    }

    &--v5 &__snippet {
      @include span(12);
      margin-top: $vertical-space-desktop / 2;
      margin-bottom: $vertical-space-mobile;
    }
  }

  // tablet screen and up
  @include breakpoint($brake-sm) {

    &--v5 &__image img {
      margin-top: -20%;
    }
    &--v5 &__snippet {
      @include span(12);
    }
    // #77264 - dziwny css, psuł a.cta w p, jeśli coś to zepsuło, to potrzebne lepsze selectory
    //&--v5 &__copy {
    //  *:first-child {
    //    margin-top: 0;
    //    padding-top: 0;
    //  }
    //}
  }

  // laptop screen and up
  @include breakpoint($brake-md) {

    &--v5 &__copy {
      @include span(7);
    }

    &--v5 &__image {
      @include span(5);
    }

    &--v5 &__snippet {
      @include span(7);
    }
    &--v5 &__image img {
      margin-top: -10%;
    }
    &--v5 {
      margin-top: -30px;
    }
  }

}
