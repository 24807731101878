.steps {
  @extend %paddington;
  @extend %pko-accordion-on-mobile;

  // Usunięcie padding-bottom 15.10.2018, https://redmine.k2.pl/issues/109999
  padding-bottom: 0;

  &__header {
    @include pko-give-padding(0);
  }

  .accordion__body {
    padding: 0 0 10px;
  }

  &__item {
    @extend %paddington;

    &--less-than-3,
    &--more-than-3 {
      .steps__description {
        display: table-cell;
        padding-bottom: 15px;
        p {
          margin: 0;
        }
      }

      .steps__number {
        @include pko-font-size(14);
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 24px;
        border-radius: 50%;
        color: $color-white;
        background: $color-blue;
        float: left;
        margin-right: 10px;
        display: table-cell;
      }

      .steps__image {
        display: none;
      }
    }
  }

  @include breakpoint($brake-sm-max) {
    &__item {
      padding: 0;
    }
  }

  @include breakpoint($brake-sm) {
    &__item {
      &--less-than-3 {

        @include span(4);
        &:nth-child(3n-2) {
          padding-left: 0;
        }
        &:nth-child(3n) {
          padding-right: 0;
        }

        .steps__description {
          margin-top: 25px;
          margin-bottom: 40px;
          display: block;
          padding-right: 30px;
        }

        .steps__number {
          @include pko-font-size(26);
          margin-top: -42px;
          width: 42px;
          height: 42px;
          line-height: 42px;
          float: none;
          display: block;
        }
        .steps__image {
          display: inline-block;
          margin-left: 50px;
        }
      }

      &--more-than-3 {
        padding-left: 0;
      }
    }

  }
}
