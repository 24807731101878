.leasing-offers__car-filters {
  width: 100%;
  background: $color-blue;
  color: $color-white;
  padding-top: 50px;
  @include breakpoint($brake-sm-max) {
    padding: 30px 0;
    margin-top: 30px;
  }
}

.leasing-car-filters {
  @include breakpoint($brake-md) {
    display: flex;
    flex-flow: row wrap;
  }
  .filter {
    margin-bottom: 32px;
    @include breakpoint($brake-sm) {
      margin-right: 50px;
      &:first-child {
        margin-bottom: 32px;
      }
    }
    &-wrap {
      flex: 0 0 33.333%;
      &:nth-child(3n) .filter {
        margin-right: 0;
      }
      &__submit {
        .cta {
          width: 100%;
          margin-top: 30px;
          &:hover {
            background: $color-red;
          }
          &:focus {
            background: $color-gray-main;
          }
        }
        @include breakpoint($brake-sm) {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          .cta {
            width: auto;
            padding: 8px 55px;
            margin-top: 0;
          }
        }
      }
    }
    &__heading {
      font-size: 17px;
      color: $color-white;
    }
    &__radio-half + label {
      width: 50%;
    }
    &__radio-five + label {
      width: 20%;
      min-width: 74px;
      border-right: 1px solid $color-blue;
    }
    &__radio + label {
      display: inline-block;
      background: $color-gray-fifth;
      color: $color-black;
      text-align: center;
      line-height: 37px;
      cursor: pointer;
      & > img {
        max-width: 44px;
        max-height: 30px; // IE fix
        vertical-align: bottom;
        margin-bottom: 7px;
        pointer-events: none;
      }
    }
    &__radio:first-of-type + label {
      border-radius: 5px 0 0 5px;
    }
    &__radio:last-of-type + label {
      border-radius: 0 5px 5px 0;
    }
    &__radio:checked + label {
      position: relative;
      background: $color-white;
      color: $color-blue;
      line-height: 44px;
      border-radius: 5px;
      border-right: none;
    }
    &__car-model {
      width: 100%;
      height: 37px;
      margin-top: 4px;
    }
    &__car-type {
      position: relative;

      @include breakpoint($brake-md-lg) {
        &.filter {
          margin-bottom: 10px;
        }
      }

      &--mobile-wrap {
        margin-right: -90px;
        margin-top: 40px;

        @include breakpoint($brake-sm) {
          margin-top: 0;
        }

        @include breakpoint($brake-md-lg) {
          margin-right: 0;
        }
      }
    }
    &__subheading {
      position: absolute;
      top: 0;
      right: 0;
      margin-left: 110px;
      font-size: 12px;
      line-height: 23px;

      @include breakpoint($brake-sm-max) {
        right: 20px;
      }
    }
    &__mobile-scroll {
      overflow-x: scroll;
      margin-right: -30px;
      @include breakpoint($brake-md-lg) {
        overflow: auto;
        margin-right: 0;
      }
    }

    &__all-vehicle {
      &-container {
        text-align: right;
        margin-top: 20px;

        @include breakpoint($brake-sm) {
          margin-right: 50px;
        }

        @include breakpoint($brake-md) {
          margin-right: 0;
        }

        @include breakpoint($brake-md-lg) {
          margin-top: 0;
        }

        .input-checkbox {
          padding-right: 0;
          right: -8px;

          @include breakpoint($brake-md) {
            top: 7px;
          }
        }
      }

      &-span {
        float: left;

        @include breakpoint($brake-md) {
          float: none;
        }
      }
    }
  }
  .irs-slider {
    &::before {
      background: $color-white;
    }
    &-wrapper {
      position: relative;
    }
    &-label-min {
      float: left;
    }
    &-label-max {
      float: right;
    }
  }
  .irs-bar, .irs-bar-edge {
    background: $color-white;
  }
  .irs-value {
    width: auto;
    text-align: left;
    border: none;
    input {
      width: 80px;
      float: left;
      color: $color-white;
      text-align: left;
      padding: 0;
      opacity: 1;
      @include breakpoint($brake-sm-max) {
        height: 32px;
        font-size: 32px;
        line-height: 1;
      }
    }

    &::before {
      float: right;
      font-size: 32px;
      color: $color-white;
      line-height: 45px;
      @include breakpoint($brake-sm-max) {
        line-height: 28px;
        -webkit-margin-before: 5.5px;
      }
    }
  }
  span[data-slider="leasing-slider-period"] input {
    width: 40px;
  }

  .filter__car-type .filter__radio:checked + label {
    margin-right: 1px;
    width: calc(20% - 2px);
  }
}
