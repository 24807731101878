.worth-to-know {
  @include pko-text-item;


  &__box,
  .feature-section-item {
    @extend %break-from-paddington; //Bug #62701
    @include pko-font-size($font-size-t2);
    margin-bottom: 30px;

    &__title {               //Bug #62701
      &--recommended {
        margin-top: 45px;
      }

      &--not-recommended {
        margin-top: 25px;
      }
    }
  }

  &__prefix {
    margin-top: 0;
    margin-bottom: 5px;
    color: $color-gray-main;
  }



  @include breakpoint($brake-sm) {
    //padding-top: 40px;

    &__box,
    .feature-section-item {
      @include span(6);
      margin-bottom: 40px;
    }
  }

  @include breakpoint($brake-md) {
    //padding-top: 47px;

    &__box,
    .feature-section-item {
      margin-bottom: 47px;
    }
  }

}
