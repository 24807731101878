// a.k.a. floating-banner
.flt-bnr {

  $root: &;

  @include pko-modal-like;
  position: fixed;
  width: 95%;
  right: 0;
  bottom: 10%;
  @include pko-z-index(iko-float-box);
  padding: 16px 40px 16px 19px;
  transform: translateX(100%);
  transition: all 1s ease;

  &__more {
    color: $link-color;
  }

  @include pko-on-event {
    #{$root}__more {
      color: $color-gray-main;
    }
    .cta {
      background-color: $color-blue;
    }
  }

  %flt-all {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 3px;
    background-color: $color-white;
  }

  &__all {
    &-mobile {
      @extend %flt-all;
      //background: hotpink;
    }
    &-desktop {
      @extend %flt-all;
      //background: #bada55;
    }
  }

  &--shown {
    transform: translateX(0);
  }

  @include breakpoint($brake-sm) {
    width: 541px;
    bottom: 25%;
    padding: 25px;
  }

  &__close {
    @extend %pko-close-absolute;
    @include pko-z-index(iko-float-box);
    @include pko-z-index();
  }

  &__wrap {
    @extend %pko-clearfix;
    display: flex;
    align-items: center;
    position: relative;
    @include pko-z-index();
  }

  &__logo {
    width: 56px;
    height: 27px;
    margin-bottom: 10px;
    vertical-align: bottom;

    @include breakpoint($brake-sm) {
      width: 100px;
      height: 48px;
      margin-bottom: 15px;
    }
  }

  &__info {
    display: block;
    float: left;
    width: calc(100% - 110px);
    @include breakpoint($brake-sm) {
      float: none;
      width: 100%;
    }
  }

  &__text {
    margin: 0 0 10px;
    color: $color-black;
    @include pko-font-size(15);
    @include pko-line-height(15, 18);
    @include breakpoint($brake-sm) {
      @include pko-font-size(18);
      @include pko-line-height(18, 25);
      margin: 0 0 20px;
    }
  }

  &__image {
    display: block;
    vertical-align: bottom;
    margin-right: 15px;
    width: 93px;
    height: 93px;
    @include breakpoint($brake-sm) {
      margin-right: 18px;
      width: auto;
      height: auto;
    }
  }
}
