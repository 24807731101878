// -----------------------------------------------------------------------------
// wrap dla contentu
// -----------------------------------------------------------------------------

.content {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;

  .disclaimer-on & {
    visibility: hidden;
  }

  @extend %wrap-paddington;

  position: relative;

  .wifi & {
    @include breakpoint($brake-sm-max) {
      padding: 0;
    }
  }
}

.site-content {

  @extend %pko-clearfix;
  position: relative;

  &__relative {
    position: relative;
  }

  &__title {
    @extend %paddington;
    @include pko-text-size($font-size-h2-mobile, $line-height-h2-mobile, $font-size-h1, $line-height-h1);
    margin: 100px 0 50px;
    padding-bottom: 50px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    &::before {
      position: absolute;
      left: 2px;
      @include breakpoint($brake-xl) {
        left: 4px; // visually align to header edge,
        // when header font-size is much bigger than pseudoelement
      }
    }
  }
  &__publish-date {
    margin-bottom: 5px;
    display: inline-block;
    width: 100%;
    margin-top: 40px;
  }
  &__columns {
    @extend %pko-clearfix;
    padding-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      padding-bottom: $vertical-space-desktop;
    }
  }
  &__columns + &__module {
    @include breakpoint($brake-sm) {
      margin-top: $vertical-space-desktop;
    }
  }
  &__main {
    min-height: 1px; // żeby nie zapadało się jak puste

    @include breakpoint($brake-sm) {
      @include span(8);
    }

    &--full-width {
      @include span(12)
    }

    // padding i inne bajery dla formularzy nie w sidebar, ale tylko kontaktowych, więc .ccform
    // czasami te style podwójen z .form--columns, bo .site-content__main i .form--columns jednocześnie
    .ccform:not(.sponsorship-form) {
      @include pko-form-padding;
    }

    //@include breakpoint($brake-sm-max) {
    //  & + .breakline {
    //
    //  }
    //}

    //#82591 gałąź ustawiona jako strona standardowa + kategoria z visualem + jedna z podgałęzi ma ustawioną
    //spec konfig 'produkty_wycofane': 1
    .site-content__module--paddington.site-content__module {
      margin-bottom: $vertical-space-mobile;

      .btn-white {
        margin-top: $vertical-space-desktop;
      }
    }
  }
  &__additional {
    @extend %paddington;

    min-height: 1px; // żeby nie zapadało się jak puste

    padding-top: $vertical-space-mobile;
    padding-bottom: $vertical-space-mobile;
    margin-top: $vertical-space-mobile;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      border-bottom: 1px solid $border-color;
      position: relative;
    }
    &::before {
      top: -#{$vertical-space-mobile};
    }
    &::after {
      top: $vertical-space-mobile;
    }

    @include breakpoint($brake-sm) {
      @include span(4);
      padding-top: 0;
      margin-top: 0;
      //padding-bottom: 0; #73053
      &::before,
      &::after {
        display: none;
      }
    }

    &--reversed {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      float: none;

      &::before,
      &::after {
        display: none;
      }

      @include breakpoint($brake-sm) {
        float: right;
      }
    }

    @include breakpoint($brake-sm-max) {
      &.hoisted-on-mobile {
        margin-top: 0; // #72465 - podciagniesie sidebara, gdy tresc konczy sie paginacją
      }
    }

  }
  &__sidebar {
    @extend %sidebar-paddington;
    padding-right: 0;
    word-break: break-word;   // ticket #134929

    .feature-section-item,
    .feature-section__item {
      float: none;
      padding: 0 !important;
      width: 100%;
    }

    img {
      max-width: 100%;
    }

    @include breakpoint($brake-sm) {
      border-left: 1px solid $color-gray-fifth;
    }
    @include breakpoint($brake-sm-max) {
      // force no padding on mobile
      padding-left: 0;
    }

    .snippet-wysiwyg:not(:first-child) {
      margin-top: 45px;
    }
  }
  &__module {
    @extend %pko-clearfix;
    position: relative;
    padding-bottom: $vertical-space-mobile;
    margin-bottom: $vertical-space-mobile;

    &:last-child {
      margin-bottom: 0;
      .site-content__main & {
        padding-bottom: 0;
      }
    }

    &--half-bottom {
      margin-bottom: 0 !important;
    }

    &.porownanie-bo {
      margin-bottom: 0 !important;
    }

    &-header {
      @include pko-font-size($font-size-h3-mobile);
      @extend %paddington;
      margin-bottom: $vertical-space-mobile;
      margin-top: $vertical-space-mobile;
    }
    // --wide dla lp iko #83256
    &-header--wide {
      @include pko-give-padding(0);
    }

    &-link {
      @include pko-font-size($font-size-t2-mobile);
    }

    &-date {
      @include pko-font-size($font-size-t3-mobile);
      color: $color-gray-second;
    }

    &--paddington {
      @extend %paddington;
    }

    &--paddington &-header {
      @include pko-give-padding(0);
    }

    &--lifted {
      //margin-top: -#{$vertical-space-mobile + 1px};
      //border-top: 1px solid $color-white;
    }

    &--hiddenarea + &--hiddenarea {
      margin-top: -30px;
      @include breakpoint($brake-sm) {
        margin-top: -40px;
      }
    }
  }

  &__block {
    margin-top: $vertical-space-mobile;
    margin-bottom: $vertical-space-mobile;

    &:last-child {
      margin: 0;
    }
  }

  @include breakpoint($brake-sm) {
    &__block {
      margin-top: $vertical-space-desktop;
      margin-bottom: $vertical-space-desktop;
    }
    &__module {
      padding-bottom: #{$vertical-space-desktop - 5px};
      margin-bottom: #{$vertical-space-desktop - 5px};
      &:last-child {
        .js-product-menu-section & {
          margin-bottom: $vertical-space-desktop / 2;
        }
      }
      &-header {
        margin-bottom: $vertical-space-desktop;
        @include pko-font-size($font-size-h2);
        //.feature-section &,
        //.rating &,                                    // ↓ może aktualności, ale nie rating i boksy tekstowe!!!
        .news & {
          margin-bottom: $vertical-space-desktop - 10; //Aktualności - odstep od tytułu Bug #62429
        }
        .feature-section &,
        .rating &,
        .news & {
          @include pko-font-size($font-size-h3);
        }
      }
      // --wide dla lp iko #83256
      &-header--wide {
        //@include pko-font-size($font-size-h2);
      }
      &-link {
        @include pko-font-size($font-size-t2);
      }
      &-date {
        @include pko-font-size($font-size-t3);
      }
      &--lifted {
        margin-top: -#{$vertical-space-desktop + 1px};
      }
      &--half-lifted {
        margin-top: -20px;
      }
    }
    &__title {
      padding-right: 50%;
    }
  }
}
