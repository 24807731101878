.bubbles {
  @extend %paddington;

  &__description {
    p {
      margin: 0;
    }
  }
}

