.landing-content {

  /*
   -------------    --------------------------------
  |             |  |                 -------------  |
  |    TEXT     |  |      IMAGE     |   SNIPPET   | |
  |             |  |                 -------------  |
   -------------    --------------------------------
  */

  $root: &;

  // tablet and up
  @include breakpoint($brake-sm) {
    &--v2 &__info {
      @include span(7);
    }

    &--v2 &__snippet {
      @include span(5);
    }
  }

  // laptop screen and up
  @include breakpoint($brake-md) {
    &--v2 &__info {
      @include span(12);
    }

    &--v2 &__copy {
      @include span(3);
    }

    &--v2 &__image {
      @include span(9);
    }

    &--v2 &__snippet {
      @include span(4);
      position: absolute;
      right: 0;
    }
  }

  // desktop screen and up
  @include breakpoint($brake-lg) {

    &--v2 &__copy {
      @include span(3);
    }

    &--v2 &__image {
      @include span(9);
    }
  }

}
