.calc__tooltip {
  display: inline-block;
  vertical-align: middle;

  .icon-infotip {
    color: $color-gray-third;
    //left: 4px;
    top: -4px;
  }

  &--hidden {
    visibility: hidden;
  }

  &--small {
    .icon-infotip {
      top: 0;

      &::before {
        @include pko-font-size(22);

        @include breakpoint($brake-md) {
          @include pko-font-size(26);
          top: -2px;
        }
      }
    }
  }

  &.js-tooltip--active {
    .icon-infotip {
      color: $color-blue;
    }
  }
}

.form .icon-infotip {
  margin-left: 20px;
}
