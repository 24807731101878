.text-boxes {
  @include pko-font-size($font-size-t2);
  clear: both;

  &__header {
    @extend %paddington;
  }

  &.site-content__module:last-child {
    padding-bottom: $vertical-space-mobile / 2;
  }

  @include breakpoint($brake-sm) {
    margin-bottom: $vertical-space-desktop;
    padding-bottom: $vertical-space-desktop;

    .feature-section__item {
      margin-bottom: $vertical-space-desktop / 2;
    }

    &__header {
      margin-bottom: 30px;
    }

    &.site-content__module {
      padding-bottom: $vertical-space-desktop / 2;
    }
  }


}

.text-box {
  @extend %paddington;
  @include pko-text-item;
 // &:not(:last-child) {
 //   margin-bottom: 25px;
 // }

  @include breakpoint($brake-sm) {
    @include span(4);
    margin-top: 10px;
  }

}
