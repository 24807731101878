.category-button {
  margin: 2%;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  color: $text-color;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 20px;
    width: 35%;
    height: 70%;
  }

  &:hover {
    color: $link-color !important;
    background-color: $color-gray-sixth;
  }

  &-wrap {
    display: flex; //IE11 fix
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 400px));
    justify-content: center;
  }
}
