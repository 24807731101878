.property-search {
  position: relative;
  padding-bottom: 30px;

  @include breakpoint($brake-sm) {
    padding-bottom: 40px;
  }

  @include breakpoint($brake-md) {
    padding-bottom: 55px;
  }

  &__top {
    margin-bottom: 15px;
    @include breakpoint($brake-sm) {
      margin-bottom: 27px;
    }
  }

  &__title {
    @extend %paddington;
    @include pko-text-size-3-way(21, 21, 24);
    margin: 15px 0 25px;

    @include breakpoint($brake-sm) {
      margin: 25px 0 32px;
    }

    @include breakpoint($brake-md) {
      margin: 30px 0 40px;
    }
  }

  &__bottom {

    &-list {
      list-style: none;
      padding-left: 0;
      margin: 0;

      &-column {
        @extend %paddington;
        @include span(12);
        margin-bottom: 0;
        clear: both;

        @include breakpoint($brake-sm) {
          @include span(6);
          margin-top: 15px;
        }
        @include breakpoint($brake-md) {
          @include span(4);
          clear: none;
          margin-top: 0;
        }

        &--collapse {
          @include breakpoint($brake-sm) {
            @include span(4);
            clear: none;
            margin-top: 0;
          }

          @include breakpoint($brake-md) {
            @include span(3);
          }
        }
      }
    }
  }

  &__list-item {
    min-height: 30px;
    @include pko-text-size-3-way(13, 14, 15);
  }

  &__subtitle {
    margin-bottom: 25px;
    @include pko-text-size-3-way(18, 17, 20);

    @include breakpoint($brake-sm) {
      margin-top: 0;
    }

    &--small {
      margin-bottom: 10px;
      @include pko-text-size-3-way(15, 16, 17);
    }
  }

  &__input {
    @include pko-font-size(15);

    &-wrap {
      @extend %paddington;
      @include span(12);

      @include breakpoint($brake-sm) {
        @include span(9);
      }

      @include breakpoint($brake-md) {
        @include span(7);
      }
    }

  }

  &__button {
    width: 100%;

    &-wrap {
      @extend %paddington;
      @include span(6);
      margin-top: 20px;

      @include breakpoint($brake-sm) {
        @include span(3);
        float: right;
        margin-top: 0;
      }
      @include breakpoint($brake-md) {
        @include span(2);
        float: right;
      }
    }

  }

  &__price {
    @include span(7);

    @include breakpoint($brake-sm) {
      @include span(12);
    }

    &-wrap {
      @include span(12);

      @include breakpoint($brake-sm) {
        @include span(6);
      }

      @include breakpoint($brake-md) {
        @include span(5);

        &--flr {
          float: right;
        }
      }

      .errorlist {
        margin: 0;
        padding: 0;
        list-style: none;
        clear: left;
      }

    }

    &-input {
      @include span(10);
      margin-bottom: 12px;
    }

    &-label {
      @include span(2);
      @include pko-font-size(15);
      line-height: 25px;
      padding: 5px 10px;
    }
  }

  &__select-wrap {
    @include span(12);
  }

  .owl-stage-outer {
    padding-bottom: 0;

    @include breakpoint($brake-sm) {
      padding-bottom: 30px;
    }

    @include breakpoint($brake-md) {
      padding-bottom: 40px;
    }
  }
}
