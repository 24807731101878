/* =================
   Artykul
   ================= */

.article {
  .text {
    h3 {
      margin-bottom: 38px; // PKOBP_S14_Artykul_02_Zakladki_v02.png, redmine #63022
    }
  }

  .site-content {
    .accordion__header {
      @include pko-font-size(20);
    }
  }
}
