$color-border-slider: #bcbcbc;

// amount slider
.calc__sliders {
  &-amount-labels {
    display: none;

    @include breakpoint($brake-md) {
      display: inline-block;
      position: absolute;
      @include pko-font-size(17);
      color: $color-gray-main;
      bottom: -25px;

      &:first-child {
        left: calc(16px + 36px);
      }

      &:last-child {
        left: initial;
        right: calc(16px + 36px);
      }
    }
  }
  &__list-item {
    margin-bottom: 0;
  }

  .irs-labels-container {
    display: block;
    width: 100%;
    position: relative;
  }

  .irs-wrapper {
    @include breakpoint($brake-sm) {
      width: 100%;
    }
  }

  .irs-wrapper-row {
    margin-top: 10px;
    display: flex;
    flex-direction: column-reverse;
  }

  .irs-controls {
    min-width: 0;
    position: relative;
    display: block;
    width: 100%;
    bottom: 0;
    margin: 0 0 30px;
    float: none;

    @include breakpoint($brake-md) {
      margin-bottom: 40px;
    }
    .irs-value {
    width: calc(100% - 25px);
    height: 52px;
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    position: relative;

    &::before {
      position: absolute;
      right: -25px;
      @include pko-font-size(22);
      line-height: 52px;
    }

    input {
      height: 52px;
      line-height: 52px;
      float: none;
      display: block;
      padding: 0;
      margin: 0;
      width: 100%;
      text-align: center;
      @include pko-font-size(22);

      ::-ms-clear,
      &::-ms-clear {
        display: none;
      }

      @include breakpoint($brake-md) {
        height: 62px;
        @include pko-font-size(24);
        line-height: 62px;
      }
    }

    @include breakpoint($brake-md) {
      width: 266px;
      height: 62px;
      margin: 0 auto;

      &::before {
        @include pko-font-size(24);
        right: -35px;
        padding-left: 20px;
        line-height: 62px;
      }
    }
  }
  }

  .irs-slider-wrapper {
    display: inline-block;
    float: none;
    width: calc(100% - 2 * 16px - 2 * 32px);

    .irs {
      height: 25px;
    }

    .irs-line {
      top: 11px;
    }

    .irs-bar-edge {
      width: 16px;

      @include breakpoint($brake-md) {
        width: 20px;
      }
    }

    .irs-line,
    .irs-bar,
    .irs-bar-edge {
      top: 11px;
    }

    .irs-slider {
      width: 25px;
      height: 25px;
      cursor: pointer;
      top: 0;
    }

    .irs-slider,
    .no-touch .irs-slider {
      &:before {
        transform: none;
      }

      &:hover,
      &:active,
      &:focus {
        &:before {
          transform: none;
        }
      }
    }

    @include breakpoint($brake-md) {
      width: calc(100% - 2 * 16px - 2 * 36px);
    }
  }

  .irs-slider-controls {
    position: relative;
    bottom: 1px;
    display: inline-block;
    vertical-align: -3px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $color-blue;
    cursor: pointer;

    @include breakpoint($brake-sm) {
      bottom: 20px;
    }

    @include breakpoint($brake-md) {
      width: 36px;
      height: 36px;
    }

    &:first-child {
      margin-right: 16px;

      &::after {
        content: $icon-minus;
        color: $color-white;
        font-family: icomoon;
        font-weight: 700;
        position: absolute;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        @include pko-font-size(16);

        @include breakpoint($brake-md) {
          width: 36px;
          height: 36px;
          line-height: 36px;
        }
      }
    }

    &:last-child {
      margin-left: 16px;

      &::after {
        @extend .irs-slider-controls:first-child::after;
        content: $icon-plus;
      }
    }
  }

  .irs-slider-label-min,
  .irs-slider-label-max {
    color: $color-black;
  }

}
