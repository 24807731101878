.block-content {
  @include pko-z-index(ajax-overlay-default);

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  cursor: not-allowed;

  &__flex {

    // do scrolowania scrollIntoView, react/BlockedContent
    &-padding {
      padding: 30px 0;
      @include breakpoint($brake-sm) {
        max-width: 50%;
      }
    }

    @include pko-flex-center-content;
    width: 100%;
    height: 100%;

    &-inner {
      @extend %text-default-2;
      @include pko-modal-like;
      cursor: initial;

      & > {
        @include pko-paragraph-spacing;
      }
      position: relative;
      width: 90%;

      h3 {
        margin: 0;
      }

      @include breakpoint($brake-sm) {
        max-width: 50%;
        &--100pc {
          max-width: 100%; // ech
        }
      }

      &-close {
        @extend %pko-close-absolute;
      }
    }
  }
}
