// narazie jest tylko drukowanie

.page-tools {
  margin: 1em 0 0;
  padding-bottom: 1em;
  clear: left;
  &__link {
    display: inline-block;
    &-icon {
      font-size: 30px;
      vertical-align: -7px;
      color: $color-blue;
      margin-left: -4px;
    }
  }

  // modyfikator to artykuł bo tam narazie jest
  &--article {
    margin: 0;
    padding-top: 1em;
    @extend %paddington;
    @include breakpoint($brake-sm) {
      padding-bottom: 0;
    }
  }
  &--fund {
    padding-top: 3em;
  }
}
