.product-item {
  margin-bottom: 40px;
  @include pko-text-item;

  &__text {
    margin-right: 10px;
    display: inline-block;
    &--tfi {
      margin-top: 11px;
    }
    &--second-link {
      margin-left: 20px;
    }
  }

  &__description li {
    @extend %text-default-2;
    margin: 5px 0;
  }

  &__link {
    color: $color-red;
  }

  &--last {
    margin-bottom: 0;
    @include breakpoint($brake-sm) {
      margin-bottom: 40px;
    }
  }

  .icon-leaf {
    display: inline-block;
    width: auto;
    height: 1em;
    padding: 0.05em;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.products-wrap {
  margin-bottom: -40px;
}
