@mixin rodo-p {
  p {
    margin: 0;
  }
}

@mixin rodo-li {
  ul {
    > li {
      &::before {
        top: 5px;
        @include breakpoint($brake-md-lg) {
          top: 6px;
        }
      }
    }
  }
}

$text-container-height-mobile: 60vh;
$text-container-height-desktop: 300px;
$modal-padding: 15px;

.cookie-rodo {
  $root: &;

  @include pko-z-index(cosmos);
  display: none;

  position: fixed;
  bottom: 10px;
  left: 0;

  width: 100%;

  background-color: $color-white;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;

  @extend %text-rodo-normal;
  padding: $modal-padding;

  @include breakpoint($brake-sm) {
    border-top: none;
    width: 90vw;
    max-width: 850px;
    right: 0;
    bottom: 0;
    margin: auto;

    @include pko-modal-like;

    padding: $modal-padding;
  }

  .no-js & {
    display: block;
  }

  &__top {
    max-height: $text-container-height-mobile;
    overflow-y: auto;

    @include breakpoint($brake-sm) {
      max-height: $text-container-height-desktop;
    }

    @include rodo-p;
    @include rodo-li;
  }

  &__bottom {
    text-align: right;
    padding-top: $modal-padding;

    .no-js & {
      display: none;
    }
  }

  &__cta {
    float: right; // hardcore oldschool
  }

  &__settings-link,
  &__remind-link,
  &__reject-link {
    float: right; // hardcore oldschool
    margin: 8px 20px 0 0;
    @extend %text-default-2;

    @include breakpoint($brake-sm) {
      @include pko-font-size(15);
    }
  }

  &--center {
    @include breakpoint($brake-sm) {
      top: 0;
      bottom: 0;
      width: 50vw;
      height: 400px; // Zastępowane przez JSa, justin case

      padding: 0;

      @include pko-font-size(15);

      #{$root}__inner-container {
        padding: 25px;
      }
    }
  }
}
