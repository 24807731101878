.calc-switch {
  display: flex;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  font-size: 0px;

  &__button {
    position: relative;
    display: flex;
    width: 50%;
    padding: 5px;
    background-color: $color-white;
    border-width: 1px;
    border-style: solid;
    border-color: $color-blue;
    color: $color-blue;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include pko-font-size($font-size-t1-mobile);
    @include breakpoint($brake-sm) {
      width: 315px;
      @include pko-font-size($font-size-t1);
    }

    &:first-child {
      border-radius:5px 0 0 5px;
    }

    &:last-child {
      border-radius:0 5px 5px 0;
    }

    &.active {
      background-color: $color-blue;
      border-color: $color-blue;
      color: $color-white;
      pointer-events: none;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -7px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $color-blue;
        clear: both;
        transform: translateX(-6px);
      }
    }

    button {
      background-color: transparent;
      color: inherit;
      border: 0;
      width: 100%;
      cursor: pointer;
    }
  }
}
