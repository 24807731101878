// Globalny styl dla elementów promowanych w wyszukiwaniu i w podpowiedziach wyszukiwania
.promoted {
  display: block;
  color: $color-red;
}

.search-page {

  .pagination-wrapper {
    @extend %break-from-paddington;
  }

  &__form {
    @extend %paddington;
    position: relative;
    margin-bottom: $vertical-space-mobile;

    .input-text {
      padding-right: 40px;
      z-index: 2;
      position: relative;
    }
  }
  &__glass {
    position: absolute;
    top: 3px;//2px;
    right: 7px;//5px;
    background: none;
    padding: 0;
    border: none;
    color: $color-gray-third;//$color-blue;
    font-size: 1.875rem;//30px;
    //max-height: 33px;
    display: inline-block;
    z-index: 3;
  }
  .ui-autocomplete {
    cursor: default;
    border-radius: 0 0 5px 5px;
    padding: 0;
    list-style: none;
    background-color: $color-white;
    border: 1px solid $color-gray-second;
    border-top: 0;
    li {
      margin: 0;
      padding: 4px 15px;
      border-top: $color-gray-second;
      &:first-child {
        border: none;
      }

      &:hover,
      &.ui-state-focus {
        color: $color-blue;
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
  }
  &__mobile-filters {
    @extend %paddington;
    margin-bottom: $vertical-space-mobile;
  }

  &__results {
    @extend %paddington;
  }

  &__result {
    $result: &;
    margin-bottom: $vertical-space-mobile;
    list-style: none;

    @include breakpoint($brake-sm) {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .promoted {
      margin-bottom: 4px;
    }

    .main-link {
      color: $color-gray-main;

      &:hover {
        h4 {
          color: $color-blue;
        }
      }
    }

    .more-link {
      @include pko-font-size($font-size-t2-mobile);
    }

    h4 {
      margin: 0 0 18px;
    }

    p {
      margin: 0 0 16px;
    }

    small {
      margin-bottom: 4px;
      display: block;
    }

    key { /* stylelint-disable-line */ // specjalny TAG dla podswietlania wynikwo - taka zwrotkę dostajemy z FAST'a
      color: $color-black;
      font-weight: bold;
    }

    &--with-image {
      @extend %pko-clearfix;

      img {
        @include span(2);
        margin-right: 40px;
      }

      #{$result}__content {
        @include span(9);
      }
    }

    &--course {
      * {
        vertical-align: middle;
      }
      picture {
        padding-right: 20px;
        display: inline-block;
        height: 28px;
      }
      a {
        line-height: 28px;
      }
      h4 {
        margin-bottom: 0;
      }
    }
  }

  &__courses {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-bottom: 43px;
    }


  }

  @include breakpoint($brake-sm-max) {
    .site-content__additional {
      display: none;
    }
  }

  @include breakpoint($brake-sm) {
    &__form {
      margin-bottom: 47px;
    }
    &__mobile-filters {
      display: none;
    }
    &__result {
      margin-bottom: 43px;
      .more-link {
        @include pko-font-size($font-size-t2);
      }
    }
  }
}

//
// scss-lint:disable IdSelector
#search-filters {
  margin-top: 20px;
  @include breakpoint($brake-sm) {
    margin-top: 0;
  }
}
// scss-lint:enable IdSelector

