$structure-indent: 150px;

.structure {
  $root: &;

  @extend %text-default-2;
  display: block;
  margin: 10px 0 30px;
  padding: 25px 0 0 25px;
  list-style: none;
  border-top: 1px solid $border-color;
  border-left: 1px solid $border-color;

  &::before {
    content: attr(title);
    margin-bottom: 15px;
    display: block;
    color: $color-gray-second;
  }

  &__item {
    @include pko-on-event() {
      > #{$root}__number,
      > #{$root}__text {
        background: $color-gray-sixth;
      }
    }
  }

  &__number,
  &__text {
    display: block;
    margin-bottom: 5px;
  }

  @include breakpoint($brake-sm) {

    & & {
      margin-left: $structure-indent;
    }

    &__number {
      float: left;
      width: $structure-indent;
    }
  }
}
