.skk {
  @extend %paddington;

  .skk_filters {
    text-align: left;

    p {
      @include breakpoint($brake-md-max) {
        width: 100%;
      }
    }
    p select option {
      @include pko-text-size(13, 18, 15, 21);
    }

    label {
      @extend %text-default-1;
    }

    &_options {
      height: auto;
      margin-top: -7px;

      @include breakpoint($brake-md) {
        margin-top: -7px;
      }

      div p {
        margin-bottom: 7px;

        input {
          @extend .input-text;
          @include pko-text-size(13, 18, 15, 21);
        }

        .skk_filters_dd_reg {
          height: auto;
          padding-left: 15px;
          padding-right: 30px;
          border-radius: 3px;
          line-height: 37px;
          min-height: 37px;
          background-color: $color-white;

          // fix dla urzadzen dotykowych
          @supports (-webkit-overflow-scrolling: touch) {
            -webkit-appearance: none;
            position: relative;

            &::after {
              content: '';
              display: block;
              position: absolute;
              right: 0;
              top: 0;
              height: 35px;
              width: 37px;
              border-left: 1px solid $border-color;
              z-index: 1;
            }

            &::before {
              @include pko-font-size(32);
              font-family: icomoon;
              content: $icon-drop-down;
              color: $icon-color;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 2;
            }

            @include breakpoint($brake-md) {
              &::after,
              &::before {
                display: none;
              }
            }
          }

          @include breakpoint($brake-md) {
            height: 102px;
            line-height: normal;
          }

          @include breakpoint($brake-lg) {
            height: 99px;
          }
        }

        #lstAvailableRegions {
          height: auto !important;

          @include breakpoint($brake-md) {
            height: 180px !important;
          }
        }
      }
    }

    .skk_filters_btn_search {
      @extend .cta;
      margin-top: 10px;
    }
  }

  &_offers {
    table {
      thead tr {
        @extend %text-default-1;
        background-color: transparent;
        height: 60px;
      }

      td {
        @extend %text-default-1;
        border-bottom: none;
        border-right: none;
        padding: 10px;
      }
    }

    &_tb {
      @extend .clean-table;

      @media (max-width: 400px) {
        thead {
          display: none;
        }
        td {
          text-align: left;
          padding-left: 0;
          padding-right: 0;

          &:nth-child(2) {
            padding-bottom: 0;
          }
          &:not(:first-child) {
            font-size: 0.8rem;
            padding-top: 0;
          }
        }
      }
    }

    .skk_pager {
      a {
        @extend %text-default-1;
        border: none;
      }

      .skk_pager_prev,
      .skk_pager_next {
        display: none;
      }
    }
  }
}