/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel {
  .owl-item {
    .owl-lazy {
      opacity: 0;
      transition: opacity 400ms ease;
    }

    img {
      transform-style: preserve-3d;
    }
  }
}

