.datepicker {
  &-wrap {
    padding: 0;
    margin-right: 10px;
    margin-bottom: 4px;
    height: 30px;
    display: block;
    position: relative;
    @extend %text-default-1;

    @include breakpoint($brake-sm) {
      display: inline-block;
      margin-bottom: 0;
    }

    &--block {
      display: block;
    }

    .datepicker-value {
      border: none;
      color: $link-color;
      width: 123px;
      line-height: 30px;
      padding-right: 25px;
      background: transparent;
      position: relative;
      @include pko-z-index('default');
      @include pko-on-event() {
        color: $color-gray-main;
        cursor: pointer;
      }

      &::after {
        @extend %icomoon;
        content: $icon-calendar;
        position: absolute;
        right: 0;
        top: -4px;
        font-size: 25px;
        height: 100%;
        color: $color-blue;
        @include breakpoint($brake-sm) {
          top: -3px;
        }
      }
    }

    @include pko-on-event() {
      &::after {
        color: $color-gray-main;
      }
    }

    .icon-calendar {
      position: relative;
      top: -2px;
      left: -5px;
      font-size: 25px;
      cursor: pointer;
      color: $color-blue;
    }
  }

  &-clear {
    display: block;
    margin-top: 20px;
  }

  &-wrapper {
    display: inline-block;
  }

}

.js-datepicker {
  cursor: pointer;
}

.ui-datepicker {
  .select-mobile-wrapper {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & &-header {
    padding-left: 0;
    padding-right: 0;
  }

  @include breakpoint($brake-sm-max) {
    left: 10px !important;
    right: 10px !important;
    position: fixed !important;
    top: 50% !important;
    transform: translateY(-50%);
    margin-top: 0 !important;
  }

  .select2-container--default {
    display: inline-block;
  }

  .select2-selection--single {
    min-width: 0;

    .select2-selection__rendered {
      @extend %text-default-2;
      padding-right: 0;
    }
  }

}

.input-text {
  &.delivery-datepicker {
    &:disabled {
      color: $color-gray-main;
    }
  }
}


