$card-image-width: 2100;
$card-image-height: 1344;
$card-view-width: 2024;
$card-view-height: 1276;
$card-border-radius: 4%;
$card-ratio: $card-image-height / $card-image-width;
$card-ratio-percent: $card-ratio * 100%;
$card-view-border-radius: #{($card-border-radius * $card-ratio)} / #{$card-border-radius};

$card-controls-left-width: 70px; // there are two
$card-controls-right-width: 40px; // there are two
$card-controls-gutter: 30px; // there are two
$card-scale-input-height: 27px; // before rotation so it's width actially and it's set inside styled-range

.card-creator {
  $root: &;

  position: relative;

  &__card-wrapper {
    width: 100%;

    @include breakpoint($brake-md) {
      width: calc(100% - (#{($card-controls-left-width + $card-controls-right-width + ($card-controls-gutter * 2))}));
      margin: 60px auto;
      margin-left: $card-controls-left-width + $card-controls-gutter;
      margin-right: $card-controls-right-width + $card-controls-gutter;
    }
  }

  &__card {
    display: block; // overwritten by js
    position: relative;
    // 2100x1344 original size and base resolution for card project
    // but card is about 2024x1276 cause of print size/margins etc
    width: 100%;
    height: 0;
    padding-bottom: $card-ratio-percent;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.55);
    background-color: #ffffff;
    border-radius: $card-view-border-radius;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 1;
  }

  &__file {
    & > input[type=file] {
      display: none;
    }

    & > label {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;
    }

    & > button {
      @extend %cta;
      @extend .cta-blue-dark;

      position: absolute;
      left: 50%;
      top: 20%;
      transform: translateX(-50%);
      pointer-events: none;
    }
  }

  &__canvas {
    display: none; // overwritten by js
    overflow: hidden;

    & > canvas {
      position: absolute;
      width: 103.75494071146245%; // proper margins for print
      height: 105.32915360501567%; // proper margins for print
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__result {
    display: none; // overwritten by js
    overflow: hidden;

    & > canvas {
      position: absolute;
      width: 103.75494071146245%; // proper margins for print
      height: 105.32915360501567%; // proper margins for print
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__constrols {
    display: none; // CardCreator turns controls on after first file upload

    @include breakpoint($brake-md) {
      position: absolute;
      top: 0;
      bottom: 0;
    }

    &--left {
      display: none;
      width: 40%;
      margin-bottom: 10px;
      text-align: left;
      vertical-align: top;

      @include breakpoint($brake-md) {
        left: 0;
        width: $card-controls-left-width;
        margin-bottom: 0;
      }
    }

    &--right {
      display: none;
      width: 60%;
      margin-bottom: 15px;
      text-align: right;
      vertical-align: top;
 
      @include breakpoint($brake-md) {
        top: 0;
        right: 0;
        bottom: auto;
        margin-bottom: 0;
        width: $card-controls-right-width;
      }
    }

    &--right-bottom {
      width: 100%;
      margin-top: 10px;
      text-align: center;

      @include breakpoint($brake-md) {
        top: auto;
        right: -5px;
        bottom: -5px;
        width: $card-controls-right-width;
        margin-top: 0;
      }

      @include breakpoint($brake-lg) {
        bottom: 14px;
      }
    }
  }

  &__scale-title {
    display: none;

    @include breakpoint($brake-md) {
      display: block;
      position: absolute;
      top: 19px;
      width: 100%;
      text-align: center;
      font-size: 17px;
    }
  }

  &__scale-input {
    display: none;
    font-size: 0;

    @include breakpoint($brake-md) {
      display: inline-block;
      position: absolute;
      top: 63px;
      bottom: 83px;
    }


    // scale container to square size based on height
    // that way we'll have input range full height after rotation
    & > img {
      height: 100%;
    }
  }

  &__scale-input-rotation {
    min-width: 100px;
    transform-origin: top left;
    transform: rotate(270deg) translate(0, ($card-controls-left-width - $card-scale-input-height) / 2);
  }

  &__change-button {
    display: inline-block;
    line-height: 62px;
    color: $color-blue;
    font-size: 15px;
    text-align: center;
    cursor: pointer;

    @include breakpoint($brake-md) {
      position: absolute;
      bottom: 20px;
      width: 100%;
      font-size: 18px;
      line-height: 1.2;
    }
  }

  &__variant {
    display: inline-block;
    text-align: center;
  }

  &__variant-button {
    $variantButton: &;
    display: inline-block;
    position: relative;
    width: 62px;
    height: 62px;
    margin: 0 0 0 15px;
    text-align: left;
    cursor: pointer;

    @include breakpoint($brake-md) {
      margin: 0 0 5px -6px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: transparent;
    }

    &:hover, &.active {
      &::before {
        content: '';
        border-radius: 50%;
        background: #f2f2f2; // todo move to colors in variables
      }
    }

    &--small {
      display: inline-block;
      width: 36px;
      height: 36px;
      margin: 4px;

      &::before {
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      &:hover, &.active {
        &::before {
          content: '';
          border-radius: 50%;
          border: 2px solid #073474;
        }
      }

      @include breakpoint($brake-md) {
        margin: 2px;
      }

      & .card-creator__variant-icon {
        max-width: 100%;
      }
    }
  }

  &__variant-icon {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    z-index: 1;
  }

  &__info {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    pointer-events: none;
    opacity: 1;
    transition: opacity 400ms ease-out;

    &--hidden {
      opacity: 0;
    }
  }

  &__info-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__info-icon {
    width: 16vw;
    height: 16vw;
  }

  &__text-example {
    position: absolute;
    top: 58.2%;
    left: 10.9%;
    width: 83.8%;
    opacity: 0.2;
  }
}