.owl-stage-outer {
  width: 100%;
}

.owl-dots {
  @extend %paddington;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  @include pko-z-index();
}

.js-slider {
  text-align: center;
  img {
    max-width: 100%;
    @media (min-width: 768px) {
      max-width: calc(100% - 130px);
    }
  }
  @media (max-width: 767px) {
    .desktop-arrow {
      display: none !important;
    }
  }
}


.owl-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 12px;
  border-radius: 50%;
  background: $color-gray-third;
  cursor: pointer;
  &.active {
    background: $color-blue;
  }
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  width: 21px;
  height: 53px;
  display: none;
  font-size: 56px;
  color: $color-blue;
  cursor: pointer;
  .fallback-slider & {
    display: block;
    font-size: 13px;
    width: auto;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    padding-top: 20px;
    margin-top: -20px;
    transform: none;
    color: $color-blue !important;
    font-family: $text-font-stack !important;
    transition: $trans-hover;
    &.disabled {
      color: $color-gray-third !important;
    }
    &:before {
      position: relative;
      top: -2px;
      font-family: $icon-font-stack;
      font-size: 16px;
    }
  }
}

.owl-prev {
  left: 38px;
  background-position: left;
  .fallback-slider & {
    float: left;
    &:before {
      float: left;
      margin: 0 3px 0 10px;
    }
  }
}

.owl-next {
  right: 38px;
  background-position: right;
  .fallback-slider & {
    float: right;
    &:before {
      float: right;
      margin: 0 10px 0 3px;
    }
  }
}

.fallback-slider .owl-nav {
  margin-top: -20px;
  top: 9px;
  position: relative;
  z-index: 2;
  .card-carousel & {
    margin: 0 15px;
    top: 20px;
  }
}
.header-features.fallback-slider .owl-nav {
  margin-top: -50px;
  top: 20px;
}

.owl-item {
  float: left;
}

.owl-stage {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.owl-stage-outer {
  overflow: hidden;
}

.owl-stage-outer {
  padding-bottom: 40px;
}
