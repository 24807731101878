.calc-combo {
  @import "calc-combo/calc-badges";
  @import "calc-combo/calc-carousel";
  @import "calc-combo/calc-form";
  @import "calc-combo/calc-header";
  @import "calc-combo/calc-options";
  @import "calc-combo/calc-results";
  @import "calc-combo/calc-sliders";
  @import "calc-combo/calc-summary";
  @import "calc-combo/calc-tooltip";
  @import "calc-combo/calc-bar";

  // fikcyjny odstep od góry, wykorzystywany przy funkcji scrollTo,
  // gdy widoczna jest navigacja na stronach produktowych
  padding-top: 40px;
  margin-top: -40px;

  &__wrapper {
    min-height: 70px;
  }

  &__switch {
    margin-bottom: 35px;
    text-align: center;
  }

  &__calculator {
    display: block;
    position: relative;
  }

  &__sliders__row {
    margin-bottom: 0;
    width: 100%;

    @include breakpoint($brake-sm) {
      margin-bottom: 35px;
    }
  }

  &__inputs__row {
    display: flex;
    flex-direction: column;

    .calc-input {
      margin-bottom: 20px;
      flex: 1;
    }

    @include breakpoint($brake-md) {
      flex-direction: row;
      justify-content: center;
      margin-bottom: 30px;
      .calc-input {
        margin: 0 20px;
      }
    }
  }

  // inputy kalkulatora
  .calc-input {
    .input-text__append {
      font-size: 1.2rem;
      line-height: 45px;
      top: 0;
      right: calc(50% - 120px);
      padding: 0;
    }

    &__header {
      font-size: 15px;
      margin-bottom: 5px;
    }

    &__error {
      font-size: 11px;
      line-height: 15px;
      color: $color-gray-main;
    }

    @include breakpoint($brake-xs-sm) {
      margin-left: 40px;

      &:first-child {
        margin-left: 0;
      }

      .input-text__append {
        right: calc(50% - 103px);
      }
      &__header {
        text-align: left;
      }
    }
    @include breakpoint($brake-sm) {
      margin-left: 10%;

      .input-text__append {
        line-height: 62px;
        right: calc(50% - 150px);
      }
    }
    @include breakpoint($brake-md) {
      margin-left: 28px;
    }
    @include breakpoint($brake-md-lg) {
      margin-left: 70px;
    }
  }
  .input-text__wrapper {
    text-align: center;

    .input-text {
      height: 45px;
      font-size: 22px;
      line-height: 45px;
      text-align: center;
      background-color: $color-white;
      font-weight: 400;
      border-radius: 3px;
      margin: 0;
      max-width: 200px;
      width: 80%;

      &--right-space {
        padding-right: 15px;
      }
    }

    @include breakpoint($brake-xs-sm) {
      text-align: left;
      .input-text {
        width: 100%;
        max-width: 170px;

        & + & {
          margin-left: 40px;
        }
      }
    }

    @include breakpoint($brake-sm) {
      .input-text {
        line-height: 62px;
        max-width: 260px;
        height: 62px;

        & + & {
          margin-left: 80px;
        }
      }
    }
  }

  .calc__carousel-installment {
    &-slider {
      &-item {
        @include breakpoint($brake-md) {
          width: 100px;
          height: 64px;
          line-height: 64px;
          @include pko-font-size(22);

          &--active {
            height: 64px;
            line-height: 64px;
          }
        }
      }
    }
  }

  .overlay {
    margin: 0 -30px;
    width: calc(100% + 60px);
  }
}
