.select-pure {
    position: relative;

    select {
        display: none;
    }
    &__field {
        @extend .input-text;
        padding-right: 40px;
        cursor: pointer;
        text-align: left;
        background-color: $color-white;
        position: relative;
        appearance: none;

        .select-icon {
            position: absolute;
            right: 1px;
            top: 1px;
            height: 35px;
            width: 37px;
            border-left: 1px solid $color-gray-fifth;
            cursor: pointer;
            font-style: normal;
            text-align: center;

            &::before {
                font-family: $icon-font-stack;
                font-size: 32px;
                line-height: 33px;
                content: $icon-drop-down;
                color: $icon-color;

                .opened & {
                    content: $icon-drop-up;
                }
            }
        }
        &:hover {
            .select-icon::before {
                color: $color-gray-main;
            }
        }

        .opened & {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .select-icon {
                &::before {
                    content: $icon-drop-up;
                }
            }
        }
    }

    &__list {
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        max-height: 200px;
        overflow-y: auto;
        margin: -7px 0 0;
        padding: 0;
        border: 1px solid $color-gray-third;
        border-top: 0 solid transparent;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        list-style-type: none;
        opacity: 0;
        pointer-events: none;
        transform-origin: top left;
        transform: scale(1,0);
        transition: all ease-in-out .1s;
        z-index: 2;

        &.active {
            opacity: 1;
            pointer-events: auto;
            transform: scale(1,1);
        }

        &-item {
            display: block;
            list-style-type: none;
            background: $color-white;
            cursor: pointer;
            text-align: left;
            padding: 6px 15px;
            transition: all ease-in-out .2s;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:hover {
                color: $link-color;
            }
        }
    }
}
