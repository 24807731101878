.calc__results {
  position: relative;
  min-height: auto;
  margin: 0 -30px;
  padding: 35px 40px;
  border-top: 1px solid $color-gray-fifth;
  border-bottom: 1px solid $color-gray-fifth;

  &-decoration {
    width: 28.28px;
    height: #{(28.28/2)}px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -1px;
    overflow: hidden;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-color: $color-white;
      border: 1px solid $color-gray-fifth;
      transform: rotate(45deg);
      display: block;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 4px;
    }
  }
  &-show-details {
    display: block;
    position: relative;
    cursor: pointer;
    @include pko-font-size(14);
    margin-bottom: 20px;

    &::after {
      content: $icon-arrow-right;
      font-family: icomoon;
      font-weight: 700;
      position: relative;
      top: 2px;
    }

    &--mobile {
      @include breakpoint($brake-md) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include breakpoint($brake-md) {
        display: inline-block;
        margin-bottom: 36px;
        @include pko-font-size(17);
      }
    }
  }
  &-details {
    margin-bottom: 20px;

    &-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin: 0;
    }

    &-item {
      min-height: 72px;
      margin-right: 7%;

      h3 {
        margin: 0;
        @include pko-font-size(24);
        font-weight: 700;

        @include breakpoint($brake-md) {
          @include pko-font-size(38);
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &--with-label {
        margin-bottom: 40px;
      }
    }

    &-label {
      @include pko-font-size(14);
      color: $color-gray-second;
    }

    &-value {
      @extend h3;
      margin-bottom: 15px;
    }
  }
  &-cta {
    margin-top: 30px;
  }

  &--hidden-rrso {
    @include breakpoint($brake-md) {
      min-height: 326px;
    }
  }

  .cta {
    margin-top: 0;
    margin-bottom: 10px;
    max-width: 300px;
    width: 100%;
    text-align: center;
    @include pko-font-size(17);
    -webkit-appearance: none;
    cursor: pointer;
  }
  .calc-loan__popup {
    &__details {
      &-container {
        .column {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @include breakpoint($brake-md) {
          display: flex;
          justify-content: space-between;

          .column {
            width: 50%;
            margin-bottom: 0;
          }
        }
      }
    }

    &__tooltip {
      right: -5px;

      .icon-infotip {
        &:before {
          font-size: 22px;
        }
      }

      @include breakpoint($brake-md) {
        top: 0;
        right: 25px;
      }
    }
  }
  .label {
    display: inline-block;
    padding: 4px 8px;
    color: #fff;
    background-color: #5897fb;
    position: absolute;
    @include pko-font-size(13);
  }
  .crossed {
    text-decoration: line-through;
    color: #000;
    font-size: 1.25rem;
    font-weight: normal;
    margin-left: 20px;
  }

  @include breakpoint($brake-sm) {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-details {
      &-container {
        margin-top: 30px;
        flex-direction: row;
      }
      &-item {
        margin-right: 20%;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-show-details {
      position: absolute;
      top: 30px;
    }
    .label {
      @include pko-font-size(15);
      position: relative;
    }
    .cta {
      width: 40%;
    }
  }
  @include breakpoint($brake-md) {
    border: 1px solid $color-gray-fifth;
    padding: 40px;
    width: 32%;
    flex-direction: column;

    &-details {
      &-container {
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 30px;
      }
      &-item {
        margin-right: 0;

        &:first-child {
          margin-bottom: 30px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
      &-label {
        @include pko-font-size(18);
      }
    }
    &-decoration {
      right: auto;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 28.28px;
      width: #{(28.28/2)}px;
      left: -1px;

      &::after {
        margin: 0 auto;
        bottom: 0;
        top: 0;
        left: -19px;
      }
    }
    &-show-details {
      position: relative;
      top: unset;
    }

    .cta {
      max-width: unset;
      width: 100%;
    }
  }
}
