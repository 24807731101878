.calc-combo-mortgage__wrapper {
  min-height: 40vh;
  width: 100%;
  margin-bottom: $vertical-space-mobile;
  position: relative;

  @include breakpoint($brake-sm) {
    margin-bottom: #{$vertical-space-desktop - 5px};
  }
}
#calc-combo-mortgage {
  // style globalne
  .calc-combo__inputs__row {
    justify-content: flex-start;

    @include breakpoint($brake-xs-sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  .calc__results-details {
    &-container {
      margin-top: 0;
    }
  }
  .calc__carousel-installment-slider-item {
    @include breakpoint($brake-md) {
      width: 64px;
    }
  }
  .calc-input {
    .input-text__append {
      right: unset;
      margin-left: 10px;

      @include breakpoint($brake-sm) {
        margin-left: 20px;
      }
    }
    &__error {
      margin: 5px auto 0;
      text-align: center;
      width: 85%;

      @include breakpoint($brake-xs-sm) {
        width: 100%;
        text-align: left;
      }
      @include breakpoint($brake-md) {
        width: calc(100% + 40px);
      }
    }
  }
  .calc__options {
    margin-bottom: 5px;
  }

  // style tylko dla kalkulatora LP
  .calc-combo-LP {
    .landing-page--v10 & {
      padding: 30px;
    }
    .calc-combo__calculator {
      position: static;
      .overlay {
        margin: 0;
        width: 100%;
        height: 100%;
      }
    }
    .calc-combo__inputs__row {
      justify-content: flex-start;

      @include breakpoint($brake-md) {
        flex-wrap: nowrap;
        &:first-child {
          .calc-input {
            margin-left: 0;
            flex: 1;
          }
        }
        .calc__options:first-child {
          padding-right: 20px;
        }
      }
    }
    .input-text__wrapper {
      max-width: unset;
    }
    @include breakpoint($brake-md) {
      .calc-input__error {
        width: calc(100% + 40px);
        position: static;
      }
    }
    .calc__results {
      //@include breakpoint($brake-md)
      padding-top: 32px;
      margin-top: 8px;
      flex-direction: column;
      &-details-label {
        vertical-align: text-bottom;
      }
    }
    .calc__carousel-header {
      text-align: left;
    }
    .calc-input .input-text__append {
      margin-left: 10px;
    }
    .calc__options .input-checkbox {
      margin-top: -5px;
    }
  }

  // style tylko dla kalkulatora szerokiego
  .calc-combo {
    .input-checkbox {
      top: 0;
    }

    .calc {
      &__carousel {
        &-header {
          font-size: 15px;
          margin-top: 0;
        }

        @include breakpoint($brake-xs-sm) {
          &-header {
            text-align: left;
          }
        }
      }
      &__summary {
        margin-bottom: 80px;
        & > div {
          margin-bottom: 30px;
        }
      }
      &__results {
        margin-top: 20px;
        &-details-item {
          min-height: unset;
          margin-bottom: 18px;
          h3 {
            @include pko-font-size(30);
            span {
              @include pko-font-size(19);
            }
          }
        }

        @include breakpoint($brake-md) {
          margin-top: 0;
          &-details-item {
            &:first-child {
              margin-bottom: 20px;
            }
          }
        }
      }
      &__options {
        h5 {
          margin-bottom: 15px;
        }

        @include breakpoint($brake-md) {
          &:first-child {
            padding-right: 50px;
            flex: 0 0 66%;
          }
        }
      }
    }

    .calc-combo__inputs__row {
      @include breakpoint($brake-md) {
        flex-wrap: nowrap;
      }
    }

    .overlay {
      margin: 0;
      width: 100%;
    }
  }
}
