.landing-header {
  @extend %paddington;
  @extend %pko-clearfix;
  position: relative;

  .landing-cta {
    @extend %paddington;
    @include pko-font-size($font-size-t2-mobile);
    color: $color-red;
    position: absolute;
    top: 15px;
    left: 0;
    padding-right: 150px;

    @include breakpoint($brake-sm) {
      display: none;
    }
  }

  &__title {
    @include pko-font-size($font-size-h2-mobile);
    font-weight: bold;
    text-transform: uppercase;
    clear: both;
    margin-bottom: 30px; // #77264, tak przy okazji, było zero ale na mobile bylo sklejone z .text
    position: relative;
    @include pko-z-index(default);

    @include breakpoint($brake-sm) {
      margin-bottom: 0; // #77264, tak przy okazji, było zero ale na mobile bylo sklejone z .text
    }
  }
  &__logo {
    @extend %paddington;
    @include pko-z-index('sitenav');
    position: relative;
    float: right;
    margin: 15px -15px 0 0;
  }
  &__mobile-image {
    max-width: 100%;
    margin-top: -50px;
  }

  @include breakpoint($brake-sm) {
    &__logo {
      margin: 40px 0 0 100px;
      img {
        margin-top: 0;
      }
    }
    &__title {
      clear: none;
      margin: 0;
      padding: 60px 0 40px;
      @include pko-font-size(36);
      min-height: 180px;
    }
    &__mobile-image {
      display: none;
    }
  }

  @include breakpoint($brake-md) {
    &__logo {
      margin-top: 45px;
      img {
        margin-top: 0;
      }
    }
    &__title {
      @include pko-font-size($font-size-h1);
      padding: 60px 0;
      min-height: 228px;
    }
  }

}
