.disclaimer-overlay {
  @include pko-z-index('ajax-overlay-top');
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  display: none;
}

.disclaimer {
  display: none;
  &__text {
    margin: 20px 0 30px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid $color-gray-fifth;
    padding: 10px 0 10px 15px;
    border-radius: 2px;
    max-height: 120px;
    max-height: 30vh;

    &,
    & p {
      @extend %text-default-3;
    }

    &-inner {
      padding-right: 25px;
    }

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    @include breakpoint($brake-sm) {
      min-width: 464px;
      height: 250px;
      overflow-y: auto;
      max-height: none;
    }
  }

  &__no {
    float: left;
    margin-top: 8px;
  }

  &__yes {
    float: right;
  }

}
