.call {

  &__top {
    @include pko-font-size($font-size-t2-mobile);
    color: $color-gray-middle;

    @include breakpoint($brake-sm) {
      @include pko-font-size(14);
    }

    &-more {
      display: none;

      @include breakpoint($brake-sm) {
        display: inline;
      }
    }
  }
  &__phone {
    @include pko-font-size($font-size-h3-mobile);

    color: $link-color;
    display: inline-block;
    margin-top: -1px;

    // @include pko-on-event() {
    // color: $link-color;
    //}

    @include breakpoint($brake-sm) {
      @include pko-font-size(20);
      margin-top: 0;
    }
  }

}
