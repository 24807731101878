.text table:not(.clean-table),
.table:not(.clean-table) {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $color-gray-fifth;

  th,
  td {
    @extend %text-default-2;
    border: 1px solid $color-gray-fifth;
    text-align: center;
  }

  th {
    padding: 11px 5px;
  }

  td {
    padding: 8px 5px 6px;
  }

  tr:not(.expandable-row) {
    &:nth-child(even) {
      background: $color-gray-sixth;
    }
  }
}

.text table.tableimageleft {
  border: none;

  th,
  td {
    border: none;
    text-align: left;
  }
}

.table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
  position: relative;
  margin: 23px 0;
  // wykomentowałem to niżej bo było dziwne
  //padding-right: 1px;
  //width: calc(100% + 1em); // 1em to standard-wrap padding-right

  .scrollable {
    margin-left: 45%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    tr {
      height: 40px;
      line-height: 40px;

      &:last-of-type {
        border-bottom: 1px solid $color-gray-fifth;
      }
    }
  }

  &.table--header-and-footer {
    tr {
      background: none;

      &:first-child,
      &:last-child {
        background: $color-gray-sixth;
      }
    }
  }
}

.table--stripped {
  table-layout: fixed;

  td, th {
    color: $color-black;
    padding-left: 12px;
    padding-right: 12px;
  }

  th {
    background-color: $color-gray-eight;
    font-weight: normal;
  }

  tr:nth-child(2n+1) {
    td {
      background-color: $color-gray-eight;
    }
  }
}

.table {

  /* przekształcenie tabeli na grid */

  &--to-grid {
    @media screen and (max-width: 767px) {

      border: none !important;
      display: block;

      & > tbody {
        & > tr {
          border: 1px solid $color-gray-fifth !important;
        }

        & > tr:not(.expandable-row) {
          margin-top: 15px;
          border-bottom: none !important;
        }

        & > tr.expandable-row {
          border-top: none !important;
        }
      }

      thead {
        display: none;
      }

      table, tbody, tr, td {
        display: block;
        width: 100%;
        text-align: left !important;
      }

      table {
        border: none !important;

        td {
          border: none !important;
          padding: 8px 5px 6px !important;

          &:not(:last-of-type) {
            border-bottom: 2px solid $color-gray-fifth !important;
          }
        }
      }

      tr.expandable-row, tr.expanded {
        display: block !important;

        & > td {
          padding: 0 !important;
        }

      }

      label {
        margin-right: 5px;
        font-weight: 600;
      }
    }
  }

  /* rozwijanie tabeli */
  tr.expandable-row {
    background: #fff;
    display: none;

    &.expanded {
      display: table-row;
    }

    .table {
      th {
        padding: 5px 3px;
      }

      td {
        padding: 4px 3px 3px;
      }
    }
  }
}