.single-point-map {
  position: relative;
  height: 280px;
  padding-top: 20px;
  padding-bottom: 20px;

  &--full-size {
    @extend %paddington;
    margin-left: -15px;
    margin-right: -15px;
    @include breakpoint($brake-sm-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include breakpoint($brake-sm) {
    height: 550px;
    margin: 0;
  }

  @include breakpoint($brake-md) {
    padding-top: 0;
  }

  // a.k.a the map
  &__inner {
    background-color: $color-gray-fifth;
    width: 100%;
    height: 100%;
  }

  &__infowindow {
    font-family: $text-font-stack;
    height: auto;
    margin: 10px;

    &-title {
      @include pko-font-size(16);
      padding-top: 10px;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-weight: 700;
      color: $color-black;

      &--small {
        margin-bottom: 2px;
      }
    }

    &-address {
      @include pko-font-size(16);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      strong,
      b {
        color: $color-black;
      }

      p {
        margin: 8px 0;
        &:first-child {
          margin: 0 0 8px;
        }
        &:last-child {
          margin: 8px 0 0;
        }
      }
    }

  }
}


