.snippet-slider-youtube {
  @extend %paddington;
  @extend %pko-owl-arrows;
  position: relative;
  @include breakpoint($brake-sm) {
    &::before {
      content: '';
      display: block;
      height: 80px;
    }
  }

  padding-bottom: $vertical-space-mobile;
  margin-bottom: $vertical-space-mobile;

  @include breakpoint($brake-sm) {
    padding-bottom: #{$vertical-space-desktop - 5px};
    margin-bottom: #{$vertical-space-desktop - 5px};
  }

  &__text {
    padding-bottom: 40px;
  }

  &__header {
    margin-top: 0;
    @include pko-font-size($font-size-h3-mobile);
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
      @include pko-font-size($font-size-h2);
    }
  }

  &__slides {
    position: relative;
    @include breakpoint($brake-sm) {
      padding: 0 70px;
    }

    &.js-slider img {
      @include breakpoint($brake-sm) {
        max-width: calc(100% - 60px);
      }
    }
  }

  &__slide {
    background: $color-white;
    @extend %pko-clearfix;
    &-img {
      height: auto;
      vertical-align: bottom;
      border: 1px solid $color-gray-main;
    }

    &-link {
      position: relative;
      @include pko-z-index(default);
      display: block;

      &::before, &::after {
        @include pko-z-index(default);
        display: block;
        position: absolute;
      }

      &::before {
        background: rgba($color-blue, 0.8);
        top: 50%;
        left: 50%;
        width: 90px;
        height: 90px;
        content: '';
        margin: -45px 0 0 -45px;
        border-radius: 50%;
      }

      &::after {
        @extend %icomoon;
        font-size: 60px;
        content: $icon-play;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        color: $color-white;
        margin: -30px 0 0 -30px;
      }

      @include pko-on-event() {
        &::before {
          background: rgba($color-white, 0.8);
        }
        &::after {
          color: $color-blue;
        }
      }
    }
  }
}
