.lp-good-morning-map {
  @extend %pko-clearfix;

  @include breakpoint($brake-sm) {
    &::before {
      content: '';
      display: block;
      height: 80px;
    }
  }

  padding-bottom: $vertical-space-mobile;
  margin-bottom: $vertical-space-mobile;

  @include breakpoint($brake-sm) {
    padding-bottom: #{$vertical-space-desktop - 5px};
    margin-bottom: #{$vertical-space-desktop - 5px};
  }

  &__details {
    @extend %paddington;
    margin-bottom: 36px;

    @include breakpoint($brake-md) {
      @include span(6);
      margin-bottom: 0;
    }
  }

  &__map {
    @extend %paddington;

    position: relative;
    margin: 0 -15px;

    @include breakpoint($brake-md-max) {
      padding: 0;
    }

    @include breakpoint($brake-sm) {
      margin: 0 -18px;
    }

    @include breakpoint($brake-md) {
      @include span(6);
      margin: 0;
    }

    &-inner {
      background-color: $color-gray-fifth;
      height: 512px;
      @include breakpoint($brake-md) {
        height: 600px;
      }
    }
  }

  &__infowindow {
    font-family: $text-font-stack;
    height: auto;
    margin: 10px;

    &-title {
      @include pko-font-size(16);
      padding-top: 10px;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-weight: 700;
      color: $color-black;

      &--small {
        margin-bottom: 2px;
      }
    }

    &-address {
      @include pko-font-size(16);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      strong,
      b {
        color: $color-black;
      }

      p {
        margin: 8px 0;
        &:first-child {
          margin: 0 0 8px;
        }
        &:last-child {
          margin: 8px 0 0;
        }
      }
    }

  }

  &--wide-map {
    @include breakpoint($brake-sm) {
      width: 75%;
      margin-left: auto;
      margin-right: auto;

      &::before {
        content: '';
        display: block;
        height: 0;
      }
    }

    .lp-good-morning-map__map-inner {
      height: 400px;

      @include breakpoint($brake-sm) {
        height: 512px;
      }

      @include breakpoint($brake-md) {
        height: 600px;
      }
    }
  }
}
