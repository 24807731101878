// Style dla snippetu Aplikacja mobilna IKO na stronie głównej infosite,
// https://redmine.k2.pl/issues/95886

.iko-homepage-wrap {
  @extend %paddington;
  overflow: hidden; //brakowało gdyż niewrapywało float: left wewnątrz #122120

  .inline-form-typ-message {
    display: none;
    margin-bottom: $vertical-space-desktop;

    @include breakpoint($brake-md) {
      display: block;

      h3 {
        @include pko-font-size(20);
      }
    }
  }
}

.iko-snippet-infosite {
  position: relative;
  padding-bottom: $vertical-space-mobile;

  @include breakpoint($brake-md) {
    padding-bottom: 0;
  }

  .site-content__module-header {
    margin-bottom: 25px;
    padding-left: 0;

    @include breakpoint($brake-sm) {
      @include pko-font-size($font-size-h3);
      margin-bottom: 70px;
    }

    &:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: 14px;
      background-image: pko-image('iko/icon.svg');
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;

      @include breakpoint($brake-sm) {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
    width: 206px;
    height: auto;

    @include breakpoint($brake-sm) {
      float: left;
      padding-right: 5%;
      width: 40%;
      height: auto;
    }

    @include breakpoint($brake-md) {
      width: 30%;
    }
  }

  &__subtitle {
    @include pko-font-size($font-size-h4);
    margin-bottom: 25px;
    padding-top: 20px;
  }

  &__text-box {
    @include breakpoint($brake-sm and $brake-md-max) {
      &::after {
        display: table;
        clear: both;
        content: '';
      }
    }
  }

  &__info-text {
    margin-bottom: 10px;
  }

  &__info-statistics {
    text-align: center;

    @include breakpoint($brake-sm) {
      margin-top: 40px;
    }

    &--text-grey {
      color: $color-gray-second;
    }
  }

  &__info-statistics-header {
    color: $color-blue;
    @include pko-font-size($font-size-h2);
    font-weight: 700;
    margin: 22px 0 0 0;
  }

  &__info-statistics-text {
    color: $color-gray-second;
    @include pko-font-size($font-size-h3);
    margin-top: 10px;
  }

  &__info-statistics-box {
    .icon-star {
      color: $color-blue;
      padding-right: 10px;
      position: relative;
      top: 5px;
      @include pko-font-size(40);
    }

    @include breakpoint($brake-sm) {
      float: left;

      &--first {
        width: 25%;

        @include breakpoint($brake-md) {
          width: 17%;
        }
      }

      &--second {
        width: 50%;
        position: relative;
        bottom: 6px;

        @include breakpoint($brake-md) {
          width: 35%;
        }
      }

      &--third {
        width: 25%;

        @include breakpoint($brake-md) {
          width: 17%;
        }
      }
    }
  }

  &__app-store-icon {
    display: block;
    margin: 0 auto 10px auto;
    width: 260px;

    &.hide-on-mobile {
      display: none;
    }

    @include breakpoint($brake-sm) {
      &.hide-on-desktop {
        display: none;
      }
    }
  }
}

// Formularz pod snippetem IKO App, https://redmine.k2.pl/issues/95887
.iko-app-phone-callback {
  display: none;
  margin-bottom: $vertical-space-desktop;

  @include breakpoint($brake-md) {
    display: block;
  }

  &__input {
    width: 300px;
    margin: 0 12px;
  }

  &__paragraph {
    color: $color-black;
    @include pko-font-size(15);

    @include breakpoint($brake-md-lg) {
      @include pko-font-size(17);
    }
  }

  &__prefix {
    width: 58px;
    padding: 8.5px 15px 8.5px 15px;
    border: 1px solid $color-gray-third;
    border-radius: 3px;
    color: $color-gray-main;
    background-color: $color-gray-disabled;;
  }
}
