.process {
  margin: 40px 0 0;

  .with-padding & {
    @extend %paddington;
  }

  &__header {
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: bold;
    font-family: $text-font-stack;
  }

  &__tabs {
    text-align: center;
    font-family: $text-font-stack;
    font-size: 12px;
    display: table;
    width: 100%;

    @include breakpoint($brake-sm) {
      font-size: 14px;
    }
  }

  &__tab {
    border: 1px solid $color-gray-fifth;
    border-left: 0;
    background: $color-gray-sixth;
    display: table-cell;
    padding: 15px 10px;
    vertical-align: middle;
    width: 50%;

    &:first-child {
      border-left: 1px solid $color-gray-fifth;
      border-radius: 5px 0 0;
    }

    &:last-child {
      border-radius: 0 5px 0 0;
    }

    &.active, &:active, &:focus {
      background: $color-blue;
      color: $color-white;
      border-color: $color-blue;

      .process__tab-title, .process__tab-title:focus {
        color: $color-white;
      }
    }
  }

  &__contents {
    border: 1px solid $color-gray-fifth;
    border-top: 0;
    padding: 20px 10px;
    counter-reset: steps;

    &--borderless {
      border: 0;
    }

    &--pad-mod {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__content {

    &--break {
      margin: 0 -20px;
    }

    &-step {
      display: block;
      width: 100%;
      padding: 20px;

      &-icon {
        display: block;
        width: 80%;
        height: auto;
        margin: 0 auto 10px;
      }

      &-text {
        position: relative;

        &:before {
          counter-increment: steps;
          content: counter(steps);
          position: absolute;
          background-color: $color-blue;
          font-family: $text-font-stack;
          color: $color-white;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          font-size: 18px;
          top: -42px;
          left: 0;
        }
      }
    }

    &-cta {
      clear: left;
      text-align: right;
      padding: 0 20px;

      .cta {
        float: right;
      }
    }

  }
}

@media all and (min-width: 560px) {
  .process__content-step {
    width: 50%;
    float: left;

    &:nth-child(2n + 1) {
      clear: left;
    }
  }
}

@media all and (min-width: 640px) {
  .process__content-step {
    width: 33%;

    &:nth-child(2n + 1) {
      clear: none;
    }

    &:nth-child(3n + 1) {
      clear: left;
    }
  }
}

@media all and (min-width: 720px) {
  .process__content-step {
    width: 25%;

    &:nth-child(3n + 1) {
      clear: none;
    }

    &:nth-child(4n + 1) {
      clear: left;
    }

    &--five-steps-on-row {
      width: 20%;

      &:nth-child(4n + 1) {
        clear: none;
      }

      &:nth-child(5n + 1) {
        clear: left;
      }
    }
  }
}
