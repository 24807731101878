.bankomaty {
  // @extend %break-from-wrap-paddington;
  //@extend %break-from-paddington;

  width: calc(100% + (#{$padding-xs} * 2));
  margin: 0 -#{$padding-xs};

  @include breakpoint($brake-sm) {
    width: calc(100% + (#{$padding-sm} * 2));
    margin: 0 -#{$padding-sm};
  }

  @include breakpoint($brake-md) {
    width: calc(100% + 80px);
    margin: 0 -40px;
  }

  @include breakpoint($brake-lg) {
    width: calc(100% + 80px);
    margin: 0 -40px;
  }

  #product & {

    width: calc(100% + ((#{$padding-xs} + 15px) * 2));
    margin: 0 calc((#{$padding-xs} + 15px) * -1);
  
    @include breakpoint($brake-sm) {
      width: calc(100% + ((#{$padding-sm} + 15px) * 2));
      margin: 0 calc((#{$padding-sm} + 15px) * -1);
    }
  
    @include breakpoint($brake-md) {
      width: calc(100% + 80px);
      margin: 0 -40px;
    }
  
    @include breakpoint($brake-lg) {
      width: calc(100% + 80px);
      margin: 0 -40px;
    }

  }
}