.snippet-slider-gfx {
  $root: &;

  @extend %paddington;
  @extend %pko-owl-arrows;
  position: relative;
  @include breakpoint($brake-sm) {
    &::before {
      content: '';
      display: block;
      height: 80px;
    }
  }

  padding-bottom: $vertical-space-mobile;
  margin-bottom: $vertical-space-mobile;

  @include breakpoint($brake-sm) {
    padding-bottom: #{$vertical-space-desktop - 5px};
    margin-bottom: #{$vertical-space-desktop - 5px};
  }

  &__text {
    padding-bottom: 40px;
  }

  &__header {
    margin-top: 0;
    @include pko-font-size($font-size-h3-mobile);
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
      @include pko-font-size($font-size-h2);
    }
  }

  &__slides {
    position: relative;
    @include breakpoint($brake-sm) {
      padding: 0 70px;
    }

    &.js-slider #{$root}__slide-img {
      @include breakpoint($brake-sm) {
        max-width: calc(100% - 60px);
      }
    }
  }

  &__slide {
    background: $color-white;
    @extend %pko-clearfix;

    &-img {
      position: absolute;
      max-height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: auto;
      width: auto;
      vertical-align: bottom;
    }

    &-link {
      position: relative;
      padding-bottom: 56.25%; // 16:9
      @include pko-z-index(default);
      display: block;
    }
  }
}
