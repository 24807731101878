.landing-content {

  /*
   -------------    -------------    -------------
  |   SNIPPETY  |  |     TEXT    |  |    IMAGE    |
   -------------    -------------    -------------
  */

  // lub (dynamiczna konfiguracja: lpCopyRearrange -> 1)

  /*
     -------------    -------------
    |   SNIPPET   |  |    IMAGE    |
     -------------    -------------
     -------------
    |   SNIPPET   |
     -------------
     -------------
    |    TEXT     |
     -------------
  */

  $root: &;

  // tablet and up
  @include breakpoint($brake-sm) {
    &--v4 &__info {
      @include span(7);
      float: right !important; // override default grid float
    }

    &--v4 &__snippet {
      @include span(5);
    }
  }

  &__snippet-inner-bottom {

    margin-left: -15px;
    margin-right: -15px;
    padding-left: 30px;
    padding-right: 30px;

    @include breakpoint($brake-sm) {
      margin: 0;
      padding: 0;
    }
    & > * {
      margin-top: 20px;
    }
  }

  // laptop screen and up
  @include breakpoint($brake-md) {
    &--v4 &__info {
      @include span(8);
    }

    &--v4 &__copy {
      @include span(4);
      width: $large-text-width;
    }

    &--v4 &__image {
      @include span(8);
      width: $large-image-width; // poco to jest ???
    }

    &--v4-text-bottom &__image {
      width: 100%;
      float: none;
    }

    &--v4 &__snippet {
      @include span(4);
    }

    &--v4 {
      #{$root}__snippet--Z #{$root}__snippet-inner,
      #{$root}__snippet--S #{$root}__snippet-inner,
      #{$root}__snippet--G #{$root}__snippet-inner {
        margin-top: -30px !important;
      }
    }
  }

}
