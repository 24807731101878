.calcQualification {
  position: relative;
  @extend %paddington;
  margin: 25px 0 0;
  @include breakpoint($brake-sm) {
    margin: 45px 0 0;
  }

  .site-content__module:last-child & {
    @include breakpoint($brake-sm) {
      margin-bottom: -45px;
    }
  }

  .calcQuestion {
    position: relative;
  }

  .form {
    //border: 1px solid $border-color;
    //border-width: 1px 0 0;
    padding: 25px 0 0;
    @include breakpoint($brake-sm) {
      padding: 47px 0 0;
    }
    &.result {
      .input-radio,
      .next,
      .js-hideOnFinish {
        display: none;
      }
      .last {
        display: block;
      }
      &.lose .last {
        display: none;
      }
    }
    &[data-begin="true"] {
      .prev {
        display: none;
      }
    }
  }

  .tip .tip-content {
    display: none;
  }

  .calcContent {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .input-radio {
    margin-bottom: 10px;
    &:first-of-type {
      padding-right: 25px;
    }
  }

  h2 {
    @extend %h3;
    margin: 0 0 18px;
    @include breakpoint($brake-sm) {
      margin: 0 0 40px;
    }
  }
  h3 {
    @extend %h4;
    margin: 0 0 21px;
    @include breakpoint($brake-sm) {
      margin: 0 0 17px;
    }
  }

  //
  // scss-lint:disable IdSelector
  #calcQuestionText {
    @extend %text-default-1;
    margin: 0 0 9px;
  }
  // scss-lint:enable IdSelector

  .calcButton {
    &.next,
    &.last {
      float: right;
    }
    &.prev {
      @extend %button-theme;
      float: left;
      background: none;
      border: none;
      color: $color-blue;
      padding-left: 0;
    }
    &.last {
      display: none;
    }
  }

  .calcNavigation {
    @extend %pko-clearfix;
    padding: 20px 0 30px;
  }

  .form__error {
    padding-top: 0;
  }

  .text {
    border-top: 1px solid $border-color;
    padding-top: 25px;
    @include breakpoint($brake-sm) {
      padding-top: 45px;
    }

    .small {
      @extend %text-default-3;
      margin-bottom: 0 !important;
    }
  }

}
