// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
@import '_header/site-nav';
@import '_header/hamburger';
@import '_header/banner';
@import '_header/login-layer';
@import '_header/login-layer-neon';
@import '_header/search-layer';
@import '_header/prevent-jump-fix';

$menu-transition-time: 0.7s;

// W PKO są dwa paddingtony wykorzystywane do lewego i prawego marginesu
// %wrap-paddington i %paddington oba reagują na szerokość ekranu dlatego
// ważne jest żeby przy wyrównywaniu marginesów korzystać tylko z nich

// Header dostaje tak samo jak content dwa marginesy.
// Pierwszy margines z %wrap-paddington z kontenera #wrap (najgłówniejszy z głównych)
// Drugi margines %paddington pochodzi z .header__inner

// Header na urządzeniach mobilnych jest sticky. Element który staje się fixed (.header__inner)
// Ignoruje margines z #wrap dlatego trzeba zapewnić headerowi %wrap-paddington z innego źródła

// Gdy menu jest sticky %paddington na .header__inner jest wyłączany natomiast
// włączany jest %wrap-paddington na .header__sticky-inner i %paddington .header__sticky-paddington.
// Wszystko dzieje się w ramach .header__inner fixed 100% żeby uniknąć "łataniał w innych miejsach

// Poniżej zostawiłem komentarze w miejscu występowania wcześniej wspomnianych marginesów
// Trzeba pamiętać że #wrap dodaje %wrap-paddington ze swojej strony
// ale jest to prawdą tylko gdy menu nie jest sticky

.header {
  $root: &;

  .select2 {
    max-width: 360px;
    // RM 104719 - szerokośc pola w formularzu
    &--contact-form {
      max-width: none;
    }
  }
  @extend %pko-clearfix;
  position: relative;
  padding-bottom: 0;
  margin-bottom: 20px;

  @include breakpoint($brake-sm-max) {
    //overflow: hidden; //#118023 - przysłaniał tło kalkulatora w bannerze

    &.header--menu-open,
    &.header--login,
    &.header--search {
      @include pko-z-index(actionsnav);
    }
  }

  .breakline {
    display: none;
    //
    // scss-lint:disable IdSelector
    #homepage & {
      display: block;
    }
    // scss-lint:enable IdSelector
  }

  &__h1 {
    margin: 0;
  }

  &__inner {

    // todo to jest tak bardzo złe - strona skakała a ja się spieszyłem, bo urlop, bo trzeba...
    &--visibility-hidden {
      visibility: hidden;
    }

    @extend %paddington; // Standardowy paddington jak menu nie jest sticky
    background: transparent;
    max-height: 56px;
    transition: max-height $menu-transition-time linear;
    overflow: hidden;

    &.overflow {
      overflow: visible;
      position: relative;
      right: 0;
      @include pko-z-index(cosmos);
    }

    @include pko-header-expanded {
      max-height: 100%;
    }

    #{$root}--expanded & {
      // REALLY expanded, after animation and timeout
      overflow: auto;
    }

    @include pko-header-expanded-or-sticky() {
      @include pko-z-index('stickynav');
      position: fixed;
      top: 0;
      left: 0;
      padding: 0; // Wyłączamy standardowy paddington jak menu staje się sticky
      border-bottom: 1px solid $border-color;
      width: 100%;
      background: $color-white;
    }
  }

  @include breakpoint($brake-sm) {
    &.header--with-tabs {
      margin-bottom: 90px;

      .breakline {
        display: none;
      }

      &-big {
        margin-bottom: 78px;
      }
    }
  }

  &__sticky-inner {
    min-height: 55px;

    @include pko-header-expanded-or-sticky() {
      @include container($max-width);
      padding-bottom: 55px;
    }

    @include pko-header-expanded-or-sticky() {
      .no-touch & {
        //padding-right: 17px;
      }
      // Dodajemy brakujący paddigton wrap bo menu jest sticky
      // i nie obowiązuje go wrap-paddington z wrapa
      @extend %wrap-paddington;
    }
  }

  &__sticky-paddington {
    @include pko-header-expanded-or-sticky() {
      @extend %paddington;
    }
  }

  &--sticky,
  &--login,
  &--search,
  &--menu-open {
    padding-top: 55px;
  }

  &-mobile-cta {
    display: none;
    float: right;
    margin-top: 5px;
    #{$root}--sticky & {
      display: block;
    }
  }

  &__actions {
    @include pko-z-index('sitenav');
    font-size: 0;
    float: left;
    margin-top: 5px;
    color: $text-color;
    position: relative;
    margin-left: 60px;

    &--no-menu {
      margin-left: 0;
    }

    @include pko-header-menu-expanded() {
      // higher z-index when mobile menu is expanded
      // (don't let hamburger/close icon to cover over it)
      @include pko-z-index('actionsnav');
    }
  }

  &__inbox {
    float: right;
    position: relative;
    padding: 0 5px;
    // margin-left: 9px;

    &:hover {
      cursor: pointer;
      color: $link-color;
      #{$root}__icon {
        color: $link-color;
      }
    }

    #{$root}__icon {
      @include pko-font-size(25);
      right: 4px;
      top: 10px;
    }

    &-indicator {
      @include new-message-alert(13, 8, -2);
    }

    &--with-cta {
      padding-left: 0;
      padding-right: 0;
      float: none;

      @include breakpoint($brake-md) {
        float: right;
        margin: 0 11px;
      }

      @include breakpoint($brake-lg) {
        margin: 0;
        padding-left: 5px;
        padding-right: 5px;
      }

      &:active #{$root}__get-loan-cta,
      &:focus #{$root}__get-loan-cta {
        color: $color-white;
      }
    }
  }

  &__open-account-cta {
    margin: 4px 0 0 20px;
    @include breakpoint($brake-md) {
      float: right;
      margin: 4px 0 -25px 11px;
      padding: 8px 10px;

      & + #{$root}__action__extra-link {
        margin-right: 20px;
      }
    }
  }

  // https://redmine.k2.pl/issues/109018
  &__get-loan-cta {
    $color-background-get-loan: #DB912C;
    $color-bg-hover-get-loan: #C98B00;

    @extend #{$root}__open-account-cta;
    background-color: $color-background-get-loan;

    margin-left: 11px;

    @include breakpoint($brake-md) {
      display: none;
    }

    @include breakpoint($brake-lg) {
      display: block;
    }
  }

  &__get-loan-icon.header__icon {
    display: none;
    position: relative;

    color: $link-color;

    @include pko-on-event() {
      color: $color-gray-third;
    }

    @include breakpoint($brake-md) {
      display: inline-block;
    }

    @include breakpoint($brake-lg) {
      display: none;
    }
  }

  &__open-account-banner {
    padding: 15px 20px;
    border-bottom: 1px solid $border-color;
    background-color: $color-gray-eight;
    box-shadow: 0 3px 5px rgba($color-black, 0.25) inset;
    margin: 47px -15px 0;
  

    @include breakpoint($brake-sm-max) {
      margin-top: 5px;

      & + .banner {
        margin-top: 20px;
      }
      & + .banner.banner--short {
        margin-top: 10px;
      }
      .homepage & + .banner {
        margin-top: 0;
      }
    }

    &-text {
      color: $color-black;
      display: block;
      line-height: 18px;
      @include pko-line-height($font-size-t2, 17);
    }

    &-cta {
      margin-left: 15px;
    }

    @include breakpoint($brake-xxs-max) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      &-cta {
        order: 2;
        margin-left: 0;
        margin-top: 5px;
      }

      &-text {
        width: 100%;
        display: block;
        order: 1;

        br {
          display: none;
        }
      }
    }

    &.hidden {
      display: none;
    }
  }

  //
  // scss-lint:disable TransitionAll
  &__action {
    float: right;
    transition: all 0.4s linear;
    color: $text-color;
    position: relative;

    &:hover {
      cursor: pointer;
      color: $link-color;
      #{$root}__icon,
      .icon-drop-down {
        color: $link-color;
      }
    }

    // dynamic configuration iko-logo
    &__logo {
      margin: 8px 0 0;
      display: none;
      @include breakpoint($brake-sm) {
        margin-left: 20px;
        display: block;
      }

      @include breakpoint($brake-md) {
        margin-left: 11px;
      }

      img {
        position: relative;
        top: 5px;
        height: 20px;
        width: 20px;
        max-height: 100%;
        max-width: 100%;
        margin-right: 7px;
      }
    }

    // Dodatkowy link, np. dla TFI https://redmine.k2.pl/issues/102466
    &__extra-link {
      display: none;

      @include breakpoint($brake-sm) {
        display: block;
        padding-top: 13px;
        margin-left: 20px;
      }

      @include breakpoint($brake-md) {
        margin-left: 0;
      }
    }
  }

  // scss-lint:enable TransitionAll

  &--login &__login &__icon,
  &--search &__search &__icon,
  &--login &__login .icon-drop-down {
    cursor: pointer;
    color: $link-color;
  }

  &--login &__login .icon-drop-down {
    transform: rotate(180deg);
    top: 7px !important;
  }

  //
  // scss-lint:disable TransitionAll
  &__login {
    display: none;
    @include breakpoint($brake-sm) {
      display: block;

      #{$root}__icon {
        font-size: 0;
      }
    }
    white-space: nowrap;
    margin-top: -2px;

    &--url {
      margin-top: 0;
      padding-top: 13px;

      &:hover span {
        color: $link-color;
      }
    }

    &--tfi {
      margin: 4px 0 -25px 11px;
      padding: 8px 10px;
      transition: background-color 250ms;
      color: $color-white;
    }

    .icon-drop-down {
      display: none;
      transition: all 0.4s linear;
      @include breakpoint($brake-sm) {
        font-size: 32px;
        top: 8px;
        left: -6px;
        position: relative;
        display: inline-block;
      }
    }
  }
  // scss-lint:enable TransitionAll

  &__login--mobile {
    display: block;
    @include breakpoint($brake-sm) {
      display: none;
    }
  }

  //
  // scss-lint:disable TransitionAll
  &__icon {
    @include pko-font-size(30);
    color: $color-gray-third;
    display: inline-block;
    position: relative;
    top: 8px;
    margin-left: 16px;
    margin-right: -4px;
    transition: all $trans-hover linear;
  }
  // scss-lint:enable TransitionAll

  &__logo {
    @extend %paddington;
    @include pko-z-index('sitenav');
    float: right;
    margin: -40px 0;
    position: relative;

    @include breakpoint($brake-sm-max) {
      .wifi & {
        position: absolute;
        float: none;
        right: 0;
        top: 50px;
      }
    }
  }

  &-features {
    @include pko-z-index('sitenav');
    @extend %pko-clearfix;
    @include pko-font-size($font-size-t2);
    position: relative;
    @include breakpoint($brake-sm) {
      &:not(:empty) {
        min-height: 120px;
      }
      &.neon-product-bottom:not(:empty) {
        min-height: 0;
      }
    }

    @include pko-header-expanded {
      @include pko-z-index('zero');
    }
  }

  &--bigger {
    @include breakpoint($brake-sm) {
      //padding-bottom: $vertical-space-desktop + 42;
    }
  }

  @include breakpoint($brake-sm) {
    padding-bottom: $vertical-space-desktop;
    margin-bottom: $vertical-space-desktop; //usunąłem te 8 pikseli ponieważ psuło taby w produktach
    //gdyby trzeba było znów dodać lepiej zastosować modyfikator
    //zamiast zmieniać globalnie

    // np. szablon "Artykul"
    &--bigger {
      padding-bottom: $vertical-space-desktop + 28;
    }

    .breakline {
      display: block;
    }

    &__actions {
      @include pko-font-size($font-size-t2);
      a:not(.cta):hover {
        color: $link-color;
      }
    }

    &__search {
      font-size: 0;
    }

    &__logo {
      position: absolute;
      right: 0;
      top: 70px;
      margin: 0;

      .wifi & {
        top: 40px;
      }
    }
  }

  @include breakpoint($brake-md) {

    &__inner {
      min-height: 100px;
      max-height: 130px;
      margin-bottom: -45px;
    }

    &__actions {
      @include pko-z-index('actionsnav');
      float: right;

      @include pko-header-sticky() {
        margin-top: 4px;
      }

      margin-left: 0;
    }

    &__login:not(&__login--url) {
      margin-right: -10px;
    }

    &__logo {
      top: 138px;

      .wifi & {
        top: 40px;
      }
    }

    &__icon {
      margin-left: 11px;
    }

  }

  // Fix dla strony IPKO BIZNES ZMIENIA SIĘ NA LEPSZE (04.10.2017)
  // ( http://cms.pkobp.pl.k2prod.inteligo.com.pl/admin2/tree/node/#url=/admin2/redesignproducts/edit/118619/ )
  // usunięte marginesy dolne oraz paddingi dolne dla elementu .header na rozdzielczościach powyżej mobilnych
  // ustawiamy w CMSie "Dodatkowe klasy CSS" na: header--no-padding-and-margin-bottom
  &--no-padding-and-margin-bottom .header {
    @include breakpoint($brake-sm) {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  // https://redmine.k2.pl/issues/107296
  // Potrzeba zmieszczenia wszystkich linków w jednej linii na tabletach o małej rozdzielczości
  // trochę niezgodnie ze sztuką
  @include breakpoint($brake-md $brake-md-lg) {
    #{$root}__inner,
    #{$root}__sticky-paddington {
      padding: 0;
    }
  }

  &__category-title {
    @extend .banner__category-title;

    margin-top: 40px;
    margin-bottom: 0;

    @extend %paddington;

    @extend %h2;
    text-transform: uppercase;
    @include pko-normalcase-in-uppercase;
    font-weight: bold;

    & + .banner--smaller-title {
      margin-top: 20px;
    }
  }
}

.header-logo {
  img {
    max-width: 100%;
    max-height: 100%;
  }

  height: 41px;

  &--pko-dc {
    width: 70px;
    height: auto;
    margin: -51px 0;
  }

  &--pko-white-bg-dc {
    width: 86px;
    height: auto;
    margin: -56px 0;
  }

  &--pko-leasing-dc {
    width: 110px;
  }

  &--pko-leasing-white-bg-dc {
    width: 130px;
    height: 58px;
    margin: -48px 0;
  }

  &--pko-bank-hipoteczny-dc {
    width: 163px;
  }

  &--pko-fintech-dc {
    width: 170px;
    height: 45px;
  }

  &--pko-ofe-dc {
    width: 91px;
  }

  &--pko-pte-dc {
    width: 88px;
  }

  &--pko-tfi-dc {
    width: 83px;
  }

  &--pko-tfi-white-bg-dc {
    width: 100px;
    height: 56px;
  }

  &--pko-faktoring-dc {
    width: 121px;
  }

  &--pko-faktoring-white-bg-dc {
    width: 140px;
    height: 67px;
    margin: -48px 0;
  }

  &--pko-lpk-ddb-dc {
    width: 103px;
    height: 115px;
  }

  &--pko-finat-dc {
    width: 96px;
  }

  &--pko-finance-dc {
    width: 111px;
  }

  &--pko-private-banking-dc {
    width: 170px;
    height: 30px;
    padding: 0;
    margin: -44px 0;
  }

  &--pko-dm-dc {
    width: 152px;
    height: 39px;
  }

  &--pko-jubileusz-dc {
    width: 95px;
    height: 92px;
    margin: -50px 0;
    @include breakpoint($brake-sm-max) {
      width: 122px;
      height: auto;
      vertical-align: middle;
    }
  }

  &--pko-cert-dc {
    top: 37px;
    width: 140px;
    height: 40px;
  }

  // #118428 - specyficzny przypadek dla produktów w gałęzi LPk - podciągniecie w gore
  &.header-logo--on-top {
    margin-top: -40px;
  }

  @include breakpoint($brake-sm) {
    height: 60px;

    &--pko-dc {
      width: 105px;
      height: 96px;
      margin: 0;
    }

    &--pko-white-bg-dc {
      width: 138px;
      height: 123px;
      margin: 0;
    }

    &--pko-leasing-dc {
      width: 140px;
    }

    &--pko-leasing-white-bg-dc {
      width: 168px;
      height: 83px;
      margin: 0;
    }

    &--pko-bank-hipoteczny-dc {
      width: 218px;
    }

    &--pko-fintech-dc {
      width: 230px;
      height: 65px;
    }

    &--pko-ofe-dc {
      width: 114px;
    }

    &--pko-pte-dc {
      width: 110px;
    }

    &--pko-tfi-dc {
      width: 102px;
    }

    &--pko-tfi-white-bg-dc {
      width: 133px;
      height: 85px;
    }

    &--pko-faktoring-dc {
      width: 157px;
    }

    &--pko-faktoring-white-bg-dc {
      width: 185px;
      height: 84px;
      margin: 0;
    }

    &--pko-lpk-ddb-dc {
      width: 131px;
      height: 167px;
    }

    &--pko-finat-dc {
      width: 120px;
    }

    &--pko-finance-dc {
      width: 143.6px;
    }

    &--pko-private-banking-dc {
      right: 12px;
      top: 125px;
      width: 170px;
      height: 40px;
      margin: -40px 0;
    }

    &--pko-dm-dc {
      width: 197px;
      height: 55px;
    }

    &--pko-jubileusz-dc {
      width: 105px;
      height: 126px;
    }

    &--pko-cert-dc {
      top: 52px;
      width: 270px;
      height: 80px;
    }

    // #118428 - specyficzny przypadek dla produktów w gałęzi LPk - podciągniecie w gore
    &.header-logo--on-top {
      top: 75px;
      margin-top: -50px;
    }
  }

  // todo ?? czy &.header-logo to zwiększenie/wzmocnienie specyficzności
  @include breakpoint($brake-md) {
    height: 70px;

    &--pko-dc {
      width: 143px;
      height: 120px;
      margin: 0;
    }

    &--pko-white-bg-dc {
      width: 185px;
      height: 155px;
      margin: 0;
    }

    &.header-logo--pko-leasing-dc {
      width: 177px;
    }

    &.header-logo--pko-leasing-white-bg-25-dc,
    &.header-logo--pko-leasing-white-bg-dc {
      width: 208px;
      height: 95px;
    }

    &.header-logo--pko-bank-hipoteczny-dc {
      width: 256px;
    }

    &.header-logo--pko-fintech-dc {
      width: 265px;
      height: 75px;
    }

    &.header-logo--pko-ofe-dc {
      width: 148px;
    }

    &.header-logo--pko-pte-dc {
      width: 142px;
    }

    &.header-logo--pko-tfi-dc {
      width: 134px;
    }

    &.header-logo--pko-tfi-white-bg-dc {
      width: 166px;
      height: 96px;
    }

    &--pko-faktoring-dc {
      width: 198px;
    }

    &--pko-faktoring-white-bg-dc {
      width: 234px;
      height: 100px;
    }

    &--pko-lpk-ddb-dc {
      width: 164px;
      height: 189px;
    }

    &--pko-finat-dc {
      width: 152px;
    }

    &--pko-finance-dc {
      width: 182.4px;
    }

    &--pko-private-banking-dc {
      right: 22px;
      top: 165px;
      width: 253px;
      height: 60px;
    }

    &--pko-dm-dc {
      width: 232px;
      height: 60px;
    }

    &--pko-jubileusz-dc {
      width: 105px;
      height: 126px;
      top: 165px;
    }

    &--pko-jubileusz-dc.landing-header__logo {
      top: -20px;
    }

    &--pko-cert-dc {
      top: 185px;
      width: 393px;
      height: 103px;
    }
  }

  @include breakpoint($max-width) {
    &--pko-private-banking-dc {
      right: auto;
      left: ($max-width / 2) - 22px;
      margin-left: 50%;
      transform: translateX(-100%);
    }
  }

  @include breakpoint($brake-lg) {
    &--pko-jubileusz-dc {
      width: 143px;
      height: 160px;
    }
  }
}

@include prevent-jump-fix();

/*
wrap - paddington
*/
