.sponsorship-steps {

  $root: &;
  $steps: 4;
  $dot-size: 27px;
  $line-height: 20px;
  $centering-pad: ($dot-size - $line-height) / 2;

  position: relative;
  margin: 45px 0;
  font-size: 0;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: $dot-size / 2 - 1.5px;
    bottom: 0;
    display: inline-block;
    width: 3px;
    height: 100%;
    background-color: $color-gray-fifth;

    @include breakpoint($brake-sm) {
      top: auto;
      left: 0;
      bottom: ($dot-size / 2) + (1.5px);
      width: 100%;
      height: 3px;
    }
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: $dot-size / 2 - 1.5px;
    display: inline-block;
    width: 3px;
    height: 0;
    background-color: $color-blue;

    @include breakpoint($brake-sm) {
      top: auto;
      left: 0;
      bottom: ($dot-size / 2) + (1.5px);
      width: 0;
      height: 3px;
    }
  }

  @for $i from 1 through $steps {
    &--step-#{$i}::after {
      width: 3px;
      height: ($i - 1) * (100% / ($steps - 1));

      @include breakpoint($brake-sm) {
        width: ($i - 1) * (100% / ($steps - 1));
        height: 3px;
      }
    }
  }

  &__step {
    position: relative;
    padding: $centering-pad 0;
    margin: 0 0 10px;
    text-align: left;

    @include breakpoint($brake-sm) {
      display: inline-block;
      width: (100% + (100% / $steps)) / $steps;
      margin: 0;
      text-align: center;

      &.first {
        width: (100% - (100% / $steps)) / $steps;
        text-align: left;
      }
      &.last {
        width: (100% - (100% / $steps)) / $steps;
        text-align: right;
        margin: 0;
      }
    }

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: inline-block;
      width: $dot-size;
      height: $dot-size;
      margin: auto;
      background-color: $color-gray-fifth;
      border-radius: 50%;
      vertical-align: middle;

      @include breakpoint($brake-sm) {
        position: static;
        max-width: 100%;
        padding-left: 0;
        margin: 0;
      }
    }
  }

  @for $i from 1 through $steps {
    &--step-#{$i} {
      #{$root}__step.step-#{$i} {
        color: $color-blue;
      }
      @for $j from 1 through $i {
        #{$root}__step.step-#{$j}::after {
          background-color: $color-blue;
        }
      }
    }
  }

  &__name {
    @extend %text-default-1;
    display: inline-block;
    max-width: 90%;
    padding-left: 15px;
    margin: 0 0 0 $dot-size;
    vertical-align: middle;

    @include breakpoint($brake-sm) {
      display: block;
      max-width: 100%;
      padding-left: 0;
      margin: 0;
    }
  }
}
