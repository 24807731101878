.bubbles {
    $root: &;
  
    &--4 {
      $style: &;

      margin-bottom: 25px;

      @include breakpoint($brake-sm) {
        margin-bottom: 45px;
      }

      #{$root}__title {
        @include pko-text-size($font-size-h3-mobile, $line-height-h3-mobile, $font-size-h3, $line-height-h3);
        margin-bottom: 40px;
        color: $text-color-head;
      }

      #{$root}__content {
        display: flex;
        flex-direction: column-reverse;
      }

      #{$root}__description {
        margin-top: 20px;
        font-size: 10px;
        color: $color-black;

        @include breakpoint($brake-sm) {
          margin-top: 0;
          padding-left: 70px;
        }
      }

      #{$root}__articles {
        display: flex;
        margin-left: -30px;
        margin-right: -30px;
        overflow-x: auto;

        @include breakpoint($brake-sm) {
          flex-wrap: wrap;
          margin-left: 0;
          margin-right: 0;
        }
      }

      #{$root}__article {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 5px;
        text-align: center;
        min-height: 240px;
        overflow: hidden;
        padding: 20px;
        flex: 0 0 240px;
        background: $color-gray-athens;

        @include breakpoint($brake-sm) {
          display: block;
          flex: 0 1 50%;
          margin: 0;
          height: auto;
          min-height: 0;
          text-align: left;
          padding: 0 30px 40px 0;
          background: transparent;
        }
  
        @include breakpoint($brake-md) {
          flex: 0 1 25%;
        }

        &:first-child {
          margin-left: 30px;

          @include breakpoint($brake-sm) {
            margin-left: 0;
          }
        }

        &--has-image {
          #{$root}__article-title,
          #{$root}__article-description {
            @include breakpoint($brake-sm) {
              padding-left: 70px;
            }
          }
        }
      }

      #{$root}__article-image {
        width: 55px;
        height: 55px;
        margin-bottom: 10px;

        @include breakpoint($brake-sm) {
          float: left;
          margin-bottom: 0;
          margin-right: 15px;
        }
  
        & > img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      #{$root}__article-title {
        font-size: 20px;
        margin-bottom: 10px;
        color: $color-black;
      }

      #{$root}__article-description {
        font-size: 15px;
        color: $color-gray-boulder;

        @include breakpoint($brake-sm) {
          color: $color-black;
        }
      }
    }
}