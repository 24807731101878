// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html {
  height: 100%;
  box-sizing: border-box;

  &.locked-scroll {
    overflow: hidden;

    @include breakpoint($brake-sm) {
      position: static;
    }
  }
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) .accordion__header:focus,
body:not(.user-is-tabbing) .select2,
body:not(.user-is-tabbing) .select2 *,
body:not(.user-is-tabbing).select-mobile-wraper.focus {
  outline: none;
}

.no-margin {
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  min-height: 100%;
  min-width: 320px;
  background-color: $bg-color;
  &.disabled-scroll {
    overflow: hidden;
    &::after {
      transition: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      content: '';
      background: rgba($overlay-color, 0.7);
      @include pko-z-index('menu-overlay');
    }
  }
  @include breakpoint($brake-sm-max) {
    &.mobile-blocked {
      overflow: hidden;
    }
  }
  &.locked-scroll {
    overflow: hidden;
    height: auto;

    @include breakpoint($brake-sm) {
      position: static;
      height: auto;
    }
  }

}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  a {
    color: inherit;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  transition: color $trans-hover ease;

  @include pko-on-event {
    &,
    & * {
      color: $color-gray-main;
    }
  }

  &.link-red {
    color: $color-red;
    cursor: pointer;
  }
  &.link-blue {
    color: $link-color;
    cursor: pointer;
    @include pko-on-event {
      &,
      & * {
        color: $color-red;
      }
    }
  }
}

hr {
  position: relative;
  border: none;
  height: 1px;
  background: $border-color;
  display: block;
  width: 100%;
  margin: 20px 0;
}

.reverse-link {
  color: $color-gray-main;

  @include pko-on-event {
    &,
    & * {
      color: $link-color;
    }
  }
}

.two-column-with-empty-sidebar {
  @include breakpoint($brake-sm-max) {
    .footer {
      margin-top: 0;
    }
    .site-content__main + .breakline {
      display: none;
    }
  }
}

.two-column-with-sidebar .site-content__additional {
  @include breakpoint($brake-sm-max) {
    &::before {
      display: none;
    }
    margin-top: 0;
    padding-top: 0;
  }
}

.one-column-last-accordion {
  .site-content__module {
    padding-bottom: 0;
    @include breakpoint($brake-sm) {
      padding-bottom: 0;
    }
  }
}

.header-features.neon {
  &-two-col > div {
    @include span(12);
    @include breakpoint($brake-sm) {
      @include span(6);
    }
    &.breakline {
      display: none;
    }
  }
  &-category-top {
    padding-bottom: 5px;
    .banner__title {
      margin-top: 0 !important;
    }
    > div {
      margin-bottom: 10px;
      @include breakpoint($brake-sm) {
        margin-bottom: 40px;
        &:nth-child(2n-1) {
          clear: left;
        }
      }
    }
  }
  &-product-bottom {
    &.breakline {
      display: none;
    }
    .banner__title {
      margin-top: 0 !important;
    }
    > div {
      @include breakpoint($brake-sm-max) {
        @include span(12);
        margin-bottom: 6px;
      }
    }
    .feature-section-item__title {
      @extend %h3;
    }
  }
}
