// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer {
  @extend %pko-clearfix;

  // stopka na walutach musi być nad contentem (ujemne marginesy)
  position: relative;
  @include pko-z-index(default);

  margin: 20px 0 0;
  padding-bottom: $vertical-space-mobile;

  @include breakpoint($brake-sm) {
    margin: #{$vertical-space-desktop - 5px} 0 0;
    padding-bottom: 30px;

    .accordion {
      margin-bottom: 0;
    }
  }

  &__links {
    @extend %pko-clearfix;

    padding-top: 26px;

    //@include span(12);
  }

  &__column {
    @extend %paddington;

    @include breakpoint($brake-sm) {
      @include span(2.4); //width: 20%
    }

    &:last-child {
      &::after {
        content: '';
        border-bottom: 1px solid $color-gray-fifth;
        width: 100%;
        display: block;
        @include breakpoint($brake-sm) {
          display: none;
        }
      }
    }
  }

  &__list {
    @include pko-font-size($font-size-t2);
    @include pko-line-height($font-size-t2, $line-height-t1);

    list-style: none;
    margin: -22px 0 -25px;
    padding: 0;

    @include breakpoint($brake-sm) {
      @include pko-font-size($font-size-t3);
      @include pko-line-height($font-size-t3, $line-height-t2);

      margin: 0;
    }

    @include breakpoint($brake-md) {
      @include pko-font-size($font-size-t2);
      @include pko-line-height($font-size-t2, $line-height-t1);
    }

    &-item {
      color: $text-color;
      padding: 4px 0;
      display: block;
      .no-hover & {
        color: $color-black;
      }
      @include pko-on-event() {
        color: $link-color;
      }
      @include breakpoint($brake-sm) {
        padding: 0;
      }

      @include pko-flag-svg-small;
    }

  }

}

.footer-top {
  @extend %pko-clearfix;

  @include breakpoint($brake-sm) {
    &--bottom-space {
      padding-bottom: 25px;
    }
  }

  &__links {
    @extend %paddington;

    @include pko-font-size($font-size-t1);
    @include pko-line-height($font-size-t1, 32);

    padding-bottom: 16px;

    @include breakpoint($brake-sm) {
      // #60874 #73310 #96442
      @include span(7);
      @include pko-font-size(18);
      @include pko-line-height(18, $line-height-t2);

      padding-bottom: 0;
    }

    @include breakpoint($brake-md) {
      @include span(8); // #96442
      @include pko-font-size(20);
      @include pko-line-height(20, $line-height-t1);
    }


    @include breakpoint($brake-lg) {
      @include span(9);
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      @extend %pko-clearfix;
    }

    &-item {
      @include breakpoint($brake-sm) {
        float: left;
        margin-right: 27px;
        padding-bottom: 16px;

        &:last-child {
          margin: 0;
        }
      }
    }

  }

  &__link {
    color: $link-color;
    @include pko-on-event {
      color: $text-color;
    }
  }

  &__social {
    @extend %paddington;

    @include breakpoint($brake-sm) {
      // #60874 #73310 #96442
      @include span(5);
      float: right; // bo można wyłączyć menu po lewej (footer-top__links)
      text-align: right;
    }

    @include breakpoint($brake-md) {
      @include span(4);
      float: right; // bo można wyłączyć menu po lewej (footer-top__links)
    }
    @include breakpoint($brake-lg) {
      @include span(3);
    }

    .social-icon--instagram {
      font-size: 1.15rem;
    }
  }
}

.footer-bottom {

  @extend %paddington;

  @include breakpoint($brake-sm) {
    &::before {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      background-color: $color-gray-fifth;
    }
  }

  &__inner {
    @extend %pko-clearfix;
    @extend %break-from-paddington;

    @include breakpoint($brake-xs-sm) {
      padding: 25px 0 0;
      .wifi & {
        padding: 0;
      }
    }
    @include breakpoint($brake-sm) {
      .wifi & {
        padding: 25px 0 0;
      }
    }
  }

  &__call {
    @extend %paddington;

    margin: 28px 0 20px;

    > div + div {
      margin-top: 10px;
    }

    @include breakpoint($brake-xs-sm) {
      margin-top: 0;
    }

    @include breakpoint($brake-sm) {
      @include span(6 last);
      margin-bottom: 0;
      text-align: right;
    }

    @include breakpoint($brake-md) {
      .call__top {
        display: inline;
      }
      > div + div {
        margin-top: 0;
      }
    }

  }

  &__info {
    @extend %paddington;

    @include breakpoint($brake-sm) {
      @include span(6);
    }

    &-lang {
      @include pko-font-size($font-size-t1-mobile);

      margin-left: 2px;
      margin-right: 14px;
      color: $link-color;

      // @include pko-on-event() {
      // color: $link-color;
      // }

      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-t2);
        margin-left: 1px;
      }

      @include pko-flag-svg-small;
    }

    &-list {
      @extend %pko-clearfix;
      @include pko-font-size($font-size-t1-mobile);
      @include pko-line-height($font-size-t1-mobile, 28);

      color: $color-gray-middle;
      list-style: none;
      margin: 6px 0 0;
      padding: 0 0 0 1px;

      &-item {
        margin-top: 16px;
      }

      @include breakpoint($brake-xs-sm) {
        @include pko-font-size(14);

        &-item {
          float: left;
          margin-right: 20px;
          margin-top: 0;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    &-link {
      color: $color-gray-second;
      @include pko-on-event() {
        color: $link-color;
      }
      &--highlited {
        padding-left: 15px;
        color: $color-black;
        @include pko-on-event() {
          color: $link-color;
        }
      }
    }

    &-extra-text {
      @include pko-font-size(13);
    }

    &--right {
      @include breakpoint($brake-sm) {
        text-align: right;
      }
    }
  }

  &__leasing { // change elements order on mobile
    @include breakpoint($brake-sm-max) {
      .footer-bottom__info {
        padding-top: 25px;
        &--right {
          margin-top: -100px;
        }
      }
    }

  }

  &__prices {
    @extend %pko-clearfix;
    @extend %paddington;
    margin-bottom: 15px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include breakpoint($brake-sm) {
        flex-wrap: nowrap;
      }

      li {
        flex: 1 1 33.3%;
        margin-top: 15px;

        @include breakpoint($brake-sm) {
          flex: 1 1 16.6%;
          margin: 0;
        }

        @include breakpoint($brake-md) {
          flex: 1 1 14.3%;
          margin: 0;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-black;
        @include pko-font-size(15);
      }
    }

    &--main {
      li {
        &:nth-child(6) {
          img {
            max-width: 100px;

            @include breakpoint($brake-md) {
              max-width: 152px;
            }
          }
        }

        &:last-child {
          display: none;

          @include breakpoint($brake-md) {
            display: block;
          }
        }
      }

      img {
        max-width: 68px;
      }
    }

    &--ca {
      display: flex;
      align-items: center;

      @include breakpoint($brake-sm-max) {
        display: block;
      }

      ul {
        li {
          @include breakpoint($brake-xxs-max) {
            flex: 1 1 50%;
          }

          img {
            max-height: 42px;

            @include breakpoint($brake-sm-max) {
              max-height: 36px;
            }

            @include breakpoint($brake-md) {
              max-height: 57px;
            }
          }
        }
      }
    }

    &__header {
      @extend %h4;
      color: $color-black;
      margin: 10px 0 18px 0;
    }

    @include breakpoint($brake-sm) {

      &__header {
        @include span(2);
        padding-right: 50px;
      }

      &__content {
        @include span(10);
        picture {
          margin-right: 15px;
        }
      }
    }
    @include breakpoint($brake-sm-max) {
      margin-top: 15px;

      &__content {
        span {
          display: none;
        }
      }
      &::after {
        content: '';
        border-bottom: 1px solid $color-gray-fifth;
        width: 100%;
        display: block;
        position: relative;
        top: 20px;
      }
    }
  }
}
