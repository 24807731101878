%site-nav-box {
  padding: 15px 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  margin-bottom: 15px;
  display: block;
}

%site-nav-box-with-icon {
  padding: 15px 0 15px 32px;
  border-top: 1px solid $border-color;
  display: block;
  margin-bottom: 0;
  @include pko-font-size(18);
  position: relative;
}

%site-nav-box-with-icon-the-icon {
  top: 15px;
  left: 0;
  display: inline;
  position: absolute;
  color: $link-color;
}

%site-nav-box-with-icon-text {
  display: inline-block;
  color: $color-black;
}

.site-nav {
  $root: &;

  @include pko-z-index('sitenav');
  position: relative;
  padding-top: 5px;

  .icon-home {
    transition: $trans-hover;

    &.active:hover {
      color: $color-gray-third;
    }
  }

  &__link--hidden {
    visibility: hidden;
  }
  &__sub-list--block {
    display: block;
  }

  .icon-home.active,
  .icon-home:hover {
    color: $link-color;
  }

  &__main-list {
    display: none;

    @include pko-header-menu-expanded() {
      display: block;
    }
  }

  &__subdropdown {
    display: inline-block;
    position: relative;
    padding-top: 15px;
    padding-right: 30px;
    & &-close span {
      font-size: 32px;
      position: absolute;
      top: 5px;
      right: 5px;
      color: $color-gray-third;
    }
    ul {
      @include pko-z-index(cosmos);
      top: 12px;
      position: absolute;
      display: none;
      background: $color-white;
      width: 220px;
      border: 1px solid $border-color;
      box-shadow: 3px 3px 0 rgba($color-black, 0.05);
      padding: 20px 25px;
      border-radius: 3px;

      &.visible {
        display: block;
      }
      li {
        display: block;
        float: none;
        margin-right: 0;
      }
    }
    span {
      @include pko-font-size($font-size-t2);
      color: $color-gray-second;
      cursor: pointer;
      transition: $trans-hover;
      @include pko-on-event() {
        &,
        em {
          color: $link-color;
        }
      }
    }
    em {
      color: $color-gray-third;
      font-size: 30px;
      position: absolute;
      top: 11px;
      right: 2px;
      transition: $trans-hover;
    }
  }
  &__sub-list &__subdropdown &__item:first-child,
  &__sub-list &__subdropdown &__item,
  &__sub-list &__subdropdown &__item:not(:first-child) {
    @include pko-font-size($font-size-t2);
    padding: 3px 0;
    float: none;
  }
  &__sub-list &__subdropdown &__item:first-child &__link,
  &__sub-list &__subdropdown &__item &__link {
    @include pko-font-size($font-size-t2);
    top: 0;
    color: $link-color;
    border-bottom: none;
    @include pko-on-event() {
      color: $color-gray-main;
    }
  }

  // todo na animacje mamy utils/animations
  @keyframes sticky {
    0% {
      top: -70px;
    }
    100% {
      top: 0;
    }
  }

  &__additional,
  &__select,
  &__close {
    display: none;
  }

  &__close {
    @include pko-font-size(36);
    @include breakpoint($brake-sm) {
      @include pko-font-size(45);
    }
    transition: color $trans-hover ease;
    float: right;
    color: $link-color;
    // colory są dla search-layer__close
    &:before {
      color: $link-color;
    }
    &:after {
      color: $color-gray-third;
    }
    cursor: pointer;
    margin-top: 5px;
    margin-right: -10px;
    position: relative;

    @include breakpoint($brake-sm) {
      margin-top: 2px;
      margin-left: -13px;
      margin-right: 0;
    }

    // colory są dla search-layer__close
    @include pko-on-event() {
      &:before {
        color: $color-gray-third;
      }
      &:after {
        color: $link-color;
      }
    }
  }

  &__sub-list {
    #{$root}__item:first-child {
      font-size: 32px;

      #{$root}__link {
        font-size: 32px;
        color: $color-gray-third;
        position: relative;
        top: 6px;
      }
    }

    &__subdropdown &__item.selected &__link,
    &__thirdlvl &__item.selected &__link {
      text-decoration: underline;
    }

    &.sticky {
      @include breakpoint($brake-sm) {
        position: fixed;
        top: 0;
        background: $color-white;
        width: 100%;
        z-index: 9999;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $border-color;
        animation: sticky 0.5s;
      }
      @include breakpoint($brake-sm-max) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  &__call {
    @extend %site-nav-box;
  }

  &__iko {
    @extend %site-nav-box-with-icon;
    &-icon {
      @extend %site-nav-box-with-icon-the-icon;
      height: 20px;
      left: 2px;
    }
    &-text {
      @extend %site-nav-box-with-icon-text;
    }
  }

  &__inbox {
    @extend %site-nav-box-with-icon;
    &-icon {
      @extend %site-nav-box-with-icon-the-icon;
      @include pko-font-size(20);
      left: 1px;
    }
    &-indicator {
      @include new-message-alert(9, -1, -4);
    }
    &-text {
      @extend %site-nav-box-with-icon-text;
    }
  }

  &__open-account {
    @extend %site-nav-box-with-icon;
    &-icon {
      @extend %site-nav-box-with-icon-the-icon;
      @include pko-font-size(24);
      left: -1px;
    }
    &-text {
      @extend %site-nav-box-with-icon-text;
    }

    &.hidden {
      display: none;
    }
  }

  // ONLY mobile - because it differ too much from other versions
  ///////////////////////////////////////////////////////////////

  @include breakpoint($brake-sm-max) {

    &__main-list,
    &__sub-list {
      clear: both;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__main-list {
      > #{$root}__item > #{$root}__link {
        display: none;
      }
    }

    &__sub-list {
      padding: 20px 0;
      display: none;

      .selected > & {
        display: block;
      }
      > #{$root}__item:not(.in-mobile) {
        display: none;
      }
      > #{$root}__item > #{$root}__link {
        @include pko-font-size($font-size-h4-mobile);
        color: $color-black;
        padding: 5px 0;
        display: inline-block;
      }
      #{$root}__item:first-child {
        margin: 0 0 4px -4px;
      }
      #{$root}__item.selected > #{$root}__link {
        color: $link-color;
        padding-bottom: 2px;
        margin-bottom: 3px;
      }
    }

    // Dodatkowy link, np. dla TFI https://redmine.k2.pl/issues/102466
    &__extra-link {
      @extend .site-nav__call;
      border-bottom: none;
      margin-bottom: 0;
    }

    &__mini {
      list-style: none;
      margin: 0;
      padding: 0;
      &:empty {
        display: none;
      }
      &-link {
        @include pko-font-size($font-size-t2-mobile);
        @include pko-line-height($font-size-t2-mobile, 28);
        display: block;
        color: $text-color;

        @include pko-flag-svg-small;
      }
    }

    &__additional,
    &__select,
    &__close {
      @include pko-header-menu-expanded() {
        display: block;
      }
    }

    &__select {
      @include pko-header-menu-expanded() {
        clear: both;
        margin: 60px 0 0;
      }
    }

    &__social {
      margin: 20px 0;
    }

    &__sub-list {
      @include pko-header-menu-expanded() {
        display: block;
      }
    }
  }

  // ONLY tablet - because it differ too much from other versions
  ///////////////////////////////////////////////////////////////

  @include breakpoint(($brake-sm) ($brake-md-max)) {

    &__additional,
    &__select {
      display: none;
    }

    &__main-list,
    &__sub-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: none;
      clear: both;
    }
    &__main-list {
      padding: 20px 0;

      #{$root}__link {
        color: $text-color;
        @include pko-on-event() {
          color: $link-color;
        }
        .selected > & {
          color: $link-color;
        }
      }
    }

    &__sub-list {
      @include pko-text-size(18, 25, 18, 25);
      position: relative;
      left: 0;
      margin-top: -10px;

      .selected > a + & {
        display: block;
      }

      > #{$root}__item:not(.in-desktop) {
        display: none;
      }
      #{$root}__item {
        margin-bottom: 15px;
      }
      #{$root}__link {
        color: $color-black;
        @include pko-on-event() {
          color: $link-color;
        }
      }
      #{$root}__item.selected > #{$root}__link {
        color: $link-color;
      }
    }

    &__item {
      display: inline-block;
      margin-right: 16px;
      &:first-child {
        margin-right: 13px;
      }
      #{$root}__sub-list &__item {
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__link {
      transition: $trans-hover;
      border-bottom: 1px solid transparent;
      padding: 2px 0;

      .selected > & {
        color: $link-color;
        border-bottom: 1px solid $link-color;
      }
    }

    &__close {
      @include pko-header-menu-expanded() {
        display: block;
      }
    }

    &__sub-list {
      @include pko-header-menu-expanded() {
        display: block;
      }
    }

  }

  // ONLY desktop - because it differ too much from other versions
  ////////////////////////////////////////////////////////////////

  @include breakpoint($brake-md) {

    &__additional,
    &__select {
      display: none;
    }

    &__main-list,
    &__sub-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__main-list {
      @include pko-text-size(11, 45, 11, 45);
      display: block;

      @include pko-header-sticky() {
        line-height: 1;
      }

      #{$root}__link {
        color: $text-color;
        @include pko-on-event() {
          color: $link-color;
        }
        .selected > & {
          color: $link-color;
        }
      }
    }

    &__sub-list {
      @include pko-text-size(17, 25, 17, 25);
      position: relative;
      left: 0;
      margin-top: -9px;
      height: 50px;

      .selected > & {
        display: block;
      }

      > #{$root}__item:not(.in-desktop) {
        display: none;
      }

      #{$root}__link {
        color: $color-black;
        @include pko-on-event() {
          color: $link-color;
        }
      }

      #{$root}__item.selected > #{$root}__link {
        color: $link-color;
      }
    }

    &__item {
      display: inline-block;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      #{$root}__sub-list & {
        margin-right: 25px;
        float: left;
        &:not(:first-child) {
          padding-top: 15px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      #{$root}__sub-list &:first-child {
        margin-right: 13px;
      }
    }

    //
    // scss-lint:disable TransitionAll
    &__link {
      transition: all $trans-hover linear;
      border-bottom: 1px solid transparent;
      padding: 4px 0;

      .selected > & {
        color: $link-color;
        border-bottom: 1px solid rgba($link-color, 0.5);
      }
    }
    // scss-lint:enable TransitionAll

    &__main-list > li > a {
      @include pko-header-sticky() {
        // show just sub-level menu when header is sticky
        display: none;
      }
    }

    &__sub-list {
      @include pko-header-sticky() {
        // change font size when header is sticky
        @include pko-font-size($font-size-t2);
      }
    }

  }
}

.site-nav__thirdlvl {
  @extend .site-nav__subdropdown;
  @include pko-text-size(17, 25, 17, 25);

  padding-top: 15px;
  padding-right: 30px;
  margin-right: 5px;
  @include breakpoint($brake-sm-max) {
    &:not(.in-mobile) {
      display: none;
    }
  }

  @include breakpoint(($brake-sm) ($brake-md-max)) {
    &:not(.in-desktop) {
      display: none;
    }
  }

  @include breakpoint($brake-md) {
    float: left;

    &:not(.in-desktop) {
      display: none;
    }
  }

  & > span {
    @include pko-text-size(17, 25, 17, 25);
    padding: 4px 0;
    border-bottom: 1px solid transparent;
    color: $color-black;
    transition: all 250ms linear;

    @include pko-on-event() {
      color: $link-color;
    }
  }

  &.selected > span {
    color: $link-color;

    @include pko-on-event() {
      color: $link-color;
    }
  }
}

/// ?????!!!
// @-moz-document url-prefix() {
//   .site-nav__subdropdown {
//     padding-top: 5px;
//   }
// }

.clonedSubNavigation,
.clonedThirdLvl {
  @include pko-z-index(cosmos);
  position: absolute;
  display: none;
  background: $color-white;
  width: 220px;
  border: 1px solid $border-color;
  box-shadow: 3px 3px 0 rgba($color-black, 0.05);
  padding: 20px 25px;
  border-radius: 3px;

  li {
    display: block;
    float: none;
    margin-right: 25px;
  }

  .site-nav__subdropdown-close span,
  .site-nav__thirdlvl-close span {
    font-size: 32px;
    position: absolute;
    top: 5px;
    right: 5px;
    color: $color-gray-third;

    cursor: pointer;
    transition: $trans-hover;
    @include pko-on-event() {
      &,
      em {
        color: $link-color;
      }
    }
  }

  em {
    color: $color-gray-third;
    font-size: 30px;
    position: relative;
    top: 7px;
    left: -5px;
    transition: $trans-hover;
  }

  .site-nav__item:first-child,
  .site-nav__item,
  .site-nav__item:not(:first-child) {
    @include pko-font-size($font-size-t2);
    float: none;
  }
  .site-nav__item:first-child &__link,
  .site-nav__item &__link {
    @include pko-font-size($font-size-t2);
    top: 0;
    color: $link-color;
    border-bottom: none;
    @include pko-on-event() {
      color: $color-gray-main;
    }
  }
  .selected>.site-nav__link {
    padding: 0;
  }
  .site-nav__item {
    margin: 6px 0;
  }
}

.leasing-car-offers .site-nav__item.selected .site-nav__link {
  border-bottom: 1px solid $link-color;

  @include breakpoint($brake-md) {
    border-bottom: 1px solid rgba($link-color, 0.5);
  }
}
