.calc-mortgage {
  .calc__header {
    &__text {
      @include pko-font-size($font-size-t3);
      margin-top: -30px;
    }
  }
  .calc__body {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    align-items: flex-start;

    @include breakpoint($brake-md) {
      flex-direction: row;
    }
  }
  .calc__form {
    width: 100%;

    .calc__row {
      margin-bottom: 10px;
      @include breakpoint($brake-md) {
        margin-bottom: 20px;
      }
    }

    .calc-input {
      position: relative;

      &__header {
        text-align: left;
        @include pko-font-size($font-size-t2);
        margin-bottom: 5px;
      }
    }
    .form__filter-radio-wrap {
      + .form__filter-radio-wrap {
        margin-left: 20px;
      }
    }

    .input-text {
      box-shadow: none;
      @include breakpoint($brake-md) {
        &__wrapper {
          max-width: 260px;
        }
      }
    }

    @include breakpoint($brake-md) {
      padding-right: 50px;
      flex: 0 1 calc(100% - 600px);
    }

    @include breakpoint($brake-lg) {
      padding-right: 180px;
    }
  }
  .calc-results {
    width: 100%;
    padding: 25px 30px 40px;
    margin-top: 40px;

    dl {
      display: flex;
      margin-bottom: 30px;
      flex-direction: column;

      dt {
        font-size: 17px;
      }

      dd {
        font-size: 28px;
        font-weight: bold;
        margin-left: 0;
      }
    }
    .table {
      border: none;

      &__row {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
      }

      &__cell {
        position: relative;
        text-align: left;

        &:not(:first-child) {
          font-size: 21px;
          font-weight: bold;
          margin-top: 10px;

          &::before {
            content: attr(data-label);
            display: block;
            font-size: 13px;
            font-weight: normal;
            margin-bottom: 5px;
          }
        }
        &:first-child {
          margin-bottom: 5px;
        }
      }
    }
    .actions {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .link {
        color: $color-white;
        span {
          color: $color-white;
        }
      }
      .cta {
        &:hover {
          background-color: $color-gray-sixth;
          color: $color-black;
        }

        .no-touch &:hover * {
          color: $color-black;
        }
      }
    }

    @include breakpoint(400px) {
      dl {
        flex-direction: row;
        dt {
          width: 60%;
        }
        dd {
          width: 40%;
          text-align: right;
        }
      }
      .table {
        &__cell {
          &:not(:first-child) {
            position: relative;
            text-align: right;

            &::before {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              margin-bottom: 0;
            }
          }
        }
      }
    }
    @include breakpoint($brake-sm) {
      dl {
        margin-bottom: 60px;
        dt {
          width: 40%;
        }

        dd {
          width: 60%;
        }
      }

      .table {
        &__row {
          flex-flow: row nowrap;
          align-items: center;

          &:not(:first-child) {
            .table__cell:not(:first-child):before {
              display: none;
            }
          }
        }
        &__cell {
          &:not(:first-child) {
            padding-left: 18px;
            margin-top: 0;
            width: 30%;

            &::before {
              top: -40px;
              right: 0;
              left: auto;
              transform: translateY(0);
            }
          }
          &:first-child {
            width: 40%;
            font-size: 13px;
            margin-bottom: 0;
          }
        }
      }
    }
    @include breakpoint($brake-md) {
      flex: 0 0 600px;
      margin-top: 0;
    }

  }
  .calc__timetable {
    margin-top: 50px;
    .table__scrollable {
      overflow-x: scroll;
      margin-top: 30px;

      &__inner {
        position: relative;
        padding-top: 30px;
        display: inline-block;
      }
      .table {
        width: 100%;
        min-width: 850px;
        table-layout: fixed;

        td {
          font-size: 13px;
          white-space: nowrap;
        }

        th {
          font-size: 14px;
        }
      }
    }
    .empty {
      border: none;
      background-color: $color-white !important;
      width: 20px;
      @include breakpoint($brake-sm) {
        width: 40px;
      }
    }
    .table__row--empty {
      td {
        padding-left: 50px;
        text-align: left;
        background-color: $color-white;
      }
    }
    .table__label {
      color: $color-black;
      font-weight: bold;
      position: absolute;
      top: 0;
      &:first-child {
        left: 18%;
      }
      &:nth-child(2) {
        left: 65%;
      }
      @include breakpoint($brake-sm) {
        &:first-child {
          left: 20%;
        }
        &:nth-child(2) {
          left: 65%;
        }
      }
      @include breakpoint($brake-md) {
        &:first-child {
          left: 22%;
        }
        &:nth-child(2) {
          left: 67%;
        }
      }

    }
    &__actions {
      text-align: center;

      .link {
        color: $color-blue;
        font-weight: bold;

        &:hover {
          color: $color-black;
        }
      }
    }
  }
  .calc-switch__button {
    width: 50%;
    button {
      @include pko-font-size($font-size-t3);
    }
    @include breakpoint($brake-sm) {
      width: 260px;
    }
  }
  .link {
    margin-top: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .calc__group {
    display: flex;
    li {
      + li {
        margin-left: 10px;
      }
      .choose-buttons {
        label {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }

  .form .icon-infotip {
    margin-left: 20px;
  }

  .lead {
    color: $color-black;
    @include pko-font-size($font-size-t1)
  }
}
