.search-layer {
  @extend %pko-clearfix;
  display: none;
  padding: 20px 0 10px;
  clear: both;

  .header--sticky & {
    margin-top: 0;
  }

  &__form {
    @include pko-z-index();
    padding: 20px 0;
    background-color: $color-white;
    margin: 0;
    position: relative;
  }

  &__input {
    padding-right: 40px;
  }

  &__button {
    @include pko-font-size(30);
    display: inline-block;
    background: none;
    border: none;
    position: absolute;
    right: 7px;
    top: 23px;
    color: $input-icon-color;
    padding: 0;

    @include breakpoint($brake-sm) {
      top: 31px;
    }

    .icon-search {
      color: inherit;
    }
  }

  &.active {
    display: block;
  }

  &__close {
    @include pko-z-index();
    display: block;
    position: absolute;
    right: 30px;
    top: 5px;

    &::after {
      display: none;
      @include breakpoint($brake-sm) {
        display: block;
      }
      position: absolute;
      content: attr(data-close-text);
      color: #bababa;
      font-size: 0.6875rem;
      font-family: pkobp, Tahoma, sans-serif;
      right: 0;
      height: 44px;
      padding: 16px 50px 0 0;
      top: 0;
      white-space: nowrap;
    }
  }

  &__results {
    ul {
      margin: 0;
      padding: 0;

      .result {
        margin: 0 0 13px;
        padding: 0;
        list-style-type: none;

        &--promoted {
          padding-bottom: 10px;
          border-bottom: solid 1px $border-color;
          // bo tak :)
          margin-left: -17px;
          margin-right: -17px;
          padding-right: 17px;
          padding-left: 17px;
        }

        &.empty {
          margin: 0;
        }
      }
    }

    a {
      @include pko-font-size($font-size-t2);
      @include pko-line-height($font-size-t2, $line-height-t2);
    }

    dfn {
      @include pko-font-size($font-size-t3);
      color: $color-gray-second;
      font-style: normal;
      display: block;
    }

    strong {
      display: block;
      @include pko-font-size($font-size-t2);
      color: $link-color;
      font-weight: normal;
    }

    p {
      margin: -2px 0 0;
    }
  }

  &__neon-wrapper {
    @include span(4);
    float: right;
    @extend %paddington;
    display: none;
    position: relative;

    &:before {
      content: '';
      border-left: 1px solid $border-color;
      position: absolute;
      @include pko-paddington-sth(left, -3.5); // kreska ma wystawać o około 3 paddingtony ;) LOL
      top: 0;
      height: 100%;
    }
  }

  &__extra-link {
    margin-top: 15px;
    float: left;
  }

  @include breakpoint($brake-sm) {
    padding: 60px 0 40px;
    position: relative;

    &__results {
      @include span(7);
      padding: 0 17px;
      margin-top: -4px;
      clear: left;
    }

    &__neon-wrapper {
      display: block;
    }

    &__form {
      padding: 28px 0 30px;
      margin: 0;
      width: 66.6%;
    }

    &__close {
      right: 0;
      top: 83px;
      .header--sticky & {
        margin-top: -30px;
      }
    }

    .header--sticky & {
      padding-top: 15px;
    }
  }

  @include breakpoint($brake-md) {

    &__neon-wrapper {
      float: right;
      margin-top: -67px;
    }

    &__form {
      @include span(8);
    }

    &__close {
      right: 0;
      top: 82px;
      .header--sticky & {
        margin-top: -30px;
      }
    }
  }
}
