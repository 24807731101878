/* =================
   Modul kalkulatorów
   ================= */

.calc {
  @extend %paddington;
  position: relative;

  ul:not(.select-pure__list) {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .site-tabs,
  .site-tabs-content,
  .site-tabs-select-wrapper,
  .site-tabs-content {
    padding-left: 0;
    padding-right: 0;
  }

  .site-tabs-select-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
  }

  // Fix dla dziwnego globalnego stylu w
  // @media (min-width: 48em) .site-tabs _site-tabs.scss
  .site-tabs {
    position: static;
    margin-top: 0;
    display: block;
    margin-bottom: 45px;

    &.hide-on-mobile {
      display: none;
      @include breakpoint($brake-sm) {
        display: block;
      }
    }

    // fix 63528 - chowanie tabów na mobile
    visibility: hidden;
    @include breakpoint($brake-sm) {
      visibility: visible;
    }
  }

  .breakline {
    display: none;

    @include breakpoint($brake-sm) {
      display: block;
    }
  }

  &__fieldset {
    padding: 0;
    margin: 0;
    border: none;
  }

  .icon-infotip {
    @extend %icon-infotip;
    position: relative;
    top: -1px;
  }

  .select2,
  .select-mobile-wrapper {
    margin: 8px 0;
  }

  .input-text {
    margin: 6px 0 8px;

    &-label {
      padding: 0 0 2px;
    }
  }

  .icon-block {
    float: right;
    margin-top: 5px;
    @include breakpoint($brake-sm) {
      margin: 9px 12px 0 0;
    }

    &.active {
      color: $color-red;
    }
  }

  &__blocked-info {
    @extend %text-default-3;
    color: $color-red;

    .icon-infotip + & {
      margin-left: 5px;
    }
  }

  &__header {
    position: relative;
    margin-bottom: 8px;

    @include breakpoint($brake-md) {
      margin-bottom: 36px;
    }
  }

  & &__selections {
    @extend %break-from-paddington; // wymuszenie nadpisania klasy form form form_fieldset
  }
  &__selections {
    margin-bottom: 20px;

    .icon-block {
      margin-right: -6px;

      @include breakpoint($brake-sm) {
        margin-right: 0;
      }
    }

    @include breakpoint($brake-sm) {
      margin-bottom: 36px;
    }

    &__list {
      margin-top: 0;

      &-item {
        @extend %paddington;
        width: 100%;
        display: inline-block;
        box-sizing: border-box;
        margin-bottom: 11px;

        @include breakpoint($brake-sm) {
          @include span(4);
          margin-bottom: 0;
        }

        &--last {
          margin-bottom: 0;
        }

        // bo .hidden globany za slaby
        &.hidden {
          display: none;
        }
      }
    }
  }

  &__options {
    margin-bottom: 22px;

    @include breakpoint($brake-sm) {
      margin-bottom: 36px;
    }

    &__header {
      @extend %text-default-1;
      margin: 0 0 4px;

      @include breakpoint($brake-sm) {
        margin: 0 0 8px;
      }
    }

    &__list {
      margin-top: 7px;

      &-item {
        float: left;
        white-space: nowrap;
        width: 100%;
        margin-right: 22px;

        @include breakpoint($brake-sm) {
          width: auto;
          height: 30px;
        }

        .input-checkbox {
          padding-right: 4px !important;
        }
      }
    }

    &__label {
      @extend %text-default-2;
      display: inline;
      position: relative;
      width: 100%;
      margin-top: 5px;
      @include breakpoint($brake-sm) {
        width: auto;
      }
      cursor: pointer;
      user-select: none;
      &--no-pointer {
        cursor: default;
      }
    }

    .input-checkbox {
      top: 5px;
      padding-right: 0;
      @include pko-font-size($font-size-t2-mobile);
      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-t2);
      }
    }
  }

  &__sliders {
    margin-bottom: 10px;

    &__header {
      @extend %text-default-1;
      float: left;
      max-width: 70%;
      margin: 10px 0 8px;

      @include breakpoint($brake-sm) {
        float: none;
        max-width: 100%;
        margin: 0;
      }
    }

    &__list {
      &-item {
        position: relative;

        @include breakpoint($brake-sm) {
          margin-bottom: 32px;
        }
      }
    }

    .irs {
      /* Główny wrapper */
      &-wrapper {
        display: inline-block;
        width: 100%;
        margin-top: 40px;

        @include breakpoint($brake-sm) {
          display: table;
          margin-top: 0;
        }

        /* Wrapper slidera i controlsow */
        &-row {
          display: block;

          @include breakpoint($brake-sm) {
            display: table-row;
          }
        }

        &--no-controls {
          margin-top: 0;

          .irs-controls {
            display: none;
          }
        }
      }

      &-slider {

        /* Slider */

        &.dragging {
          transform: scale(1.5);
        }

        &-wrapper {
          display: block;

          @include breakpoint($brake-sm) {
            display: table-cell;
            width: 100%;
          }
        }

        /* label pod sliderem */
        &-label {
          &-min,
          &-max {
            @extend %text-default-3;
            margin-top: 5px;
            color: $color-gray-third;
            display: none;

            @include breakpoint($brake-sm) {
              display: inline;
            }
          }
          &-min {
            float: left;
          }
          &-max {
            float: right;
          }
        }
      }

      /* Controlsy */
      &-controls {
        @extend %pko-clearfix;
        display: inline-block;
        float: right;
        width: 100%;
        margin-top: -75px;

        @include breakpoint($brake-xs-sm) {
          display: inline-block;
          width: auto;
        }

        @include breakpoint($brake-sm) {
          display: table-cell;
          min-width: 222px;
          margin-top: 0;
          margin-bottom: 15px;
        }
      }

      /* Input w controlsach */
      &-value {
        float: left;
        margin: 0;
        width: 89%;

        @include breakpoint($brake-xs-sm) {
          width: 118px;
        }

        /*@include breakpoint(pko-em-size(450)) {
          width: 118px;
        }*/

        @include breakpoint($brake-sm) {
          float: right;
          width: 164px;
        }

        &.input-text {
          @include breakpoint($brake-sm) {
            padding: 2px 5px 5px 0;
          }
        }

        input {
          width: 80%;

          &:read-only {
            color: $color-gray-second;
          }
        }

        &[data-unit=mc] input,
        &[data-unit=mc-y] input,
        &[data-unit=mc-e] input {
          width: 50% !important;
        }
      }

      &-labels-container {
        position: relative;
        @include breakpoint($brake-sm) {
          display: table-row;

          .tfi-calc & {
            width: 100%;
            display: table-cell;

            .irs-slider-wrapper {
              display: block;
            }
          }
        }
      }

    }
  }

  @include breakpoint($brake-sm-max) {

    .icon-block {
      margin-right: -5px;
    }

    .calc__sliders {
      &__header {
        @include pko-font-size($font-size-t2-mobile);
        margin-bottom: 4px;
        width: 50%;
        &.multi-line {
          width: 125px;
          margin: -2px 0 3px;
        }
      }
      .irs {
        height: 36px;
        &.irs-with-grid {
          height: 60px;
        }
      }
      .irs-grid-text {
        bottom: 9px;
      }
      .irs-wrapper {
        margin-top: 0;
      }
      .irs-controls {
        float: none;
        position: absolute;
        margin: 0;
        bottom: 40px;
        right: 0;
        width: auto;

        .irs-value {
          width: 118px;
          @include pko-font-size($font-size-t2-mobile);
          input {
            width: 90%;
            margin-right: 2px;
          }
        }
      }
    }

    form .calc__summary {
      padding-top: 0;
      .calc-summary-record {
        padding-bottom: 0;
        line-height: 1;

        // tak, wiemy - teksty się zle łamią na 320px,
        // grafik jak zwykle zaprojektował dla najmniejszej możliwej wartosci
        // czeka na pechowca, ktory to będzie musiał inaczej poustawiac i wytłumaczyć klientowi :)
        &__name {
          @include span(9)
        }
        &__value {
          @include span(3)
        }
      }
    }
  }

  @include breakpoint($brake-xs-sm) {
    .calc__sliders {
      &__header {
        &.multi-line {
          width: auto;
          margin: 10px 0 4px;
        }
      }
    }
  }

  @include breakpoint($brake-sm) {
    .calc__sliders {
      &__header {
        &.multi-line {
          margin: 0;
        }
      }
    }
  }

  &__disclaimer {
    @extend %text-default-2;
    color: $color-gray-main;
    margin-top: 43px;
  }

  &__summary {
    &__header {
      @extend %h3;
      margin-top: 25px;

      @include breakpoint($brake-sm) {
        margin-top: $vertical-space-desktop;
      }
    }

    &__table {
      th {
        span {
          display: inline-block;
          text-align: center;
          vertical-align: middle;
        }
      }

      th,
      td {
        &:not(:first-child) {
          min-width: 100px;
        }
      }

      &--notSoNarrow {
        min-width: 600px;
      }

      .icon-infotip {
        top: 3px;
      }

    }

    &__legend {
      margin-top: 15px;
      @extend %text-default-1;
    }

    &__desclimer {
      @extend %text-default-3;
    }
  }

  .calc-result__form-initialized {
    .input-text {
      margin: 0;
    }
    .form__row--text.side-by-side {
      margin-bottom: 0;
    }
  }

  .calc__selections {
    margin-bottom: 20px;
    @include breakpoint($brake-sm) {
      margin-bottom: 36px;
    }
  }

  .calc__options {
    margin-bottom: 20px;
    @include breakpoint($brake-sm) {
      margin-bottom: 36px;
    }
  }

  .calc__sliders {
    margin-bottom: 20px;
    @include breakpoint($brake-sm) {
      margin-bottom: 0;
    }
  }

  .calc__nav {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;

    @include breakpoint($brake-sm) {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    .tooltip {
      margin-left: 5px;
      margin-top: 25px;

      @media all and (min-width: 360px) {
        margin-left: 20px;
        margin-top: 20px;
      }
    }

    .calc-switch {
      @extend %text-default-2;

      small {
        @extend %text-default-3;
        color: $color-gray-main;
        white-space: nowrap;
      }
      .active {
        small {
          color: $color-white;
        }
      }

      @media all and (max-width: 360px) {
        .calc-switch__button {
          width: auto;
        }
      }
    }
  }

  &#{$overlay-parent-class} {
    .irs-slider-label-min,
    .irs-slider-label-max {
      visibility: hidden;
    }
  }

}
