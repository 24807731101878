// NIE EDYTUJ RĘCZNIE ->
// użuj gulpa, np. npm run gulp -- --gulpfile ./staticfiles/gulp-tasks-dev/redesign-poi-sprite.js sprite-poi-redesign

%poi-icon {
  background-image: pko-image('map/sprite.png');
  background-size: 340px 48px;
}

.poi-icon {
  @extend %poi-icon;
  position: absolute;
  top: 0;
  right: 0;
}

.poi-icon--agency {
  background-position: -314px 0;
  width: 26px;
  height: 47px;
}

.poi-icon--atm {
  background-position: -171px 0;
  width: 24px;
  height: 37px;
}

.poi-icon--corporate {
  background-position: -57px 0;
  width: 24px;
  height: 37px;
}

.poi-icon--facility {
  background-position: -85px 0;
  width: 24px;
  height: 37px;
}

.poi-icon--facility_nordea {
  background-position: -114px 0;
  width: 24px;
  height: 37px;
}

.poi-icon--iko {
  background-position: -142px 0;
  width: 24px;
  height: 38px;
}

.poi-icon--leasing {
  background-position: -0px 0;
  width: 24px;
  height: 37px;
}

.poi-icon--liqidation {
  background-position: -285px 0;
  width: 24px;
  height: 38px;
}

.poi-icon--makler {
  background-position: -228px 0;
  width: 24px;
  height: 48px;
}

.poi-icon--terminal {
  background-position: -256px 0;
  width: 24px;
  height: 38px;
}

.poi-icon--user {
  background-position: -199px 0;
  width: 24px;
  height: 37px;
}

.poi-icon--wrzutnia {
  background-position: -28px 0;
  width: 24px;
  height: 38px;
}
