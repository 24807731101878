.bubbles {
  $root: &;

  &--5 {
    $style: &;

    margin-bottom: 25px;

    @include breakpoint($brake-sm) {
      margin-bottom: 45px;
    }

    &#{$root}--right {
      #{$root}__bubbles-container {
        order: 2;
      }

      #{$root}__content {
        order: 1;
      }
    }

    #{$root}__title {
      @include pko-text-size($font-size-h3-mobile, $line-height-h3-mobile, $font-size-h3, $line-height-h3);
      margin-bottom: 40px;
      color: $text-color-head;
    }

    #{$root}__inner {
      @include breakpoint($brake-sm) {
        display: flex;
        align-items: flex-start;
      }
    }

    #{$root}__description {
      margin-bottom: 45px;
      color: $text-color;
    }

    #{$root}__bubbles-container {
      display: block;
      position: relative;
      flex-shrink: 0;

      @include breakpoint($brake-sm) {
        display: inline-block;
        width: 50%;
        vertical-align: top;
      }

      &:before {
        content: " ";
        display: inline-block;
        width: 100%;
        padding-bottom: 100%;
      }
    }

    #{$root}__bubble {
      position: absolute;

      &--1 {
        top: 23.7%;
        left: 29.7%;
      }

      &--2 {
        top: 39.1%;
        left: 68.1%;
      }

      &--3 {
        top: 60.1%;
        left: 45%;
      }
    }

    #{$root}__article {
      margin-top: 50px;

      &:first-child {
        margin-top: 0;
      }
    }

    #{$root}__article-image {
      width: 55px;
      height: 55px;
      margin-bottom: 15px;

      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    #{$root}__article-title {
      font-size: 19px;
      margin-bottom: 20px;
      color: $text-color-head;

      @include breakpoint($brake-sm) {
        font-size: 24px;
      }
    }

    #{$root}__article-description {
      font-size: 15px;
    }
  }
}