/*
 -------------    -------------
|  TEXT/GFX   |  |   SNIPPET  |
 -------------    -------------
 ------------------------------
|            TEXT             |
 ------------------------------
*/

.landing-page--v10 {
  .landing-header__logo {
    float: none;
    display: block;
    margin: 50px 0;
    padding: 0;
    height: 70px;

    @include breakpoint($brake-sm) {
      margin-top: 30px;
    }
  }

  .landing-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    &__info {
      @extend %paddington;
      width: 100%;

      @include breakpoint($brake-sm) {
        width: 50%;
      }

      &-inner {
        margin: 0 0 30px;

        .text {
          h2 {
            margin-bottom: 18px;
          }

          ul {
            padding: 0;

            li {
              position: relative;
              padding-left: 20px;
              list-style: none;

              &:before {
                content: '';
                width: 8px;
                height: 8px;
                display: inline-block;
                background-color: $color-dark-blue;
                position: absolute;
                left: 0;
                top: 7px;
                vertical-align: top;
              }
            }
          }
        }
      }
    }

    &__snippet {
      margin-top: 0;
      margin-right: -50px;
      padding: 0;
      width: 100%;

      @include breakpoint($brake-sm) {
        width: 50%;
      }

      .cta {
        padding: 8px 20px;
        cursor: pointer;
      }

      &-inner {
        @include breakpoint($brake-sm) {
          padding: 30px 50px;
        }
      }

      // wersje kolorystyczne
      &--Z {
        .overlay__icon {
          color: $color-blue;
        }

        .calc {
          .icon-infotip {
            color: $color-white;
            &:hover {
              color: $color-blue;
            }
          }
          &__sliders-installment-slider-item--active {
            background-color: $color-blue;
            color: $color-white;
          }

          &-loan .calc__sliders-installment-nav:last-child::after {
            color: $color-white;
          }

          &__results-show-details {
            border-bottom-color: $color-white;

            &:hover {
              border-bottom-color: $color-black;
            }
          }

          &__bar {
            background-color: $gold-dark;
            color: $color-white;
          }
        }
      }

      &--S {
        .calc__sliders-installment-slider-item--active {
          background-color: $color-blue;
          color: $color-white;
        }

        .calc-loan .calc__sliders-installment-nav:first-child::after,
        .calc-loan .calc__sliders-installment-nav:last-child::after {
          color: $color-blue;
        }

        .calc__bar {
          background-color: $color-gray-sixth;
          box-shadow: 0 0 4px rgba($color-black, 0.45);
        }
      }

      &--G {
        .calc__bar {
          background-color: $color-blue;
          color: $color-white;
        }
      }

      &--N {
        .calc__bar {
          background-color: $color-mid-blue;
          color: $color-white;
        }
      }

      &--G,
      &--N {
        .irs-slider:before {
          background-color: $color-orange;
        }

        .irs-bar,
        .irs-bar-edge {
          background: $color-orange;
        }

        .calc__sliders-installment-slider-item--active {
          background: $color-orange;
        }

        .overlay__icon {
          color: $color-blue;
        }

        .calc__results-show-details {
          border-bottom-color: $color-white;

          &:hover {
            border-bottom-color: $color-black;
          }
        }
        .calc .icon-infotip:hover::before {
          color: $color-white;
        }
      }

      &--G &--btn-blue-dark,
      &--N &--btn-blue {
        .calc__sliders-installment-slider-item--active {
          background: $color-orange;
        }

        .cta {
          background-color: $color-white;
        }
      }

      &--T {
        .calc__sliders-installment-slider-item--active {
          background-color: $color-blue;
          color: $color-white;
        }

        .calc-loan .calc__sliders-installment-nav:first-child::after,
        .calc-loan .calc__sliders-installment-nav:last-child::after {
          color: $color-blue;
        }

        .calc__bar {
          background-color: $color-white;
          box-shadow: 0 0 4px rgba($color-black, 0.45);
        }
      }

      &--BW {
        .landing-content__snippet-inner {
          box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
        }

        .calc__sliders-installment-slider-item--active {
          background-color: $color-blue;
          color: $color-white;
        }

        .calc-loan .calc__sliders-installment-nav:first-child::after,
        .calc-loan .calc__sliders-installment-nav:last-child::after {
          color: $color-blue;
        }

        .calc__bar {
          background-color: $color-white;
          box-shadow: 0 0 4px rgba($color-black, 0.45);
        }
      }

      // wersje kolorystyczne buttonow
      &.all-cta-red {
        .ccform .cta {
          @extend .cta-red;
        }
      }

      &.all-cta-orange {
        .ccform .cta {
          @extend .cta-orange;
        }
      }

      &.all-cta-gold {
        .ccform .cta {
          @extend .cta-gold;
        }
      }

      &.all-cta-blue-dark,
      &.all-cta--reverse {
        .ccform .cta {
          @extend .cta-blue-dark;
        }
      }

      &.all-cta-blue {
        .ccform .cta {
          @extend .cta-blue;
        }
      }

      &.all-cta-white {
        .ccform .cta {
          @extend .cta-white;
        }
      }
    }

    .calc__summary {
      margin-top: 20px;
    }
  }

  .calc {
    padding: 0;
  }

  .site-content__module {
    hr {
      margin: 40px 0;
    }

    .snippet--borderd {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid $border-color;

      @include breakpoint($brake-sm) {
        margin-bottom: 40px;
        padding-bottom: 40px;
      }
    }

    .grid {
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
      margin-right: -20px;

      .grid__item {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include breakpoint($brake-sm) {
        &--2 {
          .grid__item {
            width: 50%;
          }
        }
      }
    }

    .steps {
      padding-left: 0;
      padding-right: 0;

      ol {
        margin: 0 -15px;
        padding: 0;
        list-style: none;
        counter-reset: steps;
        display: flex;
        flex-direction: column;

        li {
          counter-increment: steps;
          position: relative;
          padding: 0 15px;

          img {
            border: 2px solid $color-blue;
            border-radius: 100%;
            width: 110px;
            height: 110px;
            margin-bottom: 20px;
            overflow: hidden;
          }

          &::before {
            content: counter(steps);
            display: block;
            position: absolute;
            top: 80px;
            left: 15px;
            width: 34px;
            height: 34px;
            line-height: 28px;
            text-align: center;
            background-color: $color-blue;
            color: $color-white;
            border: 4px solid $color-white;
            border-radius: 100%;
            font-size: 20px;
          }
        }

        @include breakpoint($brake-sm) {
          flex-direction: row;

          li {
            width: 33%;
          }
        }
      }
    }
  }

  .contact-modal {
    padding-right: 0;
    padding-left: 0;

    .cta {
      @include pko-font-size(19);
      padding: 13px 10px 14px;
      width: 100%;
      min-width: 100px;
    }

    .popup__item {
      text-align: center;
      padding: 30px 60px;
    }

    .form__body {
      text-align: left;

      .cta {
        @extend .cta--reverse;
        @include pko-font-size(19);
        padding: 13px 23px 14px;
        width: 100%;
        min-width: 100px;
      }

      .form__row--submit {
        text-align: center;
      }
    }

    &.modal--big-content .modal__content {
      display: flex;
      flex-direction: column;

      .popup__item {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid $border-color;

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }
      }

      @include breakpoint($brake-sm) {
        flex-direction: row;

        .popup__item {
          flex: 0 0 50%;
          border-bottom: none;

          &:last-child {
            border-left: 1px solid $border-color;
          }
        }
      }
    }
  }

  .overlay {
    margin: -30px -30px;
    height: calc(100% + 106px);
    width: calc(100% + 60px);
    background-color: rgba($color-white, 0.8);

    @include breakpoint($brake-md) {
      width: calc(100% + 100px);
      margin: -30px -50px;
    }
  }

  .ccform  {
    .icon-infotip {
      top: 26px;

      &--inline {
        position: absolute;
        top: auto;
      }
    }
  }

  .loan-installment-new-popup__cta-element {
    @include breakpoint($brake-md) {
      padding: 0 50px;

      &:first-child {
        padding-left: 30px;
      }
      &:last-child {
        padding-right: 30px;
      }
    }
  }

  .cta.cta-white {
    &:focus:hover {
      color: $color-white;
    }

    &:focus {
      color: $color-dark-blue;
    }
  }

  .form-narrow {
    padding-bottom: 60px;

    @include breakpoint($brake-sm) {
      padding-bottom: 80px;
    }

    .form__title {
      @include pko-font-size($font-size-h3-mobile);

      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-h2);
      }
    }
  }

  .header--lp-menu {
    height: 0;
    padding: 0;
    margin: 0;

    .header__sticky-inner {
      display: flex;
      justify-content: center;

      @include breakpoint($brake-xs-sm) {
        display: block;
      }
    }

    .header-mobile-cta {
      margin-top: 12px;
    }

    .header__inner,
    .product-menu {
      z-index: 12;
    }
  }
}
