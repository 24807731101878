.download-list {
  @include pko-font-size($font-size-t2);
  list-style: none;
  margin: 40px 0 0;
  padding: 0;

  &__item {
    padding: 0 0 0 35px;
    margin-bottom: 10px;
    display: block;
    margin-left: -5px;

    &-icon {
      font-size: 30px;
      position: relative;
      color: $color-blue;
      margin-left: -35px;
      float: left;
      top: -7px;
    }
  }
}
