.about-product {
  @extend %paddington;

  ul,
  li:not(.form__row),
  p {
    margin-bottom: 0;
  }

  &__title {
    padding: 0;
    margin-bottom: 20px;
  }

  &__item {
    @extend %paddington;

    ul:first-child {
      > li:first-child {
        margin-top: 0;
      }
    }
  }

  ul {
    padding-left: 20px;
    margin-top: 0;
  }

  li {
    padding-left: 0;
    break-inside: avoid;
  }

  .accordion__body {
    padding: 0 0 10px;
  }

  .accordion--mobile-only-footer .accordion__body {
    padding: 0;
  }

  &__header {
    @extend %paddington;

    &::before {
      content: none !important;
    }
  }

  @include breakpoint($brake-sm-max) {
    padding-bottom: 0;

    .accordion {
      border-bottom: 1px solid $border-color;
    }
    &__item {
      padding: 0 0 15px;
    }
    &__header {
      @include pko-font-size($font-size-h4-mobile);
      margin: 0;
      padding: 0;
    }
  }

  @include breakpoint($brake-sm) {
    padding: 0 0 45px;
    margin-bottom: 45px;
    &__header {
      @include pko-font-size($font-size-h2);
      margin-top: 0;
    }
    &__item {
      //@include span(6);
      //&:nth-child(2n-1) {
      //  clear: left;
      //}
      column-count: 2;
      column-gap: 50px;
    }
  }

  .about-product-under {
    @extend %paddington;
    margin-top: 20px;
    padding-bottom: 25px;
    @include breakpoint($brake-sm) {
      margin-top: 45px;
      padding-bottom: 0;
    }
    @include breakpoint($brake-sm-max) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
    > div + div {
      margin-top: 20px;
      @include breakpoint($brake-sm) {
        margin-top: 37px;

        // Fix dla elementu "Boks tekstowy" dodanego w miejscu "Snippet pod sekcją "więcej o produkcie" (?)"
        // https://redmine.k2.pl/issues/101822
        &.feature-section__item {
          margin-top: 0;
        }
      }
    }
    .text p {
      margin-bottom: 20px;
      @include breakpoint($brake-sm) {
        margin-bottom: 37px;
      }
    }
    // about-product-under__normal-link #82628 - normalne linki z tą klasą
    > div:not(.site-content__module--loan-calc-snippet):not(.about-product-under__normal-link):first-child {
      a {
        display: block;
        margin-bottom: 5px;
        @include breakpoint($brake-sm) {
          margin-bottom: 0;
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
    > div:last-child {
      p:last-child {
        margin-bottom: 0;
        @include breakpoint($brake-sm) {
          margin-bottom: 0;
        }
      }
    }

    .fees-and-interests {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
