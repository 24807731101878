@import 'snippet/process-tabs';
@import 'snippet/slider-big';
@import 'snippet/slider-youtube';
@import 'snippet/slider-gfx';
@import 'snippet/lp_good_morning_map';
@import 'snippet/iko-snippet-statistics-and-form';

.snippet {

  &--column {
    @extend %paddington;
    margin-bottom: 40px;
  }

  @include breakpoint($brake-sm) {
    margin-bottom: 0;
    &-wysiwyg {
      &.image-inline-block {
        img {
          display: inline-block;
          margin: 0;
          vertical-align: middle;
        }
      }
    }
  }
}
