.card-carousel {

  position: relative;

  &__header {
    margin-top: 0;
  }

  @include breakpoint($brake-sm-max) {
    &.site-content__module {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  @include breakpoint($brake-sm) {
    &.site-content__module {
      margin-bottom: -25px !important; // nadpisz :last-child w produkcie gdzie karuzela jest ostatnim dzieckiem...
    }
  }

  img {
    display: none;
  }

  .owl-loaded img {
    display: block;
  }

  &__wrapper {
    @extend %break-from-paddington;
    margin-top: -10px;
  }

  .owl-stage-outer {
    padding-top: 13%;
    padding-bottom: 0;
  }

  .owl-dots {
    margin-top: -20px;
    position: relative;
    display: none;
    @include breakpoint($brake-sm) {
      display: block;
    }
  }

  .owl-dot {
    transition: $trans-hover;
    &:hover {
      background: $color-blue;
    }
  }

  .owl-item {
    transition: opacity $trans-hover ease, scale $trans-hover ease;
    min-height: 1px;

    &:not(.center) {
      opacity: 0.4;
    }

    &.center {
      position: relative;
      @include pko-z-index('card-carousel-layer');
      img {
        max-width: 220%;
        width: 220%;
        margin-left: -60%;
        transform: translateY(-27%);
      }
    }

    img {
      max-width: 100%;
    }
  }

  @include breakpoint($brake-sm) {

    overflow: hidden;

    .owl-prev,
    .owl-next {
      display: block;
      color: $color-blue;
      transition: $trans-hover;
      width: auto;
      &:hover {
        color: $color-gray-main;
      }
    }

    .owl-prev {
      left: 15px;
    }
    .owl-next {
      right: 15px;
    }

    .owl-dots {
      margin-top: 50px;
    }

    &__wrapper {
      margin-left: 0;
      margin-right: 0;
    }

    .owl-stage-outer {
      padding-top: 0;
      width: calc(100% - 200px);
      margin: 0 auto;
    }

    .owl-item {

      &:not(.center) {
        transform: scale(0.8);
      }

      &.center {
        @include pko-give-margin(100px);
        img {
          max-width: 100%;
          width: 100%;
          margin-left: 0;
          transform: none;
        }
      }
    }

  }

}
