.compare-item {
  @include breakpoint($brake-sm) {
    clear: left;
  }

  &__link {
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 13px 8px 13px 18px;
    display: inline-block;
  }

  &__header {
    position: relative;

    // miejsce na ikonę, tak, żeby teks sie dobrze łamał
    padding-right: 65px;
    @include breakpoint($brake-md-lg) {
      padding-right: 75px;
    }
  }

  &__icon {
    font-size: 40px;
    color: $color-blue;
    position: absolute;
    margin-top: -18px;
    top: 50%;
    right: 0;

    @include breakpoint($brake-md-lg) {
      font-size: 48px;
      margin-top: -23px;
    }
  }
}
