.calc {
  $calc: &;

  &-loan {
    $calc-irs-class: '.irs';
    $icomoon-font: icomoon;
    $color-border-slider: #bcbcbc;

    #{$calc}__header {
      margin: 0;

      h2 {
        small {
          font-size: 15px;
        }
      }
    }

    #{$calc}__sliders {
      margin-bottom: 35px;
      width: 100%;

      &-amount-labels {
        display: none;
      }

      &-header {
        display: block;
        float: none;
        width: 100%;
        max-width: 100%;
        margin: 0 0 10px;
        padding-right: 25px;
        @include pko-font-size(17);

        @include breakpoint($brake-md) {
          margin: 0 0 15px;
          padding: 0;
        }

        &--small {
          margin: 40px 0 12px;
          padding: 0;
          @include pko-font-size(14);

          @include breakpoint($brake-md) {
            @include pko-font-size(17);
            margin: 35px 0 15px;
          }
        }
      }

      #{$calc-irs-class}-wrapper-row {
        @include breakpoint($brake-sm-max) {
          display: flex;
          flex-direction: column-reverse;
        }
      }

      #{$calc-irs-class}-labels-container {
        position: relative;
        display: table-row;
      }

      #{$calc-irs-class}-controls {
        min-width: 0;
        margin: 0 0 20px;
        position: relative;
        display: block;

        #{$calc-irs-class}-value {
          display: block;
          float: none;
          width: calc(100% - 40px);
          height: 42px;
          margin: 0;
          padding: 0;
          position: relative;

          input {
            float: none;
            display: block;
            height: 40px;
            padding: 0;
            margin: 0;
            width: 100%;
            text-align: center;
            @include pko-font-size(22);
            line-height: 42px;
            background-color: $color-white;
            font-weight: 400;
            border-radius: 3px;

            ::-ms-clear,
            &::-ms-clear {
              display: none;
            }
          }

          &::before {
            position: absolute;
            right: -30px;
            line-height: 42px;
            @include pko-font-size(22);
            color: $color-black;
          }
        }
      }

      #{$calc-irs-class}-slider-wrapper {
        float: left;
        vertical-align: 1px;
        width: 100%;

        #{$calc-irs-class} {
          height: 25px;
        }

        #{$calc-irs-class}-line {
          top: 11px;
        }

        #{$calc-irs-class}-bar-edge {
          width: 16px;

          @include breakpoint($brake-md) {
            width: 20px;
          }
        }

        #{$calc-irs-class}-line,
        #{$calc-irs-class}-bar,
        #{$calc-irs-class}-bar-edge {
          top: 11px;
        }

        #{$calc-irs-class}-slider {
          width: 25px;
          height: 25px;
          cursor: pointer;
          top: 0;
        }

        #{$calc-irs-class}-slider,
        .no-touch #{$calc-irs-class}-slider {
          &:before {
            transform: none;
          }

          &:hover,
          &:active,
          &:focus {
            &:before {
              transform: none;
            }
          }
        }
      }

      #{$calc-irs-class}-slider-controls {
        position: relative;
        bottom: 1px;
        display: inline-block;
        vertical-align: -3px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $color-blue;
        cursor: pointer;

        @include breakpoint($brake-sm) {
          bottom: -2px;
        }

        @include breakpoint($brake-md) {
          width: 36px;
          height: 36px;
          bottom: 0;
        }

        &:first-child {
          margin-right: 16px;

          &::after {
            content: $icon-minus;
            color: $color-white;
            font-family: $icomoon-font;
            font-weight: 700;
            position: absolute;
            width: 32px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            @include pko-font-size(16);

            @include breakpoint($brake-md) {
              width: 36px;
              height: 36px;
              line-height: 36px;
            }
          }
        }

        &:last-child {
          margin-left: 16px;

          &::after {
            @extend #{$calc-irs-class}-slider-controls:first-child::after;
            content: $icon-plus;
          }
        }
      }

      &-installment {
        margin: 0 -10px;

        &-slider {
          display: inline-block;
          width: calc(100% - 2 * 32px);
          visibility: hidden;

          &--visible {
            visibility: visible;
          }

          .owl-stage-outer {
            padding-bottom: 0;
          }

          &-item {
            background-color: $color-white;
            width: 70px;
            height: 50px;
            line-height: 50px;
            border: 1px solid $color-border-slider;
            position: relative;
            top: 6px;
            transition: top 0.2s ease;
            @include pko-font-size(18);
            text-align: center;
            color: $color-blue;
            cursor: pointer;

            &--active {
              height: 60px;
              line-height: 60px;
              top: 0;
            }
          }

          .owl-item:first-child #{$calc}__sliders__installment-slider-item {
            border-left: 1px solid $color-border-slider;
          }
        }

        &-nav {
          position: relative;
          bottom: 8px;
          display: inline-block;
          width: 32px;
          height: 32px;
          cursor: pointer;

          @include breakpoint($brake-md) {
            width: 36px;
            height: 36px;
            bottom: 13px;
          }

          &:first-child {
            &::after {
              content: $icon-arrow-left;
              color: $color-white;
              font-family: $icomoon-font;
              font-weight: 700;
              position: absolute;
              width: 32px;
              height: 32px;
              text-align: center;
              line-height: 32px;
              @include pko-font-size(16);
              left: -1px;

              @include breakpoint($brake-md) {
                width: 36px;
                height: 36px;
                line-height: 36px;
              }
            }
          }

          &:last-child {
            &::after {
              @extend #{$calc}__sliders-installment-nav:first-child::after;
              content: $icon-arrow-right;
              left: 1px;
            }
          }
        }

        &.hidden-controls {
          margin: 0;

          .calc__sliders-installment-slider {
            width: 100%;
          }

          .calc__sliders-installment-nav {
            display: none;
          }
        }
      }
    }

    #{$calc}__options {
      margin-bottom: 30px;
      width: 100%;

      &__label {
        display: block;
        margin-bottom: 5px;

        .calc__tooltip {
          vertical-align: top;
        }
      }

      &__list-outer {
        @extend %pko-clearfix;
      }

      &__list {
        @extend %break-from-paddington;
        overflow: hidden;

        &-item {
          @extend %paddington;
          width: 100%;
          margin-top: 10px;

          @include breakpoint($brake-md) {
            margin-top: -4px;
          }

          &--wrapped {
            white-space: normal;
            height: auto;
          }
        }
      }

      .input-checkbox {
        display: block;
        overflow: initial;
      }

      @include breakpoint($brake-md) {
        margin-bottom: 0;
      }
    }

    #{$calc}__results {
      position: relative;
      min-height: auto;

      &-show-details {
        @include pko-font-size(14);
        border-bottom: 1px solid;
        cursor: pointer;

        &::after {
          content: $icon-arrow-right;
          font-family: $icomoon-font;
          font-weight: 700;
          position: relative;
          top: 2px;
        }

        &--mobile {
          @include breakpoint($brake-md) {
            display: none;
          }
        }

        &--desktop {
          display: none;

          @include breakpoint($brake-md) {
            display: inline-block;
            margin-bottom: 36px;
            @include pko-font-size(17);
          }
        }
      }

      &-details {
        margin-bottom: 20px;

        &-container {
          width: 100%;
          margin: 0 0 20px;
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
        }

        &-item {
          min-height: 72px;
          margin-right: 7%;

          &:last-child {
            margin-right: 0;
          }

          h3 {
            margin: 0;
            @include pko-font-size(24);
            font-weight: 700;

            @include breakpoint($brake-md) {
              @include pko-font-size(38);
            }
          }

          &:last-child {
            margin-right: 0;
          }

          @include breakpoint($brake-sm) {
            margin-right: 15%;

            &:last-child {
              margin-right: 0;
            }
          }

          @include breakpoint($brake-md) {
            margin-right: 20%;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        &-label {
          @include pko-font-size(14);

          @include breakpoint($brake-md) {
            @include pko-font-size(18);
          }
        }

        &--single {

        }
      }

      &-cta {
        margin-top: 30px;
      }
    }

    #{$calc}__bar {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 500;

      .with-product-menu & {
        top: 55px;
      }

      &__inner {
        display: flex;
        align-items: center;
      }

      &__info {
        display: none;
        justify-content: space-between;
        @include span(8);
        @extend %paddington;

        dl {
          padding-right: 10px;

          dt {
            @include pko-font-size(15);
          }

          dd {
            @include pko-font-size(24);
            margin-left: 0;
            font-weight: bold;
          }
        }
      }

      &__action {
        text-align: center;
        @include span(4);
        @extend %paddington;
      }
    }

    #{$calc}__tooltip {
      display: inline-block;
      vertical-align: bottom;

      .icon-infotip {
        color: $color-gray-third;
        left: 4px;
        top: -4px;

        @include breakpoint($brake-md) {
          top: -5px;
        }
      }

      &--hidden {
        visibility: hidden;
      }

      &--small {
        .icon-infotip {
          top: 0;

          &::before {
            @include pko-font-size(22);

            @include breakpoint($brake-md) {
              @include pko-font-size(26);
              top: -2px;
            }
          }
        }
      }
    }

    #{$calc}__tooltip.js-tooltip--active {
      .icon-infotip {
        color: $color-blue;
      }
    }

    &.border-left {
      @include breakpoint($brake-md) {
        padding-left: 50px;
      }
      @include breakpoint($brake-md-max) {
        border-left: 0 none;
        border-top: 1px solid $border-color;
      }
    }

    .overlay {
      margin: 0 -30px;
      width: calc(100% + 60px);
    }

    .cta {
      cursor: pointer;
    }

    .hide-on-mobile {
      display: block;

      @include breakpoint($brake-md-max) {
        display: none;
      }
    }

    .hide-on-desktop {
      display: block;

      @include breakpoint($brake-md) {
        display: none;
      }
    }

    .block-content {
      &__flex-inner {
        p {
          margin-bottom: 0;
        }

        .text__gray {
          display: none; // Ukrycie szczegółowego komunikatu o błedach
        }
      }
    }

    &__popup {
      &__details {
        $popup-details: &;

        &-container {
          margin-bottom: 30px;

          @include breakpoint($brake-md) {
            padding-bottom: 45px;
            border-bottom: 1px solid $color-gray-fifth;
            margin-bottom: 42px;
            column-count: 2;

            &.no-border {
              border-bottom: 0 none;
              padding-bottom: 0;
            }
          }
        }

        &-section {
          margin-bottom: 10px;

          @include breakpoint($brake-md) {
            margin-bottom: 20px;
          }

          #{$popup-details}-row {
            margin-bottom: 5px;
          }

          #{$popup-details}-subcontainer {
            #{$popup-details}-label {
              @include pko-font-size(13);
            }
          }
        }

        &-row {
          margin-bottom: 10px;

          @include breakpoint($brake-md) {
            margin-bottom: 20px;

            &--top-row {
              padding-top: 5px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &-label {
          position: relative;
          @include pko-font-size(15);
          color: $color-gray-main;
          line-height: 20px;

          @include breakpoint($brake-md) {
            @include pko-font-size(15);
            display: inline-block;
            width: 80%;
            padding-right: 45px;
            text-align: right;
            vertical-align: top;
          }
        }

        &-value {
          @include pko-font-size(17);
          color: $color-black;

          @include breakpoint($brake-md) {
            @include pko-font-size(15);
            display: inline-block;
            vertical-align: top;
            width: 15%;
            text-align: right;
          }

          &--strong {
            @include pko-font-size(19);
            font-weight: 700;

            @include breakpoint($brake-md) {
              @include pko-font-size(15);
            }
          }
        }
      }

      &__header {
        @include pko-font-size(19);
        margin: 0 0 30px;

        @include breakpoint($brake-md) {
          @include pko-font-size(24);
          margin-bottom: 50px;
        }
      }

      &__button {
        width: 100%;
        @include pko-font-size(17);
        text-align: center;
      }

      &__cta {
        &-element {
          text-align: center;

          &:first-child {
            padding-bottom: 30px;
            margin-bottom: 20px;
            border-bottom: 1px solid $color-gray-fifth;
          }

          &--divided,
          &--divided-reordered {
            display: flex;
            flex-direction: column;
          }

          &--divided {
            .calc-loan__popup__cta-subelement:not(:first-child) {
              margin-top: 30px;
              padding-top: 30px;
              border-top: 1px solid $color-gray-fifth;
            }
          }

          &--divided-reordered {
            .calc-loan__popup__cta-subelement:first-child {
              order: 1;
              margin-top: 20px;
              padding-top: 30px;
              border-top: 1px solid $color-gray-fifth;
            }
          }

          &-iko-img {
            width: 32px;
            display: inline-block;
            margin-right: 15px;
          }

          &-app-store-button {
            img {
              width: 70%;
              margin: 0 auto;
              display: block;
              max-width: 200px;
            }
          }

          &-header {
            @include pko-font-size(17);
            margin: 0 0 15px;
          }

          .form {
            max-width: 90%;
            margin: 0 auto;

            &__title {
              @include pko-font-size(21);
            }

            &__fieldset {
              text-align: left;
            }

            &__row--submit {
              text-align: center;
              margin-bottom: 20px;
            }
          }

          &-copy {
            @include pko-font-size(14);
            margin-bottom: 20px;
            color: $color-black;

            &--iko {
              display: inline-block;
              text-align: left;
              width: calc(100% - 32px - 15px);
            }
          }

          .cta {
            display: inline-block;
            min-width: 196px;
          }
        }

        @include breakpoint($brake-md) {
          &-section {
            display: flex;
            flex-direction: row;
          }

          &-element {
            flex: 0 1 50%;

            .calc-loan__popup__cta-section--one-column & {
              flex: 0 1 100%;
            }

            &:first-child {
              padding-right: 20px;
              padding-bottom: 0;
              margin-bottom: 0;
              border-bottom: none;

              .calc-loan__popup__cta-section--one-column & {
                padding-right: 0;
              }
            }

            &:not(:first-child) {
              padding-left: 20px;
              border-left: 1px solid $color-gray-fifth;

              .calc-loan__popup__cta-section--one-column & {
                border-left: 0 none;
                margin: 0 auto;
              }
            }

            &--divided {
              .calc-loan__popup__cta-subelement:not(:first-child) {
                margin-top: 35px;
                padding-top: 0;
                border-top: none;
              }
            }

            &--divided-reordered {
              .calc-loan__popup__cta-subelement:first-child {
                order: 1;
                margin-top: 20px;
                padding-top: 0;
                border-top: none;
              }
            }

            &-header {
              @include pko-font-size(20);
              margin: 0 0 25px;
            }

            &-copy {
              @include pko-font-size(17);
              margin-bottom: 30px;
            }

            .cta {
              min-width: 174px;
            }
          }
        }
      }

      &__tooltip {
        display: inline-block;
        cursor: pointer;
        position: relative;
        top: 2px;

        @include breakpoint($brake-md) {
          position: absolute;
          top: 0;
        }

        .icon-infotip {
          color: $color-gray-third;
          @include pko-font-size(22);
        }
      }

      &__tooltip.js-tooltip--active {
        .icon-infotip {
          color: $color-blue;
        }
      }

      .cta {
        cursor: pointer;
      }

      .hide-on-mobile {
        display: flex;

        @include breakpoint($brake-md-max) {
          display: none;
        }
      }

      .hide-on-desktop {
        display: block;

        @include breakpoint($brake-md) {
          display: none;
        }
      }
    }

    &-snippet-modal {
      @include breakpoint($brake-md) {
        width: 880px;
      }

      @include breakpoint($brake-lg) {
        width: 990px;
      }
    }


    @include breakpoint($brake-sm) {
      #{$calc}__sliders {
        #{$calc-irs-class}-wrapper {
          width: 95%;
        }

        #{$calc-irs-class}-slider-wrapper {
          width: calc(100% - 160px);
        }

        #{$calc-irs-class}-controls {
          margin: -10px 0 0 20px;

          #{$calc-irs-class}-value {
            width: 140px;
          }
        }

        &-installment {
          &-slider {
            width: calc(100% - 2 * 38px);
          }
        }
      }

      #{$calc}__results {
        width: 100%;

        &-details {
          &-container {
            margin-top: 30px;
          }
        }
      }

      #{$calc}__bar {
        &__info {
          display: flex;
        }
      }
    }

    @include breakpoint($brake-md) {
      #{$calc}__fieldset-main-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
      #{$calc}__fieldset-first-subcontainer {
        padding-right: 50px;
        width: 68%;
      }
      #{$calc}__bar {
        &__info {
          dl {
            dd {
              @include pko-font-size(36);
            }
          }
        }
      }
      #{$calc}__sliders {
        #{$calc-irs-class}-controls {
          #{$calc-irs-class}-value {
            &::before {
              @include pko-font-size(24);
              right: -35px;
              padding-left: 20px;
            }
          }
        }
      }
    }

    @include breakpoint($brake-sm-max) {
      #{$calc}__bar {
        &__action {
          width: 100%;
          padding: 0;

          .cta {
            width: calc(100% + 30px);
            height: 100%;
            margin-bottom: 0;
            border-radius: 0;
            margin-left: -15px;
          }
        }
      }
    }

    #{$calc}__summary {
      padding-left: 0;
      padding-right: 0;
      &-details {
        margin: 20px 0 15px;
        @include breakpoint($brake-md) {
          margin: 50px 0 25px;
        }
      }
    }
  }

  &__summary {
    @extend %paddington;
    &-details {
      @include pko-font-size(17);
      color: $color-black;

      @include breakpoint($brake-md) {
        @include pko-font-size(20);
      }
    }

    &-desclimer {
      margin: 0;
      color: $color-gray-second;
    }
    @include breakpoint($brake-sm-max) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Dla LPków o szablonie treści Landing Page 6 (form-szeroki)
.lp-with-calculator-redesign {
  .landing-content__snippet {
    padding-left: 0;
    padding-right: 0;
  }

  .landing-content .landing-content__snippet-inner {
    background: none !important; // Nadpisuje inny important...
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
