// -----------------------------------------------------------------------------
// Basic typography style for copy text
// -----------------------------------------------------------------------------

html {
  font-size: 100%;
}

body {
  @extend %text-default-1;
  font-family: $text-font-stack;
  color: $text-color;
  font-weight: 400;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
  color: $text-color-head;

  small {
    @include pko-font-size(15);
    display: inline-block;
    color: $text-color;
    margin-bottom: 10px;
  }
}

p {
  margin: 18px 0 21px;
}

h1,
.h1 {
  @extend %h1;
  margin: 35px 0;
}

h1 + h2,
h1 + h3,
.h1 + h2,
.h1 + h3,
h1 + .h2,
h1 + .h3,
.h1 + .h2,
.h1 + .h3 {
  margin-top: -3px;
}

h2,
.h2 {
  @extend %h2;
  margin: 35px 0;
}

h3,
.h3 {
  @extend %h3;
  margin: 35px 0;
}

h4,
.h4 {
  @extend %h4;
  margin: 18px 0;
}

h5,
.h5 {
  @extend %h4;
  margin: 18px 0;
}

h6,
.h6 {
  @extend %h4;
  margin: 18px 0;
}

// todo te nazwy sa przykladowo, wogóle tego nie powinno byc
.text-size-1 {
  @extend %text-default-1;
}

.text-size-2 {
  @extend %text-default-2;
}

.text-size-3 {
  @extend %text-default-3;
}

// todo czy to ten plik
// kontener gdzie jest typowy tekst
.text {
  b,
  strong {
    color: $color-black;
    font-weight: 700;
  }
  a {
    > b,
    > strong {
      color: $color-blue;

      @include pko-on-event {
        color: $color-gray-main;
      }
    }
  }
  .reverse-link {
    > b,
    > strong {
      color: $color-gray-main;

      @include pko-on-event {
        color: $color-blue;
      }
    }
  }

  //
  // scss-lint:disable QualifyingElement
  h1.short {
    @include pko-font-size(24);
    margin-bottom: -5px !important; // overwrite .site-content__main .text>*:last-child { margin-bottom: 0; }
  }                                  // for h1 headers from data migration
  // scss-lint:enable QualifyingElement

  ol:not(.form__body) {
    padding-left: 1.8rem;
  }

  ul:not(.form__body) {
    list-style: none !important;
    padding-left: 0.9rem;
    overflow: hidden; // nie działa na kolumnach #87137,  poniżej fix

    &.ul--simulate-no-padding {
      padding-left: 0.9rem;
      margin: 0;
    }

    &.list-no-points {
      padding-left: 0;

      li {
        &::before {
          display: none;  
        }
      }
    }

    > li:not(.form__row) {
      &::before {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $color-gray-main;
        content: '';
        position: absolute;
        left: -14px;
        top: 7px;
        @include breakpoint($brake-md-lg) {
          top: 8px;
        }
      }
    }

    ul > li:not(.form__row) {
      &::before {
        content: '–';
        background: none;
        top: 0;
      }
    }
  }

  &.about-product__item ul {
    overflow: visible; // #87137 fix dla #86570 - kolumny na ie przestały działać
  }

  li:not(.form__row) {
    position: relative;
    margin: 0.55em 0; // todo
  }

  li ul,
  li ol {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  ol ol li {
    list-style-type: lower-latin;
  }

  // tf: obrazki w tabelce bez ticketu, quickfix
  // https://www.pkobp.pl/grupa-pko-banku-polskiego/nagrody-i-wyroznienia/pko-bank-polski-z-lampartem-2016/
  .content & table {
    img:not(.text__image--center) {
      max-width: none;
      margin: 0;
    }
  }

  // todo potrzebne?
  //&-size-2 {
  //  li:before {
  //    width: 4px;
  //    height: 4px;
  //    top: 6px;
  //    @include breakpoint($brake-md-lg) {
  //      top: 7px;
  //    }
  //  }
  //}
  //&-size-3 {
  //  li:before {
  //    width: 3px;
  //    height: 3px;
  //    top: 5px;
  //    @include breakpoint($brake-md-lg) {
  //      top: 6px;
  //    }
  //  }
  //}

  &__left {
    text-align: left;
  }

  &__right {
    text-align: right;
  }

  &__center {
    text-align: center;
  }

  &__justify {
    text-align: justify;
  }
}
