.career-offer-single {

  .additive-list {
    margin-bottom: 45px;
  }

  // Przestylwanie formularza specjalnie dla kariery
  .form {
    margin-top: 50px;
    &__label {
      @extend %text-default-1;
      display: block;
      margin-bottom: 10px;
    }
    &__row {
      max-width: 400px;
      margin-bottom: 45px;
    }
  }

  .multi-input {
    display: table;
    &__row {
      display: table-row;
    }
    &__input {
      display: table-cell;
      width: 49%;

      &--100 {
        width: 100%;
      }

      //// Tymczasowa poprawka bo nie wiem jak wywalic prefix z django-pola
      // Andrzej tez nie :P
      .prefix {
        display: none;
      }
    }
    &__separator {
      display: table-cell;
      width: 2%;
    }
  }
}
