@mixin pko-text-size-3-way($size1, $size2, $size3) {
  @include pko-font-size($size1);
  @include breakpoint($brake-sm) {
    @include pko-font-size($size2);
  }
  @include breakpoint($brake-md) {
    @include pko-font-size($size3);
  }
}

@mixin standard-wrapper {
  padding-bottom: 30px;

  @include breakpoint($brake-sm) {
    padding-bottom: 40px;
  }

  @include breakpoint($brake-md) {
    padding-bottom: 50px;
  }
}

@mixin property-box {
  display: block;
  margin-top: 30px;
  @extend %paddington;

  @include breakpoint($brake-sm) {
    @include span(3);

    &--first-in-row {
      clear: left;
    }
  }

  &-image {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    &-label {
      position: absolute;
      top: 20px;
      left: 0;
      display: block;
      max-width: 75%;
      padding: 5px;
      @include pko-text-size-3-way(13, 15, 15);

      &--tender {
        color: $color-white;
        background: $property-gradient-red-1;
        background: linear-gradient(to right, $property-gradient-red-1 0%, $property-gradient-red-2 100%);
      }

      &--freehand,
      &--preparation {
        color: $color-black;
        background: $property-gradient-grey-1;
        background: linear-gradient(to right, $property-gradient-grey-1 0%, $property-gradient-grey-2 100%);
      }

      &--rent {
        color: $color-white;
        background: $color-blue;
        background: linear-gradient(to right, $color-light-blue 0%, $color-blue 100%);
      }
    }
  }

  &-address {
    margin: 20px 0 0;
    @include pko-text-size-3-way(18, 20, 20);

    @include breakpoint($brake-md) {
      margin-top: 10px;
    }
    &--street {
      margin-top: 0;
    }
  }

  &-data {
    margin: 0;
    color: $color-gray-main;
    @include pko-text-size-3-way(15, 15, 17);
    &--first {
      margin-top: 10px;
      @include breakpoint($brake-sm) {
        margin-top: 0;
      }
    }
  }
}
