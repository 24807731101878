.responsive-iframe {
  .text & {
    margin: 18px 0 21px;
    p > & {
      margin: 0;
    }
    @include breakpoint($brake-sm) {
      width: 75%;
    }
  }

  &__inner {
    padding-bottom: 56.25%; // def
    position: relative;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &--career-offer {
    margin-top: $vertical-space-mobile;

    @include breakpoint($brake-sm) {
      margin-top: $vertical-space-desktop;
    }
  }
}
