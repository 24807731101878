.client-modal {
  &.modal--big-content.modal--popup {
    display: block;
  }
  .modal__content {
    display: flex;
    margin: -27px -30px -30px;
    padding-right: 0;
    flex-direction: column;

    &__section {
      padding: 0 25px 40px;
      text-align: center;

      h3 {
        margin-bottom: 20px;
      }

      button {
        @include pko-font-size(19);
        width: 80%;
        min-width: 100px;
        padding: 13px 43px 14px;
        cursor: pointer;
      }

      .text {
        margin: 20px 0;
      }

      &.bg-gray {
        border-width: 0;
        border-right-width: 1px;
      }
    }

    @include breakpoint($brake-sm-max) {
      &__section {
        border-bottom: 1px solid $border-color;

        h3 {
          margin-top: 30px;
        }

        &:first-child {
          padding-top: 30px;
        }
        &:last-child {
          border-bottom: 0 none;
        }
      }
    }


    @include breakpoint($brake-sm) {
      flex-direction: row;

      &__section {
        flex: 0 1 50%;
        width: 50%;
        padding: 30px 40px 65px;
      }
    }
  }
}

.k2-ie-10-11 .client-modal .modal__content {
  min-height: 0;
}
