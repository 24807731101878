.career-offer {
  margin-bottom: $vertical-space-mobile;
  @include breakpoint($brake-sm) {
    margin-bottom: $vertical-space-desktop;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    @extend %h4;
    margin-top: 0;
    margin-bottom: $vertical-space-mobile / 2;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop / 2;
    }

    display: inline-block;
    color: $color-black;
  }
  &__label {
    float: left;
    width: 50%;
    clear: left;
  }
  &__link {
    @extend %text-default-2;
    display: block;
    margin: 20px 0 0;
  }
  &__value {
    float: left;
    width: 50%;
  }
  &__row {
    @extend %pko-clearfix;
  }

  // single offer page
  &-single {
    @extend %paddington;

    &__info {
      @extend %h4;
      position: relative;
      padding-bottom: $vertical-space-mobile;
      color: $color-black;

      .breakline {
        @extend %break-from-paddington;
      }
    }
    &__description {
      padding: $vertical-space-mobile 0;
      position: relative;
      .breakline {
        @extend %break-from-paddington;
      }
      > *:first-child {
        padding-top: 0;
        margin-top: 0;
      }
      > *:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    &__provision {
      padding: $vertical-space-mobile 0;
      border-top: 1px solid $border-color;
      @include breakpoint($brake-sm) {
        padding: $vertical-space-desktop 0 0;
      }
    }
  }
  &-single &__row:not(:first-child) {
    margin-top: 10px;
  }
  &-single &__label {
    width: 130px;

    @include breakpoint($brake-sm) {
      width: 205px;
    }
  }

  &-sidebar {
    &__title {
      @extend %h3;
      margin-top: 0;
      margin-bottom: 18px !important; // overwrite .text > *:last-child
    }
  }

  // tablet and up
  @include breakpoint($brake-sm) {
    &__label {
      display: inline-block;
      width: 140px;
    }
    &-single {
      &__info {
        padding-bottom: $vertical-space-desktop;
      }
      &__description {
        padding: $vertical-space-desktop 0;
      }
    }
  }
}

// category container - .career-offers
.career-offers {
  &__holder {
    @extend %paddington;
    position: relative;
  }
  .pagination-wrapper {
    @extend %break-from-paddington;
  }
}
