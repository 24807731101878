.select-filter, // nezłe
.voivodeship, // słabe
.sort { // niewiem
  & + .select2 {
    width: auto !important;

    .select2-selection {
      border: none;

      &__rendered {
        color: $color-blue;
      }

      &__arrow {
        border-left: none;
      }
    }
  }
}

.select-mobile-wrapper select {
  @include pko-z-index(default);
}

// FIXME: do poprawy całe stylowanie, zmiana nazewnictam poządki w stylach
// TODO: usunac powiazania z nazwami elementów (voivodeship, news)

.filters-wrapper {
  .select-mobile-wrapper {
    border: none;
    display: inline-block;
    padding-right: 35px;
    color: $color-blue;
    margin-right: -20px;
    padding-left: 5px;
  }
  .select-mobile-arrow {
    position: absolute;
    border-left: none;
    right: 0;
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    width: 100%;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: -20px;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow b,
  .select-mobile-arrow {
    @extend %icomoon;
    &::before {
      content: $icon-drop-down;
      transform: none;
      background: none;
      color: $color-blue;
      font-size: 32px;
      height: 18px;
      margin-right: 7px;
    }
    &::after {
      display: none;
    }
  }
  &-news,
  &-sort {
    margin-top: -7px; // Bug 64335
    @extend %paddington;

    .select2-container--default .select2-selection--single {
      padding-right: 34px;

      .select2-selection__rendered {
        @include pko-font-size($font-size-h4-mobile);
        @include breakpoint($brake-md-lg) {
          @include pko-font-size($font-size-h4);
        }
      }
    }
  }
  &-career {
    margin-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
    }

    &--full {
      .filter-label {
        &--career {
          width: 110px;
          display: block;
          vertical-align: middle;
          margin-bottom: 10px;

          @include breakpoint($brake-xs-sm) {
            display: inline-block;
            margin-bottom: 0;
          }
        }
      }

      .select2-container {
        width: 100% !important; // Po drodze 3 importanty
      }

      .select2-container--default .select2-selection--single .select2-selection__rendered {
        white-space: nowrap;
      }

      @include breakpoint($brake-xs-sm) {
        .select2-container {
          width: auto !important; // Po drodze 3 importanty
        }

        .select2-container--default .select2-selection--single .select2-selection__rendered {
          white-space: normal;
        }
      }
    }
  }
  &-datepicker {
    @extend %h4;
    display: inline-block;
    @include breakpoint($brake-sm) {
      margin-bottom: 37px;
    }
  }
  &-voivodeship,
  &-sort {
    display: block;
    @include breakpoint($brake-sm) {
      display: inline;
    }

    &.hidden {
      display: none;
    }
  }

  // globalny styl dla prostych dropdownow w filtrach
  &-simple {
    @extend %paddington;
    margin-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop / 2;
    }

    .filter {
      margin-bottom: $vertical-space-mobile;
      @include breakpoint($brake-sm) {
        margin-bottom: $vertical-space-desktop;
      }

      &--inline {
        @include breakpoint($brake-sm) {
          display: inline-block;
          padding-right: 20px;
          margin-bottom: 0;
        }
      }

      label {
        margin-bottom: 8px;
        color: $color-black;
        @include pko-font-size($font-size-h3-mobile);
        @include breakpoint($brake-sm) {
          display: inline-block;
          vertical-align: middle;
          padding-top: 6px;
        }
        @include breakpoint($brake-md-lg) {
          @include pko-font-size($font-size-h4);
          padding-top: 4px;
        }

        .touch & {
          display: block;
        }
      }

      .select2 {
        width: auto !important;
        position: relative;
        top: -1px;

        &-selection {
          border: none !important;
          padding-right: 34px;

          &__rendered {
            color: $color-blue;
            padding-right: 10px;
            @include pko-font-size($font-size-h4-mobile);
            @include breakpoint($brake-md-lg) {
              @include pko-font-size($font-size-h4);
            }
          }

          &__arrow {
            border: none;
          }
        }
      }
    }
  }
}
