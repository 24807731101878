.form {

  .tooltip-button {
    cursor: pointer;
  }

  &__row {
    &--text,
    &--textarea,
    &--select {
      .icon-infotip {
        float: right;
        position: relative;
        top: 22px;
        right: -20px;
      }
    }
    .form &--button {
      .icon-infotip {
        margin-left: 20px;
      }
    }
  }

  // todo ?? stylowanie po klasie co miała pokazywać ikonę jest słabe
  .icon-infotip {
    display: inline-block;
    cursor: pointer;
    margin-left: 15px;

    &::before {
      position: absolute;
      font-size: 28px;
      margin-top: -18px;
      margin-left: -18px;
      transition: $trans-hover;
    }
    &:hover::before {
      color: $color-blue;
    }

    &--inline {
      float: none;
      position: relative;
      display: inline;
      top: auto;
      right: auto;
      margin-left: 26px;

      &::before {
        top: -5px;
        left: 0;
        margin-left: -24px;
        margin-top: 0;
      }
    }
  }

}
