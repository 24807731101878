.calc__fieldset {
  &__header {
    @include pko-font-size(17);
    text-align: center;
    color: $color-black;
  }

  &-main-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  @include breakpoint($brake-md) {
    &-main-container {
      flex-direction: row;
      align-items: flex-start;
    }
    &-first-subcontainer {
      padding-right: 50px;
      width: 68%;
    }
  }
}

.calc-input {

  .input-text__append {
    font-size: 1.2rem;
    line-height: 45px;
    top: 0;
    right: calc(50% - 120px);
    padding: 0;
  }
  &__header {
    font-size: 15px;
    margin-bottom: 5px;
  }
  &__error {
    font-size: 11px;
    line-height: 15px;
    color: $color-gray-main;
  }
  .input-text {
    color: $color-black;
  }

  @include breakpoint($brake-xs-sm) {
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }

    .input-text__append {
      right: calc(50% - 103px);
    }
    &__header {
      text-align: left;
    }
  }
  @include breakpoint($brake-sm) {
    margin-left: 10%;

    .input-text__append {
      line-height: 62px;
      right: calc(50% - 150px);
    }
  }
  @include breakpoint($brake-md) {
    margin-left: 28px;
  }
  @include breakpoint($brake-md-lg) {
    margin-left: 70px;
  }
}

.form__filter-radio-wrap {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}
