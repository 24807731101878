// style dla stron: do pobrania, wszystkie wnioski
.crossroads {

  .select-wrapper {
    width: 100%;
    @include breakpoint($brake-sm) {
      max-width: 312px;
    }
  }

  .accordion {
    &__body {
      @include breakpoint($brake-sm) {
        padding-top: 10px;
        padding-bottom: 0;
      }
    }

    &__column-header {
      @include pko-font-size($font-size-t1-mobile);
      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-t1);
      }
      margin-top: 17px;
      margin-bottom: 8px;
    }

    &__column-excerpt {
      @include pko-font-size($font-size-t2-mobile);
      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-t2);
      }
      color: $color-gray-second;
      margin-top: -9px;
      margin-bottom: 9px;
      &--empty {
        margin: 0;
      }
      p {
        margin: 0;
      }
    }

    &--three-columns {
      padding-bottom: 0;
      @include breakpoint($brake-sm) {
        padding-bottom: 79px;
      }
      &:nth-child(3n+1) {
        clear: left;
      }
      &:first-child {
        margin-top: -20px;
        @include breakpoint($brake-sm) {
          margin-top: 0;
        }
      }
    }
  }

}
