$card-border-radius: 4%;
$card-image-width: 2100;
$card-image-height: 1344;
$card-ratio: $card-image-height / $card-image-width;
$card-ratio-percent: $card-ratio * 100%;
$card-view-border-radius: #{($card-border-radius * $card-ratio)} / #{$card-border-radius};

.card__gallery {
  .gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 20px;

    &__item {
      padding: 0 10px;
      width: 50%;
      margin-bottom: 20px;
      cursor: pointer;

      @include breakpoint($brake-sm) {
        width: 33.33%;
      }

      .card__image {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: $card-ratio-percent;
        margin-bottom: 10px;
        border-radius: $card-view-border-radius;
        overflow: hidden;

        & > img {
          margin: 0; // wyłączenie niepotrzebnych marginesów
        }
      }
      .card__project {
        position: absolute;
        width: 103.75494071146245%; // proper margins for print
        height: 105.32915360501567%; // proper margins for print
        max-width: none;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .card__logo {
        position: absolute;
        width: 103.75494071146245%; // proper margins for print
        height: 105.32915360501567%; // proper margins for print
        max-width: none;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .card__text {
        position: absolute;
        top: 58.1%;
        left: 10.6%;
        width: 84.1%;
      }

      .card-modal {
        width: 90%;
        max-width: 1000px;
        cursor: default;

        .card__project-wrapper {
          width: 100%;
          max-width: 600px;
          margin: 0 auto;
        }

        .card__image {
          width: 100%;
          margin: 30px auto;

          @include breakpoint($brake-sm) {
            margin-bottom: 70px;
            margin-top: 0;
          }
        }
      }

      .text {
        @include pko-text-size($font-size-t2-mobile, $line-height-t2-mobile, $font-size-t1, $line-height-t1);
      }

      &:last-child {
        align-self: center;
        padding-bottom: 40px;
        text-align: center;
        cursor: auto;

        &.full-width {
          width: 100%;
          text-align: center;
          margin: 20px 0;
          padding-bottom: 0;
        }

        @include breakpoint($brake-sm-max) {
          width: 100%;
          text-align: center;
          margin: 20px 0;
          padding-bottom: 0;
        }
      }
    }

    &__footer {
      a {
        cursor: pointer;
      }
    }
  }
}


.landing-page--v10 .card-generator__below-content {
  margin-top: 0;
}
