.site-tabs {

  $root: &;

  @extend %pko-clearfix;
  @extend %paddington;
  width: 100%; // push on full width
  display: none;
  //position: absolute;

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: $border-color;
    margin-top: -1px;
    position: relative;
    top: -1px;
  }

  &__item {
    display: inline-block;
    border: 1px solid $border-color;
    padding: 8px 20px;
    float: left;
    margin-left: -1px;
    background: $color-gray-sixth;
    color: $color-gray-main;

    @include breakpoint($brake-md-max) {
      .short-tabs & {
        padding: 8px 16px;
      }
    }

    @include breakpoint($brake-md) {
      .md-narrow-tabs & {
        padding: 8px 16px 8px 14px;
      }
    }
    @include breakpoint($brake-lg) {
      .md-narrow-tabs & {
        padding: 8px 18px 8px 16px;
      }
    }

    &.active {
      background: $link-color;
      color: $color-white;
      border-top-color: $link-color;

      @include pko-on-event {
        color: $color-white !important;
      }
    }

    @include pko-on-event {
      color: $link-color;
    }

    &:first-child {
      border-top-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
    }
    &--big {
      display: flex;
      align-items: center;
      height: 77px;
      line-height: 19px;
      padding: 7px 18px 7px 15px;
    }
  }

  &-select-wrapper {
    @extend %paddington;
    padding-bottom: $vertical-space-mobile;

    .select2 {
      max-width: 360px;
    }
  }

  @include breakpoint($brake-sm) {
    // position: relative; // bo zakładki są w lewej kolumnie, a mają być na całą szerokość
    margin-top: -88px;
    display: block;
    margin-bottom: $vertical-space-desktop - 5px;

    &-select-wrapper {
      display: none;
    }
  }

  //@include breakpoint($brake-md-lg) {
    //margin-top: -92px;
  //}

  &-content {
    @extend %paddington;

    @include breakpoint($brake-sm) {
      > * > .text:first-child > h3:first-child,
      > * > .text:first-child > h2:first-child {
        top: -5px;
        position: relative;
        margin-bottom: -5px;
      }
    }

    &.js-tabs-content {
      > * {
        display: none;
        &.visible {
          display: block;
        }
      }
    }
  }

  //&--big {
  //  @include breakpoint($brake-sm) {
  //    margin-top: -88px;
  //  }
  //}

  &__button-container {
    @extend %paddington;
    margin-bottom: 30px;
    @include breakpoint($brake-sm) {
      margin: 0;
    }
  }
}

.md-select-break {

  .site-tabs {

    display: none;

    &-select-wrapper {
      display: block;
    }

    @include breakpoint($brake-md) {
      display: block;

      &-select-wrapper {
        display: none;
      }
    }
  }
}

/*doc
---
title: Tabs
name: tabs
category: Modules
---

<div class="js-tabs">
  <nav class="site-tabs js-tabs-nav" style="margin-top: 0;width: auto;position:static;">
      <a class="site-tabs__item" href="#/jeden/">jeden</a>
      <a class="site-tabs__item" href="#/dwa/">dwa</a>
      <a class="site-tabs__item" href="#/trzy/">trzy</a>
  </nav>
  <br/>
  <div class="site-tabs-content js-tabs-content">
      <div id="jeden" class="site-tab">
          jeden
      </div>
      <div id="dwa" class="site-tab">
          dwa
      </div>
      <div id="trzy" class="site-tab">
          trzy
      </div>
  </div>
</div>

```<div class="js-tabs">```<br/>
&nbsp;&nbsp;```<nav class="site-tabs js-tabs-nav">```<br/>
&nbsp;&nbsp;&nbsp;&nbsp;```<a class="site-tabs__item" href="#/jeden/">jeden</a>```<br/>
&nbsp;&nbsp;&nbsp;&nbsp;```<a class="site-tabs__item" href="#/dwa/">dwa</a>```<br/>
&nbsp;&nbsp;&nbsp;&nbsp;```<a class="site-tabs__item" href="#/trzy/">trzy</a>```<br/>
&nbsp;&nbsp;```</nav>```<br/>
&nbsp;&nbsp;```<div class="site-tabs-content js-tabs-content">```<br/>
&nbsp;&nbsp;&nbsp;&nbsp;```<div id="jeden" class="site-tab">jeden</div>```<br/>
&nbsp;&nbsp;&nbsp;&nbsp;```<div id="dwa" class="site-tab">dwa</div>```<br/>
&nbsp;&nbsp;&nbsp;&nbsp;```<div id="trzy" class="site-tab">trzy</div> ```<br/>
&nbsp;&nbsp;```</div>```<br/>
```</div>```


<p>Banner na stronie z tabami musi posiadac modyfikator .banner--with-tabs (dostaje on wtedy wiekszy margines
nad dolnym borderem, i mozemy tam zgodnie z grafika "podciagnac" taby ujemnym marginesem, symulujac jak gdyby
byly one nad cala zawartoscia strony, a nie w lewej kolumnie).</p>





*/
