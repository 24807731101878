.calc-pfr {
  .calc__header {
    @include breakpoint($brake-sm) {
      @include span(9);
      float: none;
    }
  }

  .calc-results {
    float: left;
    width: 100%;
  }
  .site-content__additional {
    margin-top: 0;
    padding: 0;

    @include breakpoint($brake-sm) {
      @include span(5);
    }

    @include breakpoint($brake-md) {
      @include span(4);
    }
    &::before,
    &::after {
      display: none;
    }
  }
  .site-content__sidebar {
    padding-left: 0;
    border: 0 none;
    @include breakpoint($brake-sm) {
      position: sticky;
      top: 100px;
    }
  }

  .calc__form {
    .calc__row {
      margin-bottom: 30px;
    }
    .form__filter-radio-wrap {
      margin-right: 50px;
      &:last-child {
        margin-right: 0;
      }
    }

    .input-text,
    .select-pure {
      max-width: 260px;
      box-shadow: none;
    }

    @include breakpoint($brake-sm) {
      @include span(6);
      padding-right: 30px;
    }
    @include breakpoint($brake-md) {
      @include span(7);
    }

    @include breakpoint($brake-lg) {
      @include span(6);
      margin-left: 8.3333%;
    }
  }

  .tooltip {
    margin-left: 10px;
  }
  .input-text {
    margin-top: 0;
  }
  .calc-input__header {
    @extend %text-default-1;
    text-align: left;

    @media all and (max-width: 360px) {
      padding-right: 20px;
    }
  }
}
