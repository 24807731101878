// todo wywalić important i zrobic czyszczenie styli w reposition

.modal {

  $root: &;

  @extend %text-default-2;
  @include pko-z-index(modal);
  @include pko-modal-like;

  @include breakpoint($brake-sm) {
    &__wrapper {
      .modal {
        transform: translate(-50%, -50%);
      }
    }
  }

  &.absolute {
    transform: translate(-50%, 0);
    position: absolute;
  }

  // fix dla x co zakrywa czasami text w &__content
  &__content {
    position: relative;
    @include pko-z-index(default);

    #{$root}--big-content & {
      margin: 20px 0 0;
      flex: 1;
      overflow-y: auto;
      padding-right: 14px;
      transform: translate3d(0, 0, 0); // iPhone bug: text disappears on scroll | #105286
      perspective: 1000; // iPhone bug: text disappears on scroll | #105286
      backface-visibility: hidden; // iPhone bug: text disappears on scroll | #105286
      .k2-ie-10-11 & {
        min-height: 70vh;
      }
      @include breakpoint($brake-sm-max) {
        min-height: 75vh;
      }
      // .text {
      //   transform: translate3d(0, 0, 0); // iPhone bug: text disappears on scroll | #105286
      // }
    }

    // newsletter
    #activation-form {
      margin-top: 19px;

      .wrap-id_email, .wrap-id_process_type,
      #id_email, #id_process_type {
        display: none !important;
      }

      .wrap-id_activation_code {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      #id_activation_code {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        height: 37px;
        border: 1px solid #bababa;
        border-radius: 3px;
        -webkit-appearance: none;
        margin-bottom: 4px;
        outline: 0;
        @include pko-font-size(15);
      }
    }
  }

  &__close {
    @extend %pko-close-absolute;
    z-index: 100;
  }

  &__close-text {
    margin-top: 15px;
    display: inline-block;

    & + & {
      margin-left: 10px;
    }
  }

  // używane tylko wewnątrz modal__content
  &__header {
    margin-top: 0;
    padding-top: 0;
    padding-right: 20px;
    @extend %text-default-1;
    &--inbox {
      color: $icon-color;
    }
    &:empty {
      display: none;
    }
    #{$root}--big-content & {
      flex: 1;
    }
  }

  &__scroll {
    @extend %text-default-3;
    padding: 10px 15px;
    overflow-y: auto;
    border: 1px solid $border-color;
    max-height: 250px;
    margin-top: 30px;
  }

  // raczej nigdy nie używane, a jak coś to tylko wewnątrz modal__content
  &__footer {
    @extend %pko-clearfix;
    padding-top: 30px;

    &:empty {
      display: none;
    }
    #{$root}--big-content & {
      flex: 1;
    }
  }

  &__decline {
    @extend %text-default-3;
    margin-top: 10px;
    float: left;
  }

  &__submit {
    float: right;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &--scroll {
    //
    // scss-lint:disable SpaceBeforeBrace
    #{$root}__content {
      padding-right: 15px;
    }
    // scss-lint:enable SpaceBeforeBrace
  }

  &--form-cookie {
    @include pko-z-index(cookie);
  }

  &--popup {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 96%;
    max-height: 100vh;
    overflow: auto;
    @include breakpoint($brake-md) {
      width: 480px;
    }

    &__header { // todo co to za klasa
      @include pko-font-size(20);
    }

    &#{$root}--facility {
      width: 730px;
    }

    &#{$root}--big-content {
      display: flex;
      flex-direction: column;
      width: 730px;
      @include breakpoint($brake-sm) {
        max-height: 96vh;
      }
      @include breakpoint(($brake-sm) (min-height pko-em-size(600))) {
        max-height: 80vh;
      }
    }

    &#{$root}--disclaimer {
      width: 524px;
    }

    &#{$root}--youtube {
      padding: 36px;

      @include breakpoint($brake-sm) {
        width: 740px;
      }

      @include breakpoint($brake-md) {
        width: 1000px;
      }

      @include breakpoint($brake-lg) {
        width: 1240px;
      }

      @include breakpoint($brake-xl) {
        width: 1420px;
      }
    }

    &#{$root}--neon {
      width: 360px;

      @include breakpoint($brake-sm) {
        width: 390px;
        padding: 50px 50px 45px;
      }
    }

    &#{$root}--image {
      padding: 36px;
      @include breakpoint($brake-sm) {
        width: auto;
      }

      img {
        display: block;
        max-width: 100%;

        @include breakpoint($brake-sm) {
          max-width: 700px;
        }

        @include breakpoint($brake-md) {
          max-width: 960px;
        }

        @include breakpoint($brake-lg) {
          max-width: 1180px;
        }

        @include breakpoint($brake-xl) {
          max-width: 1380px;
        }
      }
    }

    #{$root}__content > h3:first-child {
      margin-top: 0;
      @include pko-font-size(20);
    }

    // Exit pop-up, https://redmine.k2.pl/issues/104804
    &-blue {
      color: $color-white;
      background-color: $icon-color;

      #{$root}__close {
        color: $color-white;

        @include pko-on-event {
          color: $color-black;
        }
      }

      label.input-checkbox:hover {
        color: $color-white !important; // Jest inny important w _form.scss :(
      }

      h1,
      h2,
      h3,
      h4,
      .form__title {
        color: $color-white;
      }

      .form__info {
        color: $color-white;
      }

      a,
      .tooltip-button {
        color: $color-light-blue;

        @include pko-on-event {
          color: $color-black;
        }
      }

      .cta {
        cursor: pointer;

        @include pko-on-event {
          background-color: $color-red;
        }
      }

      .exit-popup-bottom-text {
        &::before {
          content: '';
          display: block;
          margin: 18px -30px 30px;
          border-top: 2px solid $color-white;
        }

        &:first-child {
          &::before {
            display: none;
          }

          &::after {
            content: '';
            display: block;
            margin: 18px -30px 30px;
            border-top: 2px solid $color-white;
          }
        }

        h2 {
          @extend .form__title;
        }

        .cta {
          color: $color-white;

          @include pko-on-event {
            color: $color-white;
          }
        }
      }
    }
  }

  &--wide {
    position: fixed;
    top: 25%;
    left: 50%;
    width: 63%;
    max-width: 900px;

    @include breakpoint($brake-md) {
      //width: 480px;
    }
  }

  &--box {
    @extend %text-default-3;
    max-width: 400px;
    max-height: 100vh;
    overflow: auto;

    h1,
    h2,
    h3,
    h4,
    h5 {
      &:first-child {
        margin-top: 0;
        padding-top: 0;
      }
    }
    p:not(#{$root}__content-small) {
      @include pko-font-size($font-size-t2);

    }
    @include pko-paragraph-spacing;
  }

  &--centered {
    @include breakpoint($brake-sm) {
      height: fit-content;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      margin: auto;
    }
  }

  &--box,
  &--tooltip {
    position: absolute !important;

    #{$root}__content {
      p {
        margin: 5px 0;

        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      ul:not(.poi-modal__body):not(.form__body) {
        margin: 5px 0;
        padding: 0;
        list-style: none;

        li {
          margin-bottom: 2px;
          padding-left: 13px;
          &::before {
            content: '';
            display: inline-block;
            margin-right: 8px;
            margin-left: -13px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $color-gray-main;
            top: -3px;
            position: relative;
          }
        }
      }
    }
  }
  &--fixed {
    position: fixed !important;
  }
  &--tooltip {
    overflow-wrap: break-word;
    word-wrap: break-word;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
    }

    &__content {
      @extend %text-default-3;
    }

    &.top {
      &::before,
      &::after {
        border-width: 11px 11px 0;
        border-color: $color-white transparent transparent;
        bottom: -10px;
      }
      &::before {
        border-color: $border-color transparent transparent;
        bottom: -12px;
      }
    }
    &.bottom {
      &::before,
      &::after {
        border-width: 0 11px 11px;
        border-color: transparent transparent $color-white;
        top: -11px;
      }
      &::before {
        border-color: transparent transparent $border-color;
        top: -12px;
      }
    }
    &.left {
      &::before,
      &::after {
        right: 30px;
      }
    }
    &.right {
      &::before,
      &::after {
        left: 30px;
      }
    }
  }

  &__content-small {
    @extend %text-default-3;
  }

  &__agreement-title::first-letter {
    text-transform: uppercase;
  }

  &--box &__agreement-title {
    margin-bottom: 15px;
    @include pko-font-size(16);
  }
  &--box &__agreement-text {
    @include pko-font-size($font-size-t3);
  }
  &--box &__agreement-accept {
    @include pko-font-size($font-size-t2);
  }

  @include breakpoint($brake-sm-max) {

    //@debug(& #{$root}__content);

    &__content {
      //height: 100%;
      //overflow-x: hidden;
      //overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    &--popup {
      position: fixed !important;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
      margin: 0 !important;
      width: 100% !important;
      max-width: none;
      border: 0;
      border-radius: 0;
      &::before,
      &::after {
        display: none !important;
      }
      -webkit-overflow-scrolling: touch;
    }
    &--box,
    &--tooltip {
      position: fixed !important;
      left: 10px !important;
      right: 10px !important;
      top: 50% !important;
      max-height: 96%;
      overflow: auto;
      width: auto !important;
      max-width: none;
      &::before,
      &::after {
        display: none !important;
      }
    }
    &--tooltip &__close {
      display: block;
    }
    &--wide {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--get-loan-tooltip {
    padding: 10px;
    @include pko-font-size(15);
  }
}

.icon-infotip--active,
.js-tooltip--active .icon-infotip {
  color: $icon-color;
}

// Fix spadania 'i' do nastepnej linii na modalach
.js-tooltip.spread__header {
  padding-right: 15px;
  .icon-infotip {
    position: absolute;
  }
}

// Fix wyświetlania obrazków w oknie modalnym na mobile dla serwisu SKO
.sko .modal__content .text img {
  max-width: 100%;
  height: auto;
}

/*doc
---
title: Modal
name: modal
category: Modules
---

Modale wywolujemy na zadanie w JS:

```new Popup(content);```<br/>
```new Box(content, $(trigger), {position: ['top', 20px, 'left', -30px], width: 400});```<br/>
```new Tooltip(content, $(trigger), {position: ['bottom', 20px, 'right', -30px], width: 200});```<br/>

Argument z triggerem w przypadku Boxa i Tooltipa jest wymagany, poniewaz te rodzaje modali pozycjonuja sie wzgledem niego.<br/>
Argument z opcjami jest opcjonalny. Tablica z pozycjami oznacza kolejno:<br/>
- w Boxie - pionowy punkt odniesienia wzgledem triggera, offset od tego punktu, poziomy punkt odniesienia, offset od tego punktu,<br/>
- w Tooltipie - pionowe polozenie, pionowe przesuniecie, poziome polozenie, poziome przesuniecie.

*/
