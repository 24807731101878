.calc-investor-deposit {
  .site-content__main + div & {
    padding-top: 50px;
  }
  .calc {
    &__header {
      @include breakpoint($brake-sm) {
        @include span(8);
        float: none;
      }
    }

    &__form{
      position: relative;

      .form {
        @include breakpoint($brake-md) {
          @include span(8);
        }
      }
      &__summary {
        @include span(4);
        display: none;
        padding-left: 7%;


        @include breakpoint($brake-md) {
          display: block;
        }

        dt {
          color: $color-gray-second;
          font-size: 0.8rem;
        }

        dd {
          margin-left: 0;
          color: $color-black;
        }

        table {
          border-collapse: collapse;

          th {
            padding: 5px;
            border: 1px solid $color-gray-fifth;//$color-blue;
            color: $color-gray-second;
            font-size: 0.8rem;
            font-weight: normal;
            text-align: left;
          }

          td {
            padding: 5px;
            border: 1px solid $color-gray-fifth;//$color-blue;
            color: $color-black;
            font-size: 0.8rem;
            font-weight: normal;
          }
        }
      }
    }

    &__fieldset {
      width: 100%;

      &__header {
        @extend h3;
        margin-bottom: 20px;
        margin-top: 25px;

        .icon-infotip {
          top: -5px;
          left: 10px;
        }

        @include breakpoint($brake-sm-max) {
          font-size: 20px;
          margin-top: 25px;
          .icon-infotip {
            left: 3px;
          }
        }
      }


      &:first-child {
        margin-top: 45px;
      }
    }

    &__sliders {
      @include span(12);
      position: relative;
      float: none;
      overflow: visible;

      @include breakpoint($brake-md) {
        @include span(9);
      }

      &__list-item {
        margin-bottom: 0;
      }

      .irs-controls {
        position: absolute;
        top: -1px;
        bottom: auto;
        right: 0;

        .irs-value {
          border: 0 none;
          padding: 0;

          input {
            @extend h3;
            margin-top: 0;
            margin-bottom: 0;
            font-weight: bold;
            width: 100%;
            pointer-events: none;
            height: auto;
            padding: 0;
          }
          &::before {
            font-weight: bold;
            color: $color-black;
          }
        }
      }

      .irs-grid-pol {
        z-index: 1;
      }
    }

    &__group {
      display: block;
      padding: 0;
      margin: 0 -20px;

      &__item {
        width: 50%;
        display: inline-block;
        margin-bottom: 20px;

        @include breakpoint($brake-sm) {
          width: 25%;
        }

        label {
          text-align: center;
          padding: 15px;
          margin: 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          small {
            display: block;
            margin-top: 10px;
            width: 100%;
          }

          .image {
            display: inline-block;
            margin-bottom: 15px;

            .active & {
              background-color: $color-white;
            }
          }
        }
      }

      &--column5 {
        .calc__group__item {
          width: 20%;
        }
      }

      &--result {
        .calc__group__item {
          width: 100%;

          @include breakpoint($brake-sm) {
            width: 25%;
          }
        }
      }
    }

    &__summary {
      &__header {
        margin-bottom: 35px;
        h3 {
          margin-bottom: 0;
        }
        p {
          @extend %text-default-2;
        }
      }
    }

    .chart-container__wrap {
      svg {
        width: 100%;
      }
    }

  }


  // Kalkulator na Bankowosci Prywatnej jednak zloty
  .private-banking & {

    .choose-buttons {

      label .image > path {
        fill: $color-gold;
      }

      &.active label .image > path {
        fill: $color-white;
      }
    }

    .icon-infotip:hover::before {
      color: $color-gold;
    }
  }
}
