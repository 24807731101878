@keyframes rotate-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

//@keyframes xmas-star {
//  100% {
//    background-position: -720px;
//  }
//}

@keyframes xmas-star-2 {
  0% {
    transform: scale(0.7);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
