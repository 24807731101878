// px -> rem, base 16
@function pko-size-from-base($size, $base: 16) {
  @return $size / $base;
}

// px (unitless) -> rem
@function pko-rem-size($size) {
  @return pko-size-from-base($size) * 1rem;
}

// px (unitless) -> em
@function pko-em-size($size) {
  @return pko-size-from-base($size) * 1em;
}

// px (unitless), px (unitless) -> unitlessl
@function pko-line-height($size-font, $size-line) {
  @return decimal-ceil($size-line / $size-font, 4);
}

/// Native `url(..)` function wrapper
/// @param {String} $base - base URL for the asset
/// @param {String} $type - asset type folder (e.g. `fonts/`)
/// @param {String} $path - asset path
/// @return {Url}
@function pko-asset($base, $type, $path) {
  @return url($base + $type + $path);
}

/// Returns URL to an image based on its path
/// @param {String} $path - image path
/// @param {String} $base [$base-url] - base URL
/// @return {Url}
/// @require $base-url
@function pko-image($path, $base: $base-url) {
  @return pko-asset($base, 'img/', $path);
}

/// Returns URL to a font based on its path
/// @param {String} $path - font path
/// @param {String} $base [$base-url] - base URL
/// @return {Url}
/// @require $base-url
@function pko-font($path, $base: $base-url) {
  @return pko-asset($base, 'font/', $path);
}
