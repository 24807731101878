// Tabela z IKO (https://iko.pkobp.pl/funkcje/wykaz/) - https://redmine.k2.pl/issues/110980

.table-wrapper-scrollable {
  position: relative;
  margin: 100px 0 50px;
  width: 100%;

  .scrollable {
    margin-left: 45%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    table {
      border: none;

      tr {
        box-shadow: inset 0 1px 0 0 #dedede;
        border: none;

        @include breakpoint($brake-sm-max) {
          box-shadow: none;
        }
      }

      tr:last-child {
        td, th {
          box-shadow: inset 0 -1px 0 0 #dedede;

          @include breakpoint($brake-sm-max) {
            box-shadow: none;
            border-bottom: none;
          }
        }
      }

      td, th {
        border: none;
      }

      img {
        max-width: 100%;
      }
    }
  }

  .pinned {
    position: absolute;
    left: 0;
    top: 0;
    width: 45%;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100%;
    border-right: 1px solid $color-gray-fifth;

    .table-responsive-scrollable {
      height: 100%;
      border: none;

      tr, th {
        border: none;
      }

      tr:last-child {
        td {
          border-bottom: none !important;
        }
      }

      img {
        max-width: 100%;
      }
    }
  }

  .table-responsive-scrollable {
    width: 100%;
    table-layout: fixed;

    &.responsive-scrollable {
      //obliczane w js, 100% * liczba kolumn w tabeli
      table-layout: fixed;
      min-width: 100%;
      position: relative;

      td, th {
        //obliczane w js, 100% / liczba kolumn w tabeli
        position: relative;
        overflow: hidden;

        &:first-child {
          display: none;
        }
      }
    }

    &.table-responsive-scrollable--white tr {
      background-color: $color-white !important;
    }

  }

  .table__navi {
    color: $color-gray-second;
    font-weight: normal;
    @include pko-font-size(25);

    position: absolute;
    right: 0;
    top: -40px;

    h2 &,
    h3 &,
    h4 & {
      float: right;
      position: relative;
      top: -3px;
    }

    &__prev,
    &__next {
      display: inline-block;
      position: relative;
      top: 4px;
      width: 25px;
      height: 25px;

      svg {
        fill: $color-gray-second;
        width: 100%;
        height: 100%;
      }
    }

    &__current {
      color: $color-black;
    }
  }
}

.table-responsive-scrollable {
  @include breakpoint($brake-sm) {
    width: 100% !important;
    position: relative;

    td, th {
      width: auto !important;
    }

    img {
      max-width: 100% !important;
      width: 100%;
    }

    &.table-responsive-scrollable--white {
      border: none;

      tr {
        &:first-child {
          td, th {
            border-top: none;
          }
        }

        &:last-child {
          td, th {
            border-bottom: none;
          }
        }

        td:first-child,
        th:first-child {
          border-left: none;
        }

        td:last-child,
        th:last-child {
          border-right: none;
        }
      }
    }
  }

  &.table-responsive-scrollable--white tr {
    background-color: $color-white !important;
  }

  &.table-responsive-scrollable--no-border {
    border: none !important;

    @include breakpoint($brake-sm-max) {
      td {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
      }
    }
  }
}
