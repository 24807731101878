// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

// todo ?? fix
// scss-lint:disable QualifyingElement
// with icon
%btn-white {
  @extend %button-theme;

  border: 1px solid $border-color;
  background: $color-white;
  color: $link-color;
  border-radius: 3px;
  padding: 7px 16px 8px 18px;
  cursor: pointer;


  .form__facility & {
    border: 1px solid $color-gray-third;

    @include pko-on-event() {
      border: 1px solid $color-gray-second;
    }
  }

  span[class^="icon"] {
    @include pko-font-size(24);
    float: right;
    color: $link-color;
    display: inline-block;
    margin: -4px -12px -4px 7px;
  }

  span[class="icon-minus"],
  span[class="icon-plus"] {
    float: left;
    margin-left: -12px;
    margin-right: 7px;
  }

  @include pko-on-event() {
    color: $text-color;
    span[class^="icon"] {
      color: $text-color;
    }
  }

  &.hidden {
    display: none;
  }

  &.active {
    color: $text-color;

    span[class^="icon"] {
      color: $link-color;
    }

    @include pko-on-event() {
      color: $link-color;
      span[class^="icon"] {
        color: $text-color;
      }
    }
  }

  &:focus,
  .no-touch &:focus {
    color: $link-color;
  }

  @include breakpoint($brake-sm) {
    span[class^="icon"] {
      @include pko-font-size(30);
      margin: -5px -10px -10px 10px;
    }
    span[class="icon-minus"],
    span[class="icon-plus"] {
      margin-left: -10px;
      margin-right: 10px;
    }
  }
}

.choose-buttons {
  label {
    border-radius: 3px;
    border: 1px solid $color-gray-third;
    margin: 5px 0;
    display: block;
    text-align: center;
    background: $color-white;
    color: $color-blue;
    cursor: pointer;
    padding: 5px 12px;
  }
  input:checked + label,
  &.active label {
    background: $link-color;
    color: $color-white;
    pointer-events: none;
    &:hover {
      color: $color-white;
    }
  }
}

.cta {
  @extend %cta;

  &--reverse {
    @extend %cta;
    background-color: $link-color;

    &:hover,
    .no-touch &:hover {
      background-color: $button-red-background-color;
    }
  }

  &--wide {
    @include breakpoint($brake-sm) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  // kolory przycisków
  &-red {
    background-color: $color-red;
    &:hover {
      background-color: $color-dark-blue;
    }
  }
  &-orange {
    background-color: $color-orange-dark;
    &:hover {
      background-color: $color-dark-blue;
    }
  }
  &-gold {
    background-color: $color-orange;
    &:hover, {
      background-color: $color-dark-blue;
    }
    .bg-navy & {
      &:hover,
      .no-touch &:hover * {
        background-color: $color-white;
        color: $color-dark-blue;
      }

      .no-touch &:hover * {
        color: $color-dark-blue;
      }
    }
  }
  &-blue-dark {
    background-color: $color-dark-blue;
    &:hover {
      background-color: $button-red-background-color;
    }
  }
  &-blue-dark-red {
    background-color: $color-dark-blue;
    &:hover,
    .no-touch &:hover {
      background-color: $button-red-background-color;
    }
  }
  &-blue {
    background-color: $color-mid-blue;
    &:hover {
      background-color: $button-red-background-color;
    }
  }
  &-blue-light {
    background-color: $color-light-blue;
    &:hover {
      background-color: $color-mid-blue;
    }
  }
  &-white {
    background-color: $color-white;
    border: 1px solid $border-color;
    color: $link-color;

    &:hover {
      background-color: $color-dark-blue;
      border-color: $color-dark-blue;
    }

    &:focus:hover,
    .no-touch &:focus:hover {
      color: $color-white;
    }

    &:focus,
    .no-touch &:focus {
      color: $color-dark-blue;
    }
  }
}


.btn-white {
  @extend %btn-white;
}

.btn-white-light {
  @extend %btn-white;

  &:hover {
    background-color: $color-gray-sixth;
    color: $color-black;
  }

  .no-touch &:hover * {
    color: $color-black;
  }
}

.btn-white-dark {
  @extend %btn-white;
  min-width: 200px;
  color: $color-black;
  text-align: center;

  &:hover,
  .no-touch &:hover {
    background-color: $color-gray-eight;
    color: $color-black;
  }

  .no-touch &:hover * {
    color: $color-black;
  }

  &--with-icon {
    span[class^="icon"] {
      float: none;
      font-size: 1rem;
      line-height: 1rem;
      margin-right: 8px;
      margin-left: 0;
    }
    span.icon-file-pdf {
      font-size: 1.6em;
      vertical-align: text-top;
      margin-top: 1px;
    }
  }

  &--with-arrow {
    padding-left: 30px;
    span.icon-arrow-right {
      font-size: 1.3rem;
      margin-top: -1px;
    }
  }

  span[class^="icon"], &:hover > span[class^="icon"], .no-touch &:hover > span[class^="icon"] {
    color: $color-dark-blue;
  }
}

// wersje kolorystyczne buttonow na formularzu
.all-cta-red {
  .ccform .cta {
    @extend .cta-red;
  }
}

.all-cta-orange {
  .ccform .cta {
    @extend .cta-orange;
  }
}

.all-cta-gold {
  .ccform .cta {
    @extend .cta-gold;
  }
}

.all-cta-blue-dark,
.all-cta--reverse {
  .ccform .cta {
    @extend .cta-blue-dark;
  }
}

.all-cta-blue {
  .ccform .cta {
    @extend .cta-blue;
  }
}

.all-cta-white {
  .ccform .cta {
    @extend .cta-white;
  }
}
