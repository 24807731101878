.circles {
  margin: 45px -30px 45px;
  padding: 0;

  @include breakpoint($brake-md) {
    margin: 45px -20px 45px;
  }

  @include breakpoint($brake-lg) {
    margin: 45px 0px 45px;
  }

  &__inner {
    overflow-y: hidden;
    justify-content: space-between;
  }

  &__item {
    @include breakpoint($brake-lg) {
      margin-left: 1px;
      margin-right: 1px;
    }

    &--style-red {
      background-color: $color-red-career;
    }
  }
  // &__item {
  //   @include breakpoint($brake-sm) {
  //     width: 190px;
  //     height: 190px;
  //     flex: 0 0 190px;
  //     margin: 0 5px;
  //   }
  //   @include breakpoint($brake-sm) {
  //     width: 196px;
  //     height: 196px;
  //     flex: 0 0 196px;
  //     margin: 0 5px;
  //   }
  //   @include breakpoint($brake-lg) {
  //     width: 205px;
  //     height: 205px;
  //     flex: 0 0 205px;
  //     margin: 0 5px;
  //   }
  // }
}