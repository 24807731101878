//
// scss-lint:disable QualifyingElement
.form {
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.input {

  &-radio,
  &-checkbox {
    @include pko-font-size($font-size-t2);
    display: inline-block;
    position: relative;
    padding-right: 5px;

    .no-touch input:not(:disabled) + &:hover,
    .no-touch input:not(:disabled) + &:active,
    .no-touch.user-is-tabbing input:not(:disabled) + &:focus {
      color: $color-blue;
    }
  }

  .form__row--radiobox &-checkbox,
  .form__row--radiobox-agreement &-checkbox {
    @include pko-font-size($font-size-t3-mobile);
    display: block;

    .tooltip-button {
      display: inline-block;
      line-height: 22px;
      padding-right: 5px;
    }
  }

  input[type="radio"] + &-radio {
    padding-left: (17 + 8) * 1px;
    min-height: 17px;
    line-height: 22px; // todo

    &::before {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      content: '';
      border: 1px solid $color-gray-third;
      display: inline-block;
      position: absolute;
      top: 11px;
      left: 0;
      margin-top: -9px;
      .form__row--error & {
        border-color: $color-error-border;
        background: $color-error-background;

        // error color on blue bg
        .landing-content__snippet-inner & {
          border-color: $color-error-special;
        }
      }
    }
    &::after {
      width: 9px;
      height: 9px;
      background-color: transparent;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      content: '';
      top: 6px;
      left: 4px;
    }

    &--big {
      padding-left: 32px;

      &::before {
        width: 22px;
        height: 22px;
        top: 7px;
      }
      &::after {
        width: 10px;
        height: 10px;
        top: 4px;
        left: 6px;
      }
    }
  }

  .no-touch .user-is-tabbing input[type="radio"]:focus:not(:disabled) + &-radio,
  .no-touch input[type="radio"]:hover:not(:disabled) + &-radio,
  .no-touch input[type="radio"]:active:not(:disabled) + &-radio {
    cursor: pointer;
    color: $color-blue;
    &::before {
      border-color: $color-gray-second;
    }
  }

  .no-touch .user-is-tabbing input[type="radio"]:focus:not(:disabled) + &-radio {
    &::before {
      border-color: $color-blue;
      box-shadow: 0 0 5px $color-light-blue inset;
    }
  }

  .no-touch .user-is-tabbing .form__row--error input[type="radio"]:focus:not(:disabled) + &-radio,
  .no-touch .form__row--error input[type="radio"]:hover:not(:disabled) + &-radio,
  .no-touch .form__row--error input[type="radio"]:active:not(:disabled) + &-radio {
    &::before {
      border-color: $color-error-border;
      background: $color-error-background;

      // error color on blue bg
      .landing-content__snippet-inner & {
        border-color: $color-error-special;
      }
    }
  }

  input[type="radio"]:checked + &-radio {
    &::after {
      background-color: $color-blue;
    }
  }

  input[type="radio"]:disabled + &-radio {
    color: $color-gray-second;
    &::before {
      background-color: $color-gray-disabled;
    }
  }
  input[type="radio"].input-readonly + &-radio {
    pointer-events: none;
    &::before {
      background-color: $color-gray-readonly;
    }
    &::after {
      color: $color-gray-second;
    }
  }

  input[type="radio"]:disabled:checked + &-radio {
    color: $color-gray-second;
    &::after {
      background-color: $color-gray-third;
    }
  }

  input[type="checkbox"] + &-checkbox {
    padding-left: (22 + 8) * 1px;
    min-height: 22px;
    line-height: 22px;
    overflow: hidden; // todo

    .form__row--radiobox-agreement & {
      line-height: 22px;
      padding-top: 0;
      padding-bottom: 0;
    }

    &::before {
      width: 22px;
      height: 22px;
      border-radius: 3px;
      content: '';
      border: 1px solid $color-gray-third;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $color-white;
      .form__row--error & {
        border-color: $color-error-border;
        background: $color-error-background;

        // error color on blue bg
        .landing-content__snippet-inner & {
          border-color: $color-error-special;
        }
      }
    }

    &::after {
      @extend %icomoon;
      @include pko-font-size(30);
      width: 30px;
      height: 30px;
      background-color: transparent;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      content: '';
      top: -3px;
      left: -5px;
    }
  }

  .no-touch .user-is-tabbing input[type="checkbox"]:focus:not(:disabled) + &-checkbox,
  .no-touch input[type="checkbox"]:hover:not(:disabled) + &-checkbox,
  .no-touch input[type="checkbox"]:active:not(:disabled) + &-checkbox {
    cursor: pointer;
    color: $color-blue;
    &::before {
      border-color: $color-gray-second;
    }
  }

  .no-touch .user-is-tabbing input[type="checkbox"]:focus:not(:disabled) + &-checkbox {
    &::before {
      border-color: $color-blue;
      box-shadow: 0 0 5px $color-light-blue inset;
    }
  }

  .no-touch .user-is-tabbing .form__row--error input[type="checkbox"]:focus:not(:disabled) + &-checkbox,
  .no-touch .form__row--error input[type="checkbox"]:hover:not(:disabled) + &-checkbox,
  .no-touch .form__row--error input[type="checkbox"]:active:not(:disabled) + &-checkbox {
    &::before {
      border-color: $color-error-border;
      background: $color-error-background;

      // error color on blue bg
      .landing-content__snippet-inner & {
        border-color: $color-error-special;
      }
    }
  }

  input[type="checkbox"]:checked + &-checkbox {
    &::after {
      content: $icon-check2;
      color: $color-blue;
    }
  }
  input[type="checkbox"]:disabled + &-checkbox {
    color: $color-gray-second;
    &::before {
      background-color: $color-gray-disabled;
    }
  }
  input[type="checkbox"].input-readonly + &-checkbox {
    pointer-events: none;
    &::before {
      background-color: $color-gray-readonly;
    }
    &::after {
      color: $color-gray-second;
    }
  }
  input[type="checkbox"]:disabled:checked + &-checkbox {
    color: $color-gray-second;
    &::after {
      color: $color-gray-third;
    }
  }

  // input checkbox background wrapper
  &-checkbox-bg--wrapper {
    display: inline-block;
    border: 1px solid #bbb;
    padding: 8px;
    margin-bottom: 35px;
    border-radius: 5px;

    &.checked {
      border-color: $color-blue;
      background: $color-blue;
      color: $color-white;

      input[type="checkbox"]:focus:not(:disabled) + .input-checkbox,
      input[type="checkbox"]:hover:not(:disabled) + .input-checkbox,
      input[type="checkbox"]:active:not(:disabled) + .input-checkbox {
        color: $color-white;
      }
    }
  }

  // text, number, tel...
  &-text {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 37px;
    border: 1px solid $color-gray-third;
    border-radius: 3px;
    color: $color-gray-main;
    -webkit-appearance: none;

    &::placeholder {
      color: $color-gray-second;
      opacity: 1;
    }

    &.autocomplete-shown {
      border-radius: 3px 3px 0 0;
    }

    &:disabled {
      background-color: $color-gray-disabled;
      border-color: $color-gray-fifth;
      color: $color-gray-third;
    }

    @include pko-on-event() {
      &:not(:disabled) {
        border-color: $color-gray-second;
      }
      &:disabled {
        background-color: $color-gray-disabled;
        border-color: $color-gray-fifth;
        color: $color-gray-third;
      }
    }

    .form__row--error &:not(.no-validation) {
      border-color: $color-error-border !important;
      background: $color-pink !important;

      // error color on blue bg
      .landing-content__snippet-inner & {
        border-color: $color-error-special;
      }
    }

    &-label {
      @include pko-font-size($font-size-t1-mobile);
      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-t1);
      }
      display: inline-block;
      padding: 0 0 6px;
    }

    &__append {
      position: absolute;
      right: 0;
      top: 6px;
      padding: 8px 10px;
    }

    &__wrapper {
      position: relative;
    }

    &--right-space {
      padding-right: 50px;
    }
  }

  &-textarea {
    vertical-align: top;
    resize: vertical;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid $color-gray-third;
    border-radius: 3px;
    -webkit-appearance: none;

    @include pko-on-event() {
      &:not(:disabled) {
        border-color: $color-gray-second;
      }
    }

    &:disabled {
      background-color: $color-gray-disabled;
      border-color: $color-gray-fifth;
      color: $color-gray-third;
    }

    &--low {
      height: 140px;
    }
  }

  &-select {
    width: 100%;
  }

  &-error {
    position: absolute;
  }
}
// scss-lint:enable QualifyingElement
