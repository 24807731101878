.factoring-modal-with-iframe {
  &.modal--big-content.modal--popup {
    display: block;
  }
  .modal__content {
    display: flex;
    margin: -27px -30px -30px;
    padding-right: 0;
    flex-direction: column;

    &__section {
      padding: 0 25px 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .text {
        margin: 20px 0;
      }

      &.bg-gray {
        border-width: 0;
        border-right-width: 1px;
      }
    }

    @include breakpoint($brake-sm-max) {
      &__section {
        border-bottom: 1px solid $border-color;

        h3 {
          margin-top: 30px;
        }

        &:first-child {
          padding-top: 30px;
        }
        &:last-child {
          border-bottom: 0 none;
        }
      }
    }


    @include breakpoint($brake-sm) {
      flex-direction: row;

      &__section {
        flex: 0 1 50%;
        width: 50%;
        padding: 30px 40px 65px;
      }
    }
  }

  .factoring-iframe-container{
    padding: 0;
    min-height: 280px;
    @include breakpoint($brake-sm-max) {
      min-height: 290px;
      padding-top: 0!important;
    }

    iframe{
      height: 100%;
      width: 100%;
      border: none;
      outline: 0;

      @include breakpoint($brake-sm-max) {
        height: 290px;
      }
    }
  }
  .modal__extra__padding-mobile{
    @include breakpoint($brake-sm-max) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.k2-ie-10-11 .factoring-modal-with-iframe .modal__content {
  min-height: 0;
}
