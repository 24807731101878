.xmas {
  display: none;

  @include pko-z-index();

  @include breakpoint(pko-em-size(1880)) {
    display: block;
    width: 325px;
    height: 920px;
    position: fixed;
    bottom: 0;
    margin-left: -325px;

    &__bg {
      width: 100%;
      height: 100%;
    }

    &__star {
      transform: scale(0.7);
      opacity: 0.5;
      position: absolute;
      top: 230px;
      left: 30px;
      width: 180px;
      height: 180px;
      overflow: hidden;
      //animation: xmas-star 250ms steps(4) infinite alternate;
      animation: xmas-star-2 7500ms infinite alternate;
    }
  }

  @include breakpoint(min-height pko-em-size(920)) {
    top: 0;
    bottom: auto;
  }
}
