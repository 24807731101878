.call-me {
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 20px;
  pointer-events: none;
  @include pko-z-index(call-me-btn-mobile);

  @include breakpoint($brake-sm) {
    bottom: 100px;
    width: auto;
    @include pko-z-index(call-me-btn);
  }

  .no-touch .locked-scroll & {
    right: 0;
  }

  &__button {
    position: relative;
    width: 56px;
    height: 56px;
    border: 0 none;
    border-radius: 100%;
    float: right;
    margin-right: 20px;
    display: flex;
    visibility: hidden;
    opacity: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.33);
    transition: opacity 0.3s ease;
    pointer-events: all;

    span {
      margin: 0 auto; // for old devices with justify-content: center not working
      left: 0;
      right: 0;
      width: 100%;
      animation: changeActive;
      animation-duration: .3s;
      animation-fill-mode: backwards;
    }

    .icon-active {
      display: none;
    }

    &.visible {
      visibility: visible;
      opacity: 1;
      z-index: 10;
    }

    &.active {
      z-index: 0;

      span {
        margin: 0 auto; // for old devices with justify-content: center not working
        left: 0;
        right: 0;
        width: 100%;
        animation: changeActive;
        animation-duration: .3s;
        animation-fill-mode: forwards;
      }

      .icon-active {
        display: block;
      }

      .icon-no-active {
        display: none;
      }
    }
  }

  &__popup {
    position: absolute;
    display: block;
    opacity: 0;
    bottom: 50px;
    right: 20px;
    width: 212px;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    border: 0 none;
    visibility: hidden;
    @include pko-font-size(15);
    cursor: pointer;
    transition: all 0.3s ease;
    pointer-events: all;

    &:after {
      content: '';
      position: absolute;
      bottom: -8px;
      right: 20px;
      display: block;
      width: 16px;
      height: 16px;
      transform: rotate(45deg);
      background-color: $color-white;
      box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.15);
    }

    &.active {
      visibility: visible;
      opacity: 1;
      bottom: 80px;
      right: 20px;
    }

    &.wide {
      width: 100%;
      bottom: -20px;
      right: 0;
      border-radius: 6px 6px 0 0;

      @include breakpoint($brake-sm) {
        width: 290px;
        right: 20px;
        bottom: 80px;
        border-radius: 6px;
        border: 1px solid $color-gray-callme;
      }

      &:after {
        @include breakpoint($brake-sm) {
          border-right: 1px solid $color-gray-callme;
          border-bottom: 1px solid $color-gray-callme;
        }
      }
    }

    &.bg-gold:after {
      // dopasowanie do koloru gradientu .bg-gold zamiast idealnego $gold-light
      background-color: #e1c472;
      background: linear-gradient(to bottom right, rgba(#ddc06c, 1) 0, rgba(#dbbf6e, 1) 51%);
    }

    &.bg-navy:after {
      background-color: $color-blue;
    }

    &.bg-light-blue:after {
      background-color: $color-light-blue;
    }

    &.bg-gray:after {
      background-color: $color-gray-sixth;
    }

    p {
      @include pko-text-size($font-size-t2-mobile, $line-height-t2-mobile, $font-size-t3, $line-height-t3);
      margin: 10px 0;
    }

    h3 {
      margin: 0 0 10px;
    }

    &__inner {
      max-height: 71vh;
      overflow-y: scroll;
      -webkit-overflow-scrolling:touch;

      @include breakpoint($brake-sm) {
        max-height: auto;
        overflow-y: visible;
      }
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    & > svg {
      fill: $color-blue;
    }

    @include breakpoint($brake-sm) {
      display: none;
    }
  }

  &__form {
    .form__row--radiobox {
      margin: 10px 0;
    }

    .cta {
      width: 100%;
    }

    .icon-infotip {
      display: none; // bo nie wiadomo czemu pojawia sie tooltip
    }

    .form__row--radiobox .input-checkbox { //zgody marketingowej
      @include pko-text-size($font-size-t3-mobile, $line-height-t3-mobile, $font-size-t4, $line-height-t4);
    }

    .form__row--info {
      @include breakpoint($brake-sm-max) {
        margin-bottom: 50px;
      }
    }

    .form__row--submit {
      @include breakpoint($brake-sm-max) {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 16px;
        background: white;
      }
    }

    [data-id='id_cell_phone'] {
      &::before {
        content: '+48';
        @extend .input-text;
        position: absolute;
        display: inline-block;
        width: auto;
        padding: 1px 15px;
        line-height: 33px;
        height: 37px;
        border-right: 0;
        border-radius: 3px 0 0 3px;
      }

      input {
        position: relative;
        left: 40px;
        width: calc(100% - 40px);
        border-left: 0;
        border-radius: 0 3px 3px 0;
      }
    }
  }

  &__alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    bottom: -80px;
    transition: all 0.3s ease;

    &__icon {
      margin-top: 30px;
    }

    &__content {
      width: 100%;
      padding: 30px 30px 10px;
    }

    &__button {
      width: 100%;
      margin-top: 30px;
    }

    &.showSmooth {
      opacity: 1;
      visibility: visible;

      > div {
        margin-bottom: 0;
      }
    }
  }
}

@keyframes changeActive {
  0% {
    transform: rotate(0);
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}

