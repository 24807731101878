.documents-archive-list {
  $root: &;

  $color-blockchain: #0d9fd5;
  $color-background-details: #f2f2f2;

  @mixin table-header-spacing {
    padding-top: 5px;
    padding-left: 20px;
    padding-bottom: 10px;
    border-right: 1px solid $border-color;
  }

  @mixin table-elements-spacing {
    padding: 10px 20px;
    border-right: 1px solid $border-color;
    display: flex;
    align-items: center;
  }

  @extend %paddington;
  margin-bottom: $vertical-space-desktop;

  &__header {
    margin-bottom: $vertical-space-mobile;

    h2,
    h3 {
      @include pko-font-size(19);
      margin-bottom: 0;

      @include breakpoint($brake-md-lg) {
        @include pko-font-size(24);
      }
    }

    @include breakpoint($brake-md-lg) {
      @include pko-font-size(17);

      margin-bottom: $vertical-space-desktop;
    }
  }

  &__filter {
    margin-bottom: $vertical-space-desktop - 10px;

    @include breakpoint($brake-md-lg) {
      margin-bottom: $vertical-space-desktop - 20px;
    }

    &-date-container {
      .datepicker-wrap {
        display: inline-block;
      }

      @include breakpoint($brake-md-lg) {
        margin-bottom: 25px;

        .datepicker-wrap {
          @include pko-font-size(20);
        }
      }
    }

    &-inputs-container {
      @include breakpoint($brake-md-lg) {
        display: flex;
        margin-bottom: 10px;
      }
    }

    &-input-wrap {
      .input-text {
        margin-top: 15px;
      }

      @include breakpoint($brake-md-lg) {
        // flex-basis: 50%; --> Fix dla IE 11 poniżej!
        width: 50%;
        flex-basis: auto;

        &--first {
          margin-right: 50px;
        }

        .input-text {
          margin-top: 0;
        }
      }
    }

    &-input-label {
      display: none;

      @include breakpoint($brake-md-lg) {
        display: inline-block;
        @include pko-font-size(15);
        margin-bottom: 5px;
      }
    }

    &-search-container {
      @include breakpoint($brake-md-lg) {
        display: inline-block;
      }

      .cta {
        margin: 20px 0;
        @include pko-font-size(15);
        width: 125px;

        @include breakpoint($brake-md-lg) {
          width: 155px;
          margin-right: 50px;
        }
      }
    }

    &-verification-link {
      @include breakpoint($brake-md-lg) {
        display: inline-block;
        @include pko-font-size(15);
      }
    }
  }

  &__table {
    &-header {
      display: flex;
      justify-content: space-between;
      @include pko-font-size(13);
      margin-bottom: 10px;

      @include breakpoint($brake-sm) {
        @include pko-font-size(15);
      }

      @include breakpoint($brake-md-lg) {
        flex-direction: row-reverse;
        align-content: stretch;
        margin-bottom: 0;

        @include pko-font-size(17);
        font-weight: 700;
        color: $color-black;
      }

      &-date {
        text-align: right;

        @include breakpoint($brake-md-lg) {
          display: none;
        }

        &--desktop {
          display: none;

          @include breakpoint($brake-md-lg) {
            display: inline-block;
            text-align: left;

            // flex-basis: 155px; --> Fix dla IE 11 poniżej!
            width: 155px;
            flex-basis: auto;

            @include table-header-spacing;
          }
        }
      }

      &-download {
        display: none;

        @include breakpoint($brake-md-lg) {
          display: inline-block;
          text-align: left;

          // flex-basis: 310px; --> Fix dla IE 11 poniżej!
          width: 310px;
          flex-basis: auto;

          @include table-header-spacing;
        }
      }

      &-blank {
        display: none;

        @include breakpoint($brake-md-lg) {
          display: block;

          // flex-basis: 160px; --> Fix dla IE 11 poniżej!
          width: 160px;
          flex-basis: auto;
        }
      }

      &-title {
        @include breakpoint($brake-md-lg) {
          // flex-basis: calc(100% - 310px - 155px - 160px); --> Fix dla IE 11 poniżej!
          width: calc(100% - 310px - 155px - 160px);
          flex-basis: auto;

          @include table-header-spacing;
        }
      }
    }

    &-elements-container {
      @include breakpoint($brake-md-lg) {
        #{$root}__table-element:nth-child(odd) {
          background-color: $color-gray-sixth;
        }
      }
    }

    &-element {
      border-top: 1px solid $border-color;
      padding: 10px 0;
      @include pko-font-size(11);

      display: flex;
      flex-wrap: wrap;

      @include breakpoint($brake-sm) {
        @include pko-font-size(15);
      }

      @include breakpoint($brake-md-lg) {
        align-items: stretch;
        padding: 0;
        min-height: 50px;
      }

      &:last-child {
        border-bottom: 1px solid $border-color;
      }

      &-title {
        // flex-basis: 70%; --> Fix dla IE 11 poniżej!
        width: 70%;
        flex-basis: auto;

        margin: 0;
        color: $color-gray-main;
        @include pko-font-size(11);
        word-wrap: break-word;

        @include breakpoint($brake-sm) {
          @include pko-font-size(15);
        }

        @include breakpoint($brake-md-lg) {
          order: 2;

          // flex-basis: calc(100% - 310px - 155px - 160px); --> Fix dla IE 11 poniżej!
          flex-basis: auto;
          width: calc(100% - 310px - 155px - 160px);

          @include table-elements-spacing;
        }
      }

      &-date {
        // flex-basis: 30%; --> Fix dla IE 11 poniżej!
        width: 30%;
        flex-basis: auto;

        text-align: right;

        @include breakpoint($brake-md-lg) {
          order: 1;

          // flex-basis: 155px; --> Fix dla IE 11 poniżej!
          width: 155px;
          flex-basis: auto;

          @include table-elements-spacing;

          text-align: left;
        }
      }

      &-title,
      &-date {
        margin-bottom: 20px;

        @include breakpoint($brake-md-lg) {
          margin-bottom: 0;
        }
      }

      &-links-container {
        // flex-basis: 70%; --> Fix dla IE 11 poniżej!
        width: 70%;
        flex-basis: auto;

        @include breakpoint($brake-md-lg) {
          order: 3;

          // flex-basis: 310px; --> Fix dla IE 11 poniżej!
          width: 310px;
          flex-basis: auto;

          @include table-elements-spacing;
          justify-content: space-between;
        }
      }

      &-show-details {
        // flex-basis: 30%; --> Fix dla IE 11 poniżej!
        width: 30%;
        flex-basis: auto;

        text-align: right;
        line-height: 19px;

        color: $color-blue;
        cursor: pointer;

        @include breakpoint($brake-md-lg) {
          order: 4;

          // flex-basis: 160px; --> Fix dla IE 11 poniżej!
          width: 160px;
          flex-basis: auto;

          @include table-elements-spacing;
          border-right: none;
          justify-content: center;
        }

        .icon-arrow-down,
        .icon-arrow-top {
          color: $color-blue;
          margin-left: 2px;
          @include pko-font-size(14);
          position: relative;
          top: 3px;
          pointer-events: none;

          @include breakpoint($brake-sm) {
            @include pko-font-size(17);
          }

          @include breakpoint($brake-md-lg) {
            top: 1px;
            left: 5px;
          }
        }
      }

      &-download-link {
        color: $color-gray-main;

        @include pko-on-event {
          color: $color-blue;

          span {
            color: $color-blue;
          }
        }

        @include breakpoint($brake-sm) {
          position: relative;
          bottom: 4px
        }

        .icon-file-pdf {
          @include pko-font-size(16);
          color: $color-blue;

          @include pko-on-event {
            color: $color-blue;
          }

          @include breakpoint($brake-sm) {
            @include pko-font-size(21);
            position: relative;
            top: 4px
          }

          @include breakpoint($brake-md-lg) {
            @include pko-font-size(26);
          }
        }
      }

      &-blockchain-link {
        color: $color-gray-main;
        margin-left: 8px;

        @include pko-on-event {
          color: $color-blue;
        }

        @include breakpoint($brake-sm) {
          margin-left: 12px;
          display: none;
        }

        &--full-icon {
          display: none;

          @include pko-on-event {
            color: $color-blue;

            span {
              color: $color-blue;
            }
          }

          @include breakpoint($brake-sm) {
            display: inline-block;
            @include pko-font-size(15);
            position: relative;
            bottom: 4px;
          }
        }

        .icon-blockchain {
          color: $color-blue;
          @include pko-font-size(17);
          transition: color 250ms ease;

          @include pko-on-event {
            color: $color-blue;
          }

          @include breakpoint($brake-sm) {
            @include pko-font-size(21);
            position: relative;
            top: 4px;
            margin-right: 6px;
          }

          @include breakpoint($brake-md-lg) {
            @include pko-font-size(25);
          }
        }
      }

      &-details {
        // flex-basis: calc(100% + 30px * 2); --> Fix dla IE 11 poniżej!
        width: calc(100% + 30px * 2);
        flex-basis: auto;

        background-color: $color-background-details;
        box-shadow: 0 6px 19px -9px rgba(0, 0, 0, 0.4) inset, 0 -6px 19px -9px rgba(0, 0, 0, 0.24) inset;

        margin: 14px -30px -11px -30px;
        padding: 0 30px;

        @include breakpoint($brake-md) {
          // flex-basis: calc(100% + 35px * 2); --> Fix dla IE 11 poniżej!
          width: calc(100% + 35px * 2);
          flex-basis: auto;

          margin: 14px -35px -11px -35px;
        }

        @include breakpoint($brake-md-lg) {
          order: 5;

          // flex-basis: 100%; --> Fix dla IE 11 poniżej!
          width: 100%;
          flex-basis: auto;

          margin: 0;
          padding: 20px 30px 40px;
          box-shadow: none;
          background-color: $color-white;
          border-top: 1px solid $border-color;
        }

        &-hash {
          color: $color-black;
          margin-bottom: 30px;
          word-wrap: break-word;
        }
      }
    }
  }

  .pagination-wrapper {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;

    .pagination {
      border-top: none;
    }

    @include breakpoint($brake-sm) {
      padding-bottom: $vertical-space-mobile;

      .pagination {
        padding-top: $vertical-space-mobile;
      }
    }

    @include breakpoint($brake-md-lg) {
      padding-bottom: $vertical-space-desktop;

      .pagination {
        padding-top: $vertical-space-desktop;
      }
    }
  }

  a.documents-archive-list__table-element-blockchain-link:active span,
  a.documents-archive-list__table-element-blockchain-link:focus span {
    color: $color-blue;
  }
}
