// nie wiem czy ta reguła się nam sprawdzi
// scss-lint:disable NestingDepth QualifyingElement

/* =================
   Porównywarka lokat
   ================= */

.calc--compare-deposit.calc {
  .renewal-number {
    display: inline-block;
    width: 99px;
    vertical-align: middle;
    padding: 0 5px;
    &-wrapper {
      @include pko-font-size($font-size-t1-mobile);
      padding-top: 2px;
      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-t1);
        padding-top: 10px;
      }
    }
  }

  .recalculate-input {
    float: left;
  }
  .recalculate-button {
    margin-top: 6px;
    float: left;
    clear: left;
    @include breakpoint($brake-sm) {
      clear: none;
      float: right;
    }
  }

  @include breakpoint($brake-sm-max) {
    input[type="radio"] + .input-radio--big {
      display: block;
    }
  }

  @include breakpoint($brake-sm) {
    input[type="radio"] + .input-radio--big {
      padding-right: 28px;
      margin-bottom: 10px;
    }
  }

  .calc__sliders {
    margin-bottom: 18px;
    &__radios {
      padding-bottom: 7px;
    }
    &__list {
      margin-bottom: -24px;
      @include breakpoint($brake-sm) {
        margin-bottom: -9px;
      }
      &-item {
        margin-bottom: 18px;
        min-height: 65px;
      }
    }

    .irs {
      /* Controlsy */
      &-controls {

        min-width: 0;

        @include breakpoint($brake-sm) {
          min-width: 158px;
        }

        .irs-value {
          width: 118px;

          @include breakpoint($brake-sm) {
            width: 137px;
          }

          input {
            width: 65%;
            padding: 0;

            @include breakpoint($brake-sm) {
              width: 67%;
            }
          }
        }

        &--with-days {
          @include breakpoint($brake-sm-max) {
            bottom: 70px;
          }
        }

        &--with-currency {
          .irs-value {
            width: 30%;
            float: right;
            padding-right: 2px;
            &::before {
              display: none;
            }
            input {
              width: 90%;
            }
          }

          @include breakpoint($brake-xs-sm) {
            .irs-value {
              width: 118px;
            }
          }

          @include breakpoint($brake-sm) {
            min-width: 312px;
            position: relative;
            margin: 0;
            top: -13px;

            .irs-value {
              width: 148px;
              padding-right: 10px;

              input {
                width: 100%;
                margin-right: 0;
                max-height: 44px;
              }
            }
          }
        }
      }

      /* Waluta w controlsach */
      &-currency {
        float: right;
        width: 38%;
        margin-left: 3%;

        @include breakpoint($brake-xs-sm) {
          width: 95px;
          margin-left: 10px;
        }

        // Nadpisanie styli selecta
        .select2,
        .select-mobile-wrapper {
          margin: 0;
          height: 31px;

          .select2-selection--single {
            height: 31px;

          }
          .select2-selection__rendered,
          .select-mobile-wrapper__val {
            line-height: 31px;
          }

          .select2-selection__arrow,
          .select-mobile-arrow {
            height: 30px;
          }
        }

        @include breakpoint($brake-sm) {
          width: 139px;

          // Nadpisanie styli selecta
          .select2,
          .select-mobile-wrapper {
            margin: 0;
            height: 48px;

            .select2-selection--single {
              height: 48px;

            }
            .select2-selection__rendered,
            .select-mobile-wrapper__val {
              line-height: 46px;
            }

            .select2-selection__arrow,
            .select-mobile-arrow {
              height: 46px;
            }

            .select-mobile-arrow {
              &::before,
              &::after {
                top: 7px;
              }
            }
          }
        }
      }
    }
  }

  .calc__results {
    width: 100%;
    border-top: 1px solid $color-gray-fifth;
    margin-top: 43px;
  }

  .calc__options--header {
    @include breakpoint($brake-sm-max) {
      margin-bottom: 12px;
    }
  }

  .calc-result {
    @extend %pko-clearfix;
    border-bottom: 1px solid $color-gray-fifth;
    padding: 15px 0;

    @include breakpoint($brake-sm) {
      padding: 0;
    }

    &--empty {
      padding: 47px 0;
      color: $color-red;
    }

    &__type,
    &__interest,
    &__payoff,
    &__renewal,
    &__actions {
      @extend %text-default-1;
      width: 100%;
      color: $color-black;
      padding: 5px 0;

      @include breakpoint($brake-sm) {
        position: relative;
        height: 119px;
        padding: 71px 0 0;
        vertical-align: middle;
        float: left;
      }
    }

    &__actions {
      @extend %text-default-2;
    }

    &__clearfix {
      content: '';
      display: table;
      clear: both;
    }

    @include breakpoint($brake-sm) {
      &__type {
        width: 27%;
      }

      &__interest {
        width: 19%;
      }

      &__payoff {
        width: 20%;
      }

      &__renewal {
        width: 16%;
      }

      &__actions {
        width: 18%;
        text-align: right;
      }
    }

    &__form {
      width: 100%;
      .form {
        padding-bottom: 26px;

        .form__body {
          margin-top: -5px;
        }

        .facility {
          margin-bottom: 4px;
        }

        .side-by-side {
          @include breakpoint($brake-sm) {
            display: inline-block;
            width: 27%;
            vertical-align: top;
          }
        }

        .infoBox-content {
          @include breakpoint($brake-xs-sm) {
            padding-bottom: 36px;
          }
        }
      }
    }

    &__label {
      width: 100%;
      @extend %text-default-3;
      color: $color-gray-main;

      @include breakpoint($brake-sm) {
        position: absolute;
        bottom: 57px;
      }
    }

    &__show-form {
      color: $color-red;
      cursor: pointer;

      &.active {
        color: inherit;
      }
    }
  }

  .calc__disclaimer {
    @extend %text-default-3;
    padding: 47px 0 0;
    //border-bottom: 1px solid #dedede;
    margin: 0;
  }
}
