.calc__options {
  width: 100%;
  margin-bottom: 36px;

  h5 {
    .icon-infotip {
      top: -3px;
    }
  }

  &__label {
    display: block;
    margin-bottom: 5px;

    .calc__tooltip {
      vertical-align: top;
    }
  }

  &__list-outer {
    @extend %pko-clearfix;
  }

  &__list {
    @extend %break-from-paddington;
    overflow: hidden;

    &-item {
      @extend %paddington;
      width: 100%;
      margin: 0;

      &--wrapped {
        white-space: normal;
        height: auto;
      }

      @include breakpoint($brake-md) {
        margin-top: -4px;
        @include span(6);

        &:nth-child(2n-1) {
          clear: left;
        }
      }
    }
  }

  .input-checkbox {
    display: block;
    overflow: initial;
  }

  @include breakpoint($brake-md) {
    margin-bottom: 0;
  }
}
