.bankomania-news-slider {
  position: relative;
  @extend %paddington;

  &__title {
    max-width: 65%;

    @include breakpoint($brake-sm) {
      max-width: 80%;
    }

    @include breakpoint($brake-md) {
      max-width: 85%;
    }
  }

  &__description {
    margin-bottom: 30px;

    @include breakpoint($brake-sm) {
      margin-bottom: 40px;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    @extend %paddington;
  }

  &__wrapper {
    position: relative;
  }

  &__item-image-link {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 165px;
    overflow: hidden;

    @include breakpoint($brake-xs-sm) {
      height: 360px;
    }

    @include breakpoint($brake-sm) {
      height: 115px;
    }

    @include breakpoint($brake-md) {
      height: 200px;
    }
  }

  &__item-image {
    position: absolute;
    left: -10000%; 
    right: -10000%; 
    top: -10000%; 
    bottom: -10000%;
    margin: auto;
    min-width: 1000%;
    min-height: 1000%;
    transform: scale(0.1);
  }

  &-item-title {
    margin: 25px 0 35px;
    font-size: 1.5rem;
  }

  .owl {
    &-stage-outer {
      padding-bottom: 15px;

      &.centered-items {
        @include breakpoint($brake-sm) {
          display: flex;
          justify-content: center;
        }
      }
    }

    &-nav {
      &.disabled {
        display: none;
      }
    }

    &-dots {
      margin-bottom: -10px;
      
      &.disabled {
        display: none;
      }
    }

    &-prev,
    &-next {
      display: block;
      z-index: 2;
      top: 20%;
      width: 40px;
      height: 46px;
      padding: 8px 4px;
      border: 1px solid $color-gray-fifth;
      border-radius: 5px;
      background-color: $color-white;
      font-size: 28px;

      &.disabled {
        display: none;
      }

      @include breakpoint($brake-md) {
        top: 30%;
      }
    }

    &-prev {
      left: -5%;
    }

    &-next {
      right: -5%;
    }
  }
}
