// Wyszukiwarka dokumentów REACT - używana do KID oraz MIFID

$files-search-background-gray: #f2f2f2;

.files-search-snippets {
  @extend %paddington;

  margin-bottom: $vertical-space-desktop;

  @include breakpoint($brake-md) {
    max-width: 65%;
  }
}

.files-search {
  &__gray {
    background-color: $files-search-background-gray;
  }

  &__form {
    @extend %paddington;

    margin-bottom: $vertical-space-desktop;
    background-color: $files-search-background-gray;
    position: relative;

    &-header {
      padding: $vertical-space-mobile 0;

      @include breakpoint($brake-md) {
        padding: $vertical-space-desktop 0 30px 0;
      }

      &-title {
        margin: 0 0 30px;

        @include pko-font-size(24);

        @include breakpoint($brake-md) {
          margin: 0 50px 0 0;
          float: left;
          max-width: 25%;
        }
      }

      &-choice-container {
        @include breakpoint($brake-md) {
          float: left;
          width: 65%;
        }
      }

      &-choice-badge {
        color: $color-white;
        background-color: $color-blue;
        border-radius: 16px;
        @include pko-font-size(13);
        padding: 7px 20px;
        margin-bottom: 15px;
        max-width: max-content;
        word-wrap: break-word;

        @include breakpoint($brake-md) {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }

    &-current-step {
      margin: 0 0 30px;

      @include pko-font-size(20);
    }

    &-fieldset {
      position: relative;

      @include breakpoint($brake-md) {
        column-count: 4;
      }

      &--half-width {
        @include breakpoint($brake-md) {
          column-count: 2;
        }
      }
    }

    &-input {
      margin-bottom: 20px;

      @include breakpoint($brake-md) {
        margin-bottom: 10px;
      }

      .input-radio::before {
        background-color: $color-white;
      }
    }

    &-footer {
      padding: 30px 0 $vertical-space-mobile 0;

      @include breakpoint($brake-md) {
        padding: 30px 0 $vertical-space-desktop 0;
      }

      &--no-padd-top {
        padding-top: 0;
      }
    }

    &-next-button {
      float: right;
      width: 160px;

      &:disabled {
        background-color: $color-gray-second;

        &:hover {
          background-color: $color-gray-second;
        }
      }
    }

    &-previous-button {
      line-height: 33px;

      @include breakpoint($brake-md) {
        line-height: 37px;
      }

      @include breakpoint($brake-md-lg) {
        @include pko-font-size(17);
      }

      float: left;
      background: none;
      color: $color-blue;
      padding-left: 0;
      padding-right: 0;
    }

    &-skip-button {
      @extend .files-search__form-previous-button;

      float: right;
      margin-right: 10px;

      @include breakpoint($brake-sm) {
        margin-right: 20px;
      }
    }

    &-cancel-button {
      line-height: 33px;

      @include breakpoint($brake-md) {
        line-height: 37px;
      }

      @extend .files-search__form-previous-button;

      float: none;
      margin-top: $vertical-space-mobile;
      display: inline-block;

      @include breakpoint($brake-md) {
        float: right;
        margin-top: 0;
      }
    }
  }

  &__results {
    @extend %paddington;

    &-items {
      @include breakpoint($brake-sm) {
        column-count: 2;
      }
    }

    &-title {
      margin: 0 0 $vertical-space-desktop 0;

      @include pko-font-size(24);
    }
  }

  &__download-link {
    @include pko-font-size(15);

    display: block;
    margin-bottom: 30px;

    // Fix gdy jest jeden dokument na liście
    overflow: hidden; /* Fix for firefox and IE 10-11  */
    //-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    //break-inside: avoid; /* IE 10+ */
    break-inside: avoid-column;

    &--all-documents {
      margin: 70px 0 0;
    }
  }

  &__icon {
    color: $color-blue;
    @include pko-font-size(26);

    position: relative;
    top: 5px;
    padding-right: 6px;
  }

  &__breakline {
    margin-top: $vertical-space-desktop;
  }

  .pagination {
    margin-top: 30px;

    .skip {
      position: relative;
      top: 5px;
      left: 2px;
    }
  }
}
