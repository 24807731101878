.accordion {
  @extend %pko-clearfix;

  $root: &;
  $mobile-only: '#{$root}--mobile-only';
  $mobile-only-footer: '#{$root}--mobile-only-footer';
  $mobile-only-contact: '#{$root}--mobile-only-contact';

  border-bottom: 1px solid $color-gray-fifth;

  // jeden z wielu :(
  margin-bottom: 0;

  .text + & {
    margin-top: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-top: $vertical-space-desktop;
    }
  }

  .site-content__module &:not(.accordion--simple) {
    margin-bottom: 0;
  }

  + .btn-white,
  + .btn-white + .btn-white {
    margin-top: 50px;
  }

  &--no-border-bottom {
    border-bottom: 0 none;
  }

  &--mobile-only-footer {
    border-bottom: 0;
  }
  &--mobile-only-contact {
    margin-bottom: 30px;
    #{$root}__header {
      @include pko-font-size($font-size-h3-mobile);
    }
    @include breakpoint($brake-sm) {
      border-bottom: 0;
    }
  }

  @include breakpoint($brake-sm) {
    margin-bottom: 30px;
  }

  &__header {
    $root: &;
    $header-inside-active: '#{$root}.active';

    @include pko-text-size(17, 21, 24, 30);
    display: block;
    min-height: 53px;
    border-top: 1px solid $color-gray-fifth;
    padding: 16px 50px 15px 0;
    @include breakpoint($brake-sm) {
      padding: 15px 50px 12px 0; //Bug #62819
    }
    transition: color $trans-hover;
    cursor: pointer;
    position: relative;
    color: $color-black;
    margin: 0;
    &:hover {
      color: $link-color;
      .accordion__header-icon {
        color: $text-color;
      }
    }
    &.active {
      color: $link-color;
      .accordion__header-icon {
        color: $link-color;
      }
      &:hover {
        color: $color-black;
        .accordion__header-icon {
          color: $text-color;
        }
      }
    }

    & > a {
      @extend .clearfix;
    }

    .site-content__module-header {
      margin-bottom: 0;
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0;
      @include breakpoint($brake-sm-max) {
        @include pko-font-size($font-size-h4-mobile);
      }
      @include breakpoint($brake-sm) {
        margin-bottom: 80px;
        margin-top: 0;
      }
    }

    &-icon {
      @extend %pko-icon-plus;

      color: $icon-color;
      position: absolute;
      top: 17px;
      right: 1px;
      &::before,
      &::after {
        transition: height $trans-hover, top $trans-hover;
        background-color: currentColor;
      }
      @include breakpoint($brake-md-lg) {
        right: 17px;
        top: 19px;
      }
      @include pko-when-inside($header-inside-active) {
        @extend %pko-icon-minus;
        position: absolute;
      }
    }

    // style dla stopki
    @include breakpoint($brake-sm) {
      @include pko-when-inside($mobile-only-footer) {
        padding: 0;
        cursor: initial;
        min-height: 0;
        border: 0;
        @include pko-font-size($font-size-t3);
        @include pko-line-height($font-size-t3, $line-height-t3);
        &-icon {
          display: none;
        }

        @include pko-on-event(true) {
          color: $color-gray-second;
          background-color: transparent;
        }
        &.active {
          color: $color-gray-second;
          background-color: transparent;
        }
    }

    // style dla kontaktu
      @include breakpoint($brake-sm) {
        @include pko-when-inside($mobile-only-contact) {
          @include pko-font-size($font-size-h3);
          padding: 0;
          margin-bottom: 10px;
          margin-top: 0;
          cursor: initial;
          min-height: 0;
          border: 0;
          &-icon {
            display: none;
          }

          @include pko-on-event(true) {
            //color: $color-black;
            background-color: transparent;
          }
          &.active {
            //color: $color-black;
            background-color: transparent;
          }
        }
      }

    }

    &--simple-text {
      @include pko-text-size(15, 21, 15, 21);
    }
  }

  &__body {
    padding: 21px 0 43px; //Bug #63016 + Bug #62819

    .site-content__module & {
      padding: 5px 0 25px;
      @include breakpoint($brake-sm) {
        padding: 25px 0 45px;
      }
    }

    @include pko-when-inside($mobile-only-contact) {
      padding: 0;
    }

    // zawsze pokazuj w stopce
    @include breakpoint($brake-sm) {
      @include pko-when-inside($mobile-only-footer) {
        padding: 10px 0 43px;

        &,
        &.hidden {
          display: block;
        }
      }
    }

    // zawsze pokazuj w kontakcie
    @include breakpoint($brake-sm) {
      @include pko-when-inside($mobile-only-contact) {
        padding: 12px 0 43px;

        &,
        &.hidden {
          display: block;
        }
      }
    }
  }

  &--products {
    margin-bottom: 30px;
    @include breakpoint($brake-sm) {
      margin-bottom: 40px;
    }
    @include breakpoint($brake-md) {
      margin-bottom: 47px;
    }
  }

  &--two-columns {
    @extend %paddington;

    &-wrap {
      overflow: hidden;
      @extend %break-from-paddington;
    }

    @include breakpoint($brake-sm) {
      @include span(6);
    }
  }

  &--simple {
    border-bottom: 0 none;
    margin-bottom: 15px;

    .accordion__header {
      @include pko-text-size(15, 18, 17, 22);
      min-height: auto;
      border-top: 0 none;
      padding: 0 0 0 35px;
      @include pko-on-event() {
        color: $color-black;
      }

      &.active .accordion__header-icon,
      &-icon {
        right: auto;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
      }
    }
    .accordion__body {
      padding-left: 5px;
      padding-bottom: 0;
      padding-top: 15px;
    }
  }

    //
    // scss-lint:disable IdSelector
  #newsletter-page &--two-columns {
    @include breakpoint($brake-lg) {
      @include with-layout(8) {
        // Temporary 8-column grid
        @include span(3);
      }
    }
  }
  // scss-lint:enabled IdSelector

  &--three-columns {
    @extend %paddington;


    &-wrap {
      overflow: hidden;
      @extend %break-from-paddington;
    }

    @include breakpoint($brake-sm) {
      @include span(4);
      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }

  // todo skasowałem z kategorii z visualem
  .breakline--inline {
    top: -10px;
    display: none;
    @include breakpoint($brake-sm) {
      display: block;
    }
  }



  .category-item-container .site-content__main .category-items--accordion:not(:first-child) {
    margin-bottom: 30px;
    @include breakpoint($brake-sm) {
      margin-bottom: 50px;

      //
      // scss-lint:disable SelectorDepth
      + .worth-to-know {
        .feature-section-item .feature-section-item__title--recommended {
          margin-top: -10px;
        }
      }
      // scss-lint:enable SelectorDepth
    }
  }

  // #70898 / blednie uzyty akordeon kontaktu w innym miejscu - fix sztywniaczek :(
  .group-structure .accordion.accordion--mobile-only-contact {
    margin-bottom: 0;
  }
}

// for product page; fix for 64641 broke accordions on other pages

//.site-content__main .site-tabs-content article .product-tab-accordion:first-child {
//  position: static;
//}

// bug 64641 ususniecie gornej linii i podniesienie akordeonu aby go zrównać,
// w taki cudaczny sposob ktory nie spowoduje podniesienia zakladek...

.site-tabs-content article .accordion:not(.accordion--simple):first-child,
.category-item-container .site-content__main .category-items--accordion:first-child .accordion:first-child {
  position: relative;
  top: -15px;
  margin-bottom: 15px;

  @include breakpoint($brake-sm) {
    top: -51px;
    margin-bottom: -21px;
    // scss-lint:disable SelectorDepth
    .accordion__elem:first-child .accordion__header {
      border-top: none !important;
    }
    // scss-lint:enable SelectorDepth
  }
}


/*doc
---
title: Accordion
name: accordion
category: Modules
---

<div class="accordion accordion--products js-accordion">
    <div class="accordion__elem">
        <a href="#1" class="accordion__header js-accordion-header" tabindex="0">
            Konta osobiste
            <i class="accordion__header-icon"></i>
        </a>
        <div class="accordion__body js-accordion-body hidden">
            W ramach jednej oplaty korzystasz z konta jak chcesz i ile chcesz. Brak oplat za wyplaty ze wszystkich
            bankomatów na swiecie, krajowe przelewy, zlecenia stale ...
        </div>
    </div>
</div>

```<div class="accordion accordion--products js-accordion">``` <br/>
&nbsp;&nbsp;```<div class="accordion__elem">``` <br/>
&nbsp;&nbsp;&nbsp;&nbsp;``` <div href="#1" class="accordion__header js-accordion-header" tabindex="0">``` <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;```Konta osobiste``` <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;```<i class="accordion__header-icon"></i>``` <br/>
&nbsp;&nbsp;&nbsp;&nbsp;```</div>``` <br/>
&nbsp;&nbsp;&nbsp;&nbsp;``` <div class="accordion__body js-accordion-body hidden">``` <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;``` W ramach jednej oplaty korzystasz z konta jak chcesz i ile chcesz.
Brak oplat za wyplaty ze wszystkich bankomatów na swiecie, krajowe przelewy, zlecenia stale ...``` <br/>
&nbsp;&nbsp;&nbsp;&nbsp;``` </div>``` <br/>
&nbsp;&nbsp; ``` </div>``` <br/>
```</div>```
*/
