/* =================
   Moduł kalkulatora hipotecznego
   ================= */

.calc {
  &--mortgage {
    .input-text-label {
      padding: 0;
    }

    .site-tabs {
      @include breakpoint($brake-sm-max) {
        margin-bottom: 20px;
      }
    }

    .calc__sliders {
      .irs-controls {
        @include breakpoint($brake-sm) {
          width: 248px;
        }
      }

      .irs-value {
        @include breakpoint($brake-sm) {
          width: 190px;
          input {
            width: 85%;
            padding: 3px 0 0;
            line-height: 1;
          }
        }
      }
    }
  }
}
