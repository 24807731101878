.snippet-promotional {
  @extend %paddington;
  padding-bottom: 0;

  // restyle for wrapper .iko-hompage-wrap
  .iko-homepage-wrap & {
    padding-left: 0;
    padding-right: 0;

    .breakline {
      display: none;
    }
  }

  &__title {
    margin-bottom: 14px;
  }

  &__container {
    @include breakpoint($brake-sm) {
      display: flex;
      align-content: flex-start;
      justify-content: space-between;
    }
  }

  &__left, &__right {
    width:100%;
    margin-bottom: 35px;

    @include breakpoint($brake-sm) {
      width: 41.6%;
      margin-bottom: 0;
    }
  }

  &__image-link {
    display: block;
    text-align: center;

    @include breakpoint($brake-sm) {
      text-align: left;
    }
  }

  &__image {
    max-width: 100%;

    @include breakpoint($brake-sm) {
      max-height: 270px;
    }
  }

  &__button-left {
    margin-right: 0;
    vertical-align: top;

    @include breakpoint($brake-md) {
      margin-right: 8px;
    }
    @include breakpoint($brake-lg) {
      margin-right: 15px;
    }
  }

  &__button-left, &__button-right {
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 17px;
    line-height: 1.2;

    @include breakpoint($brake-md) {
      width: auto;
      padding: 10px 20px;
      text-align: left;
      font-size: 15px;
    }
  }

  // restyle app buttons for mobile
  .iko-snippet-infosite__app-store-icon {
    display: block;
    margin: 0 auto 15px auto;
    width: 100%;
    height: 49px;
    background: $color-black;
    border-radius: 4px;
    text-align: center;

    & > img {
      max-height: 100%;
    }

    @include breakpoint($brake-sm) {
      display: none;
    }
  }

  &__right &__button-right {
    display: none;

    @include breakpoint($brake-sm) {
      display: inline-block;
    }
  }
}

// restyle form
.iko-app-phone-callback--modal-form {
  display: block;

  @include breakpoint($brake-sm) {
    margin: -20px 0 0 0;
  }
}

// restyle site-content__module on homepage
.homepage .site-content__module--paddington.site-content__module--snippet-promotional {
  padding: 0; // despair
}
