// Strona do weryfikacji Blockchain

.blockchain-verify {
  $root: &;
  $color-green-verified: #39a935;
  $overlayText: 'Trwa analizowanie pliku';

  @extend %paddington;
  margin-bottom: $vertical-space-desktop;

  @include breakpoint($brake-sm) {
    margin-bottom: 2 * $vertical-space-desktop;
  }

  &__header {
    margin-bottom: 26px;

    @include breakpoint($brake-sm) {
      float: left;
      width: 40%;
      padding-right: 58px;
      margin-bottom: 0;
    }

    &-title {
      @include pko-font-size(19);
      margin: 0 0 16px;

      @include breakpoint($brake-sm) {
        @include pko-font-size(26);
        margin: 0 0 28px;
      }

      @include breakpoint($brake-lg) {
        @include pko-font-size(32);
        margin: 0 0 42px;
      }
    }

    p {
      @include pko-font-size(14);

      @include breakpoint($brake-sm) {
        @include pko-font-size(15);
      }

      @include breakpoint($brake-lg) {
        @include pko-font-size(17);
      }
    }
  }

  &__container-form {
    @include breakpoint($brake-sm) {
      float: left;
      width: 60%;
    }
  }

  &__form {
    border-radius: 3px;
    border: 1px dashed $color-gray-fifth;
    overflow-y: hidden;

    @include breakpoint($brake-lg) {
      display: flex;
      height: 242px;
      overflow-x: hidden;
    }

    &--vertical {
      flex-direction: column;
    }

    &-title {
      @extend #{$root}__header-title;

      text-align: center;

      @include breakpoint($brake-sm) {
        @include pko-font-size(24);
        margin-bottom: 16px;
      }

      @include breakpoint($brake-lg) {
        text-align: left;
      }

      &--file-title {
        display: none;

        @include breakpoint($brake-lg) {
          display: block;
          text-align: center;
        }
      }
    }

    &-error {
      &-container {
        text-align: center;
        padding: 30px 15px;

        @include breakpoint($brake-lg) {
          flex-grow: 1;
          flex-basis: 50%;
        }
      }

      &-title {
        margin: 0;
        @include pko-font-size(19);

        @include breakpoint($brake-sm) {
          @include pko-font-size(24);
        }
      }

      &-icon {
        display: block;
        color: $color-red;
        @include pko-font-size(45);
        margin-bottom: 15px;
      }
    }

    &-verified,
    &-unverified {
      &-container {
        @extend #{$root}__form-error-container;
        padding: 15px 15px 30px;

        @include breakpoint($brake-sm) {
          padding: 15px 40px 30px;
        }

        @include breakpoint($brake-lg) {
          padding: 15px 120px 30px;
        }
      }

      &-title {
        @extend #{$root}__form-error-title;
      }

      &-icon {
        display: block;
        color: $color-green-verified;
        @include pko-font-size(115);
        margin-bottom: 15px;
      }
    }

    &-unverified {
      &-container {
        @include breakpoint($brake-lg) {
          padding: 15px 80px 30px;
        }
      }

      &-icon {
        color: $color-red;
      }
    }

    &-file {
      &-container {
        text-align: center;
        padding: 42px 0;
        margin: 0;
        border-bottom: 1px dashed $color-gray-fifth;
        transition: margin-top 0.5s ease;

        @include breakpoint($brake-sm) {
          padding: 42px 25px;
        }

        @include breakpoint($brake-lg) {
          flex-grow: 1;
          flex-basis: 50%;
          border-bottom: none;
          padding: 0 25px;
          display: flex;
          height: 100%;
          align-items: center;
          border-right: 1px dashed $color-gray-fifth;
          position: relative;
          transition: margin-left 0.5s ease;
        }

        &--active {
          border-bottom: none;
          border-right: none;
        }
      }

      &-title-description {
        display: none;

        @include breakpoint($brake-lg) {
          display: block;

          padding-top: 5px;
          margin-bottom: 26px;
        }
      }

      &-input {
        display: block;
        opacity: 0;
        width: 100%;
        height: 0;
      }

      &-label {
        cursor: pointer;

        @include pko-font-size(14);

        @include breakpoint($brake-sm) {
          @include pko-font-size(15);
        }
      }

      &-name {
        padding: 6px 15px 0;
        word-break: break-all;
        color: $color-blue;

        @include breakpoint($brake-sm) {
          display: inline-block;
          padding: 0;
        }
      }

      &-name-container {
        @include breakpoint($brake-sm) {
          text-align: left;
        }

        @include breakpoint($brake-lg) {
          @include pko-font-size(17);
        }
      }

      &-clear {
        display: block;
        margin-top: 5px;
        color: $color-red;
        margin-left: 5px;
        cursor: pointer;
        @include pko-font-size(20);

        @include breakpoint($brake-sm) {
          display: inline-block;
          margin-top: 0;

          position: relative;
          top: 4px;
          left: 4px;
        }
      }

      &-drag-area {
        display: none;

        @include breakpoint($brake-lg) {
          position: absolute;
          opacity: 0.5;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: block;

          background: linear-gradient(90deg, $gold-light 2px, transparent 1%) center,
          linear-gradient($gold-light 2px, transparent 1%) center, $gold-dark;
          background-size: 5px 5px;
        }
      }
    }

    &-manual {
      &-container {
        padding: 30px 15px;
        margin: 0;

        @include breakpoint($brake-sm) {
          padding: 42px 25px;
        }

        @include breakpoint($brake-lg) {
          flex-grow: 1;
          flex-basis: 50%;
          padding: 0 25px;
          display: flex;
          height: 100%;
          align-items: center;
        }

        .form__error {
          padding: 8px 15px;

          @include breakpoint($brake-sm) {
            position: absolute;
            bottom: 5px;
            padding: 8px 17px;
          }

          @include breakpoint($brake-lg) {
            position: relative;
            bottom: 0;
          }
        }
      }

      &-input {
        @include breakpoint($brake-sm) {
          float: left;
          width: calc(75% - 10px);
          margin-right: 10px;
        }

        @include breakpoint($brake-lg) {
          float: none;
          width: 100%;
          margin-right: 0;
        }

        &--full-width {
          @include breakpoint($brake-sm) {
            float: none;
            width: 100%;
            margin-right: 0;
          }
        }
      }

      &-confirm {
        width: 100%;
        margin-top: 15px;
        @include pko-font-size(15);
        cursor: pointer;

        @include breakpoint($brake-sm) {
          float: left;
          width: 25%;
          margin-top: 0;
        }

        @include breakpoint($brake-lg) {
          width: 100px;
          float: none;
          margin-top: 15px;
        }
      }
    }

    &-captcha {
      &-container {
        padding: 30px 15px;
        background-color: $color-gray-sixth;
        border-top: 1px solid $color-gray-fifth;

        @include breakpoint($brake-sm) {
          padding: 25px;
        }

        @include breakpoint($brake-lg) {
          display: flex;
          padding-bottom: 40px;
          position: relative;
        }

        label {
          text-align: center;

          @include breakpoint($brake-sm) {
            text-align: left;
          }

          @include breakpoint($brake-lg) {
            @include pko-font-size(17);
          }
        }

        .form__error {
          text-align: center;

          @include breakpoint($brake-sm) {
            text-align: right;
            padding-right: 0;
          }

          @include breakpoint($brake-lg) {
            position: absolute;
            bottom: 5px;
            left: 11px;
          }
        }
      }
    }

    &-analyze-button {
      display: block;
      margin: 30px auto 0;

      @include breakpoint($brake-sm) {
        margin: 20px auto 0;
      }

      @include breakpoint($brake-lg) {
        margin: 0 0 0 20px;
        position: relative;
        top: 31px;
        height: 37px;
      }
    }
  }

  &__additional-buttons-container {
    margin-top: 20px;

    @include breakpoint($brake-sm) {
      display: flex;
      justify-content: center;
    }
  }

  &__additional-informations {
    &-container {
      margin-top: 10px;
      border-radius: 3px;
      border: 1px dashed $color-gray-fifth;
      padding: 15px;

      @include breakpoint($brake-sm) {
        padding: 25px;
      }
    }

    &-row {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-bottom: 0;
      }

      @include breakpoint($brake-md) {
        margin-bottom: 20px;
        flex-direction: row;
      }
    }

    &-element {
      text-align: center;

      &--label {
        @include breakpoint($brake-md) {
          width: 40%;
          margin-right: 25px;
          text-align: right;
          align-self: flex-start;
          @include pko-font-size(15);

          @include breakpoint($brake-lg) {
            margin-right: 42px;
          }

          @include breakpoint($brake-xl) {
            margin-right: 48px;
          }
        }
      }

      &--value {
        word-break: break-all;
        @include breakpoint($brake-md) {
          width: 60%;
          text-align: left;
          @include pko-font-size(17);
        }
      }
    }
  }

  &__reload-button,
  &__show-all-files-button {
    text-align: center;
    width: 200px;
    display: block;
    margin: 0 auto 15px;

    @include breakpoint($brake-sm) {
      margin: 0 15px;
    }
  }

  #{$root}__reload-button,
  .cta {
    cursor: pointer;
  }

  .desktop-flex-full-width {
    @include breakpoint($brake-lg) {
      flex-basis: 100%;
    }
  }

  // Zwiększenie Specificity
  & .hidden.hidden {
    display: none;
  }

  .overlay {
    background-color: $color-white;

    &::after {
      content: $overlayText;
      text-align: center;
      color: $color-black;
      @include pko-font-size(19);

      position: absolute;
      left: 0;
      right: 0;
      top: 20%;

      @include breakpoint($brake-sm) {
        top: 15%;
        @include pko-font-size(24);
      }
    }

    &__icon {
      color: $color-gray-main;

      &::before {
        display: block;
        transform: scale(0.5);
      }
    }
  }

  .focused {
    outline: 2px solid lightblue;
  }

  .btn-white {
    word-break: normal;
  }
}
