.table-cantor {
  width: 100%;
  border-collapse: collapse;
  color: $color-black;
  margin-bottom: 40px;

  td {
    &:first-child {
      font-weight: bold;
    }
  }

  th, td {
    font-weight: bold;
    text-align: right;
    padding: 16px 0;
    border-bottom: 1px solid $color-gray-fifth;

    &:first-child {
      text-align: left;
    }
  }

  caption {
    text-align: left;
    margin-bottom: 10px;

    @include breakpoint(pko-em-size(480)) {
      display: none;
    }
  }

  &__text-grey {
    font-weight: normal;
    color: $color-gray-main;
  }

  .course__flag svg {
    width: 40px;
    height: 22px;
    vertical-align: bottom;
    margin-right: 10px;
    border: none;
  }

  &__disclaimer {
    margin-top: 35px;
  }

  @include breakpoint(max-width pko-em-size(480)) {
    th:first-child {
      font-size: 0;
    }
  }

  @include breakpoint($brake-md) {
    #cantor_currency_rate & {
      width: 66%;
    }
  }
}

.table-in-columns {
  column-count: 1;
  column-gap: 40px;
  margin-bottom: 40px;

  .table-cantor {
    display: inline-table;
    margin-bottom: 0;
    break-inside: avoid;

    thead {
      display: none;
    }

    &:first-child {
      thead {
        display: table-header-group;
      }
    }
  }
  @include breakpoint($brake-sm) {
    column-count: 2;

    .table-cantor:nth-child(2n+1) {
      thead {
        display: table-header-group;
      }
    }
  }
  @include breakpoint($brake-md) {
    column-count: 4;

    .table-cantor {
      thead {
        display: table-header-group;
      }
    }
  }
}

@include breakpoint($brake-sm) {
  .cantor-neon-container {
    padding-left: 5%;
  }
}
