// to jest neon medium

.sidebar-product {
  @include pko-text-item;

  .modal & {
    @include breakpoint($brake-sm) {
      width: 300px;
    }
    &__image {
      margin-left: 0;
    }
  }

  &__title {
    @include pko-font-size($font-size-h2-mobile);
    text-transform: uppercase;
    font-weight: bold;

    @include pko-normalcase-in-uppercase;

    &--recommended::before {
      margin-bottom: 5px;
      color: $color-red;
    }
  }
  &__image {
    @extend %break-from-sidebar-paddington;
    margin-bottom: 5px;
    margin-right: 0;
    max-width: 320px; // to chyba nie działa
    min-height: 80px;
    @include breakpoint($brake-sm) {
      max-width: none;
    }
    @include breakpoint($brake-sm-max) {
      margin-left: 0;
    }
    img {
      width: 100%;
    }
  }

  @include breakpoint($brake-md) {
    &__title {
      @include pko-font-size($font-size-h2);
    }
  }

  &:not(:first-child) {
    margin-top: 45px;
  }
}

.sidebar-text-item {
  @include pko-text-item;
}

.item__link {

  display: block;
  margin-top: 5px;

  &-red {
    display: block;
    color: $color-red;
    margin-top: 5px;
    @include pko-on-event() {
      color: $color-blue;
    }
  }

}
