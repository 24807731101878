.leasing-offers {

  &__files {
    padding-top: $vertical-space-mobile;

    @include breakpoint($brake-sm) {
      padding-top: $vertical-space-desktop;
    }
  }

  .news__item,
  .pagination-wrapper,
  .filters-wrapper-simple {
    @extend %break-from-paddington;
  }

  // scss-lint:disable IdSelector
  #leasing-offers-mobile-filters {
    padding-top: $vertical-space-mobile;
  }
  // scss-lint:enabled IdSelector

  &__mobile-filters {
    margin-bottom: $vertical-space-desktop;
    display: block;

    @include breakpoint($brake-sm) {
      display: none;
    }
  }

  .news {
    &__title {
      margin-bottom: 5px;

      @include breakpoint($brake-sm) {
        margin-bottom: 5px;
      }
    }

    &__item {
      @extend %pko-clearfix;
      clear: both;

      &-image {
        margin-bottom: 20px;

        @include breakpoint($brake-sm) {
          margin-bottom: 0;
          margin-right: 50px;
        }

        img {
          @include breakpoint($brake-sm) {
            margin-bottom: 0;
          }
        }
      }
    }

    &__main-link {
      display: block;
    }

    &__link {
      display: block;
      margin-top: 10px;

      @include breakpoint($brake-md) {
        margin-top: 20px;
      }
    }
  }

  &__datepickers {
    @include breakpoint($brake-md) {
      float: left;
      width: 50%;
    }

    label {
      margin-right: 10px;
    }

    .datepicker-wrap .datepicker-value {
      padding-right: 0;

      &::after {
        position: relative;
        top: 5px;
        left: 0;
      }
    }
  }
}

.site-content-leasing {
  .property-slider__item-data {
    margin-bottom: 15px;
  }

  &__additional {
    margin-bottom: 30px;
  }
}
