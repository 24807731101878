/* cookie ma równiez wersje bez js */

.cookie {
  @extend %text-default-3;
  @include pko-z-index(cookie);
  @extend %wrap-paddington;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $color-white;
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: 1px solid $border-color;


  &--no-js {
    display: none;
    .no-js & {
      display: block;
    }
  }

  &__link {
    margin-right: 5px;
    &:last-child,
    & + & {
      margin-right: 0;
    }
  }

  &__close {
    color: $color-blue;
    cursor: pointer;
    //float: right;
    font-size: 32px;
    position: absolute;
    right: 0;
    top: -8px;
    transition: color $trans-hover;

    @include breakpoint($brake-xl) {
      right: 22px;
    }

    @include pko-on-event() {
      color: $color-gray-main;
    }
  }
  &__wrapper {
    @extend %paddington;
    padding-right: 32px;
    position: relative;

    @include breakpoint($brake-xl) {
      padding-right: 54px;
    }
  }

}
