.grid-show {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100000;
  @include breakpoint($brake-sm) {
    display: block;
  }
}

.grid-show:checked + .debug-grid {
  @include breakpoint($brake-sm) {
    display: block;
  }
}

.debug-grid {
  position: fixed;
  z-index: 100000;
  height: 100%;
  pointer-events: none;
  display: none;
  left: 0;
  right: 0;

  &__col {
    @extend %paddington;
    @include span(1);
    height: 100%;
    pointer-events: none;

    &::after {
      display: block;
      height: 100%;
      width: 100%;
      background: rgba($hotpink, 0.3);
      content: '';
      pointer-events: none;
    }
  }
}
