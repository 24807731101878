@mixin pko-z-index($name: 'default') {
  z-index: map-get($z-indexes, $name);
}

@mixin debug-grid {
  // debug start
  height: 100px;
  margin-bottom: 10px;
  position: relative;

  &::before {
    @include pko-z-index('default');
    text-align: center;
    content: attr(data-dimmensions);
    color: $color-white;
    position: absolute;
    z-index: 1;
    top: 3px;
    left: 3px;
    width: 4rem;
  }

  &::after {
    display: block;
    height: 100%;
    width: 100%;
    background: rgba($color-black, 0.5);
    content: '';
  }
  // debug end
}

@mixin pko-retina() {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin pko-visible-from($breakpoint, $display-mode) {
  display: none;
  @include breakpoint($breakpoint) {
    display: $display-mode;
  }
}

@mixin pko-give-padding($size) {
  padding-left: #{$size};
  padding-right: #{$size};
}

@mixin pko-give-margin($size) {
  margin-left: -#{$size};
  margin-right: -#{$size};
}

@mixin pko-font-size($size) {
  font-size: pko-rem-size($size);
}

@mixin pko-line-height($font-size, $line-height) {
  line-height: pko-line-height($font-size, $line-height);
}

@mixin pko-text-size($size1, $size2, $size3, $size4) {
  @include pko-font-size($size1);
  @include pko-line-height($size1, $size2);
  @include breakpoint($brake-md-lg) {
    @include pko-font-size($size3);
    @include pko-line-height($size3, $size4);
  }
}

@mixin pko-text-size-no-break($size1, $size2) {
  @include pko-font-size($size1);
  @include pko-line-height($size1, $size2);
}

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin pko-on-event($self: false) {
  @if $self {
    &,
    .no-touch &:hover,
    .no-touch &:active,
    .no-touch &:focus {
      @content;
    }
  } @else {
    .no-touch &:hover,
    .no-touch &:active,
    .no-touch &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin pko-when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin pko-with-class($context) {
  &#{$context} {
    @content;
  }
}

/// Mixins for header purposes

@mixin pko-header-expanded() {
  .header--menu-open &,
  .header--login &,
  .header--search & {
    @content;
  }
}

@mixin pko-header-not-expanded() {
  .header:not(.header--menu-open, .header--login, .header--search) & {
    @content;
  }
}

@mixin pko-header-sticky() {
  .header--sticky & {
    @content;
  }
}

@mixin pko-header-menu-expanded() {
  .header--menu-open & {
    @content;
  }
}

@mixin pko-header-expanded-or-sticky() {
  .header--menu-open &,
  .header--login &,
  .header--search &,
  .header--sticky & {
    @content;
  }
}

@mixin pko-header-expanded-and-sticky-but-not-menu() {
  .header--sticky.header--login &,
  .header--sticky.header--search & {
    @content;
  }
}

@mixin pko-header-not-expanded-not-sticky() {
  .header:not(.header--menu-open, .header--login, .header--search, .header--sticky) & {
    @content;
  }
}

//
// scss-lint:disable TransitionAll
@mixin pko-text-item {
  &__title {
    @extend %h3;
    margin: 0;
    transition: all $trans-hover ease;

    &--recommended,
    &--not-recommended {
      position: relative;

      &::before {
        display: block;
        bottom: 100%;
        left: 0;
        margin-bottom: 2px;
        color: $text-color;
        text-transform: none;
        font-weight: normal;
        @include pko-font-size($font-size-t2);
      }
    }

    &--recommended {
      &::before {
        content: attr(data-recommended);
        display: block;
      }
    }

    @include breakpoint($brake-sm) {
      &--not-recommended {
        position: relative;

        &::before {
          content: ' ';
          //height: 17px;
        }
      }
    }
  }
  &__link {
    display: inline-block;
  }
  &__button {
    color: $color-red;
    display: inline-block;
  }
  &__link,
  &__button {
    margin-right: 10px;
    @include pko-on-event() {
      color: $text-color;
    }
  }
  &__disclaimer {
    color: $text-color;
    @include pko-on-event() {
      color: $color-blue;
    }
  }
  &__description {
    p {
      @extend %text-default-2;
      margin: 5px 0;
      color: $text-color;
    }

    li {
      margin: 5px 0;
      color: $text-color;
    }

    a {
      @extend %text-default-2;
    }
  }

  $root-main-link: &;

  &__main-link {
    @include pko-on-event() {
      #{$root-main-link}__title,
      #{$root-main-link}__title-text {
        color: $color-blue;
      }
    }
  }

}

// scss-lint:enable TransitionAll
@mixin pko-normalcase-in-uppercase {
  // to jest span dla lower case
  span {
    text-transform: none;
  }
}

// todo wszędzie tam, gdzie jest cos wyglada jak modal: np. login, wazna wiadomość na desktop
@mixin pko-modal-like {
  border: 1px solid $border-color;
  border-radius: 3px;
  box-shadow: 3px 3px 0 rgba($color-black, 0.05);
  padding: 27px 30px 30px;
  background-color: $color-white;
}

@mixin pko-paragraph-spacing {
  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

// mixin dla formularzy, które mają mieć paddington i miejsce na infotipy
// do zastanowienia się i potem soldnych testów, czy nie lepiej extend placeholder
@mixin pko-form-padding {
  @extend %paddington;

  .form__row {
    @include breakpoint($brake-sm) {
      padding-right: 35px;

      &--header-agreement {
        padding-right: 0;
      }
    }
  }

  @include breakpoint($brake-md-max) {
    &.form-narrow {
      .form-phone-with-prefix {
        margin: 0;

        .form__row {
          display: block;

          &:first-child {
            width: 100%;
          }

          &:last-child {
            width: 100%
          }
        }
      }
    }
  }

  @include breakpoint($brake-sm) {
    .form-phone-with-prefix {
      margin: -4px 0;

      .form__row {
        display: inline-block;
        vertical-align: top;

        &:first-child {
          width: 160px;
        }

        &:last-child {
          width: calc(100% - 160px);
        }
      }
    }
  }
}

@mixin pko-flag-svg-small {
  svg {
    width: 17px;
    height: 9px;
  }
}

@mixin pko-paddington-sth($prop, $sign) {

  #{$prop}: $padding-xs * $sign;

  @include breakpoint($brake-sm) {
    #{$prop}: $padding-sm * $sign;
  }

  @include breakpoint($brake-md) {
    #{$prop}: $padding-md * $sign;
  }

  @include breakpoint($brake-md-lg) {
    #{$prop}: $padding-md-lg * $sign;
  }

  @include breakpoint($brake-lg) {
    #{$prop}: $padding-lg * $sign;
  }

}

@mixin pko-rate-arrows {
  opacity: 1; // fix sass ;)
  &--up::before,
  &--down::before {
    content: '';
    display: block;
    background: pko-image('rating-arrows.png') no-repeat;
    float: right;
    position: absolute;
    height: 13px;
    width: 9px;
    right: 0;
    top: 5px;
  }

  &--up::before {
    background-position: right bottom;
  }
  &--down::before {
    background-position: right top;
  }
}

@mixin pko-flex-center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@import '../companies/property/utils/_mixins'; // todo move bo leasing ??

@mixin new-message-alert( $size, $top, $right ) {
  height: #{$size}px;
  width: #{$size}px;
  right: #{$right}px;
  top: #{$top}px;
  background-color: $color-red;
  border: 2px solid $color-white;
  border-radius: 50%;
  position: absolute;
}
