.landing-content {

  /*
   -------------    ---------------------
  |    TEXT     |  |        IMAGE        |
   -------------    ---------------------
  */

  $root: &;

  // tablet and up
  @include breakpoint($brake-sm) {
    &--v1 &__info {
      @include span(12);
    }

    &--v1 &__copy {
      @include span(5);
    }

    &--v1 &__image {
      @include span(7);
      img {
        margin-top: -10%;
      }
    }
  }

  // laptop screen and up
  @include breakpoint($brake-md) {
    &--v1 &__info {
      @include span(12);
    }

    &--v1 &__copy {
      @include span(4);
    }

    &--v1 &__image {
      @include span(8);
      img {
        margin-top: -20%;
      }
    }
  }

}
