.calc__bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 500;

  .with-product-menu & {
    top: 55px;
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__info {
    display: none;
    justify-content: space-between;
    @include span(8);
    @extend %paddington;

    dl {
      padding-right: 10px;

      dt {
        @include pko-font-size(15);
      }

      dd {
        @include pko-font-size(24);
        margin-left: 0;
        font-weight: bold;
      }
    }
  }

  &__action {
    text-align: center;
    @include span(4);
    @extend %paddington;
  }

  @include breakpoint($brake-sm) {
    &__info {
      display: flex;
    }
  }

  @include breakpoint($brake-md) {
    &__info {
      dl {
        dd {
          @include pko-font-size(36);
        }
      }
    }
  }
  @include breakpoint($brake-sm-max) {
    &__action {
      width: 100%;
      padding: 0;

      .cta {
        width: calc(100% + 30px);
        height: 100%;
        margin-bottom: 0;
        border-radius: 0;
        margin-left: -15px;
      }
    }
  }

  &--combo {
    .calc__bar__info {
      .calc-loan__popup__tooltip {
        top: 0;
        
        @include breakpoint($brake-md) {
          top: 15px;
        }
      }
    }

    .calc__bar__action {
      .hide-on-desktop {
        display: inline-block;

        @include breakpoint($brake-sm) {
          display: none;
        }
      }
      
      .hide-on-mobile {
        display: none;

        @include breakpoint($brake-sm) {
          display: inline-block;
        }
      }
    }

    &-consol {
      .calc__bar__info {
        width: 80%;
    
        dl {
          padding-right: 8px;

          dt {
            @include pko-font-size(12);
    
            @include breakpoint($brake-md) {
              @include pko-font-size(15)
            }
          }
      
          dd {
            @include pko-font-size(20);
    
            @include breakpoint($brake-md) {
              @include pko-font-size(36);
            }
          }
        }
      }

      .calc__bar__action {
        @include breakpoint($brake-sm) {
          width: 20%;
        }
          
        .cta {
          @include breakpoint($brake-sm) {
            @include pko-font-size(14);
          }

          @include breakpoint($brake-md) {
            @include pko-font-size(15)
          }
        }
      }
    }
  }
}
