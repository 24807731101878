.header-leasing {
  @include breakpoint($brake-md-max) {
    border-bottom: 1px solid $color-gray-third;
    &.header--search .site-nav {
      display: none;
    }
  }
  .header__inner {
    clear: both;
  }
  .header__logo {
    top: -48px;
    margin: 0;

    @include breakpoint($brake-sm) {
      top: initial;
    }

    @include breakpoint($brake-md) {
      top: 116px;
      margin: 10px 0 0;
    }
  }
  .site-nav {
    float: left;
    &__main-list {
      display: none;
    }
    &__sub-list {
      margin-top: 0;
      margin-bottom: 20px;

      @include breakpoint($brake-sm) {
        margin-bottom: 30px;
      }

      @include breakpoint($brake-md) {
        margin-bottom: 0;
      }
    }
    &__hamburger {
      display: none;
    }
    @include breakpoint($brake-md-max) {
      width: 100%;
      position: absolute;
      bottom: 0;
      &__sub-list {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: baseline;
        padding: 0;
        margin-right: 20px;
      }
      &__item {
        display: inline-block !important;
        margin-right: 15px;
      }
    }

    @include breakpoint($brake-md) {
      width: 50%;
    }
  }
  &__title {
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 20px;
    margin-bottom: 95px;

    @include breakpoint($brake-sm) {
      margin-bottom: 75px;
    }

    @include breakpoint($brake-md) {
      margin-bottom: 0;
    }
  }
  .header__actions {
    @include breakpoint($brake-md-max) {
      float: left;
      margin-left: 0;
    }
  }
  &.header--sticky {
    .site-nav {
      padding-right: 50px;

      &__sub-list {
        @include breakpoint($brake-md-max) {
          margin-bottom: 0;
          position: relative;
          top: 10px;
        }
      }
    }
    .header__actions {
      display: none;
    }
  }

  .header__login {
    @include breakpoint($brake-sm) {
      &--url {
        padding-top: 2px;

        .header__icon {
          @include pko-font-size(30);
        }
      }
    }
  }
}
