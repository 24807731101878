.card-generator {

  &__logo {

  }

  &__title {
    @extend .h2;
    margin-top: 0;
  }

  &__subtitle {

  }

  &__description {

  }

  &__header-button-wrapper {
    display: block;
    margin-top: 15px;
    text-align: center;

    @include breakpoint($brake-sm) {
      display: inline-block;
      margin-top: 0;
      margin-right: 15px;
      text-align: left;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__header-button {
    @extend %cta;
    @extend .cta-blue-dark;
    vertical-align: middle;
  }

  &__header-button-regulation {
    display: inline-block;
    padding: 8px 0;
    vertical-align: middle;

    @include pko-font-size($font-size-t3);

    @include breakpoint($brake-sm) {
      @include pko-font-size($font-size-t2);
    }
  }

  // measurements for card-generator layout
  $left-width: 24%;
  $gutter: 80px;
  $right-width: calc(100% - #{$left-width} - #{$gutter});

  &__info {
    display: block;
    width: 100%;

    @include breakpoint($brake-md) {
      display: inline-block;
      width: $left-width;
      margin-right: $gutter;
      vertical-align: top;
    }
  }

  &__content {
    @extend %paddington;
  }

  &__generator-frame {
    display: block;
    padding: 20px 30px;
    border-top: 1px solid $color-gray-fifth;
    border-bottom: 1px solid $color-gray-fifth;
    margin: 30px -30px 0;
    background-color: $color-gray-background;

    @include breakpoint($brake-md) {
      display: inline-block;
      width: $right-width;
      padding: 20px 30px;
      border: 1px solid $color-gray-fifth;
      border-radius: 5px;
      margin: 170px 0 0 0;
      vertical-align: top;
    }
  }
}

.landing-page--v10 .card-generator {
  margin: 0 0 0;

  @include breakpoint($brake-sm) {
    margin: 0 0 30px;
  }
}

.landing-page--v10 .card-generator-landing-info {
  @include breakpoint($brake-sm) {
    width: 42.6%;
  }
}

.landing-page--v10 .card-generator-landing-image {
  width: 100% - 42.6%;
}

.landing-page--v10 .card-generator-landing-image-mobile {
  display: block;
  margin-top: -140px;
  margin-bottom: -30px;
  margin-left: -30px;
  margin-right: -45px;

  @include breakpoint($brake-sm) {
    display: none;
  }
}