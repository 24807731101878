// kolory przekazywane przez wysiwyg, ale i nie tylko, np. text__gray używana w komunikatach błędu w calc

@mixin pko-wysiwyg-color {
  &,
  & *,
  .text &,
  .text & * {
    @content;
  }
}

/*

w neonie dużym

<p><a href="#" class="text__red">sdfsdf<strong>asdfsdf sdfsd </strong></a><strong class="text__blue">asdfsdf sdfsd </strong>Teraz aby otrzymać wygodnie i szybko pożyczkę wystarczy zeznanie podatkowe za 2016 rok.<strong> </strong></p>
*/

.text {
  &__black {
    @include pko-wysiwyg-color {
      color: $color-black;
    }
  }
  &__red {
    @include pko-wysiwyg-color {
      color: $color-red;
    }
  }
  &__blue {
    @include pko-wysiwyg-color {
      color: $color-blue;
    }
  }
  &__gray {
    @include pko-wysiwyg-color {
      color: $color-gray-main;
    }
  }

  img {
    border-style: solid; // #71154, nadpisanie normalize
    border-width: 0; // #71154, nadpisanie normalize - domslnie 0 px dla wszystkich
    color: $color-black;
  }

  // tf: 43px jest zastanawiające
  img:not([class*="text__image"]) {
    max-width: 100%;
    margin-top: 43px;
    margin-bottom: 43px;
    display: block;
  }

  &__image--left,
  &__image--right,
  &__image--center {
    max-width: 100%;
    margin: 26px auto;
    display: block;
    p:first-child &:first-child {
      margin-top: 0;
      @include breakpoint($brake-sm) {
        margin-top: 6px;
      }
    }
    p:last-child &:last-child {
      margin-bottom: 0;
    }
  }
  &__image--inline {
    max-width: 100%;
    margin: 26px auto;
    display: block;
    @include breakpoint($brake-sm) {
      margin: 0;
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
    }

  }
  &__image--always-inline {
    max-width: 100%;
    margin: 0;
    display: inline-block;
  }

  @include breakpoint($brake-sm) {
    &__image--left {
      float: left;
      margin-right: 26px;
      max-width: 50%;
      p & {
        margin-top: 6px;
      }
    }
    &__image--right {
      float: right;
      margin-left: 26px;
      max-width: 50%;
      p & {
        margin-top: 6px;
      }
    }
  }

  &--margin-bottom {
    margin-bottom: 30px;
  }
}

ul,
ol,
li {
  .text > &:first-child,
  .text > *:first-child > &:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  .text > &:last-child,
  .text > *:last-child > &:last-child {
    margin-bottom: 0;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
table,
img:not([class*="text__image"]) {
  .text &:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  .text &:last-child {
    margin-bottom: 0;
  }
}
