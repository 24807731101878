.calc__header {
  h2 {
    margin: 30px 0;
    @include pko-font-size(21);
    font-weight: bold;

    small {
      font-size: 15px;
    }

    @include breakpoint($brake-md) {
      @include pko-font-size(24);
      margin-bottom: 40px;
    }
  }
}
