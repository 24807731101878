.promotional-offer {
  @extend %pko-clearfix;
  @extend %break-from-paddington;
  padding-bottom: 30px;
  clear: both;
  &:last-child {
    padding-bottom: 0;
  }
  &__info {
    @extend %paddington;
    @include breakpoint($brake-sm) {
      @include span(9);
    }
    @include breakpoint($brake-lg) {
      @include span(6);
    }

    h3 {
      margin-top: 0;
      margin-bottom: 18px;
    }
  }

  &__description {
    margin-bottom: 18px;
    p {
      margin: 0;
    }
  }
  &__image {
    @extend %paddington;
    margin: 15px 0;
    text-align: center;
    @include breakpoint($brake-sm) {
      margin: 0;
      @include span(3);
    }
    font-size: 0;
    img {
      max-width: 100%;
    }
  }
  &__link {
    @extend %text-default-2;
  }
}

.promotional-snippet {
  margin-top: 52px;
  padding-bottom: 45px;
}
