.scrollie {

  $root: &;
  @include pko-z-index('default');
  display: none;

  .disabled-scroll & {
    transform: translateX(-17px); // todo obliczyć
  }

  @include breakpoint($brake-lg-xl) {
    font-size: 12px;
    display: inline-block;
    position: fixed;
    bottom: 17px;
    right: 30px;
    text-align: center;
    line-height: 1.2;
    color: $scrollie-gray;
    transition: bottom $trans-hover ease;

    &.up {
      bottom: 36px;
    }

    &__arrow {
      display: inline-block;
      font-size: 2.8em;
      margin-top: -5px;
      margin-bottom: -5px;
      color: $arrow-color;
      transition: color $trans-hover ease;
    }

    @include pko-on-event() {
      color: $arrow-color;
      #{$root}__arrow {
        color: $color-gray-main;
      }
    }
  }

  @include breakpoint($brake-xl) {
    margin-right: calc((100vw - 1242px) / 2 - 90px);
  }
}
