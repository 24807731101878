.circles {
  @extend %paddington;
  overflow: hidden;
  margin: 0 -15px 45px;

  @include breakpoint($brake-sm) {
    margin-left: -18px;
    margin-right: -18px;
  }

  @include breakpoint($brake-lg) {
    margin-left: -20px;
    margin-right: -20px;
  }

  &__inner {
      display: flex;
      padding: 15px 20px;
      margin-left: -5px;
      margin-right: -5px;
      overflow-x: auto;

      @include breakpoint($brake-sm) {
        justify-content: center;
      }

      @include breakpoint($brake-md-lg) {

      }

      @include breakpoint($brake-lg) {
        margin-left: -21px;
        margin-right: -21px;
      }
  }

  &__item {
    width: 205px;
    max-width: 205px;
    height: 205px;
    display: flex;
    flex-direction: column;
    margin: 0 5px;
    border-radius: 50%;
    flex: 0 0 205px;

    @include breakpoint($brake-sm) {
      width: 18.5vw;
      height: 18.5vw;
      flex: 0 0 18.5vw;
      margin: 0.4vw;
    }

    @include breakpoint($brake-md-lg) {
      width: 205px;
      height: 205px;
      flex: 0 0 205px;
      margin: 0 5px;
    }

    @include breakpoint($brake-lg) {
      margin-left: 21px;
      margin-right: 21px;
    }

    &--style-grey {
      background-image: linear-gradient($color-gray-athens, $color-gray-iron);
    }
    &--style-red {
      background-color: $color-red;
      color: $color-white;
    }
    &--style-blue {
      background-color: $color-blue-anakiwa;
      color: $color-white;
    }
    &--style-gold {
      background-color: $color-yellow-salomie;
      color: $color-white;
    }
  }

  &__small-text,
  &__lead-text {
      text-align: center;
  }

  &__small-text {
    display: flex;
    padding: 0 25px;
    flex: 1 1 50%;
    justify-content: center;
    font-size: 16px;
    &:nth-child(1) {
      align-items: flex-end;
    }
    &:nth-child(3) {
      align-items: flex-start;
    }

    @include breakpoint($brake-sm) {
      font-size: 1.4vw;
    }

    @include breakpoint($brake-md-lg) {
      font-size: 16px;
    }

    @include breakpoint($brake-lg) {
      font-size: 16px;
    }
  }

  &__lead-text {
    display: block;
    margin-bottom: -3px;
    padding: 0 5px;
    font-size: 50px;
    line-height: 1.2;

    @include breakpoint($brake-sm) {
      font-size: 4.5vw;
    }

    @include breakpoint($brake-md-lg) {
      font-size: 48px;
    }

    @include breakpoint($brake-lg) {
      font-size: 48px;
    }
  }
}