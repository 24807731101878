/*
* Styl 3 - Duze zdjecie i numerowana lista artykulow
*/

.bubbles {
  $root: &;

  &--3 {
    $style: &;

    margin-bottom: 25px;

    @include breakpoint($brake-sm) {
      margin-bottom: 45px;
    }

    &#{$root}--left {
      #{$root}__cover { 
        order: 2;

        @include breakpoint($brake-sm) {
          order: 1;
        }
      }

      #{$root}__content { 
        order: 1;

        @include breakpoint($brake-sm) {
          order: 2;
        }
      }
    }

    &#{$root}--right {
      #{$root}__cover { 
        order: 2;
      }

      #{$root}__content { 
        order: 1;
      }
    }

    #{$root}__inner {
      display: flex;
      flex-wrap: wrap;

      @include breakpoint($brake-sm) {
        flex-wrap: nowrap;
      }
    }

    #{$root}__title {
      margin-bottom: 40px;
      font-size: 24px;
      color: $color-black;
    }

    #{$root}__cover {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0 0;
      
      @include breakpoint($brake-sm) {
        margin: 0;
        flex-basis: 50%;
      }
    }

    #{$root}__cover-image {
      width: 100%;
    }

    #{$root}__description {
      margin-bottom: 45px;
      color: $text-color;
    }

    #{$root}__content {
      flex: 1 1 100%;
      order: 1;

      @include breakpoint($brake-sm) {
        padding-left: 25px;
        padding-right: 25px;
        flex-basis: 50%;
        order: 2;
      }
    }

    #{$root}__description {
      margin-bottom: 45px;
      color: $text-color;
    }

    #{$root}__articles {
      counter-reset: bubble;
      position: relative;

      &::before {
        content: '';
        width: 1px;
        background: $color-white;
        border-left: 1px dashed $color-gray-alto;
        position: absolute;
        left: 14px;
        top: 0;
        bottom: 0;
      }
    }

    #{$root}__article-image {
      width: 30px;
      height: 30px;
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      background: $color-white;
      border: 4px solid $color-blue-congress;
      border-radius: 50%;
      outline: 10px solid $color-white;

      @include breakpoint($brake-sm) {
        top: 4px;
      }

      img {
        width: 100%;
      }
    }

    #{$root}__article {
      margin-top: 25px;
      padding-left: 90px;
      position: relative;

      @include breakpoint($brake-sm) {
        margin-top: 45px;
        padding-left: 130px;
      }

      &:first-child {
        margin-top: 0;

        &::after {
          background: $color-blue-congress;
        }
      }

      &:last-child {
        background-color: $color-white;

        &::after {
          background: $color-blue-congress;
        }
      }

      &::before {
        counter-increment: bubble;
        content: counters(bubble, ".", decimal-leading-zero);
        color: $color-gray-alto;
        font-size: 30px;
        line-height: 1;
        font-weight: bold;
        position: absolute;
        left: 45px;
        top: 2px;

        @include breakpoint($brake-sm) {
          font-size: 37px;
          left: 70px;
        }
      }

      &::after {
        content: '';
        width: 30px;
        height: 30px;
        position: absolute;
        left: 0;
        top: 0;
        background: $color-white;
        border: 4px solid $color-blue-congress;
        border-radius: 50%;
        outline: 10px solid $color-white;

        @include breakpoint($brake-sm) {
          top: 4px;
        }
      }
    }

    #{$root}__article-title {
      margin-bottom: 5px;
      font-size: 19px;
      font-weight: bold;
      color: $color-black;
    }

    #{$root}__article-description {
      font-size: 15px;
    }
  }
}