.card-billing {

  &__h3--extra-margin {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &__h4 {
    margin-top: 20px;
    margin-bottom: 10px;

    &--more-bottom {
      margin-bottom: 40px;
    }

    &--less-margin {
      margin-top: 20px;
    }
  }

  //
  // scss-lint:disable QualifyingElement
  ul.card-billing__list {
    list-style: none;
    padding: 0;
    margin-top: -16px;
    &:last-child {
      margin-bottom: 0;
      .card-billing__text p:last-child {
        margin-bottom: 0;
      }
    }
  }
  // scss-lint:enable QualifyingElement

  &__list {
    &-item {
      clear: left;
      &::before {
        display: none;
      }
    }
  }

  &__date {
    display: block;
    float: left;
    width: 38px;
    height: 42px;
    background: pko-image('kalendarz.png') no-repeat;
    line-height: 50px;
    text-align: center;

    strong {
      color: $color-gray-main;
    }
  }

  &__text {
    float: left;
    width: 80%;
    padding-left: 20px;

    h4 {
      margin-top: 3px;
      margin-bottom: 0;
    }

    p {
      margin-top: 2px;
      margin-bottom: 22px;
    }
  }

  @include breakpoint($brake-sm) {

    &__h3--extra-margin {
      margin-top: 87px;

    }

    &__h4 {
      margin-top: 39px;
      margin-bottom: 38px;

      &--more-bottom {
        margin-bottom: 38px;
      }
    }

  }
}
