.site-content-leasing {
  &__comparison-table {
    width: 100%;
    font-size: 15px;
    margin-bottom: 30px;
    border-collapse: collapse;
    @include breakpoint($brake-sm-max) {
      font-size: 14px;
    }
    @include breakpoint($brake-sm) {
      margin-bottom: 50px;
    }
    th {
      vertical-align: top;
      text-align: left;
      h3 {
        margin-top: 0;
      }
    }
    th, td {
      padding: 10px;
      border-right: 1px solid $color-gray-fifth;
      border-bottom: 1px solid $color-gray-fifth;
      @include breakpoint($brake-sm) {
        width: 20%;
        padding: 15px;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
      }
      &.centered {
        text-align: center;
      }
    }
    tr:last-child td {
      border-bottom: none;
    }
    &--surcharge {
      font-size: 24px;
      font-weight: bold;
      color: $color-black;
      td:first-child, small {
        font-size: 15px;
      }
    }
    &--installment {
      font-size: 32px;
      font-weight: bold;
      color: $color-black;
      td:first-child, small {
        font-size: 17px;
      }
    }
    &--propname {
      font-weight: bold;
      color: $color-black;
    }
    &--nav {
      position: relative;
      font-size: 36px;
      padding: 10px 0;
      margin-bottom: 20px;
      border-top: 1px solid $color-gray-fifth;
      border-bottom: 1px solid $color-gray-fifth;
      .nav-next {
        position: absolute;
        top: 10px;
        right: 0;
      }
      .nav-dots {
        position: absolute;
        top: 0;
        left: 50%;
        text-align: center;
        margin-left: -33px;
      }
      .nav-dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 12px;
        border-radius: 50%;
        background: #bababa;
        &.active {
          background: $color-blue;
        }
      }
    }
  }
  .overlay__icon {
    top: 15%;
  }
}
