@import '_form/input';
@import '_form/select';
@import '_form/select-pure';
@import '_form/tooltip';
@import '_form/captcha';
@import '_form/filters';
@import '_form/files';
@import '_form/datepicker';


//dodanie osobnych styli dla formularza Fintech ochkFrom.js
.LetsFintechContactForm div.form__multichoice-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: $brake-sm) {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }
}

.form {
  $root: &;

  @include pko-font-size($font-size-t2);
  position: relative;
  margin: 0;

  // reset /////////////////////////////////
  //
  // scss-lint:disable QualifyingElement
  input[type="radio"],
  input[type="checkbox"] {
    // we've got custom checkboxes
    // - hide native fields
    opacity: 0;
    position: absolute;
  }

  input[type="checkbox"].input-readonly,
  input[type="radio"].input-readonly {
    pointer-events: none;
  }

  // scss-lint:enable QualifyingElement

  &__fieldset,
  fieldset {
    margin: 0;
    padding: 0;
    border: none;

    & + & {
      margin-top: -5px;

      @include breakpoint($brake-sm) {
        margin-top: 0;
      }
    }
  }

  &-narrow {
    @include breakpoint($brake-sm) {
      width: 50%;
      padding-left: 0;
      padding-right: 0;
    }

    .form__body {
      padding-left: 0;
    }
  }

  // elements //////////////////////////////

  &__body {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  &__top-errors {
    @extend %text-default-1;
    color: $color-red;

    ul {
      list-style: none;
      padding-left: 0;

      li {
        &::before {
          display: none;
        }
      }
    }
  }

  &__title {
    @extend %h3;
    margin-top: 0;
    margin-bottom: 18px;

    .text > &:last-child {
      margin-bottom: 18px; // overwrite .text > *:last-child

      &:empty {
        margin: 0;
      }
    }
  }

  .text p {
    // top description
    margin: 0 0 19px;

    .text > &:last-child {
      margin: 0 0 19px; // overwrite .text > *:last-child
    }

    // Administracja danymi - info
    // .form__row.form__row--info > .text.form__info > .description-for-data-administration-info
    &.description-for-data-administration-info {
      @include pko-font-size($font-size-t3-mobile);
      // margin-top: -18px !important; nie przewidziane wszystkie przypadki, kasuje (err)
      // po prostu nie!!!
    }

  }

  // rodo ma taką formę
  .description-for-data-administration-info {
    @include pko-font-size($font-size-t3-mobile);
  }

  &__facility {
    &-text:not(:empty) {
      margin: 12px 0;
    }
  }

  &__info {
    color: $color-gray-main;
  }

  &__subtitle {
    @extend %h4;
    margin: 25px 0 23px;
  }

  &__row:first-child &__subtitle {
    margin-top: 0;
  }

  &__hidden-info {
    // content for modals
    display: none;
  }

  &__label {
    // we use only placeholers
    // 2017-06-08 not only
    display: none;

    &--shown {
      display: block;
      margin-bottom: 3px;
    }
  }

  &__links {
    list-style: disc;
    margin-bottom: 10px;
  }

  &__info-box {
    background-color: $color-gray-sixth;
    border: 1px solid $border-color;
    position: relative;
    padding: 20px;
    margin-right: 35px;
    margin-top: 16px;
    margin-bottom: 10px;
    @include pko-font-size(13);

    &::after {
      content: '';
      display: block;
      background: $color-gray-sixth;
      border-right: 1px solid $border-color;
      border-top: 1px solid $border-color;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -6px;
      left: 40px;
      transform: rotate(-45deg);
    }
  }

  &__row {
    $row-root: &;

    position: relative;
    margin: 5px 0;

    // margines dla #file-url-combo
    &[data-id="id_url_fintech"] {
      margin-bottom: 28px;
    }

    &--submit {
      margin: 30px 0 0;
    }

    &--header-radio-buttons {
      margin-top: 28px;
    }

    &--header-agreement {
      margin-top: 28px;
    }

    // style dla #file-url-combo
    &--header-files-combo {
      margin-top: 28px;
      font-weight: 700;

      .form__body &:first-child {
        margin-top: 5px;
      }
    }

    &--radiobox, &--info-with-contraint-margin {
      margin: 28px 0 23px;

      a {
        display: inline-block;
        vertical-align: top;
      }

      .form__error {
        padding-top: 0;
      }

      //
      // scss-lint:disable QualifyingElement
      label.input-checkbox:hover {
        color: $color-gray-main !important; //#75383
      }

      // scss-lint:enable QualifyingElement

    }

    &--radiobox-agreement {
      margin-bottom: 31px;

      #{$root}__error {
        padding-top: 8px;
      }
    }

    &--header-agreement + &--radiobox {
      margin-top: 16px;
    }

    &--info {
      margin: 25px 0 23px;

      & + & {
        margin-top: -9px; // 104489#note-16
      }
    }

    &--facility {
      margin: 5px 0 10px;
    }

    &--captcha {
      margin: 10px 0 28px;
    }

    &--facility + &--radiobox {
      // rely on facility margin-bottom, which is lower
      margin-top: 0;
    }

    &--radiobox + &--info {
      // because info text has top space from line-height
      // TF: zrobilem zero (mogłoby być 23px), z domuślnego 25px;
      margin-top: 0;
    }

    &--radiobox + &--radiobox {
      // lower space between neighbouring checkboxes
      margin-top: -24px;
    }

    // #{&}[style*='display: none'] + &--radiobox {
    // lower space between neighbouring checkboxes,
    // when they are separeted with invisible form rows
    // margin-top: -24px;
    // EDIT: REMOVED BECAUSE OF SWITCHED ORDER OF FIELDS
    // }
    &--info + &--submit,
    &--radiobox + &--submit {
      // rely on info/radiobox margin-bottom,
      // because infotext/radiolabel has bottom space from line-height
      margin-top: 0;
    }

    // wszystkie wiersze oprócz wybranych mają odleglosc od description
    // niew iem jak to wyglada w przypadku info, captcha, ... ale to nie w tym tickecie
    &--description + {
      #{$row-root}:not(#{$row-root}--submit):not(#{$row-root}--radiobox) {
        margin-top: 12px;
      }
    }

    &--lang-switch {
      @extend %pko-clearfix;
    }
  }

  &__multichoice-column {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }

  &__description {
    color: $color-gray-second;
    @extend %text-default-3;
    padding-top: 12px;
    display: block;
  }

  &__submit {
    border: none;
  }

  // variants and layout conditions ///////////////

  .site-content__sidebar & {
    &:not(:first-child) {
      margin-top: #{$vertical-space-desktop - 5px};
    }

    @include breakpoint($brake-sm) {
      &:first-child {
        margin-top: -5px;
      }
    }
  }

  .banner & {
    &:not(:first-child) {
      margin-top: 20px;
    }

    @include breakpoint($brake-sm) {
      &:not(:first-child) {
        margin-top: 38px;
      }
    }
  }

  .site-content__main > & {
    // when form is directly in main column
    // add doesn't have parent's padding
    @extend %paddington;

    > .text > .form__title:first-child {
      margin-top: -7px !important; // overwrite .text :first-child
    }
  }

  .header &--callback {
    // modal product callback form
    margin-top: $vertical-space-mobile;
    margin-bottom: 0;
    @include breakpoint($brake-sm) {
      margin-top: #{$vertical-space-desktop - 10};
    }

    #{$root}__title {
      @extend %h3;
    }

    .input-radio,
    .input-checkbox {
      @extend %text-default-2;
    }
  }

  &--thank-you {
    visibility: hidden;
  }

  // errors ////////////////////////

  &__error {
    @extend %text-default-3;
    color: $color-red;
    padding: 8px 25px;

    .mod-G & {
      // on dark blue background
      color: $color-error-special;
    }

    @include breakpoint($brake-sm) {
      padding: 8px 15px;
    }

    &-fade-able {
      opacity: 0;
      transition: opacity 500ms linear;

      &--show {
        opacity: 1;
      }
    }
  }

  // formularze z kolumnami
  &--columns {
    @include pko-form-padding; // to już chyba niepotrzebne bo mamy .product-menu-section .ccforms

    .form__columns {
      @extend %break-from-paddington;
      @extend %pko-clearfix;
    }

    .form__fieldset {
      @extend %paddington;

      @include breakpoint($brake-sm) {
        @include span(6);

        &:nth-child(n+3) {
          @include span(12);
          clear: left;
          float: none;
        }
      }
    }
  }

  &__flag {
    display: block;
    background: $color-gray-third;
    padding: 2px;
    margin-right: 3px;
    float: left;
    margin-bottom: 10px;
    cursor: pointer;

    svg {
      width: 32px;
      height: 18px;
      border-radius: 2px;
      display: block;
    }
  }
}

//Ostylowanie buttonów w Formularz callback (tel. komórkowy, email, bez PESEL, w pytaniem od walutę dochodu)

.BWSMobilePhoneWithQuestionCallbackForm, .BWSMobilePhoneWithQuestionEmailCallbackForm {
  .radio-button {
    @include breakpoint($brake-sm) {
      flex: 0 1 78px;
      margin-right: 10px;
    }

    &__label {
      height: 37px;
      line-height: normal;
      padding: 8px;
      color: $text-color;

      &-text {
        vertical-align: initial;
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  .contest_answer__error {
    margin-top: -12px;
    padding: 0 25px 8px;
    @extend %text-default-3;
    color: $color-red;
  }
}

// Ostylowanie labelki w new fintech
.LetsFintechContactForm .form__label {
  display: block;
  margin-top: 10px;
}

// Ostylowanie labelki do selecta "Obroty firmy" w formularzu Trade Service,
// https://redmine.k2.pl/issues/94379
.TradeServiceContactForm .form__label {
  display: block;
  margin: 10px 0;
}


.KKRatesSuspensionIndividualCallbackForm,
.KKRatesSuspensionBusinessCallbackForm {
  .form__label {
    display: block;
    margin-top: 10px;
  }
}

.InstalmentSuspentionCallbackForm {
  .form__row--radiobox + .form__row--submit {
    margin-top: 30px;
  }
}

// Ostylowanie formularza dla DM w drzewie PKO BP
// https://redmine.k2.pl/issues/110248
.CampaignDmForm {
  $form: 'form';

  &.campaign-dm-form--wide { // wersja pozioma
    @include breakpoint($brake-sm) {
      .#{$form} {
        &__columns {
          max-width: 630px;

          margin-left: auto;
          margin-right: auto;
          margin-top: 30px;
        }
      }

      &.#{$form} .#{$form}__body .#{$form}__row {
        padding-right: 0;
      }
    }
  }

  .input-autocomplete-wrap {
    position: relative;
  }

  .ui-autocomplete { // Tak jak w staticfiles/front/infosite/scss/pages/_poi.scss , trochę zmodyfikowane
    position: absolute;
    top: 0;
    left: 0;
    @include pko-z-index();
    max-height: 175px;
    overflow-y: auto;

    cursor: default;
    border-radius: 0 0 5px 5px;
    padding: 0;
    list-style: none;
    background-color: $color-white;
    border: 1px solid $color-gray-second;
    border-top: 0;

    li {
      margin: 0;
      padding: 4px 15px;
      border-top: $color-gray-second;
      height: auto;
      line-height: 30px;

      &:first-child {
        border: none;
      }

      &:hover,
      &.ui-state-focus {
        color: $color-blue;
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }

    width: 100% !important;
  }
}

// Ostylowanie radio_select_large_buttons dla formularzy kontaktowych oddziałów zagranicznych
// https://redmine.k2.pl/issues/130534

.ForeignBranchCZContactForm,
.ForeignBranchCZENContactForm,
.ForeignBranchCZCZContactForm,
.ForeignBranchDEContactForm,
.ForeignBranchDEENContactForm,
.ForeignBranchDEDEContactForm,
.ForeignBranchSKContactForm,
.ForeignBranchSKENContactForm,
.ForeignBranchSKSKContactForm {
  .radio-button {
    flex: 1 1 100%;

    &:first-child {
      margin-right: 0;

      @include breakpoint($brake-sm) {
        margin-right: 0.5%;
      }
    }

    &:last-child {
      margin: 5px 0 14px;

      @include breakpoint($brake-sm) {
        margin: 14px 0 0 0.5%;
      }
    }

    &__label {
      color: $color-gray-main;

      &:hover {
        background-color: $color-gray-sixth;
      }

      &-text {
        @include pko-font-size($font-size-t2);
      }
    }

    @include breakpoint($brake-sm) {
      flex: 0 1 49.5%;
    }
  }
}

// Style dla formularza kontaktowego dla korporacji
// https://redmine.k2.pl/issues/138749

.CorpoContactForm,
.CorpoContactNewForm {
  .form__row--select {
    margin-top: 28px;

    .select2-container {
      margin-top: 14px;
    }
  }

  .form__row--header-radio-buttons {
    margin-bottom: 28px;
  }

  .form__label {
    display: block;
  }

  .radio-button {
    flex: 1 1 30%;
  }
}

.site-content__container {
  .CorpoContactForm,
  .CorpoContactNewForm {
    margin-top: 30px;
  }
}

// Zgody dla formularza kontaktowego PKO Finat
// https://redmine.k2.pl/issues/132637

.RedesignAskQuestionWithEmailFinatContactForm {
  .description-for-data-administration-info {
    margin-bottom: 0;
  }

  .form__row--radiobox-agreement {
    margin-top: 10px;

    + .form__row--radiobox {
      margin-top: -24px;
    }
  }
}

// Style dla formularzy z opcjonalną klasą .form-narrow dodawaną w CMS
.site-content__module--paddington,
.about-product-under {
  .form-narrow {
    padding-left: 0;
    padding-right: 0;
  }
}

.breakline.breakline {
  &--form {
    position: relative;
    margin-bottom: 25px;

    @include breakpoint($brake-sm) {
      position: relative;
      margin-bottom: 45px;
    }
  }
}

// Formularz rejestracyjny na Webinaria
.RegistrationWebinarForm {
  .form__row--radiobox {
    margin-bottom: 31px;
  
    .form__error {
      padding-top: 8px;
    }
  }
}

//#144068 - stylowanie formularza logowania do zahasłowanych funduszy
.tfi-login {
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint($brake-sm) {
    flex-flow: row nowrap;
  }

  &__form {
    padding: 20px 0 0;

    @include breakpoint($brake-sm) {
      flex: 0 0 50%;
      padding: 0 20px 0;
    }
  }

  &__img {
    @include breakpoint($brake-sm) {
      flex: 0 0 50%;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .TFILoginForm {
    .form__title {
      @include pko-font-size($font-size-t1);

      @include breakpoint($brake-sm) {
        @include pko-font-size(18);
      }

      @include breakpoint($brake-md) {
        @include pko-font-size(20);
      }
    }

    .form__row {
      @include breakpoint($brake-xs-sm) {
        width: 280px;
      }
    }
  }
}

// Formularz kontaktowy Rzecznik Klienta
.ToSpokesmanContactForm {
  .ContactFormResponseInfo {
    display: none; //#145244 - ukrycie dodatkowego info
  }
}

// Formularz kontaktowy PKO Faktoring
.RedesignFaktoringContactForm {
  .form__row--header-radio-buttons {
    .form__label {
      display: block;
    }
  }
}

// Formularz kontaktowy PKO Faktoring Promocja
.RedesignFaktoringPromotionContactForm {
  .form__row--header-radio-buttons {
    .form__label {
      display: block;
    }
  }
}

// Formularz kontaktowy PKO Faktoring Zewnętrzny
.RedesignFaktoringExternalContactForm {
  .form__row--header-radio-buttons {
    .form__label {
      display: block;
    }
  }
}

// Formularz kontaktowy PKO Faktoring Depozytowy
.RedesignFaktoringDepositContactForm {
  .form__row--header-radio-buttons {
    .form__label {
      display: block;
    }
  }
}

// Formularz kontaktowy PKO Faktoring Landing Page
.RedesignFaktoringLandingPageContactForm
{
  .form__row--header-radio-buttons {
    .form__label {
      display: block;
    }
  }
}

.form-leasing
{
  li[data-id="id_SecondSalesAndMarketingApprovalLeasing"] .form__error {
    margin: -10px 0 30px 0;
  }
}


