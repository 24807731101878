// Przestylwanie formularza specjalnie dla niosków sponsorskich
.sponsorship-form {

  $width-input: 366px;
  $margin-input: 25px;

  margin-top: 50px;

  &__is-client-container {
    @include breakpoint($brake-sm) {
      width: 110px;
    }
  }

  &__controls {
    padding: 30px 0;
    border-top: 1px solid $color-gray-fifth;
    margin: 25px 0 0;

    @include breakpoint($brake-sm) {
      padding: 30px 0 0;
    }
  }

  &__submit {
    float: right;
  }

  &__change-step {
    float: right;
    margin-right: 15px;
  }

  &__cancel {
    height: 36px;
    line-height: 36px;
  }

  .form {
    &__row--date-left {
      .form__field-width {
        display: block;
        @include breakpoint($brake-sm) {
          display: inline-block;
        }
        width: auto;
        & + .form__field-width {
          margin-top: 5px;
          @include breakpoint($brake-sm) {
            margin-top: 0;
            margin-left: 10px;
          }
        }
      }
      .form__errors {
        display: block;
      }
    }
    &__row--date-right {
      .form__errors {
        display: block;
      }
    }

    &__label {
      // "we use only placeholers"
      // no we dont :P
      @extend %text-default-1;
      display: block;
      width: 100%;
      padding: 12px 0 8px;
      margin: 0;
      vertical-align: top;

      @include breakpoint($brake-md) {
        width: 209px;
        padding: 12px 30px 0 0;
        display: inline-block;
      }
    }

    &__title {
      margin-bottom: 25px;
      font-size: 20px;

      &--margined {
        margin-top: 35px;
      }

      &--big {
        font-size: 24px;
      }
    }

    &__errors {
      display: inline-block;
      width: 100%;

      @include breakpoint($brake-sm) {
        width: 175px;
        vertical-align: top;
      }
    }

    &__error {
      display: block;
      width: 100%;

      margin: 8px 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    &__prefixes {
      display: inline-block;
      width: 100%;
      max-width: $width-input;
      font-size: 0;

      @include breakpoint($brake-sm) {
        width: $width-input;
        margin-right: $margin-input;
      }
    }

    &__field-width {
      display: inline-block;
      width: 100%;
      max-width: $width-input;

      @include breakpoint($brake-sm) {
        margin-right: $margin-input;
      }
    }

    &__date {
      position: relative;
      display: inline-block;
      width: 100%;
      padding-left: 25px;

      @include breakpoint($brake-sm) {
        max-width: 153px;
      }

      .input-text {
        width: 100%;
        cursor: text;

        @include breakpoint($brake-sm) {
          width: 153px;
        }
      }

      &-prefix {
        position: absolute;
        left: 0;
        top: 8px;
      }
    }

    &__date-icon {
      @extend %icomoon;
      position: absolute;
      right: 5px;
      top: 0;
      bottom: 0;
      height: 25px;
      margin: auto;
      cursor: pointer;

      @include breakpoint($brake-sm) {
        right: -20px;
      }

      &::after {
        content: $icon-calendar;
        font-size: 25px;
        color: $color-blue;
      }
    }

    &__with-suffix {
      display: inline-block;
      width: 100%;
      max-width: $width-input;
      font-size: 0;

      @include breakpoint($brake-sm) {
        width: $width-input;
        margin-right: $margin-input;
      }

      &-input,
      &-suffix {
        display: inline-block;
      }

      &-input {
        width: 90%;
      }

      &-suffix {
        @extend %text-default-1;

        width: 10%;
        text-align: right;
      }
    }

    &__required-star {
      color: $color-red;
    }
    @include breakpoint($brake-sm) {

      &__captcha {
        display: inline-block;
        width: 100%;
        text-align: right;

        label {
          text-align: right;
        }

        &-img-wrap {
          max-width: 104px;
        }

        .input-text {
          max-width: 104px;
          margin-right: 0;
        }

        &-align {
          display: inline-block;
          width: 270px;
          text-align: center;
        }

        .form__error {
          text-align: right;
        }
      }
    }

    &__summary {
      display: block;
      margin: 20px 0;

      &-name {
        @extend %text-default-1;
        display: inline-block;
        width: 100%;
        padding: 0 20px 0 0;
        margin: 0;
        box-sizing: border-box;
        vertical-align: top;
        color: $color-gray-main;

        @include breakpoint($brake-sm) {
          width: 209px;
        }
      }

      &-value {
        @extend %text-default-1;

        display: inline-block;
        color: $color-black;

        @include breakpoint($brake-sm) {
          max-width: $width-input;
        }
      }
    }
  }

  .input-text,
  .select2-container,
  .input-select,
  .select-mobile-wrapper {
    max-width: $width-input;

    @include breakpoint($brake-sm) {
      margin-right: $margin-input;
    }
  }
  .input-select { // nie widać skoku dzieki temu
    height: 36px;
  }
  .input-textarea {
    height: 75px;
  }
  .input-text {
    @extend %text-default-1;

    $width-prefix: 66px;
    $margin-prefix: 5px;
    $width-prefix-percent: 21.7%;
    $margin-prefix-percent: 1.3%;

    &--prefix {
      width: $width-prefix-percent;
      margin-right: $margin-prefix-percent;

      // max to 'zło', ale nie muszę resetować do domyślnych 15px i pamiętać o tym jakby się zmieniło z 15 na coś innego
      @include breakpoint($brake-sm-max) {
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
      }

      @include breakpoint($brake-sm) {
        padding-left: 15px;
        padding-right: 15px;
        text-align: left;
        width: $width-prefix;
        margin-right: $margin-prefix;
      }
    }
    &--prefixed {
      width: 100% - $width-prefix-percent - $margin-prefix-percent;

      @include breakpoint($brake-sm) {
        width: $width-input - $width-prefix - $margin-prefix;
        margin-right: 0;
      }
    }
    &--prefixed-2 {
      width: 100% - $width-prefix-percent - $margin-prefix-percent - $width-prefix-percent - $margin-prefix-percent;

      @include breakpoint($brake-sm) {
        width: $width-input - $width-prefix - $width-prefix - $margin-prefix - $margin-prefix;
        margin-right: 0;
      }
    }
  }
  .input-checkbox {
    font-size: 15px;
    font-weight: bold;

    &__content {
      padding-left: 30px;
      margin: 20px 0 0;

      .smaller {
        font-size: 13px;
      }
    }
  }
}

.sponsorship-wrapper {
  @extend %paddington;
}
