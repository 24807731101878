.bubbles {
  &--1 {
    margin-top: 25px;

    @include breakpoint($brake-sm) {
      margin-top: 45px;
    }
  }

  &--2 {
    margin-top: 25px;

    @include breakpoint($brake-sm) {
      margin-top: 45px;
    }
  }

  &--3 {
    margin-top: 25px;

    @include breakpoint($brake-sm) {
      margin-top: 45px;
    }
  }

  &--4 {
    margin-top: 25px;

    @include breakpoint($brake-sm) {
      margin-top: 45px;
    }
  }


  &--5 {
    margin-top: 25px;

    @include breakpoint($brake-sm) {
      margin-top: 45px;
    }
  }

  &--6 {
    margin-top: 25px;

    @include breakpoint($brake-sm) {
      margin-top: 45px;
    }
  }

  &--7 {
    margin-top: 25px;

    @include breakpoint($brake-sm) {
      margin-top: 45px;
    }
  }

  &--8 {
    margin-top: 25px;

    @include breakpoint($brake-sm) {
      margin-top: 45px;
    }
  }
}