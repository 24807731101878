.calc__summary {
  padding: 0;
  margin-top: 20px;

  &-details {
    @include pko-font-size(17);
    color: $color-black;
    margin-bottom: 15px;
  }

  &-desclimer {
    margin: 0;
    color: $color-gray-second;
  }

  @include breakpoint($brake-md) {
    margin-top: 50px;

    &-details {
      @include pko-font-size(20);
      margin-bottom: 25px
    }
  }

}
