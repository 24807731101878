// błędy
// (1) pole było większe niż trzeba, gdy tekst był za długi whitespace nowrap rozychał

.select2 {
  $root: &;

  .form__row--error &-container--default &-selection--single,
  .form__row--error &-container--default &-selection--single &-selection__arrow {
    border-color: $color-error-border;
    background: $color-pink;

    // error color on blue bg
    .landing-content__snippet-inner & {
      border-color: $color-error-special;
    }
  }

  &--mobile-dropdown.select2-container--default .select2-results__option--highlighted[aria-selected],
  &--mobile-dropdown.select2-container--default .select2-results__option[aria-selected="true"],
  &-container--default .select2-results__option--highlighted[aria-selected],
  &-container--default .select2-results__option[aria-selected="true"] {
    background-color: transparent;
    color: $link-color;
  }

  &--mobile-dropdown .select2-results__options {
    @include pko-font-size(14);
    padding: 12px;
  }

  #{$root} #{$root}-results__options {
    .input-select--mobile + & {
      @include pko-font-size(14);
      padding: 12px;
    }
  }

  #{$root} #{$root}-container--default &-results__option--highlighted[aria-selected] {
    .input-select--mobile + & {
      background-color: transparent;
      color: $text-color;
    }
  }

  &-container--focus,
  .no-touch &-container:hover,
  .no-touch &-container:focus,
  .no-touch &-container:active {

    &#{$root}-container--default {
      #{$root}-selection--single {
        border-color: $color-gray-second;
        .input-select--mobile + & {
          background-color: $icon-color;
        }
      }
    }
  }

  &-container--disabled {
    &#{$root}-container--default {
      #{$root}-selection--single {
        @include pko-on-event(true) {
          border-color: $color-gray-fifth;
          background-color: $color-gray-disabled;
        }
        .select2-selection__arrow {
          border-left: 1px solid $color-gray-fifth;
        }
        .select2-selection__rendered {
          color: $color-gray-third;
        }
      }
    }
  }

  &-container--default {

    &:not(#{$root}-container--open),
    .input-select--mobile + & {
      width: 100% !important; // override plugin's inline width attribute
    }

    #{$root}-search--dropdown #{$root}-search__field {
      @extend .input-text;
    }

    #{$root}-selection--single {
      .course__filter-wrap & {
        padding-left: 2px;
      }
      border-radius: 3px;
      padding-left: 15px;
      padding-right: 15px;
      height: 37px;
      border: 1px solid $color-gray-third;
      .input-select--mobile + & {
        background-color: $icon-color;

        #{$root}-selection__arrow b {
          &::before,
          &::after {
            color: $color-gray-third;
          }
        }
      }

      #{$root}-selection__rendered {
        .course__filter-wrap & {
          padding-right: 15px;
        }
        max-width: calc(100vw - 100px); // fix for text-overflow to work
        padding-right: 32px;
        line-height: 37px;
        height: 37px;
        white-space: nowrap;
        width: 100%;
        padding-left: 0;
        @include pko-font-size($font-size-t2);
        color: $color-gray-main;

        .input-select--mobile + & {
          color: $color-white;
        }

        .property & {
          line-height: 33px;
        }
      }

      #{$root}-selection__arrow {
        height: 35px;
        width: 37px;
        border-left: 1px solid $color-gray-third;

        b {
          @extend %icomoon;
          display: block;
          border: none;
          margin-left: -16px;
          margin-top: -17px;
          &::before {
            @include pko-font-size(32);
            content: $icon-drop-down;
            color: $icon-color;
          }
        }
      }
    }

    &:hover {
      #{$root}-selection__arrow {
        b {
          &::before {
            color: $color-gray-main;
          }
        }
      }
    }

  }

  &-container--open {
    #{$root}-selection--single {
      #{$root}-selection__arrow {
        b {
          margin-top: -18px;
          .no-touch &::before {
            content: $icon-drop-up;
            color: $icon-color;
          }
        }
      }
    }
    &:hover {
      #{$root}-selection__arrow {
        b {
          &::before {
            color: $icon-color;
          }
        }

      }
    }
  }

  .site-content__additional &-container:not(.hidden) {
    // fix too long select2 options in sidebar
    position: relative;
    width: 100%;
    display: table;
    table-layout: fixed;
  }

}

.select-simple {
  &.select2-container--default .select2-results > .select2-results__options {
    max-height: unset !important;
  }

  .select2 {
    &-results {
      border-radius: 0;

      &__options {
        white-space: nowrap;
      }
    }

    &-dropdown {
      width: auto !important;
      height: auto;
      left: 0;
      margin-left: 0;
      margin-top: -27px;
      box-shadow: 3px 3px 0 rgba($color-black, 0.05);
      border: 1px solid $color-gray-fifth !important;
      border-radius: 4px;
      padding: 21px 15px;
      @include pko-font-size($font-size-t2);

      .select2-selection--single {
        padding: 0 15px 0 0;

        @include pko-on-event() {
          b {
            @extend %pko-icon-arrow-gray;

          }
        }
      }
    }
  }
}

// #133877 - remove scroll from subject list in contact form
#select2-id_question_subject-results {
  max-height: none !important;
}

// todo arrow
.select-mobile {

  $root: &;

  &-wrapper {
    position: relative;
    padding-left: 15px;
    padding-right: 0;
    height: 37px;
    line-height: 37px;
    border: 1px solid $color-gray-third;
    border-radius: 3px;
    color: $color-gray-main;
    background-color: $color-white;
    overflow: hidden; // bład (1)

    &__val {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal; // bład (1)
      .property & {
        white-space: nowrap;
      }
    }

    .no-touch &.focus {
      border-color: $color-gray-second;
      #{$root}-arrow:before {
        content: $icon-drop-up;
      }
    }

    &.disabled {
      border: 1px solid $color-gray-fifth;
      background-color: $color-gray-disabled;
      color: $color-gray-third;
    }

    select {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }

  .form__row--error &-wrapper {
    border-color: rgba($color-red, 0.3);
    background: $color-pink;
  }

  &-arrow {
    @extend %icomoon;
    float: right;
    border-left: 1px solid $color-gray-third;
    height: 35px;
    width: 37px;
    margin-left: 15px; // fix, żeby lepiej wyglądało
    pointer-events: none;

    &::before {
      @include pko-font-size(32);
      position: absolute;
      top: 0;
      right: 2px;
      content: $icon-drop-down;
      color: $icon-color;
    }
  }

}

.site-nav__select {
  .select-mobile {

    &-wrapper {
      padding-left: 0;
      border: none;
      border-bottom: 1px solid $border-color;
      border-radius: 0;
    }
    &-arrow {
      border-left: none;
    }

  }
}

.contact-hour-select-section {
  display: table;
  width: 100%;

  h3 {
    &.form__subtitle {
      margin: 25px 0 23px;
    }
  }

  &__label {
    width: 32%;
    margin: 0;
    display: table-cell;
    vertical-align: middle;
    line-height: 1.3;
  }
  .select2 {
    display: table-cell;
  }

  @include breakpoint($brake-sm-max) {
    max-width: 100%;
  }
}

//.touch .select2-hidden-accessible.form__select--new {
//  display: block;
//  clip: auto !important;
//  width: 100% !important;
//  margin: 0 !important;
//  height: 100% !important;
//  top: 0;
//  left: 0;
//  opacity: 0;
//  z-index: 1;
//}

.select2-container--default .select2-selection--multiple {
  cursor: default;
}

/*.touch */
.select2-selection__choice__remove {
  display: none !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.select2-selection.select2-selection--multiple {
  border: 1px solid #bababa;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  margin-top: 7px;
  margin-left: 10px;
}

.select2-results-header {
  margin: 10px 0 10px 5px;
  text-transform: uppercase;
  font-size: 12px;
}
