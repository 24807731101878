.partner {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    &-header {
      margin-top: -5px;
      margin-bottom: 6px;

      &__link {
        &.margin-top-30-link {
          margin-top: 30px;
          display: inline-block;
        }
      }
    }

    &-item {
      margin-bottom: $vertical-space-mobile;

      @include breakpoint($brake-sm) {
        margin-bottom: $vertical-space-desktop - 5px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-photo {
      width: 100%;
      max-width: 160px;
      margin-bottom: 20px;

      &-wrapper {
        @extend %paddington;
        @include breakpoint($brake-xs) {
          margin-bottom: 15px;
        }
        @include breakpoint($brake-sm) {
          @include span(3);
          margin-bottom: 0;
        }
        .image {
          text-align: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }

    &-text {
      @extend %paddington;
      @include breakpoint($brake-sm) {
        @include span(9);
        float: right;
      }
    }

    &-contact {
      @extend %text-default-2;

      p {
        @extend %text-default-2;

        margin: 0;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: inline-block;
          margin: 6px 0 20px;
        }
      }

    }
  }

  &__no-results {
    @extend %paddington;
  }

  &__sidebar {
    &-title {
      margin-bottom: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    &-contact {
      @extend %text-default-2;
    }
  }

  &__voivodeship {
    &-wrapper {
      @include breakpoint($brake-sm) {
        max-width: 82%;
      }

      .select2,
      .select-mobile-wrapper {
        margin-bottom: 16px;

        @include breakpoint($brake-sm) {
          margin: 7px 0 44px;
        }
      }
    }

    &-label {
      @extend %h3;
      color: $color-black;
      margin-bottom: 8px;
      display: block;

      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-t1);
        color: $color-gray-main;
      }
    }
  }

  //
  // scss-lint:disable QualifyingElement
  &--mobile-only {
    option[disabled] {
      display: none;
    }
  }
  // scss-lint:enable QualifyingElement
}

//
// scss-lint:disable QualifyingElement
ul[id*=select2-subcategory-mobile-only] {
  [aria-disabled=true] {
    display: none;
  }
}

// scss-lint:enable QualifyingElement
