.box-with-image {
  &__title {
    @extend %paddington;
    margin-bottom: 40px;
  }
  &__content {
    display: flex;
    flex-direction: column;

    &__item {
      flex-grow: 1;
      flex-basis: 0;
      @extend %paddington;
      margin-bottom: 30px;
    }

    @include breakpoint($brake-sm) {
      flex-flow: row nowrap;
      margin-bottom: 50px;
    }
  }

  &__date {
    @include pko-font-size($font-size-t2-mobile);
    color: $color-gray-second;
    margin-top: 10px;
    margin-bottom: -10px;
  }

  &__image {
    max-width: 100%;
    max-height: 270px;
    margin-bottom: 7px;

    &--ico {
      max-height: 96px;
    }
  }

  &__description {
    color: $text-color;
  }

  &__cta {
    @include breakpoint($brake-sm-max) {
      display: block;
    }
  }
}
