$color-border-slider: #bcbcbc;

// rates carousel
.calc__carousel {
  &-header {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px;
    padding-right: 25px;
    @include pko-font-size(17);
    color: $color-black;
    text-align: center;

    &--small {
      margin: 15px 0 12px;
      padding: 0;
      @include pko-font-size(14);
    }

    @include breakpoint($brake-md) {
      margin: 0 0 15px;
      padding: 0;
      &--small {
        @include pko-font-size(17);
        margin: 35px 0 15px;
      }
    }
  }
  &-installment {
    margin: 0;

    &-slider {
      display: inline-block;
      visibility: visible;
      width: calc(100% - 2 * 16px - 2 * 32px);

      &-item {
        width: 48px;
        height: 48px;
        line-height: 48px;
        top: 0;
        border-radius: 3px;
        background-color: $color-white;
        border: 1px solid $color-border-slider;
        position: relative;
        transition: top 0.2s ease;
        @include pko-font-size(18);
        text-align: center;
        color: $color-blue;
        cursor: pointer;

        &--active {
          top: 0;
          height: 48px;
          line-height: 48px;
          background-color: $color-blue;
          color: $color-white;
        }
      }

      &--visible {
        visibility: visible;
      }

      .owl-stage-outer {
        padding-bottom: 0;
      }

      .owl-item:first-child .calc__sliders__installment-slider-item {
        border-left: 1px solid $color-border-slider;
      }

      @include breakpoint($brake-md) {
        width: calc(100% - 2 * 16px - 2 * 36px);
      }
    }

    &-nav {
      position: relative;
      bottom: 8px;
      display: inline-block;
      width: 32px;
      height: 32px;
      cursor: pointer;
      border-radius: 50%;
      background-color: $color-blue;

      &:first-child {
        margin-right: 16px;

        &::after {
          content: $icon-arrow-left;
          color: $color-white;
          font-family: icomoon;
          font-weight: 700;
          position: absolute;
          width: 32px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          @include pko-font-size(16);
          left: -1px;

          @include breakpoint($brake-md) {
            width: 36px;
            height: 36px;
            line-height: 36px;
          }
        }
      }

      &:last-child {
        margin-left: 16px;

        &::after {
          @extend .calc__carousel-installment-nav:first-child::after;
          content: $icon-arrow-right;
          left: 1px;
        }
      }

      @include breakpoint($brake-md) {
        width: 36px;
        height: 36px;
        bottom: 13px;
      }
    }

    &.hidden-controls {
      margin: 0;

      .calc__sliders-installment-slider {
        width: 100%;
      }

      .calc__sliders-installment-nav {
        display: none;
      }
    }
  }
}
