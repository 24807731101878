.category-item {
  @extend %paddington;
  @include pko-text-item;
  @include pko-font-size($font-size-t2);
  margin-bottom: 30px;

  @include breakpoint($brake-sm) {
    @include span(6);
    margin-bottom: 40px;
  }
  @include breakpoint($brake-md) {
    margin-bottom: 45px;
  }

  &__text {
    margin-right: 5px;
    display: inline-block;
  }

  &:nth-child(2n+1) {
    clear: left;
  }

  &--silver {
    &::before {
      @extend %text-default-2;
      content: '';
      display: block;
      height: 21px;
      line-height: 21px;
      padding: 0 4px;
      color: $color-white;
      @include breakpoint($brake-sm-max) {
        // #66650
        line-height: 22px;
        margin-bottom: 2px;
      }
    }
    &[data-prefix]::before {
      content: attr(data-prefix);
      display: inline-block;
      background: $privatebanking-gradient;
    }
  }

  .private-banking &--silver::before, 
  .private-banking &[data-prefix]::before {
    display: none;
  }

  // parent container (.category-items)
  &s {
    position: relative;
    @extend %pko-clearfix;

    &--accordion {
      // naokolo accordionu, w którym sa .category-item
      @extend %paddington;
    }

    // brak pierwszej linii w akordeonie
    @include breakpoint($brake-sm) {
      &:empty + &--accordion {
        margin-top: -15px;
      }
      &:empty + &--accordion .accordion__elem:first-child .accordion__header {
        border-top: none;
      }
    }
    @include breakpoint($brake-md) {
      &:empty + &--accordion {
        margin-top: -20px;
      }
    }

    .accordion & {
      // wewnatrz accordionu, w którym sa .category-item
      @extend %break-from-paddington;
      margin-bottom: -40px;
    }

    &:not(.category-items--accordion) + .worth-to-know {
      padding-top: 0;
      .breakline {
        bottom: auto;
        top: 0;
        display: block !important;
      }
    }
  }

  // single items on category with visual
  &s--visual-single {
    @extend %break-from-paddington;
    padding-top: 30px;
    @include breakpoint($brake-sm) {
      padding-top: 47px;
    }
  }
  &s--visual-single & {
    @include breakpoint($brake-sm) {
      @include span(4);
      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }


  &-container {
    .accordion--products {
      margin-bottom: 0;
    }
    .worth-to-know {
      @extend %pko-clearfix;
      padding-bottom: 47px;
      margin-bottom: 0;
      position: relative;
      .breakline {
        display: none;
      }
    }
    .feature-section__item {
      margin: 0 !important;
    }
    .feature-section-item__title {
      margin-bottom: 0;
    }
  }

}


