.recommended-product {
  @extend %pko-clearfix;

  &__wrap {
    position: relative;
    > .breakline {
      display: none;

      @include breakpoint($brake-sm) {
        display: block;
      }
    }

    @include breakpoint($brake-sm) {
      @include span(12);
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }

  &__box {
    @extend %paddington;

    &:first-child {
      margin-top: 30px;
      @include breakpoint($brake-sm) {
        margin-top: 0;
      }
    }

    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px;

    &-header {
      margin-top: 15px;
      margin-bottom: 20px;

      &--no-img {
        margin-top: 0;
      }
    }

    &-img {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 370px;
      height: auto;
      min-height: 140px;

      @include breakpoint($brake-xs-sm) {
        min-height: 200px;
      }
    }

    @include breakpoint($brake-sm) {
      @include span(6);
      margin-bottom: 0;
    }

    @include breakpoint($brake-sm) {
      .breakline {
        display: none;
      }
    }
  }
}
