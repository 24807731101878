// Strona główna portalu Agencja Redesign
.agency-main-page {
  &__snippets {
    @extend %paddington;
  }

  .poi-list {
    @include breakpoint($brake-sm) {
      display: none; // Jednak tego nie pokazujemy na desktop, życzenie klienta
    }

    &__noresult-inner,
    &__item:last-child {
      border-bottom: none;
    }
  }

  .poi-under-map {
    display: none; // Jednak tego nie pokazujemy na desktop, życzenie klienta

    padding-top: 25px;
    padding-bottom: 25px;
  }

  .overlay--poi {
    .overlay__icon--poi {
      @include breakpoint($brake-sm) {
        top: 400px;
      }
    }
  }
}

// Strona produktowa Agencji
.agency-product.two-column-with-sidebar {
  .site-content__additional {
    @include breakpoint($brake-sm-max) {
      margin-top: $vertical-space-mobile;
      padding-top: $vertical-space-mobile;
    }
  }
}
