//todo temp !!1
//temp - highcharts 5.0.2 tooltip się pokazuje w lewych górnym rogu wykresu
//po updacie do nowszej wersji highcharts trzeba usunąć + przetestować
.modal--currencies .highcharts-tooltip {
  display: none;
}

.currencies-chart {
  width: 880px;
  max-width: 100%;

  &__title {
    @extend %h3;
    margin: 0;
    padding: 0;
  }

  &__range {
    list-style: none;
    margin: 0;
    padding: 15px 0 10px;
    float: left;

    li {
      display: inline-block;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }

    a {
      color: inherit;
      padding-bottom: 5px;
      &.active {
        color: $color-blue;
        border-bottom: 1px solid rgba($color-blue, 50%);
      }
    }
  }

  &__holder {
    clear: both;
    margin: 35px 0 25px;
  }

  &__table {
    @extend %text-default-2;

    th,
    td {
      text-align: right;
      &:first-child {
        text-align: left;
      }
    }
    th {
      font-weight: normal;
    }
    &-datetime {
      color: $color-gray-third;
      @extend %text-default-3;
    }
    &-value {
      color: $color-black;
      width: 80px;
    }
  }

  &__legend {
    &-item {
      @extend %pko-clearfix;
      &::before {
        content: '';
        display: inline-block;
        border-radius: 3px;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        float: left;
      }
      &.nr1::before {
        background: $color-blue;
      }
      &.nr2::before {
        background: $color-red;
      }
      &.nr3::before {
        background: $color-gray-third;
      }
      &.nr4::before {
        background: $color-blue-light;
      }
    }
  }

  @include breakpoint($brake-sm) {
    padding: 20px;

    &__legend,
    &__table {
      float: left;
      width: 50%;
    }
    &__range {
      float: right;
      padding: 0;
      li {
        margin-left: 14px;
      }
    }
    &__table {
      margin-bottom: 11px;
    }
    &__legend-item {
      float: left;
      width: 45%;
      &::before {
        height: 1px;
        width: 30px;
        top: 8px;
        position: relative;
      }
    }
  }

  @media (max-width: #{$brake-sm - 0.01em}) {
    &__holder {
      margin: 30px 0 0 -20px;
      height: 200px; // falback for vh no support
      height: 40vh;
    }
    &__legend {
      padding-top: 15px;
    }
    &__legend {
      &-item {
        &::before {
          margin-top: 3px;
        }
      }
    }
  }

}
