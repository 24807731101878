.newsletter {
  h3 {
    margin-bottom: 40px;
  }
  .field-wrapper {
    @include breakpoint($brake-md) {
      width: 47%;
    }
  }
  &__register {
    margin-top: 38px;

    .site-content:not(.sko) & {
      margin-bottom: 40px;
      border-bottom: solid 1px $color-gray-fifth;
      padding-bottom: 30px;
      @include breakpoint($brake-sm) {
        padding-bottom: 50px;
      }
    }

    .modal & {
      margin: 0;
      padding: 0;
      border-bottom: none;
    }
  }
  &__settings {
    h2 {
      margin-top: 0;
      margin-bottom: 34px;
    }
    .form__body {
      margin-top: $vertical-space-mobile;
      @include breakpoint($brake-sm) {
        margin-top: $vertical-space-desktop;
      }
    }
  }
  &__links {
    h3 {
      margin-bottom: 0;
    }
    a {
      font-size: 14px;
    }
    strong {
      font-size: 14px;
    }
  }
  &__login {
    @include breakpoint($brake-sm) {
      .form__title {
        top: -5px;
        position: relative;
        margin-bottom: -5px;
      }
    }
  }
}
