// -----------------------------------------------------------------------------
// icomoon i inne
// -----------------------------------------------------------------------------

// plus i minus używane w wersjach icomoon i pure css
// menu tylko w formie pure css, ale niech zostanie
// check2 do checkboxa używany

[class^="icon-"],
[class*=" icon-"] {
  @extend %icomoon;
}

.icon-blockchain {
  &:before {
    content: $icon-blockchain;
  }
}
.icon-blockchain-full {
  &:before {
    content: $icon-blockchain-full;
  }
}
.icon-sign {
  &:before {
    content: $icon-sign;
  }
}
.icon-exclamation-badge {
  &::before {
    content: $icon-exclamation-badge;
  }
}
.icon-mail-alt {
  &::before {
    content: $icon-mail-alt;
  }
}
.icon-mail {
  &::before {
    content: $icon-mail;
  }
}
.icon-nk {
  &::before {
    content: $icon-nk;
  }
}
.icon-star {
  &::before {
    content: $icon-star;
  }
}
.icon-iko-logo {
  &::before {
    content: $icon-iko-logo;
  }
}
.icon-umbrella {
  &::before {
    content: $icon-umbrella;
  }
}
.icon-compare {
  &::before {
    content: $icon-compare;
  }
}
.icon-play {
  &::before {
    content: $icon-play;
  }
}
.icon-goldenline {
  &::before {
    content: $icon-goldenline;
  }
}
.icon-linkedin {
  &::before {
    content: $icon-linkedin;
  }
}
.icon-file-zip {
  &::before {
    content: $icon-file-zip;
  }
}
.icon-file-xls {
  &::before {
    content: $icon-file-xls;
  }
}
.icon-file-default {
  &::before {
    content: $icon-file-default;
  }
}
.icon-print {
  &::before {
    content: $icon-print;
  }
}
.icon-phone {
  &::before {
    content: $icon-phone;
  }
}
.icon-reload {
  &::before {
    content: $icon-reload;
  }
}
.icon-handicapped {
  &::before {
    content: $icon-handicapped;
  }
}
.icon-block {
  &::before {
    content: $icon-block;
  }
}
.icon-check2 {
  &::before {
    content: $icon-check2;
  }
}
.icon-file-doc {
  &::before {
    content: $icon-file-doc;
  }
}
.icon-home {
  &::before {
    content: $icon-home;
  }
}
.icon-infotip {
  &::before {
    content: $icon-infotip;
  }
}
.icon-calendar {
  &::before {
    content: $icon-calendar;
  }
}
.icon-localize {
  &::before {
    content: $icon-localize;
  }
}
.icon-localize-2 {
  &::before {
    content: $icon-localize-2;
  }
}
.icon-file-pdf {
  &::before {
    content: $icon-file-pdf;
  }
}
.icon-map-pin {
  &::before {
    content: $icon-map-pin;
  }
}
.icon-drop-down {
  &::before {
    content: $icon-drop-down;
  }
}
.icon-drop-up {
  &::before {
    content: $icon-drop-up;
  }
}
.icon-arrow-left {
  &::before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &::before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-top {
  &::before {
    content: $icon-arrow-top;
  }
}
.icon-arrow-down {
  &::before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-double {
  &::before {
    content: $icon-arrow-double;
  }
}
.icon-arrow-left-small {
  &::before {
    content: $icon-arrow-left-small;
  }
}
.icon-arrow-right-small {
  &::before {
    content: $icon-arrow-right-small;
  }
}
.icon-chart {
  &::before {
    content: $icon-chart;
  }
}
.icon-file-xml {
  &::before {
    content: $icon-file-xml;
  }
}
.icon-close {
  &::before {
    content: $icon-close;
  }
}
.icon-padlock {
  &::before {
    content: $icon-padlock;
  }
}
.icon-menu {
  &::before {
    content: $icon-menu;
  }
}
.icon-minus {
  &::before {
    content: $icon-minus;
  }
}
.icon-plus {
  &::before {
    content: $icon-plus;
  }
}
.icon-search {
  &::before {
    content: $icon-search;
  }
}
.icon-spinner6 {
  &::before {
    content: $icon-spinner6;
  }
}
.icon-facebook {
  &::before {
    content: $icon-facebook;
  }
}
.icon-twitter {
  &::before {
    content: $icon-twitter;
  }
}
.icon-twitter-x {
  filter: grayscale(1) contrast(0.79) brightness(1.3);
  transition: .25s ease;

  &:hover {
    filter: none;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url(../img/social-media/twitter-x-blue.svg) no-repeat;
    background-size: contain;
    margin-top: 1px;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-rss {
  &::before {
    content: $icon-rss;
  }
}
.icon-youtube2 {
  &::before {
    content: $icon-youtube2;
  }
}
.icon-pozyczka-iko {
  &:before {
    content: $icon-pozyczka-iko;
  }
}
.icon-customer {
  &:before {
    content: $icon-customer;
  }
}
.icon-bank {
  &:before {
    content: $icon-bank;
  }
}
.icon-sign-agreement {
  &:before {
    content: $icon-sign-agreement;
  }
}
.icon-payment-day {
  &:before {
    content: $icon-payment-day;
  }
}
.icon-phone-list {
  &:before {
    content: $icon-phone-list;
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link;
  }
}

.icon-cashless .path1:before {
  content: "\e93e";
  color: rgb(0, 76, 154);
}
.icon-cashless .path2:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(0, 76, 154);
}
.icon-cashless .path3:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(228, 32, 44);
}
.icon-cashless .path4:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(228, 32, 44);
}


// specjalne ikony, dodatkowe

.icon-file {
  &-docx,
  &-rtf {
    &::before {
      content: $icon-file-doc;
    }
  }

  &-xlsx {
    &::before {
      content: $icon-file-xls;
    }
  }

  &-png {
    &::before {
      content: $icon-file-png;
    }
  }
  &-jpg,
  &-jpeg {
    &::before {
      content: $icon-file-jpg;
    }
  }
  &-gif,
  &-html,
  &-txt,
  &-text,
  &-pionki,
  &-zwolen,
  &-warszawa,
  &-minsk,
  &-niehorze,
  &-rzeszow,
  &-zyrardow,
  &-siedlce {
    &::before {
      content: $icon-file-default;
    }
  }
}

// Ikony do tabel, "plus" i "krzyżyk"

.icon-table-check {
  &::before {
    content: $icon-check2;
  }
}

.icon-table-uncheck {
  &::before {
    content: $icon-close;
  }
}

// -----------------------------------------------------------------------------
// HTML5
// -----------------------------------------------------------------------------

.pko-icon {
  &-plus {
    @extend %pko-icon-plus;
  }

  &-minus {
    @extend %pko-icon-minus;
  }

  &-menu {
    position: relative;
    width: 20px;
    height: 15px;
    &__inner {
      @extend %pko-icon-menu;
    }
  }

  &-search {
    @extend %pko-icon-search;
  }

  &-arrow {
    @extend %pko-icon-arrow;
  }

}
