.pagination-wrapper {
  @extend %paddington;
  margin-top: $vertical-space-mobile;
  padding-bottom: $vertical-space-mobile;
  @include breakpoint($brake-sm) {
    margin-top: $vertical-space-desktop;
    padding-bottom: 0;
  }
}

.pagination {
  @extend %paddington;
  padding-top: $vertical-space-mobile;
  @include breakpoint($brake-sm) {
    padding-top: $vertical-space-desktop;
  }
  text-align: right;
  list-style: none;
  position: relative;
  border-top: 1px solid $border-color;
  margin: 0;

  li {
    @include pko-font-size(16);
    @include pko-line-height(16, 16);
    @include breakpoint($brake-md-lg) {
      @include pko-font-size(20);
      @include pko-line-height(20, 20);
    }
    display: inline-block;
    vertical-align: text-bottom;
    padding-left: 12px;

    &.next,
    &.prev {
      padding-left: 0;
      width: 25px;
      position: relative;
      top: 1px;

      @extend %icomoon;
      a {
        font-size: 0;
      }
    }

    &.prev {
      padding-right: 13px;
    }

    &.next {
      padding-left: 8px; // bug #62902
    }

    &.skip {
      padding-left: 5px; // bug #62902
      + li {
        padding-left: 8px; // bug #62902
      }
    }

    a {
      color: $color-blue;
      transition: color $trans-hover;
      @include pko-on-event() {
        color: $color-gray-main;
      }
    }
    &.next a::before,
    &.prev a::before {
      vertical-align: top;
      @include pko-font-size(24);
      @include pko-line-height(24, 20);
      @include breakpoint($brake-md-lg) {
        @include pko-font-size(28);
        @include pko-line-height(28, 24);
      }
    }
    .next a::before {
      content: $icon-arrow-right-small;
    }
    .prev a::before {
      content: $icon-arrow-left-small;
    }
  }

  // TOOD: skonsultować z TF/MC - bo to troche taki sztywniak wyszedł
  &--full-text {
    padding-left: 17px !important;
    padding-right: 17px !important;

    li {
      padding: 0;
      &.prev-text,
      &.next-text {
        a {
          position: relative;
          display: inline-block;
          min-width: 40px;
          height: 21px;
          &::before {
            @extend %icomoon;
            @include pko-font-size(24);
            @include pko-line-height(24, 20);
            @include breakpoint($brake-md-lg) {
              @include pko-font-size(28);
              @include pko-line-height(28, 24);
            }
            position: absolute;
            color: $color-blue;
            transition: color $trans-hover;
            top: -1px;
          }
          &:hover::before {
            color: $color-gray-main;
          }
          span {
            display: none;
            @include breakpoint($brake-xs-sm) {
              display: inline;
            }
          }
        }
      }
      &.next-text {
        float: right;
        a {
          padding-right: 10px;
          &::before {
            content: $icon-arrow-right-small;
            right: -18px;
          }
        }
      }
      &.prev-text {
        float: left;
        a {
          padding-left: 10px;
          &::before {
            content: $icon-arrow-left-small;
            left: -18px;
          }
        }
      }
    }
  }

  &--wide {
    width: 100%;
    text-align: center;
  }
}
