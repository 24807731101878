@mixin art-slider {
  position: relative;
  //padding-bottom: 56.25%; // 16:9
  padding-bottom: 47.25%; // bardzie nasze

  img {
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: auto;
    width: auto;
  }
}

.art-collection {

  // slider

  @extend %pko-owl-arrows;

  &-slider {
    &__holder {
      position: relative;
    }
    &__item {
      text-align: center;
    }
    .feature-section {
      &__item {
        width: 100% !important;
        @include art-slider;
      }
    }
    p {
      clear: left;
      margin: 0;
      padding-top: 20px;
    }
  }

  // aktualności

  .newsList {
    padding-bottom: 0;
    .breakline {
      display: none;
    }
    @include breakpoint($brake-sm) {
      padding-top: 25px;
      .site-content__module-header {
        margin-top: 0;
      }
    }
  }

}

.art-gallery-news {
  &-slider {
    position: relative;
    .owl-prev,
    .owl-next {
      display: block;
      color: $color-blue;
      &.disabled {
        color: $color-gray-third;
      }
    }
    .owl-prev {
      left: 0;
    }
    &__img {
      @include art-slider;
    }
  }
  &-intro {
    @extend %h3;
    margin-bottom: 50px;
    p {
      @extend %h3;
    }
  }
}

.article-gallery {
  @extend %paddington;
  margin-bottom: 50px;
}
