$course-space: 89px;

.course {
  position: relative;
  margin-bottom: 30px;

  &--less-mb {
    margin-bottom: 76px;
  }

  &--no-mb {
    margin-bottom: 20px;
  }

  &-container {
    @extend %pko-clearfix;

    .course:last-child::after {
      display: none !important;
    }
    @include breakpoint($brake-sm) {
      &--spreads {
        .course:last-child {
          margin-bottom: 76px;
        }
      }
    }
    position: relative;
    clear: left;
    margin-top: 30px;

    #cardPaymentMethod & {
      @include breakpoint($brake-sm-max) {
        margin-top: 0;
      }
    }
  }

  &-irs-reference-stake {
    clear: left;
    margin-bottom: 40px;
  }

  &s-hour-select {
    .select-mobile-wrapper {
      width: auto;
      padding-right: 27px;
      float: left;
      border: none;
      height: auto;
      top: -1px;
      line-height: 1;
      padding-left: 0;
      select {
        color: $color-blue;
        // pokazywanie selecte pomysł bo ktoś nie umiał inaczej, js, mobilne selecty mają wrappera
        // ale w sumie ciekawy pomysł
        opacity: 1;
        appearance: none;
        border: none;
        background: none;
        text-align: left;
        padding-left: 0;
      }
      .select-mobile-arrow {
        border-left: none;
      }
      .select-mobile-wrapper__val {
        display: none;
      }
    }
    &-label {
      float: left;
      margin-right: 6px;
      line-height: 32px;
      @include breakpoint($brake-sm) {
        float: none;
        margin-right: 0;
      }
    }
  }

  &__filter-wrap {
    @extend %paddington;
    @extend %pko-clearfix;
    position: relative;
    @include pko-z-index(default);

    .select2-container--default .select2-selection--single .select2-selection__rendered {
      font-size: 1em;
    }

    #select2-filter-currency-container {
      transform: translateY(-2px);
      @media screen and (min-width: $brake-sm) and (max-width: 1119px) {
        transform: translateY(-3px);
      }
    }
  }

  &::after {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    display: none;
    top: 0;
    right: $course-space / 2;
    background: $border-color;
  }

  &-wrapper {
    position: relative;
    clear: left;
    overflow: hidden;
    @media (max-width: #{$brake-sm - 0.01em}) {
      top: -29px;
      padding-top: 29px;
      margin-bottom: -29px;
    }
    @include breakpoint($brake-sm) {
      top: -74px;
      padding-top: 74px;
      margin-bottom: -74px;
    }
  }

  .select-container__label {
    margin-bottom: 10px;

    @include breakpoint($brake-sm-max) {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  &.margin-section {
    padding: 20px;
    margin-top: 10px;

    @include breakpoint($brake-sm) {
      margin-top: 30px;
    }

    .value {
      @include pko-font-size(30);
      color: $color-black;
      font-weight: bold;
    }
  }

  &-warning {
    color: $color-red;
    margin: 17px 0 27px;

    @include breakpoint($brake-sm) {
      margin: 0 0 37px;
    }
  }

  &__title {
    margin: -2px 0 0;
    max-width: 320px;
  }
  &__flag {
    display: inline-block;
    svg {
      border: 1px solid $color-gray-fifth;
      width: 51px;
      height: 28px;
    }
  }
  &__info {
    @extend %text-default-2;
    color: $color-gray-second;
    &-value {
      color: $color-gray-main;
    }
  }
  &__bank {
    color: $color-gray-second;
    @extend %text-default-2;
  }
  &__chart-icon {
    cursor: pointer;
    float: right;
    color: $color-blue;
    font-size: 30px;
    &::before {
      position: relative;
      top: 4px;
      right: -5px;
    }
  }
  &__table {
    width: 100%;
    text-align: right;
    margin-top: 15px;
    max-width: 320px;

    &-version {
      padding-top: 0;
      float: right;
      top: 0;
      position: absolute;
      right: 0;
    }

    &-spread {
      margin-bottom: 30px;
      }

    td,
    th {
      padding: 2px 0;
    }
    td {
      @include pko-font-size($font-size-t2-mobile);
    }

    &--irs {
      &-title {
        padding-bottom: 0 !important;
        h3 {
          margin-top: 5px;
          margin-bottom: 0;
          line-height: 17px;
        }
      }
      th,
      td {
        text-align: left;
        width: 50%;
      }
      th {
        padding-bottom: 15px;
      }
    }
  }
  &__change {
    &--up {
      color: $color-green;
    }
    &--down {
      color: $color-red;
    }
  }
  &__label {
    @include pko-font-size($font-size-t2-mobile);
    font-weight: normal;
    color: $color-gray-second;
    tbody & {
      text-align: left;
    }
  }

  @include breakpoint($brake-sm) {
    @include span(6);
    padding-right: $course-space;

    &:not(&--no-mb) {
      margin-bottom: $course-space;
    }

    &:not(&--no-border)::after {
      display: block;
    }

    &--no-border {
      padding-right: 40px;
    }

    &-container {
      margin-right: -#{$course-space / 2};
      margin-top: 0;

      .course:nth-child(2n-1) {
        clear: left;
      }
      .course:nth-child(2n) {
        &::after {
          display: none;
        }
      }
    }
    &__label {
      @include pko-font-size($font-size-t2);
    }
    &__table {
      td {
        @include pko-font-size($font-size-t2);
      }
    }

    &-column {
      width: 50%;
      float: left;
    }
    &-column & {
      width: 100%;
    }
    &__table-version {
      padding-top: 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  @include breakpoint($brake-md) {
    @include span(4);
    padding-right: 95px;

    &--no-border {
      padding-right: 40px;
    }

    &__table {
      width: 98%; //grid :/
    }
    &__title {
      width: 97%; //grid :/

      &--no-mt {
        margin-top: -1px;
        margin-bottom: 40px;
      }
    }

    &-container {
      .course:nth-child(n),
      .course:nth-child(2n-1),
      .course:nth-child(2n) {
        clear: none;
      }
      .course:nth-child(3n+1) {
        clear: left;
      }
      .course:not(.course--no-border):nth-child(3n+1),
      .course:not(.course--no-border):nth-child(3n+2) {
        &::after {
          display: block;
        }
      }
      .course:nth-child(3n) {
        &::after {
          display: none;
        }
      }
    }
  }

  &__billings { //cały content - bo inputy ukryte:)
    @include breakpoint($brake-md) {
      margin-top: -58px;
      margin-bottom: 40px;
    }
  }

  &__bilings-tab {
    margin-top: -18px;
    &-title {
      margin-top: 75px; //qa 53 / a local 75 ... whhaaaaa?!
      margin-bottom: 35px;
      @include breakpoint($brake-sm) {
        //  margin-top: 13px;
      }
    }
    &-aside-title {
      margin-bottom: 18px;
      &--first {
        margin-top: 57px;
      }
    }
    &-aside-image {
      margin-bottom: 10px;
    }
  }

  &__irs-tab {
    &-box {
      padding-right: 75px;
      margin-bottom: 40px;
      &:nth-of-type(2) {
        &::after {
          display: none;
        }
      }
    }
    &-title {
      margin-top: 30px;
      margin-bottom: 0;
      @include breakpoint($brake-sm) {
        margin-top: -2px;
      }

      &--extra-mt {
        margin-top: 36px;
        margin-bottom: 12px;
      }
    }
    &-subtitle {
      margin-top: 0;
    }
  }

  &__spread {
    margin-top: -9px;
    margin-bottom: 43px;
  }

  &-banners .banner__link-mobile {
    float: none;
    margin-left: 0;
  }

  &__foreign-branch-rates {
    @extend %paddington;

    .course__filter-wrap {
      padding: 0; 
    }

    .course-wrapper {
      margin-bottom: -18px;
    }
  }
}

.newsletter-banner {
  // cursor: pointer;

  position: relative;
  height: 180px;
  @include breakpoint($brake-sm-max) {
    max-width: 340px;

    // height: 130px;
    height: 150px;
  }

  &__text {
    max-width: 78%;

    @include breakpoint($brake-lg) {
      max-width: 100%;
    }
  }

  &::before {
    content: '';
    height: 130px;
    float: right;
    display: block;

    // OLD
    // width: 120px;
    // background: pko-image('newsletter_envelop.png') no-repeat top right;
    width: 115px;
    background: pko-image('course_banner.jpg') no-repeat left;
    background-size: contain;

    margin-right: -20px;

    @include breakpoint($brake-sm) {
      // margin-right: -30px;
      margin-right: -40px;

      background-size: auto;
    }
    @include breakpoint($brake-md) {
      // margin-right: -50px;
      margin-right: -40px;
    }
    @include breakpoint($brake-lg) {
      // margin-right: -30px;
      margin-right: -40px;
    }
  }
  h3 {
    text-transform: uppercase;
    margin-bottom: 0;
  }
  p {
    @extend %text-default-2;
    margin: 10px 0;
    transition: $trans-hover;
    span {
      white-space: nowrap;
    }
  }
  div {
    position: absolute;
  }
  a {
    color: $color-red;
  }

  /*&:hover {
    a {
      color: $color-gray-main;
    }
    p {
      color: $color-blue;
    }
  }*/
}

