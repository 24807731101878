.participant {
  &--hidden {
    display: none;
  }

  &__title {
    margin-top: 20px;

    @include breakpoint($brake-sm) {
      padding-right: 35px;

      .site-content__sidebar & {
        padding-right: 0;
      }
    }
  }

  &__add-button {
    cursor: pointer;
  }

  &__remove-button {
    cursor: pointer;
    color: $color-red;
    float: right;
    margin-top: -5px;
  }

  &__checkbox-side {
    @include breakpoint($brake-sm) {
      display: inline-block;
      width: 250px;
      margin: 5px 0;
      vertical-align: middle;
    }
  }

  &__select-side {
    width: 100%;

    @include breakpoint($brake-sm) {
      display: inline-block;
      width: calc(100% - 250px);
      min-width: 200px;
      margin: 0;
      vertical-align: middle;
      padding-bottom: 5px;

      .site-content__sidebar & {
        width: 100%;
      }
    }
  }
}
