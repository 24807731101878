.error {
  @extend %paddington;
  padding-bottom: 50px;

  .property & {
    padding-top: 50px;
  }

  &__title {
    @include pko-text-size($font-size-h2-mobile, $line-height-h2-mobile, $font-size-h1, $line-height-h1);
    @include pko-line-height($font-size-h2-mobile, 28);
    @include pko-normalcase-in-uppercase;

    margin: 10px 0 15px;
    font-weight: bold;
    text-transform: uppercase;

    @include breakpoint($brake-sm) {
      @include pko-font-size(36);
    }
    @include breakpoint($brake-md) {
      @include pko-font-size(48);
    }
  }

  &__description {
    @include pko-text-size(14, 19.5, $font-size-t1, $line-height-t1);
  }
}
