.property-gallery {

  &__wrap {
    position: relative;
    @include standard-wrapper;
    margin-bottom: 0;

    img {
      display: none;
    }

    .owl-loaded img {
      display: block;
      margin: 0 auto;

      @include breakpoint($brake-sm) {
        display: table-cell;
      }
    }
  }

  &__picture {
    @include span(12);
  }

  &__spacer {
    @extend %paddington;
    display: none;
  }

  .owl-dots {
    position: relative;
    display: block;
  }

  .owl-dot {
    width: 9px;
    height: 9px;
    transition: $trans-hover;
    &:hover {
      background: $color-blue;
    }
  }

  .owl-item {
    transition: opacity $trans-hover ease, scale $trans-hover ease;

    img {
      width: 100%;
    }
  }

  &__item {
    width: 100%;
    display: table;

    @include breakpoint($brake-sm) {
      width: 45%;
      padding: 45px 20px 45px 0;
      vertical-align: middle;
      display: table-cell;
      border-right: 1px solid $color-gray-seventh;
    }

    @include breakpoint($brake-md) {
      width: 55%;
      padding: 0 20px 0 0;
    }
  }

  @include breakpoint($brake-sm) {
    overflow: hidden;

    &__picture {
      @include span(10);
    }

    &__spacer {
      display: block;
      @include span(1);
      height: 1px;
    }

    .owl-prev,
    .owl-next {
      display: block;
      color: $color-blue;
      transition: $trans-hover;
      width: auto;
      top: calc((100% - 115px) / 2);
      &:hover {
        color: $color-gray-main;
      }
    }

    .owl-prev {
      left: -15px;
    }

    .owl-next {
      right: -15px;
    }
  }

  @include breakpoint($brake-md) {

    &__picture {
      @include span(8);
    }

    &__spacer {
      @include span(2);
      height: 100px;

      &--left {
        padding-right: 0;
      }

      &--right {
        padding-left: 0;
      }
    }
  }
}
