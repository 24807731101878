.video-photo-slider {
  @include breakpoint($brake-md) {
    padding-right: 60px;
    padding-left: 60px;
  }
  &::before {
    display: none;
  }
  &__header {
    margin-bottom: 0;
  }
  .owl-item {
    position: relative;
    min-height: 1px;
    -webkit-backface-visibility: hidden;
  }
  .item {
    padding: 5px 0;
    height: 200px;
    width: 100%;
    @include breakpoint($brake-md) {
      &-caption {
        text-align: center;
      }
    }
    @media (orientation: portrait) {
      width: 100% !important; // important, zeby nadpisac inlinowe style z Owla
    }
  }
  .owl-item.active.center {
    height: 260px;
    z-index: 3;
    .item {
      animation: sliderAnimation;
      animation-duration: .5s;
      animation-fill-mode: forwards;
    }
    + .active {
      justify-content: flex-start;
    }
  }
  .owl-item:not(.center) {
    @include breakpoint($brake-md) {
      display: flex;
      justify-content: flex-end;
    }
    @include breakpoint($brake-sm) {
      .owl-video-play-icon, .item-caption {
        display: none;
      }
    }
  }
  .owl-stage-outer {
    position: relative;
    padding-top: 40px;
    padding-bottom: 0;
    -webkit-transform: translateZ(0);
    @include breakpoint($brake-md) {
      padding-bottom: 15px;
    }
    .owl-stage {
      height: 280px;
    }
  }
  .owl-video-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: #000;
  }
  .owl-video-tn, .item-img-with-caption {
    width: 100%;
    height: 100%;
  }
  .owl-video-tn, .item-img {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity .4s ease;
  }
  .owl-video-play-icon {
    &::before, &::after {
      @include pko-z-index(default);
      display: block;
      position: absolute;
      cursor: pointer;
    }

    &::before {
      background: rgba($color-blue, 0.8);
      top: 50%;
      left: 50%;
      width: 90px;
      height: 90px;
      content: '';
      margin: -45px 0 0 -45px;
      border-radius: 50%;

      .private-banking & {
        background: rgba($color-gold, 0.8);
      }
    }

    &::after {
      @extend %icomoon;
      font-size: 60px;
      content: $icon-play;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      color: $color-white;
      margin: -30px 0 0 -30px;
    }
  }
  .owl-video-playing {
    .owl-video-tn, .owl-video-play-icon {
      display: none;
    }
  }
  .owl-dot.active {
    .private-banking & {
      background: $color-gold;
    }
  }

  .owl-video-frame {
    iframe {
      height: 180px;
      @include breakpoint($brake-sm) {
        height: 250px;
      }
    }
  }
  .owl-nav.disabled {
    display: none;
    @include breakpoint($brake-md) {
      display: block;
    }
  }
  @include breakpoint($brake-md) {
    .owl-prev, .owl-next {
      top: 130px;
      .private-banking & {
        color: $color-gold;
      }
    }
    .owl-dot:last-child {
      margin-right: 0;
    }
  }
}

@keyframes sliderAnimation {
  0% {
    height:200px;
    width:100%;
    margin:0
  }
  to {
    margin:-30px 0 0 -40px;
    width:440px;
    height:260px;
  }
}

@media screen and (max-width:768px) {
  @keyframes sliderAnimation {
    0% {
      height:200px;
      width:100%;
      margin:0
    }
    to {
      margin:-30px 0 0 -40px;
      width:100%;
      height:200px;
      overflow-x:hidden
    }
  }
}

@media print {
  .video-photo-slider {
    display: none;
  }
}
