.poi-quick-actions {
  $root: &;
  color: $color-blue;

  @include pko-on-event() {
    color: $color-gray-icon;
    #{$root}__icon {
      color: $color-gray-icon;
    }
  }

  &__text {
    vertical-align: middle;
    display: inline-block;

    &--small {
      @include pko-font-size(13);
    }
  }

  &__icon {
    @include pko-font-size(26);
    color: $color-blue;
    vertical-align: middle;
    transition: color $trans-hover ease;
  }

  &.poi-appointment{
    cursor:pointer;
    margin-left: 15px;

    #poi-list-active &{
      margin-left: 0;
    }
  }

}
