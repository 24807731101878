.radio-buttons {
    width: 100%;
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	//justify-content: flex-start;
	//align-items: center;
	//align-content: stretch;
}

.radio-button {
    display: block;
    flex: 1 1 auto;
    margin-top: 14px;
    margin-right: 5%;

    @include breakpoint($brake-sm) {
        flex: 0 1 30%;
    }

    &:last-child {
        margin-right: 0;
    }

    &__input {} // hidden by default

    &__label {
        display: block;
        width: 100%;
        height: 64px;
        padding: 5px;
        border-radius: 3px;
        border: 1px solid $color-gray-third;
        line-height: 50px;
        text-align: center;
        background: $color-white;
        color: $color-blue;
        cursor: pointer;
    }

    &__label-text {
        display: inline-block;
        line-height: 1.2;
        @include pko-font-size(18);
        vertical-align: middle;
    }

    &__input:checked + &__label {
        background: $color-blue;
        color: $color-white;
    }
}
