.form {

  &__captcha {
    text-align: center;
    @extend %pko-clearfix;
    img {
      height: 37px;
      max-height: 100%;
    }
    label {
      display: block;
      margin-bottom: 8px;
      text-align: left;
    }
    input {
      width: 40%;
      float: right;
    }
    .js-captcha-reload {
      cursor: pointer;
    }
    &-reload {
      color: $color-blue;
      font-size: 32px;
      top: 2px;
      position: relative;
    }
    &-img-wrap {
      border: 1px solid $color-gray-third;
      float: left;
      height: 37px;
      text-align: center;
      width: 40%;
    }
    .form__error {
      text-align: left;
    }
  }

}
