.snippet-slider-big {
  @extend %paddington;
  @extend %pko-owl-arrows;
  position: relative;
  @include breakpoint($brake-sm) {
    &::before {
      content: '';
      display: block;
      height: 80px;
    }
  }
  clear: left;

  padding-bottom: $vertical-space-mobile;
  margin-bottom: $vertical-space-mobile;

  @include breakpoint($brake-sm) {
    padding-bottom: #{$vertical-space-desktop - 5px};
    margin-bottom: #{$vertical-space-desktop - 5px};
  }

  &__slides {
    position: relative;
    @include breakpoint($brake-sm) {
      padding: 0 70px;
    }

    &.js-slider img {
      @include breakpoint($brake-sm) {
        max-width: 100%;
        &.snippet-slider-big__slide-img--bound {
          max-width: 350px;
        }
      }
    }
  }

  &__slide {
    background: $color-white;
    @extend %pko-clearfix;
    &-img {
      max-width: 100%;
      height: auto;
      @include breakpoint($brake-sm) {
        width: 50%;
        float: right;
        margin-left: 20px;
      }


      &--bound {
        max-height: 350px;
        max-width: 350px;
        width: auto;
      }
    }
    &-text {
      text-align: left;
    }
    &-header {
      text-align: left;
      text-transform: uppercase;
      position: relative;
      @include pko-normalcase-in-uppercase;
      @include pko-z-index(default);
      @include pko-text-size($font-size-h2-mobile, $line-height-h2-mobile, $font-size-h1, $line-height-h1);
      @include pko-line-height($font-size-h2-mobile, 28);
      @extend %data-recommended;

      @include breakpoint($brake-sm) {
        margin-top: 0;
        padding-top: 40px;
      }
    }
  }
}
