.spread {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &-modal.modal--big-content {
    display: block;
    padding: 40px;

    .modal__content {
      padding-right: 0;

      & > h3:first-child {
        @extend %h3;
        margin-top: 0;
      }
    }

    .icon-infotip {
      cursor: pointer;
    }
  }

  &__section {
    padding: 30px 25px;
    text-align: center;
    border-bottom: 1px solid $border-color;

    h4 {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    li {
      text-align: left;
    }

    .cta,
    .btn-white, .btn-white-light {
      min-width: 180px;
    }

    &:last-child {
      border-bottom: 0 none;
      padding-bottom: 0;
    }
  }

  @include breakpoint($brake-sm-max) {
    &-modal.modal--big-content {
      .modal__content {
         margin-top: 0;

        & > h3:first-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @include breakpoint($brake-sm) {
    flex-direction: row;

    &-modal.modal--big-content {
      padding: 60px;
    }

    &__section {
      padding-bottom: 0;
      border-right: 1px solid $border-color;
      border-bottom: 0;
      padding-top: 0;
      width: 100%;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: 0 none;
        padding-right: 0;
      }

      .modal--big-content & {
        flex: 0 1 50%;
        width: 50%;
      }
    }
  }
}

.k2-ie-10-11 .spread-modal .modal__content {
  min-height: 0;
}
