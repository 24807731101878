@import 'poi/icon-list';

.overlay--poi {
  width: auto;
  left: -15px;
  right: -15px;
  @include breakpoint($brake-sm) {
    left: 0;
    right: auto;
    width: 100%;
  }
}

.poi {
  .icon-handicapped {
    margin-top: 45px;
    color: $color-blue;
    display: block;
    @include pko-font-size(50);
    margin-left: -11px;
  }

  .icon-cashless {
    @include pko-font-size(40);
    display: block;
    position: absolute;
    right: -10px;
    top: 135px;

    @include breakpoint($brake-sm) {
      right: 40px;
      top: 145px;
    }

    @include breakpoint(($brake-sm) ($brake-md-max)) {
      right: 20px;
    }

    &.without-handicapped {
      top: 90px;
    }
  }

  .poi-list__item-active .icon-cashless {
    top: 100px;

    &.without-handicapped {
      top: 45px;
    }
  }

  .ui-autocomplete {
    position: absolute;
    top: 0;
    left: 0;
    cursor: default;
    @include pko-z-index();
    border-radius: 0 0 5px 5px;
    padding: 0;
    list-style: none;
    background-color: $color-white;
    border: 1px solid $color-gray-second;
    border-top: 0;

    li {
      margin: 0;
      padding: 4px 15px;
      border-top: $color-gray-second;

      &:first-child {
        border: none;
      }

      &:hover,
      &.ui-state-focus {
        color: $color-blue;
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }

    width: 100% !important;
  }

  &__popup-filter {
    @include pko-font-size($font-size-t2-mobile);
    display: block;
    margin: 0 0 0 34px;
    width: 130px;
    -webkit-tap-highlight-color: rgba($color-black, 0);
  }

  // box with hours //////////////////////////
  &-modal {
    &__close {
      margin-top: 15px;
      display: inline-block;

      & + & {
        margin-left: 10px;
      }
    }

    &__floating-select {
      margin: 5px 0 12px;
      @include breakpoint($brake-xs-sm) {
        margin: 0;
        position: absolute;
        top: -8px;
        right: 0;
        min-width: 100px;
      }
    }

    &__body {
      margin: 0;
      padding: 0;
      list-style: none;

      // todo className
      li {
        position: relative;
        min-height: 32px;
      }

      &--big {
        @include breakpoint($brake-sm) {
          max-height: 500px;
          overflow: auto;
        }
      }

      &--days {
        @extend %pko-clearfix;
        margin-top: 10px;

        li {
          @include span(6);
        }
      }

      &--allhours {
        margin-bottom: 18px;
      }
    }

    &__hour {
      @extend %pko-clearfix;

      &-col {
        @include span(12);
        @include breakpoint($brake-xs-sm) {
          @include span(6);
        }

        label {
          float: left;
          margin-right: 15px;
          padding-top: 8px;
        }
      }

      &-select {
        @include span(6);
        width: 65%;
        margin-bottom: 20px;
      }
    }
  }
}

// .poi-route i poi-appointment przeniesiony do własnego komponentu poi-quick-actions

.poi-map-facility {
  width: 100%;
  height: 320px;
}

.poi-map-wrap-facility {
  width: 100%;
  @include breakpoint($brake-sm) {
    margin: 0;
  }
}

.poi-map {
  width: 100%;
  height: 320px;

  @include breakpoint($brake-sm) {
    height: 525px;
  }

  &__infowindow {
    font-family: $text-font-stack;

    &-title {
      @include pko-font-size(12);
      padding-top: 10px;
      margin-bottom: 10px;
      font-weight: 700;
      color: $color-black;

      &--small {
        margin-bottom: 2px;
      }
    }

    &-address {
      @include pko-font-size(12);
      font-style: normal;
      font-weight: 400;
      line-height: 14px;

      strong,
      b {
        color: $color-black;
      }

      p {
        margin: 8px 0;

        &:first-child {
          margin: 0 0 8px;
        }

        &:last-child {
          margin: 8px 0 0;
        }
      }
    }
  }

  &__localize-icon {
    $root: &;

    @include pko-font-size(36);
    color: $color-blue;

    &-container {
      background-color: $color-white;
      border: 2px solid $color-white;
      border-radius: 50%;
      box-shadow: 0 2px 6px rgba($color-black, 0.3);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      @include pko-on-event() {
        #{$root} {
          color: $color-gray-icon;
        }
      }
    }
  }
}

.poi-map-wrap {
  @extend %paddington;
  @include breakpoint($brake-sm-max) {
    padding: 0;
  }

  @include pko-give-margin(15px);
  @include breakpoint($brake-sm) {
    margin: 0;
  }
}

// marker-clusterer
.poi-cluster {
  border-radius: 50%;
  background-color: $color-blue;
}

.poi-snippet-under-map {
  @extend %paddington;
  padding-right: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  @include breakpoint($brake-sm) {
    padding-top: 46px;
    padding-bottom: 42px;
  }
}

.poi-filter-bottom {
  $root: &;
  min-height: 1px;

  &--wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__el {
    display: inline-block;
    margin-right: 20px;
    @include pko-font-size(15);
  }

  &__a {
    padding-left: 5px;
    @include pko-on-event() {
      color: $color-gray-icon;
      #{$root}__icon {
        color: $color-gray-icon;
      }
    }
  }

  &__icon {
    margin-left: -2px;
    color: $color-blue;
    vertical-align: middle;
  }
}

.poi-form {
  margin-bottom: 10px;
}

.poi-result,
.poi-load-more .cta {
  display: none;
  cursor: pointer;
}

.poi-load-more {
  @extend %paddington;

  .cta {
    margin-bottom: 25px;
    cursor: pointer;

    @include breakpoint($brake-sm) {
      margin-bottom: 50px;
    }
  }
}

.poi-list {
  &__active {
    &:empty {
      display: none;
    }

    @include pko-give-margin(15px);
    @include breakpoint($brake-sm) {
      margin: 0;
    }

    background-color: $color-gray-sixth;
    border-bottom: 1px solid $border-color;

    .poi-list__item {
      border-bottom: none;
    }

    @include breakpoint($brake-sm) {
      display: none;
    }
  }

  &__noresult {
    @extend %paddington;
    padding-top: 30px;
    position: relative;
    @include breakpoint($brake-sm) {
      padding-top: 50px;
    }

    &-inner {
      padding-bottom: 30px;
      border-bottom: 1px solid $color-gray-fifth;
      @include breakpoint($brake-sm) {
        padding-bottom: 50px;
      }
    }
  }

  &__results {
    @extend %paddington;
    @include breakpoint($brake-sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-results__list {

    address {
      font-style: normal;
      position: relative;
      padding-right: 70px;

      @include breakpoint($brake-sm) {
        padding-right: 85px;
      }
    }
  }

  &__address {
    font-style: normal;
  }

  &__ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  &__ul-active {
    list-style: none;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    @extend %paddington;
  }

  &__p-top {
    padding-right: 40px;
  }

  &__item-active {
    @extend %paddington;
    position: relative;
    font-size: 13px;
    line-height: 18px;
    padding-top: 27px;
    padding-bottom: 25px;

    p {
      margin: 18px 0 0;
    }

    p:first-of-type {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    strong,
    b {
      color: $color-black;
    }

    .poi-icon {
      top: 30px;
      right: 15px;
    }
  }

  &__item {
    $root: &;
    @extend %paddington;
    @include span(12);
    position: relative;
    font-size: 13px;
    line-height: 18px;
    border-bottom: 1px solid $color-gray-fifth;
    padding-top: 27px;
    padding-bottom: 25px;

    &:last-child {
      border-bottom: 0 none;
    }

    @include breakpoint($brake-sm) {
      @include span(4);
      border-bottom: none;
      padding-top: 50px;
      padding-bottom: 50px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 25px;
        bottom: 27px;
        right: 12.5px;
        height: calc(100% - 50px);
        width: 1px;
        background-color: $color-gray-fifth;
      }

      &:nth-child(3n) {
        &::after {
          display: none;
        }
      }
      &-inner {
        padding-right: 30px;
      }
    }

    @include breakpoint($brake-sm-max) {
      padding-left: 0;
      padding-right: 0;
    }

    p {
      margin: 18px 0 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    strong,
    b {
      color: $color-black;
    }

    &-header {
      font-size: 18px;
      line-height: 23px;
      padding-right: 25px;
      margin: 0;
    }

    &-address-name {
      margin: 0;
      padding-right: 40px;
      @include pko-font-size(17);
      color: $color-black;
    }

    .poi-list__toggle {
      margin-bottom: 18px;
    }

    .poi-icon {
      top: 30px;
      @include breakpoint($brake-sm) {
        top: 50px;
        right: 30px;
      }

      @include breakpoint($brake-md) {
        right: 50px;
      }
    }
  }

  &__showmap-btn{
    margin-right: 15px;
    margin-top:5px;
    display: inline-block;
  }

  &__appointment-btn {
    cursor: pointer;
    margin-top:5px;
    display: inline-block;
  //  white-space: nowrap;
  }

  &__icons {
    position: absolute;
    top: 0;
    right: 0;

    @include breakpoint($brake-sm) {
      right: 50px;
    }

    &--active {
      right: 10%;
    }
  }
}

.poi-list-facility {
  &__ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin-bottom: 20px;
  }

  &__p {
    margin: 0;
    color: $color-gray-second;

    &--black {
      color: $color-black;
    }

    &--red {
      color: $color-red;
    }
  }

  &__results {
    margin-top: 23px;
  }

  &__noresult {
    margin-top: 23px;
  }

  &__load-more {
    .cta {
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
}

.poi-filter {
  position: relative;

  &__main {
    @extend %break-from-paddington;
    @include breakpoint($brake-sm-max) {
      margin-left: 0;
      margin-right: 0;
    }
    list-style: none;
    margin-top: 15px;
    padding: 0;

    &-el {
      @extend %paddington;
      @include breakpoint($brake-sm-max) {
        padding-left: 0;
        padding-right: 0;
      }
      padding-bottom: 15px;

      &:last-child {
        padding-bottom: 12px;
      }

      position: relative;
      @include breakpoint($brake-sm) {
        @include span(4);
        padding-bottom: 23px;
        &:last-child {
          padding-top: 26px;
          padding-bottom: 0;
        }
      }
    }

    &-label {
      display: inline-block;
      padding: 0 0 6px;
    }
  }

  &__list {
    list-style: none;
    margin: 10px 0 0;
    padding: 0;

    &-action,
    &-more {
      display: block;
      margin: 0 0 0 34px;
      -webkit-tap-highlight-color: transparent;
    }

    &-action {
      width: 130px;
    }

    &-more {
      width: 150px;
      margin-top: 10px;
    }

    li {
      padding-bottom: 15px;
      position: relative;
      min-height: 58px;
    }

    //
    // scss-lint:disable QualifyingElement
    label.input-checkbox {
      padding-left: 34px;
      padding-right: 34px;
    }

    // scss-lint:enable QualifyingElement
  }

  &__reset {
    @include pko-font-size(13);
    margin-bottom: 25px;
    display: inline-block;
    margin-top: -20px;
  }

  &__link-space {
    display: inline-block;
    margin-bottom: 15px;
  }

  &_toggler {
    margin-bottom: 32px;
    margin-top: 5px;
    display: inline-block;
    cursor: pointer;

    .icon-plus,
    .icon-minus {
      pointer-events: none;
    }
  }

  &_toggler-text {
    pointer-events: none;
  }

  &__localize {
    $root: &;
    display: none;
    color: $color-blue;
    cursor: pointer;
    @extend %text-default-2;
    -webkit-tap-highlight-color: rgba($color-gray-main, 0);
    @include pko-on-event() {
      color: $color-gray-main;
      #{$root}-icon {
        color: $color-gray-icon;
      }
    }

    &-icon {
      font-size: 30px;
      color: $color-blue;
      position: relative;
      vertical-align: -8px;
      margin: 0 -4px 0 -6px;
    }

    @include breakpoint($brake-sm) {
      @include pko-z-index(default);
      // position: absolute;
      // right: 23px;
      // top: 30px;
    }

    &--facility {
      @include breakpoint($brake-sm) {
        position: absolute;
        right: 0;
        top: 28px;
      }
    }
  }

  &__autocomplete-wrap {
    position: relative;
  }

  .ui-menu-item {
    height: 37px;
    line-height: 30px;
  }

  .ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

}

.poi-filter-facility {

  &__main {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    @include breakpoint($brake-sm) {
      margin: 0 -8px;
    }

    &-el {
      padding-bottom: 15px;

      &:last-child {
        padding-bottom: 12px;
      }

      position: relative;
      @include breakpoint($brake-sm) {
        padding: 0 8px 30px;
        @include span(4);
        &:last-child {
          padding-top: 26px;
          padding-bottom: 0;
        }
      }
    }

    &-label {
      display: inline-block;
      padding: 0 0 6px;
    }
  }

}

// accept points search //////////////////////////
.poi-accept-points {
  @extend %pko-clearfix;
  @extend %paddington;

  margin-bottom: 30px;
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 27px;
  padding-bottom: 15px;
  border-radius: 3px;

  border: 1px solid $border-color;
  box-shadow: 3px 3px 0 rgba($color-black, 0.05);

  background-color: $color-white;
  position: relative;

  @include breakpoint($brake-sm) {
    padding: 27px 30px 22px;
    margin-left: 0;
    margin-right: 0;
  }

  &__close {
    position: absolute;
    right: 7px;
    cursor: pointer;
    top: 6px;

    span {
      font-size: 32px;
      color: $color-blue;
      transition: color $trans-hover;
      @include pko-on-event() {
        color: $color-gray-main;
      }
    }

    @include breakpoint($brake-sm) {
      right: 7px;
    }
  }

  &__title {
    @extend %text-default-1;
    margin-top: 0;
    margin-bottom: 27px;
    padding-top: 0;
    padding-right: 20px;
  }

  &__results {
    li {
      position: relative;
      @include span(12);
      display: inline-block;
      min-height: 32px;
    }

    &:not(:empty) {
      margin-top: 15px;
    }
  }

  &__input {
    margin-bottom: 15px;
  }

  @include breakpoint($brake-sm) {

    &__results {
      clear: left;

      li {
        @include span(6);

        &:nth-child(2n-1) {
          clear: left;
        }
      }
    }
    &__input {
      @include pko-z-index(default);
      position: relative;
      margin-bottom: 15px;
      width: 48%;
    }
  }

  @include breakpoint($brake-lg) {

    &__results {
      li {
        @include span(4);

        &:nth-child(2n-1) {
          clear: none;
        }

        &:nth-child(3n-2) {
          clear: left;
        }
      }
    }
  }
}

// poi-filter-top ///////////////////////////
@mixin right-poi-filter-border() {
  &::before {
    content: '';
    height: 60px;
    width: 1px;
    background: $border-color;
    display: block;
    float: right;
  }
}

.poi-filter-top {
  @extend %break-from-paddington;
  @include breakpoint($brake-sm-max) {
    margin: 0;
  }

  @include breakpoint($brake-sm) {
    margin-bottom: 20px;
  }

  &--iko {
    margin-left: 0;
    margin-right: 0;
  }

  .form {
    @extend %pko-clearfix;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__el {
    position: relative;
    @extend %paddington;
  }

  &__label,
  &__link {
    display: block;
    @include pko-font-size($font-size-t2); // todo zmienny rozmiar?

    &--more {
      display: block;
      margin-top: 10px;
      padding-left: 30px;
    }
  }

  @include breakpoint($brake-sm) {
    &__el {
      @include span(6);
      padding-top: 2px;
      height: 60px;

      &:nth-child(2) {
        height: 90px;
      }

      &:nth-child(2n+3) {
        margin-top: -30px;
      } 

      &:nth-child(2n-1) {
        @include right-poi-filter-border;
      }

      &:last-child {
        &::before {
          height: 40px !important;
        }
      }
    }

    &__link {
      padding-left: 30px;
    }

    .poi-icon {
      position: relative;
      float: right;
      margin: 0 22px 0 30px;
    }
  }

  @include breakpoint($brake-md) {
    &__el {
      @include span(4);
      height: 80px;

      &:nth-child(2) {
        height: 80px;
      }

      &:nth-child(2n+3) {
        margin-top: 0;
      } 

      &:nth-child(2n-1) {
        &::before {
          display: none;
        }
      }

      &:nth-child(4) {
        margin-top: -20px;

        &::before {
          height: 120px!important;
        }
      }

      &:nth-child(3n-1),
      &:nth-child(3n-2) {
        @include right-poi-filter-border;

        &::before {
          height: 80px;
        }
      }
    }
  }

  @include breakpoint(pko-em-size(1080)) {
    &__el {
      height: 90px;
      
      &:nth-child(2n+3) {
        margin-top: 0;
      } 

      &:nth-child(4),
      &:nth-child(6) {
        margin-top: -30px;
      }

      &:nth-child(6) {
        height: 120px;
      }

      &:last-child {
        margin-top: -60px;
      }

      &:nth-child(2) {
        &::before {
          height: 90px;
        }
      }

      &:nth-child(4),
      &:nth-child(5) {
        &::before {
          height: 60px!important;
        }
      }

      &:nth-child(3n-2) {
        &::before {
          height: 60px;
        }
      }
    }
  }

  @include breakpoint($brake-lg) {
    &__el {
      height: 60px;

      &:nth-child(2),
      &:nth-child(6) {
        height: 60px;
      }

      &:nth-child(2n+3) {
        margin-top: 0;
      } 

      &:nth-child(4),
      &:nth-child(6) {
        margin-top: 0;
      }

      &:nth-child(3n-1),
      &:nth-child(3n-2) {
        @include right-poi-filter-border;

        &::before {
          height: 60px;
        }
      }
    }

    &__link {
      &--atm,
      &--more {
        display: inline;
      }

      &--more {
        padding-left: 18px;
      }
    }
  }
}

.poi-choose-facility {
  width: 100%;

  @include breakpoint($brake-sm) {
    width: 670px;
  }

  &__header {
    color: $color-black;
    margin-bottom: 20px;
  }
}
