.social {

  font-size: 0;
  height: 19px;

  &-icon {
    @include pko-font-size(16);
    display: inline-block;
    vertical-align: top;
    color: $color-gray-icon;
    margin-right: 18px;

    &--fb {
      @include pko-font-size(18);
    }
    &--rss {
      padding-top: 1px;
    }
    &--yt {
      margin-top: -12px;
      margin-bottom: -11px;
      font-size: 2.8rem;
    }
    &--twitter {
      @include pko-font-size(19);
      margin-top: -1px;
    }
    &--linkedin {
      padding-top: 1px;
    }
    &--goldenline {
      padding-top: 1px;
      margin-right: 0;
    }
    @include pko-on-event() {
      color: $link-color;
    }
  }
}
