//Brak BEM ponieważ napisujemy klasy vendora

.smart-app-banner:not(.wifi) {
  .smartbanner-pko {
    background: #F2F2F2;
    box-shadow: none;
    border-bottom: 1px solid $color-gray-fifth;
    font-family: $text-font-stack;
    font-weight: 300;

    @include breakpoint($brake-sm) {
      &.smartbanner-desktop {
        position: relative;
      }
    }
  }

  .smartbanner-title {
    display: block;
    height: auto;
    white-space: normal;
    color: black;
    font-weight: 200;

    @include breakpoint($brake-md) {
      font-size: 1.25rem;
      margin-bottom: 5px;
    }
  }

  .smartbanner-close {
    margin: 0;
    &:hover {
      cursor: pointer;
    }
  }

  .smartbanner-container {
    @extend %paddington;
    @include breakpoint($brake-sm) {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      justify-content: space-between;
    }


    .smartbanner-desktop-button {
      //@extend %cta;
      clear: left;
      float: left;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .smartbanner-info {
    @include breakpoint($brake-sm) {
      width: auto;
      font-weight: 200;
    }

    @include breakpoint($brake-md) {
      font-size: 0.9375rem;
    }

    div, span {
      text-shadow: none;
    }
  }

  .smartbanner-icon {
    margin-left: 20px;
    width: 35px;
    height: 35px;

    @include breakpoint($brake-sm) {
      height: 50px;
      width: 50px;
      margin-left: 0;
      margin-bottom: 5px;
      margin-right: 20px;
    }
  }

  .smartbanner-close {
    text-shadow: none;
    background: transparent;
    box-shadow: none;
    &:before {
      font-size: 30px;
    }
  }

  .smartbanner-button {
    background: transparent;
    border: none;
    box-shadow: none;
    color: $link-color;
    @include breakpoint($brake-sm) {
      position: static;
    }

    &-text {
      background: transparent;
      font-family: $text-font-stack;
      font-weight: 300;
      padding: 0;
    }
  }
}

.smartbanner.smartbanner-pko.smartbanner-desktop {
  display: none;
  height: 0px;
  overflow: hidden;

  @include breakpoint($brake-md) {
    display: block;
  }

  &.hidden {
    display: none;
    overflow: hidden;
  }
}
