.product-menu {

  display: none;

  @include breakpoint($brake-sm) {
    &.visible {
      display: block;
    }
  }

  @include pko-z-index('product-menu');
  position: fixed;
  background: $color-white;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid $border-color;
  height: 55px;

  &__wrapper {
    @extend %paddington;

    @include breakpoint($brake-xl) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__list {
    @extend %paddington;
    list-style: none;
    margin: 0 0 -1px;
    float: left;
    height: 55px;
    line-height: 55px;
    display: flex;
    flex-wrap: nowrap;

    li {
      display: block;
      float: left;
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }

      a {
        @include pko-font-size($font-size-t2);
        color: $text-color;
        display: inline-block;
        height: 55px;
        white-space: nowrap;
      }
      &.active a {
        color: $color-blue;
        border-bottom: 1px solid $color-blue;
      }
    }
  }

  &__info-text {
    margin: 0 0 -1px;
    padding: 0 25px 0 20px;
    float: left;
    height: 55px;
    line-height: 55px;
    @include pko-font-size($font-size-t2);
  }

  &__button {
    &-wrap {
      float: right;
      display: inline-block;
      @extend %paddington;
      padding-top: 8px;
      padding-bottom: 8px;

      // Dodatkowy button, scrollujący do kalkulatora pożyczki
      &--extra-button {
        display: none;
        padding-right: 0;

        @include breakpoint($brake-md) {
          display: inline-block;
        }
      }
    }
  }

  &__deposit-counter {
    float: right;
    position: relative;
    top: 10px;

    .deposit-counter-container {
      &__text {
        @include pko-font-size($font-size-t2);
      }

      &__badge {
        @include pko-font-size(24);
      }
    }
  }
}

.js-product-menu-section {
  & .site-content__module-header,
  & form:not(.calc-loan__popup-form) h2 {
    // hash offset respecting fixed menu height
    position: relative;
    @include breakpoint($brake-sm) {
      &::before {
        content: '';
        display: block;
        height: 80px;
        margin-top: -80px;
      }
    }
  }
}

// co to za szajs
// scss-lint:disable IdSelector
#product-form {
  // overwrite default notification styles because it must be fixed in this case
  @include breakpoint($brake-sm) {
    position: fixed !important;
    top: 8px !important;
    transform: none;
    margin-top: 0;
    margin-right: -40px !important;
  }
  .form__title {
    margin-top: 0;
  }
}

// scss-lint:enable IdSelector
