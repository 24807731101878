.site-content-leasing {
  &__module-bordered {
    border-bottom: 1px solid $color-gray-fifth;
  }
  &__gray-section {
    background: $color-gray-eight;
  }
  &__gray-section-desktop {
    @include breakpoint($brake-md) {
      background: $color-gray-eight;
      .leasing-offers__comparison-wrap {
        border: none;
      }
    }
  }
  &__form {
    padding: 30px 15px;
    @include breakpoint($brake-lg) {
      margin-bottom: 10px;
    }
    .form {
      &__submit {
        width: 100%;
      }
    }
    @include breakpoint($brake-md-lg) {
      padding: 50px 15px;
      .form {
        display: flex;
        flex-flow: row nowrap;
        &__body {
          display: flex;
          flex-flow: column wrap;
          max-height: 420px;
          height: 420px; // fix for IE flex bug
        }
        &.validated .form__body {
          height: 540px;
          max-height: 560px;
        }
        &__row--text {
          width: 400px;
          padding-right: 50px;
        }
        &__row--textarea, &__row--select {
          padding-right: 50px;
        }
        &__row--radiobox {
          max-width: 400px;
          margin: 5px 0 19px;
        }
        &__row--info-with-contraint-margin {
          margin: 0;
        }
        .description-for-data-administration-info {
          font-size: 15px;
        }
        &__submit {
          width: 50%;
        }
        .text {
          flex: 0 0 33.333%;
          padding-right: 50px;
          &-enlarge {
            font-size: 17px;
          }
        }
      }
    }
  }
  .site-content__sidebar {
    border: none;
  }
  &__car-gallery {
    @include span(6);

    @include breakpoint($brake-md-max) {
      @include span(12);
      padding: 0;
      &.article-gallery {
        margin-bottom: 10px;
      }
    }
    img {
      display: inline-block;
      max-width: 100%;
    }
    .leasing-slider {
      position: relative;
      margin-top: 30px;
      @include breakpoint($brake-md) {
        padding: 0 50px;
        &__featured, .leasing-slider-img {
          position: relative;
          padding-bottom: 47.25%;
          & > img {
            //max-width: calc(100% - 130px);
            max-height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            height: auto;
            width: auto;
            padding: 0 5px;
          }
        }

        .owl-stage-outer {
          padding-bottom: 0;
        }
      }
      &__featured {
        @include breakpoint($brake-md-max) {
          display: none;
        }
      }
    }
    .owl-prev, .owl-next {
      color: $color-blue;
      @include breakpoint($brake-md) {
        display: block;
      }
    }
    .owl-prev {
      left: 0;
    }
    .owl-dot {
      padding: 7px;
      z-index: 100;
    }
  }
  &__car-description {
    @include span(6);
    @include breakpoint($brake-md-max) {
      @include span(12);
    }
  }
  .link-back {
    display: block;
    @include breakpoint($brake-md) {
      margin-bottom: 30px;
    }
  }
  .property-slider__item-data {
    & > span {
      &:first-child {
        @include breakpoint($brake-lg) {
          width: 33%;
        }
      }
      &:nth-child(2) {
        color: $color-black;
      }
      @include breakpoint($brake-md-max) {
        @include span(12);
      }
    }
  }
  .property-slider__item-price {
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    small {
      font-size: 14px;
    }
  }
  .property-slider__item-price-excess {
    font-weight: bold;
  }
  .files-download__link {
    display: block;
    font-size: 15px;
    color: $color-blue;
    margin-top: 30px;
  }
  .add-to-comparison {
    font-size: 15px;
    vertical-align: top;
    &-single-offer {
      position: absolute;
      opacity: 0;
    }
  }
}

