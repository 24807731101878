.calc__badges {
  &-container {
    display: none;
    color: $color-black;

    @include breakpoint($brake-md) {
      display: flex;
      margin-bottom: 45px;
      flex-direction: row;
    }
  }

  &-item {
    display: flex;
    @include pko-font-size(20);

    @include breakpoint($brake-md) {
      margin-right: 65px;

      &:last-child {
        margin-right: 0;
      }
    }

    .icon-check2 {
      display: inline-block;
      color: $color-blue;
      @include pko-font-size(36);
      position: relative;
      top: -7px;
      margin-right: 10px;
    }
  }
}
