.ui-widget-content {
	.ui-button-clear {
		border-radius: 8px 0;
		padding: 5px 20px;
		display: inline-block;
		background-color: $color-blue;
		margin: 7px 20px;
		color: $color-white;
		cursor: pointer;
		transition: background-color 250ms;
		&:hover {
			background-color: $color-red;
		}
	}
}