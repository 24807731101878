.landing-content {

  /*
   -------------    -------------
  |    TEXT     |  |    IMAGE    |
   -------------    -------------
   ------------------------------
  |           SNIPPET            |
   ------------------------------
  */

  $root: &;

  &--v6 &__image {
    @extend %paddington;
  }

  &--v6 &__snippet-inner {
    background: linear-gradient(to right, $lp-gradient-blue-1 0%, $lp-gradient-blue-2 100%) !important;
  }

  &-subtext--v6 {
    margin-top: 0;
  }

  // tablet and up
  @include breakpoint($brake-sm) {

    &--v6 &__info {
      @include span(12);
    }

    &--v6 &__copy {
      @include span(6);
    }

    &--v6 &__image {
      @include span(6);
      position: relative;
      img {
        max-width: 100%;
        right: 0;
      }
    }

    &--v6 &__snippet {
      @include span(12);
      margin-top: $vertical-space-desktop / 2;
      margin-bottom: $vertical-space-mobile;

      &-inner {
        background: linear-gradient(to right, $lp-gradient-blue-1 0%, $lp-gradient-blue-2 100%) !important;
      }
    }
  }

  // tablet screen and up
  @include breakpoint($brake-sm) {

    &--v6 &__image {
      img {
        margin-top: -20%;
      }
    }
    &--v6 &__snippet {
      @include span(12);
    }
  }

  // laptop screen and up
  @include breakpoint($brake-md) {

    &--v6 &__copy {
      @include span(7);
    }

    &--v6 &__image {
      @include span(5);
      left: -30px;
    }

    &--v6 &__snippet {
      @include span(12);

      .form__body {
        float: left;
      }
      .form__body--first {
        @include span(4);

        li {
          float: left;
          margin-top: 0;

          &:nth-child(1),
          &:nth-of-type(2) {
            width: 100%;
          }

          &:nth-of-type(3) {
            width: 49%;
          }

          &:nth-of-type(4) {
            width: 49%;
            margin-left: 2%;
          }
        }
      }
      .form__body--second {
        margin-left: 50px;
        @include span(7)
      }
      .form__body--last {
        clear: both;
      }
      .form__row--header-agreement {
        margin-top: 0;
      }
      .form__row--radiobox {
        width: 50%;
        display: inline-block;
      }
      .form__row--radiobox-agreement {
        margin-bottom: 20px;

        + .form__row--radiobox {
          margin-top: 10px;
        }
      }
      .form__row--submit {
        margin-top: 0;
      }
    }
    &--v6 &__image img {
      margin-top: -10%;
    }
    &--v6 {
      margin-top: -30px;
    }
  }

}
