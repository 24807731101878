$login-layer-button-margin: 30px;
$login-layer-button-height: 210px;

.login-layer {
  $root: &;

  //@extend %pko-clearfix;
  display: none;
  padding: 45px 0 10px;
  clear: both;

  .header--sticky & {
    margin-top: 0;
  }

  &__logo {
    // reszta w svg w templatce
    &--1 #{$root}__big-image {
      width: 65px;
      svg {
        height: 25px;
      }

      @include breakpoint($brake-sm) {
        width: 86px;
        svg {
          height: 33px;
        }
      }
    }
    &--2 #{$root}__big-image {
      width: 126px;
      svg {
        height: 25px;
      }

      @include breakpoint($brake-sm) {
        width: 167px;
        svg {
          height: 33px;
        }
      }
    }
    &--3 #{$root}__image {
      width: 85px;
    }
    &--4 #{$root}__image {
      width: 80px;
    }
    &--5 #{$root}__image {
      width: 77px;
    }
    &--6 #{$root}__image {
      width: 60px;
    }
    &--7 #{$root}__image {
      width: 90px;
    }
    &--8 #{$root}__image {
      width: 90px;
    }
    &--9 #{$root}__image {
      width: 90px;
      margin-top: 5px;
    }
    &--10 #{$root}__image {
      width: 70px;
    }
    &--11 #{$root}__image {
      width: 60px;
      margin-top: 5px;
    }
    &--12 #{$root}__image {
      width: 110px;

      @include breakpoint($brake-sm) {
        width: 84px;
      }

      @include breakpoint($brake-md) {
        width: 110px;
      }
    }

    &--13.login-layer__logo--13 { // Zwiększenie Specificity
      margin-bottom: 25px;

      @include breakpoint($brake-sm) {
        margin-bottom: 0;
      }
    }

    &--13 #{$root}__image {
      width: 78px;
      margin-left: 5px;

      svg {
        max-height: 34px;
      }

      @include breakpoint($brake-sm) {
        width: 96px;
        margin-left: auto;

        svg {
          max-height: 41px;
        }
      }
    }
  }
  &__big {
    @extend %text-default-2;
    color: $color-gray-second;
    display: block;
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 20px 15px;
    margin-bottom: 10px;
    margin-top: 0;
    margin-right: 0;
    background-position: center top 20px;
    float: left;
    width: 100%;
    min-height: 70px;
    text-align: left;
    position: relative;
  }
  &__big-image {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    max-width: 50%;
  }
  &__description {
    display: none;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 30px;
  }
  &__actions {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    span {
      color: $link-color;
      @include pko-font-size(15);
    }
  }
  &__link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__small {
    float: left;
    width: 50%;
    height: 35px;
    margin: 10px 0;
    position: relative;
    text-align: center;
  }
  &__image {
    display: inline-block;
    svg {
      max-width: 75px;
      max-height: 30px;
    }
  }
  &__neon-container {
    display: block;
    padding: 0;
    margin: 10px 0 20px;
    float: left;
    width: 100%;
    height: auto;
    text-align: center;

    .login-layer--no-neon & {
      display: none;
    }
  }
  &__neon {
    position: static;
    top: auto;
    transform: none;
    left: auto;
    right: auto;
  }

  &.active {
    display: block;
  }

  &__close {
    display: block;
    position: absolute;
    right: 30px;
    top: 5px;
  }

  &__toggle-other { // żeby priorytet nad button podnieść
    @extend %btn-white;
  }

  #{$root} &__toggle-other { // żeby priorytet nad button podnieść
    display: inline-block;
    width: 100%;
    padding: 9px 15px;
    font-size: 15px;
  }

  &__show-first-login {
    #{$root}__first-login {
      display: block;
      clear: both;
      margin-top: 23px;
      margin-left: 1px;
      margin-bottom: 20px;
      font-size: 14px;
    }
  }

  @include breakpoint($brake-sm-max) {
    &__small:nth-child(1) {
      clear: left;
    }
  }

  @include breakpoint($brake-sm) {
    padding: 50px 0 60px;
    position: relative;

    &__close {
      right: 0;
      top: -26px;
    }

    &__title {
      margin-bottom: 41px;
    }

    .header--sticky & {
      padding-top: 15px;
    }

    &__description {
      display: block;
      margin-top: 0;
      margin-bottom: 40px;
    }
    &__big-buttons {
      width: 66%;
      float: left;

      #{$root}--no-neon & {
        width: 100%;
      }
    }
    &__big {
      @include pko-font-size($font-size-t2);
      float: left;
      width: calc(50% - (#{$login-layer-button-margin} / 2));
      height: $login-layer-button-height;
      padding: $login-layer-button-margin $login-layer-button-margin 50px;
      margin-bottom: 30px;
      margin-right: $login-layer-button-margin / 2;
      background-position: center center;
      border-bottom: 1px solid $border-color;

      &:nth-child(2n) {
        margin-right: 0;
        margin-left: $login-layer-button-margin / 2;
      }
    }
    &__big-image {
      position: static;
      left: auto;
      top: auto;
      transform: none;
      height: 35px;
      max-width: 200px;
      margin: 0 0 30px;
    }
    &__description {
      display: block;
    }
    &__title {
      margin-top: 0;
      margin-bottom: 30px;
    }
    &__actions {
      position: absolute;
      right: auto;
      left: $login-layer-button-margin;
      top: auto;
      bottom: $login-layer-button-margin;
      transform: none;

      span {
        color: $link-color;
      }
    }
    &__neon-container {
      width: 33%;
      height: $login-layer-button-height;
      margin: 13px 0 20px;
      position: relative;
    }
    &__neon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      padding: 0 0 0 90px;
    }
    &__small {
      position: relative;
      border: none;
      height: 100px;
      width: 20%;
      margin: 0;
    }
    &__image {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;

      svg {
        max-width: 110px;
        max-height: 40px;
      }
    }
    &__big,
    &__small {
      &:hover {
        border: 1px solid $border-color-hover;
        box-shadow: 0px 0px 14px -4px $border-color-hover;
      }
    }
    .js-hidden-area {
      padding-top: 35px;
    }

    #{$root} &__toggle-other {
      width: auto;
      float: left;
    }

    &__show-first-login {
      #{$root}__first-login {
        @include pko-font-size(15);
        display: inline-block;
        border-top: none;
        height: 40px;
        position: absolute;
        left: 66%;
        transform: translateX(-100%);
        top: 339px;
      }
    }
    &__show-first-login#{$root}--no-neon {
      #{$root}__first-login {
        left: 100%;
        white-space: nowrap;
      }
    }
  }

  @include breakpoint($brake-md) {
    padding-top: 87px;

    &__show-first-login {
      #{$root}__first-login {
        top: 383px;
      }
    }

    &__close {
      top: 82px;

      .header--sticky & {
        margin-top: -30px;
      }
    }
  }
}
