.inbox-message-element {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid $border-color;
  @include pko-text-item;
  &__image {
    margin-bottom: 10px;
    img {
      display: block;
      width: 85px;
      @include breakpoint($brake-sm) {
        width: auto;
        max-width: 30%;
        float: left;
        padding-right: 30px;
      }
      @include breakpoint($brake-md) {
        max-width: 40%;
      }
    }
  }

  &__title {
    @include pko-font-size(16);
    font-weight: bold;
    padding-bottom: 5px;
    @include breakpoint($brake-sm) {
      padding-top: 20px;
    }
    @include breakpoint($brake-md) {
      padding-top: 10px;
    }
  }
  &__description {
    @include breakpoint($brake-md) {
      margin-left: 40%;
    }
  }

  &__content p {
    padding-bottom: 5px;
    @include breakpoint($brake-sm) {
      padding-bottom: 20px;
    }
  }

  &__disclaimer {
    margin-left: 20px;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
