.login-layer-neon {
  display: inline-block;
  text-align: center;

  &__image {
    display: inline-block;
    max-width: 400px;
    max-height: 128px;
    margin-bottom: 17px;

    img {
      max-width: 100%;
      max-height: 100px;
      @include breakpoint($brake-sm) {
        max-height: 128px;
      }
    }
  }
  

  &__title {
    @include pko-font-size(24);
    margin-bottom: 11px;
    font-weight: bold;
    line-height: 1.2;
    color: $color-black;
  }

  &__text {
    @include pko-font-size(15);
    line-height: 1.4;
    margin-bottom: 10px;
  }

  &__buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: space-between;
    align-items: flex-start;
  }

  &__button-more {
    @include breakpoint($brake-sm) {
      @include pko-font-size(15);
    }
    margin: 0 15px;
    white-space: nowrap;
  }

  &__button-submit {
    @include breakpoint($brake-sm) {
      @include pko-font-size(15);
    }
    color: $color-red;
    margin: 0 15px;
    white-space: nowrap;
  }
}