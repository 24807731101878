.visa-page {
  .site-content {
    &__columns {
      @include breakpoint($brake-sm-max) {
        padding-bottom: 35px;
        &.mobile--no-padding {
          padding: 0;
        }
      }
    }
    &__main {
      width: 100%;
      float: none;
      @include breakpoint($brake-sm-max) {
        margin: 40px 0;
      }
    }
    &__block:last-child {
      @extend .site-content__block;
    }

    &__block__actions {
      .cta {
        margin: 0 20px;
        padding: 8px 60px;
        cursor: pointer;

        .icon {
          color: inherit;
          vertical-align: text-bottom;
          margin-left: 10px;
        }

        &--reverse {
          background-color: transparent;
          border: 1px solid $border-color;
          color: $color-red;

          .icon {
            margin-left: 0;
            margin-right: 10px;
          }

          &:hover {
            border-color: $color-gray-second;
          }
        }

        @include breakpoint($brake-sm-max) {
          padding-left: 20px;
          padding-right: 20px;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }

  .site-tabs {
    $root: &;

    text-align: center;
    margin-top: 0;
    position: relative;

    // navigation
    &__item {
      float: none;
      text-align: center;
      margin: 0 30px;
      background-color: transparent;
      border: none;
      padding: 0;
      position: relative;
      z-index: 1;

      .box--rounded {
        font-size: 26px;
        line-height: 44px;
        padding: 25px 90px;
        text-transform: uppercase;

        @include breakpoint($brake-sm-max) {
          font-size: 20px;
          line-height: 30px;
        }
      }
      .circle {
        border-radius: 100%;
        display: block;
        margin: 50px auto 10px;
        background-color: $color-gray-third;
        color: $color-white;
        padding: 10px;
        transition: all 250ms linear;
        height: 42px;
        width: 42px;
        line-height: 24px;
        text-align: center;

        @include breakpoint($brake-md) {
          margin-top: 70px;
        }
        @include breakpoint($brake-sm-max) {
          margin-top: 30px;
        }
      }

      .icon {
        display: block;
        text-align: center;
        font-size: 70px;
        color: inherit;
        margin-bottom: 10px;

        @include breakpoint($brake-sm-max) {
          font-size: 50px;
        }
      }

      &.active {
        color: $color-black;

        .box--rounded {
          background-color: transparent;
          border-color: $color-blue;
          color: $color-blue;
        }

        .circle {
          background-color: $color-blue;
        }

        &:hover, &:focus {
          color: $color-black !important;
          .box--rounded {
            color: $color-blue !important;
          }
        }
      }
      &:after {
        display: none;
      }

      &:hover {
        .circle {
          background-color: $color-blue;
        }
      }
    }

    @include breakpoint($brake-sm-max) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 35px;

      &__item {
        max-width: 300px;
        width: 97%;
        padding: 20px 0;
        margin: 0;

        .box--rounded {
          padding: 20px 0;
        }
      }
    }

    &--dotted {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      position: relative;
      flex-wrap: nowrap;
      padding: 0;

      .site-tabs__item {
        flex: 1;
      }

      &::after {
        content: '';
        position: absolute;
        top: 70px;
        left: calc(50% + 18px);
        transform: translateX(-50%);
        width: 66.66%;
        height: 6px;
        background: url('../img/visa/dot.png') top left repeat-x;
        background-size: contain;
      }

      @include breakpoint($brake-md) {
        width: 60%;

        &::after {
          top: 88px;
        }

        @media all and (-ms-high-contrast:none) {
          width: 54%;
          &::after {
            width: 70%;
          }
        }
      }

      @include breakpoint($brake-sm-max) {
        .site-tabs__item {
          padding: 0;
        }
        padding: 0;
        &::after {
          width: 65%;
          top: 50px;
        }
      }
    }

    &-select-wrapper {
      @include breakpoint($brake-sm-max) {
        display: none;
      }
    }

    &-content {
      .site-content__block,
      .site-content__module {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .section--flex {
    display: flex;

    &__item {
      flex: 0 1 50%;
    }

    @include breakpoint($brake-sm-max) {
      flex-wrap: wrap;

      &:not(.section--flex--no-mobile-reverse) {
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
      }

      &__item {
        flex: 0 1 100%;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .box--rounded {
    border: 1px solid $border-color;
    border-radius: 10px;
    padding: 45px 30px;
  }

  .table-wrapper {
    margin: 0;
  }

  .text {
    .table {
      border: 0;
      tr:nth-child(even) {
        background-color: transparent;
      }
      th {
        border: 0;
        text-align: left;
        padding-left: 0;
        padding-right: 20px;
        font-weight: normal;
        color: $color-black;
        @include pko-font-size($font-size-t1);
      }
      td {
        border: 0;
        border-top: 1px solid $border-color;
        background-color: transparent;
        text-align: left;
        padding-left: 0;
        padding-right: 0;
        @include pko-font-size($font-size-t1);
      }
    }
  }

  .boxes {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 40px 0;

      &__item {
        flex: 0 0 48%;
        width: 48%;
        margin-bottom: 40px;
        display: flex;

        h2 {
          text-align: center;
        }
        h4, p, ul, li {
          margin-bottom: 5px;
          margin-top: 5px;
        }
        small {
          line-height: 1.2;
        }

        .box--rounded {
          display: flex;
          width: 100%;
        }

        .image {
          flex: 0 0 100px;
          height: 100px;
          margin-right: 30px;
          margin-bottom: 20px;
          background-position: left top;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      &--inline {
        flex-wrap: nowrap;
        margin-top: 0;
        margin-bottom: 0;

        .boxes__item {
          flex: 0 0 28%;
          width: 28%;
          display: block;
          position: relative;

          &::after {
            content: $icon-arrow-right;
            display: block;
            position: absolute;
            right: -20%;
            top: 100px;
            width: 31px;
            height: 31px;
            border-radius: 100%;
            border: 1px solid $color-gray-second;
            text-align: center;
            line-height: 30px;
            font-family: $icon-font-stack;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }

      @include breakpoint($brake-md) {
        &__item {
          .image {
            flex: 0 0 120px;
            margin-right: 30px;
            width: 120px;
          }
        }
      }

      @include breakpoint($brake-sm-max) {
        margin: 35px 0;
        &__item {
          display: flex;
          flex: 0 0 100%;
          margin-bottom: 20px;

          .box--rounded {
            border: 0;
            padding: 0;
          }

          .image {
            flex: 0 0 45px;
            height: auto;
            margin-bottom: 0;
            background-position: left 10px;
          }
        }

        &.mobile--full-width {
          .boxes__item {
            flex-wrap: wrap;
            .image {
              min-height: 61px;
              background-position: left top;
            }
            .text {
              flex: 0 0 100%;
            }
          }
        }

        &--inline {
          flex-wrap: wrap;
          margin-top: 0;
          margin-bottom: 0;

          .boxes__item {
            flex: 0 0 100%;
            display: flex;
            padding-bottom: 30px;

            &::after {
              right: 50%;
              top: auto;
              bottom: -27px;
              transform: rotate(90deg) translateX(-50%);
              border: 0;
            }
            &:last-child {
              padding-bottom: 10px;
            }
          }
        }
      }
    }

  .quote {
    display: flex;
    margin-bottom: 70px;

    h2 {
      text-align: left;
      margin: 0;
      flex: 0 0 15%;
    }

    &__item {
      position: relative;

      &::before {
        content: '';
        display: block;
        background: url(../img/quotes-sign.png) transparent top left no-repeat;
        background-size: contain;
        max-width: 60px;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:not(:first-child) {
        padding-left: 65px;
        margin: 0;
        margin-left: 20px;
        font-size: 20px;
        font-style: italic;
        flex: 0 0 calc(42.5% - 10px);
      }
    }

    @include breakpoint($brake-sm-max) {
      flex-wrap: wrap;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 40px;

      h2 {
        flex: 0 0 100%;
        margin-bottom: 40px;
        text-align: center;
      }
      &__item {
        &:not(:first-child) {
          flex: 0 0 100%;
          margin-left: 0;
          margin-bottom: 20px;
        }
        &::before {
          width: 36px;
        }
      }
    }
  }

  .more {
    &-link {
      .less {
        display: none;
      }

      &.active {
        .less {
          display: inline;
        }
        .more {
          display: none;
        }
      }
    }

    &-content {
      display: none;
      color: $color-gray-second;
      margin-top: 10px;
    }
  }

  .abstract {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .text-black {
    color: $color-black;
  }
  .text-red {
    color: $color-red;
  }

  .graph {
    width: 100%;
    margin-top: 40px;
    text-align: center;
    padding: 0 50px;
    font-size: 15px;

    &__item {
      width: 14.4%;
      vertical-align: bottom;
      padding-bottom: 40px;

      &:nth-child(1) {
        width: 16%;
      }
    }
    &__space {
      vertical-align: top;

      &--longer {
        width: 31%;
      }
    }

    tr:first-child {
      .graph__item {
        padding-bottom: 85px;
      }
    }

    @include breakpoint($brake-sm-max) {
      margin: 35px -10px 0;
      padding: 0;
      font-size: 13px;

      &__wrapper {
        margin-top: 35px;
      }

      &__item {
        padding-top: 0;
      }

      tr:first-child {
        .graph__item {
          padding-bottom: 30px;
        }
      }
    }
  }

  .tooltip {
    margin: 6px 0;
    cursor: pointer;

    .icon-infotip {
      display: none;
      font-size: 24px;
      margin-left: 5px;
      vertical-align: text-bottom;
    }
  }
}


.visa-cjm {
  .box--rounded {
    @include breakpoint($brake-sm-max) {
      border: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .site-content__block:last-child {
    @include breakpoint($brake-sm-max) {
      margin-top: 35px;
      margin-bottom: 35px;
    }
  }

  .site-tabs-content .box--rounded {
    @include breakpoint($brake-sm-max) {
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      padding: 35px 0;
      margin: 35px 0 0;
      border-radius: 0;
    }
  }

  .boxes.mobile--full-width .boxes__item .image {
    @include breakpoint($brake-sm-max) {
      flex: 0 0 62px;
      min-height: 50px;
      margin-bottom: 10px;
    }
  }
  .boxes__item .box--rounded {
    @include breakpoint($brake-sm-max) {
      flex-wrap: wrap;
    }
  }
}
