.table-paginator {
  margin-bottom: 30px;

  @include breakpoint($brake-sm) {
    position: relative;
    top: -51px;
    margin-bottom: 0;
  }

  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  padding: 11px 0;

  &__arrow {
    font-size: 32px;
    margin-top: -5px;
    height: 32px;
    &--left {
      float: left;
      margin-left: -10px;

      @include breakpoint($brake-sm) {
        margin-left: -20px;
        margin-top: -20px;
        font-size: 62px;
      }
    }
    &--right {
      float: right;
      margin-right: -10px;

      @include breakpoint($brake-sm) {
        margin-right: -20px;
        margin-top: -20px;
        font-size: 62px;
      }
    }

    //
    // scss-lint:disable QualifyingElement
    span[class*=icon-arrow] {
      color: $color-blue;
      transition: $trans-hover;

      &:hover {
        color: $color-gray-main;
      }
    }
    // scss-lint:enable QualifyingElement
  }

  &__dots {
    margin: 0;
    padding: 5px 0 0;
    list-style: none;
    text-align: center;

    &-item {
      display: inline-block;
      margin: 0 3px;
    }
    &-link {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: $color-gray-fifth;
      transition: $trans-hover;
    }
    &-item.active &-link,
    &-item:hover &-link {
      background: $color-blue;
    }
  }

  + .slider-table + .slider-table-mobile {
    margin-top: 3px;
  }

  &.fixed {
    @include pko-z-index('sticky-table-nav');
    position: fixed;
    top: 54px;
    left: 0;
    width: 100%;
    background: $color-white;
    margin: 0;

    + .slider-table {
      margin-top: 15px;

      @include breakpoint($brake-sm) {
        margin-top: 85px;
      }

      + .slider-table-mobile {
        margin-top: 96px;
      }
    }
  }

  &.fixed &__wrapper {
    @extend %wrap-paddington;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
  }

  &.fixed &__paddington {
    @extend %paddington;
  }

  @include breakpoint($brake-sm) {
    margin: 0;
    padding: 32px 0;
  }

  @include breakpoint($brake-md) {
    &.fixed {
      top: 0;
    }
  }

}
