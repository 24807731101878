.bg-white, .bg-white-with-shadow  {
  background-color: $color-white;
  .calc__bar {
    background-color: $color-white;
    box-shadow: 0 0 4px rgba($color-black, 0.45);
  }
}

.bg-navy {
  background: $color-blue;

  .calc__bar {
    background-color: $color-blue;
  }
}

.bg-gray {
  background: $color-gray-sixth;
  border: 1px solid $border-color;

  .calc__bar {
    background-color: $color-gray-sixth;
    box-shadow: 0 0 4px rgba($color-black, 0.45);
  }
}

.bg-blue {
  background: $color-mid-blue;

  .calc__bar {
    background-color: $color-mid-blue;
  }
}

.bg-light-blue {
  background: $color-light-blue;
}

.bg-gold {
  background: linear-gradient(to bottom right, rgba($gold-dark, 0.93) 0, rgba($gold-light, 1) 100%);

  .calc__bar {
    background-color: $color-gold;
  }
}


// jasne teksty
.bg-navy,
.bg-blue,
.bg-light-blue,
.bg-gold {
  color: $color-white;

  .calc__bar,
  .calc-input__header,
  .calc-input__error,
  .calc__results-details-label,
  .calc__results-details-value,
  .calc-loan .calc__sliders .irs-controls .irs-value,
  .calc-loan .calc__sliders .irs-controls .irs-value:before,
  &.calc-combo__results .crossed,
  .calc-combo & .calc__results-details-label,
  .calc-combo .calc__sliders-header,
  &.calc-combo-LP .calc__carousel-header,
  &.calc-combo-LP .calc__results-details-label,
  .icon-infotip:hover::before {
    color: $color-white !important;
  }

  .icon-infotip::before {
    color: $color-white !important; // important bo stylowane przez nadrzedną klase .form
  }

  .input-checkbox,
  .input-radio {
    transition: color $trans-hover;
    color: $color-white !important; // Bug 64532 - dziwny blad na IE
    &:hover {
      color: $color-white !important;
    }
  }
  input[type="radio"] + .input-radio::before {
    border-color: $color-white;
  }
  input[type="radio"]:checked + .input-radio::after {
    background-color: $color-white;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $color-white;

    small {
      color: $color-white;
    }
  }
  .form__title {
    color: $color-white;
  }
  .form__description {
    color: $color-white;
  }
  a:not(.btn-white):not(.cta-white):not(.cta-gold) {
    color: $color-blue-light;
    @include pko-on-event() {
      color: $color-black;
    }
    transition: $trans-hover;
  }

  // nie wiem czy ta reguła się nam sprawdzi
  // scss-lint:disable QualifyingElement
  .no-touch & input[type="checkbox"]:focus:not(:disabled) + .input-checkbox,
  .no-touch & input[type="checkbox"]:hover:not(:disabled) + .input-checkbox,
  .no-touch & input[type="checkbox"]:active:not(:disabled) + .input-checkbox,
  .description-for-data-administration-info {
    color: $color-white;
  }
  // scss-lint:enable QualifyingElement


  .block-content__flex-inner {
    color: $color-black;
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: $color-black;

      small {
        color: $color-black;
      }
    }
  }
}

// dodatkowe elementy
.bg-blue,
.bg-navy {
  .irs-slider:before {
    background-color: $color-orange;
  }

  .irs-bar,
  .irs-bar-edge {
    background: $color-orange;
  }

  .calc-loan .calc__sliders-installment-slider-item--active {
    background: $color-orange;
    border-color: $color-orange;
    color: $color-white;
  }

  .calc-loan .calc__tooltip .icon-infotip {
    &:hover::before {
      color: $color-white;
    }
  }

  .overlay__icon {
    color: $color-blue;
  }
}

.bg-gray {
  .calc-loan .calc__sliders-installment-slider-item--active {
    background-color: $color-blue;
    color: $color-white;
  }

  .calc-loan .calc__sliders-installment-nav:first-child::after,
  .calc-loan .calc__sliders-installment-nav:last-child::after {
    color: $color-blue;
    border-color: $color-blue;
  }
}

.bg-gold {
  .overlay__icon {
    color: $color-blue;
  }

  .calc-loan .calc__sliders-installment-slider-item--active {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
  }

  .calc-loan .calc__tooltip .icon-infotip {
    color: $color-white;
  }

  .calc-loan .calc__sliders-installment-nav:first-child::after,
  .calc-loan .calc__sliders-installment-nav:last-child::after {
    color: $color-white;
  }
}

.bg-white, .bg-white-with-shadow {
  .calc-loan .calc__sliders-installment-slider-item--active {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
  }

  .calc-loan .calc__sliders-installment-nav:first-child::after,
  .calc-loan .calc__sliders-installment-nav:last-child::after {
    color: $color-blue;
  }
}

.bg-white-with-shadow {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
}


