.bubbles {
  $root: &;

  &--6 {
    $style: &;

    margin-bottom: 25px;

    @include breakpoint($brake-sm) {
      margin-bottom: 45px;
    }

    #{$root}__title {
      @include pko-text-size($font-size-h3-mobile, $line-height-h3-mobile, $font-size-h3, $line-height-h3);
      margin-bottom: 40px;
      color: $text-color-head;
    }

    #{$root}__articles {
      @include breakpoint($brake-sm) {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: -60px;
      }
    }

    #{$root}__article {
      margin-top: 60px;

      @include breakpoint($brake-sm) {
        width: 50%;
        padding-right: 35px;
      }
    }


    #{$root}__article-image {
      width: 55px;
      height: 55px;
      margin-bottom: 20px;

      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    #{$root}__article-title {
      font-size: 18px;
      margin-bottom: 10px;
      color: $text-color-head;

      @include breakpoint($brake-sm) {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    #{$root}__article-description {
      font-size: 15px;
    }
  }
}