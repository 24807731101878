.landing-content {

  @extend %pko-clearfix;

  .cta {
    @include pko-font-size($font-size-t1);
    padding: 13px 43px 14px;
    margin: 0 0 5px;
  }

  &-subtext {
    @extend %text-default-2;
    margin-top: 65px;

    &.site-content__module {
      padding-bottom: 23px;
      overflow: hidden;
    }

    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    @extend %paddington;
    &-inner {
      @extend %break-from-paddington;
    }
  }

  &__calculator h2 {
    margin-top: 0;
  }

  .form__row--submit-button {
    margin-top: 20px;
  }

  &__image,
  &__copy {
    @extend %paddington;
    @include pko-font-size($font-size-t1-mobile);
  }

  &__copy h3 {
    margin-bottom: 15px;
  }

  &__image {
    img {
      max-width: 100%;
    }
  }

  &__snippet {
    @extend %paddington;

    .form__body {
      margin-bottom: -5px;
    }

    &-inner {
      position: relative;
      padding: 25px 30px 28px;
      @include pko-z-index(default);

      h1,
      h2,
      h3,
      h4 {
        &:first-child {
          margin-top: 0;
          padding-top: 0;
        }
      }
      p:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .form__title {
        margin-top: 0;
        padding-top: 0;
      }
    }

    // S - szary, Z - złoty, G - granatowy, N - niebieski
    &--S &-inner,
    &--Z &-inner,
    &--N &-inner,
    &--G &--inner {
      padding: 25px 30px;
    }

    &--S &-inner {
      background: rgba($color-gray-sixth, 0.93);
      border: 1px solid $border-color;
    }
    &--G &-inner {
      background: rgba($color-blue, 0.93);
    }
    &--N &-inner {
      background: $color-mid-blue;
    }

    &--G &-inner,
    &--N &-inner {
      a:not(.btn-white) {
        @include pko-on-event() {
          color: $color-black;
        }
      }
    }

    &--Z &-inner {
      a:not(.btn-white) {
        @include pko-on-event() {
          color: $color-black;
        }
        transition: $trans-hover;
      }
    }

    &--G &-inner,
    &--N &-inner,
    &--Z &-inner {
      color: $color-white;

      .input-checkbox,
      .input-radio {
        transition: color $trans-hover;
        color: $color-white !important; // Bug 64532 - dziwny blad na IE
        &:hover {
          color: $color-white !important;
        }
      }

      h1,
      h2,
      h3,
      h4 {
        color: $color-white;

        small {
          color: $color-white;
        }
      }
      .form__title {
        color: $color-white;
      }
      .form__description {
        color: $color-white;
      }
      a:not(.btn-white) {
        color: $color-blue-light;
      }
      // nie wiem czy ta reguła się nam sprawdzi
      // scss-lint:disable QualifyingElement
      .no-touch & input[type="checkbox"]:focus:not(:disabled) + .input-checkbox,
      .no-touch & input[type="checkbox"]:hover:not(:disabled) + .input-checkbox,
      .no-touch & input[type="checkbox"]:active:not(:disabled) + .input-checkbox,
      .description-for-data-administration-info {
        color: $color-white;
      }
      // scss-lint:enable QualifyingElement


      .block-content__flex-inner {
        color: $color-black;
      }

      .calc__sliders .irs-controls .irs-value::before {
        color: $color-white;
      }
    }

    &--Z &-inner {
      background: linear-gradient(to bottom right, rgba($gold-dark, 0.93) 0, rgba($gold-light, 0.93) 100%);
    }
  }

  // only mobile
  @include breakpoint($brake-sm-max) {

    &__snippet {
      padding: 0 !important; // override paddington extend
      margin-top: 25px;
      margin-bottom: 25px;
    }
    //#70601 - na LP wystaje poza krawedz strony - zminiejszono na 15px;
    // #93016 - zrobiłem 30px, żeby było równo z całą stroną i dla sniipetów w col2
    &__snippet-inner {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 30px;
      padding-right: 30px;
    }

    &__image {
      display: none;
    }

    &__copy {
      h3 {
        display: none;
      }
    }
  }

  // tablet and up
  @include breakpoint($brake-sm) {
    &__snippet {
      margin-top: 5px;
      &--Z &-inner,
      &--S &-inner,
      &--N &-inner,
      &--G &-inner {
        box-shadow: 3px 3px 0 rgba($color-black, 0.05);
        margin-top: -15px;
      }
    }
    &__copy {
      @include pko-font-size($font-size-t2);
    }

    &__image,
    &__copy {
      @include pko-font-size($font-size-t1);
    }
  }

  // laptop screen and up
  @include breakpoint($brake-md) {
    &-subtext {
      margin-top: 80px;
    }

    &__snippet {
      padding-bottom: 15px;

      &--Z &-inner,
      &--S &-inner,
      &--N &-inner,
      &--G &-inner {
        margin-top: 0;
      }
    }
    &__image img {
      margin-top: -15%;
    }
  }

}

.lp-form-and-img {
  .landing-content-subtext {
    margin-top: 0;
    display: inline-block;
    @include breakpoint($brake-sm) {
      margin-top: -150px;
      padding-top: 20px;
      width: 41.66667%;
      display: block;
    }
    @include breakpoint($brake-md) {
      margin-top: -170px;
      display: block;
    }
    margin-bottom: 50px;
  }
  .landing-header__title {
    max-width: 52%;
  }
  .landing-content__copy {
    &.text {
      display: none;
    }
  }
  .landing-content__image {
    img {
      @include breakpoint($brake-md) {
        max-width: 144%;
      }
    }
  }
}

