.artificial-file-input {
  width: 100%;
  position: relative;
  cursor: pointer;

  &__input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include pko-z-index();
    cursor: pointer;
    opacity: 0;

  }
}
