.leasing-offers {
  &__car {
    position: relative;
    .pagination {
      padding: 0 0 50px 0;
      border-top: none;
      &-wrapper {
        margin-top: 0;
      }
    }
  }
  &__comparison-wrap {
    position: relative;
    border-top: 1px solid $color-gray-fifth;
  }
  &__comparison-offers {
    clear: both;
    .leasing-offers__list--comparison {
      display: none;
    }
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    color: $color-black;
    &--offer-wrap {
      flex: 0 0 30.333%;
      margin: 0 3% 3% 0;
      @include breakpoint($brake-sm-max) {
        flex: 0 0 100%;
        margin: 0 0 7%;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    &--snippet-wrap {
      flex: 0 0 30.333%;
      margin: 0 3% 3% 0;
      @include breakpoint($brake-sm-max) {
        flex: 0 0 100%;
        margin: 0 0 7%;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    &--img {
      max-width: 100%;

      @include breakpoint($brake-md) {
        width: 100%;
      }
    }
    &--description {
      display: inline-block;
      max-width: 150px;
    }
    &--model {
      font-size: 19px;
      margin: 0;
      word-break: break-all;
    }
    &--type {
      font-size: 13px;
    }
    &--link {
      color: $color-black;
    }
    &--price {
      display: inline-block;
      float: right;
      font-size: 32px;
      font-weight: bold;
      text-align: right;
      @include breakpoint($brake-md-max) {
        font-size: 18px;
      }
    }
    &--currency {
      display: block;
      font-size: 15px;
    }
    &--placeholder {
      height: 200px;
      background: $color-gray-seventh;
    }
    &--comparison {
      margin-top: 10px;
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
      }
      input[type="checkbox"]:disabled + label {
        opacity: 0.5;
      }
      .add-to-comparison {
        font-size: 13px;
        color: $color-gray-second;
        vertical-align: top;
      }
    }
  }
  &__last-seen {
    .leasing-offers__list--offer-wrap {
      flex: 0 0 22.7%;
      &:nth-child(3n) {
        margin-right: 3%;
      }
      @include breakpoint($brake-md) {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  &__car-special-offers {
    text-align: left;
    .leasing-offers__list--description {
      max-width: 120px;
    }
    .leasing-offers__list--model, .leasing-offers__list--type {
      font-size: 12px;
    }
    .leasing-offers__list--price {
      font-size: 14px;
      margin-top: 10px;
    }
    .owl-prev, .owl-next {
      color: $color-blue;
      @include breakpoint($brake-md) {
        display: block;
      }
      &.disabled {
        opacity: 0.5;
      }
    }
    .owl-prev {
      left: 0;
    }
    .owl-next {
      right: 38px;
    }
    .owl-dot {
      padding: 7px;
      z-index: 100;
    }
    @include breakpoint($brake-md) {
      .owl-dots {
        bottom: 30px;
      }
    }
    .owl-stage {
      @include breakpoint($brake-lg) {
        height: 320px; // fix skakania elementów pod sliderem
        overflow-y: hidden;
      }
    }
    .leasing-offers__list--offer {
      color: $color-black;
      padding: 25px;
      transition: padding .3s ease;
      @include breakpoint($brake-lg) {
        padding: 75px;
        margin: 0 25px;
      }
      &.featured {
        padding: 0;
        .leasing-offers__list--description {
          max-width: 160px;
        }
        .leasing-offers__list--model {
          font-size: 20px;
        }
        .leasing-offers__list--type {
          font-size: 13px;
        }
        .leasing-offers__list--price {
          font-size: 32px;
        }
      }
    }

    // Haksy, wszędzie haksy...
    .owl-item:not(.active) + .owl-item.active .leasing-offers__list--offer { // Oferta na lewo od featured
      @include breakpoint($brake-md) {
        padding-top: 60px;
      }

      @include breakpoint($brake-lg) {
        padding-right: 0;
        padding-top: 40px;
      }
    }

    .owl-item.active + .owl-item.active .leasing-offers__list--offer:not(.featured) { // Oferta na prawo od featured
      @include breakpoint($brake-md) {
        padding-top: 60px;
      }

      @include breakpoint($brake-lg) {
        padding-left: 0;
        padding-top: 40px;
      }
    }

    .leasing-offers__list--offer:not(.featured) .leasing-offers__list-img-wrap { // Wyrównanie zdjęć bocznych
      @include breakpoint(pko-em-size(769)) { // Fix dla iPad
        display: flex;
        align-items: flex-end;
        height: 150px;
      }

      @include breakpoint($brake-md) {
        height: 170px;
      }
    }

    .leasing-offers__list--offer-wrap {
      @include breakpoint($brake-lg) {
        margin: 0;
      }
    }
  }
  .leasing-offers__list--description {
    text-align: left;
  }
  img {
    max-width: 100%;
  }
}

.heading {
  &__comparison {
    float: left;
  }
  &__no-mt {
    margin-top: 0;
  }
}

.link {
  &__show-comparison {
    display: block;
    margin: 20px 0;
    text-align: right;
    @include breakpoint($brake-md) {
      float: right;
      margin-top: 35px;
    }
  }
  &__delete-from-comparison {
    display: block;
    font-size: 13px;
  }
}

