.property-slider {
  position: relative;
  @extend %paddington;
  @include standard-wrapper;

  &__title {
    display: none;
    @include pko-font-size(24);

    @include breakpoint($brake-sm) {
      display: inline-block;
      padding: 40px 0;
      margin: 0;
    }

    @include breakpoint($brake-md) {
      padding: 45px 0;
      margin: 0;
    }
  }

  img,
  .property-slider__item-details {
    display: none;
  }

  .owl-loaded img,
  .owl-loaded .property-slider__item-details {
    display: block;

    @include breakpoint($brake-sm) {
      display: table-cell;
    }
  }

  .owl-dots {
    position: relative;
    display: block;
  }

  .owl-dot {
    width: 9px;
    height: 9px;
    transition: $trans-hover;
    &:hover {
      background: $color-blue;
    }
  }

  .owl-item {
    transition: opacity $trans-hover ease, scale $trans-hover ease;

    img {
      max-width: 100%;
    }
  }

  &__item {
    @include span(12);
    display: table;
    float: none;
    margin-top: 30px;

    @include breakpoint($brake-sm) {
      margin-top: 0;
    }

    &-image {
      width: 100%;

      img {
        width: 100%;
      }

      @include breakpoint($brake-sm) {
        @include span(5);
        float: none;
        vertical-align: middle;
        display: table-cell;
      }

      @include breakpoint($brake-md) {
        @include span(6);
        float: none;
      }
    }

    &-details {
      @include span(12);
      padding-left: 15px;
      @extend %break-from-paddington;
      float: none;
      margin-top: 30px;

      @include breakpoint($brake-sm) {
        @include span(5);
        padding-left: 25px;
        float: none;
        border-left: 1px solid $color-gray-seventh;
        vertical-align: middle;
        margin-top: 0;
      }

      @include breakpoint($brake-md) {
        @include span(4);
        float: none;
      }
    }

    &-spacer {
      display: none;
    }

    &-half-spacer {
      display: none;
    }

    &-address {
      margin: 0;
      @include pko-text-size-3-way(17, 18, 20);
      font-weight: bold;
    }

    &-info {
      margin: 10px 0 15px;
      @include pko-text-size-3-way(14, 15, 17);

      @include breakpoint($brake-sm) {
        margin: 20px 0;
      }
    }

    &-data {
      width: 100%;
      @include pko-text-size-3-way(14, 15, 17);
      @extend %pko-clearfix;

      span {
        float: left;
        display: block;
        width: 50%;
      }

      &-name {
        color: $color-black;
      }
    }

    &-link {
      display: block;
      margin-top: 25px;
      @include pko-text-size-3-way(13, 15, 15);

      @include breakpoint($brake-md) {
        margin-top: 30px;
      }
    }
  }

  @include breakpoint($brake-sm) {

    overflow: hidden;

    .owl-prev,
    .owl-next {
      display: block;
      color: $color-blue;
      transition: $trans-hover;
      width: auto;
      top: 50%;
      &:hover {
        color: $color-gray-main;
      }
    }

    .owl-prev {
      left: -5px;
    }
    .owl-next {
      right: -5px;
    }

    &__wrapper {
      //margin: 40px 0 0;
    }

    .owl-stage-outer {
      width: 96%;
      margin: 0 auto;
      padding-top: 0;
    }

    &__item {
      &-spacer {
        @include span(0.7);
        display: table-cell;
        float: none;
      }

      &-half-spacer {
        @include span(0.5);
        display: table-cell;
        float: none;
      }
    }
  }

  @include breakpoint($brake-md) {
    .owl-prev {
      left: 0;
    }

    .owl-next {
      right: 0;
    }
  }

}
