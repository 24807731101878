.deposit-counter {
  display: block;

  &-container {
    display: inline-block;
    cursor: default;

    &__text {
      @include pko-font-size(14);

      @include breakpoint($brake-md-lg) {
        @include pko-font-size($font-size-t1);
      }

      font-weight: bold;
      color: $color-black;

      &--red {
        color: $color-red;
      }
    }

    &__badge {
      @include pko-font-size(23);

      @include breakpoint($brake-md-lg) {
        @include pko-font-size(26);
      }

      color: $color-blue;

      position: relative;
      top: 5px;
    }
  }

  // Produkt bez visuala
  & + .banner__description {
    margin-top: 25px;

    // Margines dla NEONa
    .neon-template & {
      margin-top: 5px;
    }
  }

  // Kategoria produktów
  & + .category-item__description {
    margin-top: 10px;
  }
}

// Poniżej fixy zmiany kolorów deposit countera na eventach w module NEON oraz w innych miejscach,
// gdzie jest <a> jako rodzic
a {
  @include pko-on-event() {
    .deposit-counter-container__text {
      color: $color-black;
    }

    .deposit-counter-container__text--red {
      color: $color-red;
    }

    .deposit-counter-container__badge {
      color: $color-blue;
    }
  }
}
