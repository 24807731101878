// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

//@font-face {
//  font-family: 'pkobp';
//  src: font('pkobankpolski-light-webfont.woff2') format('woff2'),
//  font('pkobankpolski-light-webfont.woff') format('woff'),
//  font('pkobankpolski-light-webfont.ttf') format('truetype');
//  font-weight: 300;
//  font-style: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'pkobp';
  src: pko-font('pkobankpolski-regular-webfont.woff2') format('woff2'),
  pko-font('pkobankpolski-regular-webfont.woff') format('woff'),
  pko-font('pkobankpolski-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'pkobp';
  src: pko-font('pkobankpolski-bold-webfont.woff2') format('woff2'),
  pko-font('pkobankpolski-bold-webfont.woff') format('woff'),
  pko-font('pkobankpolski-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//  font-family: 'pkobp';
//  src: pko-font('pkobankpolski-black-webfont.woff2') format('woff2'),
//  pko-font('pkobankpolski-black-webfont.woff') format('woff'),
//  pko-font('pkobankpolski-black-webfont.ttf') format('truetype');
//  font-weight: 900;
//  font-style: normal;
//  font-display: swap;
//}

@font-face {
  font-family: 'icomoon';
  src: pko-font('icomoon_v22.woff2') format('woff2'),
  pko-font('icomoon_v22.woff') format('woff'),
  pko-font('icomoon_v22.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
