%paddington {

  @include pko-give-padding($padding-xs);

  @include breakpoint($brake-sm) {
    @include pko-give-padding($padding-sm);
  }

  @include breakpoint($brake-md) {
    @include pko-give-padding($padding-md);
  }

  @include breakpoint($brake-md-lg) {
    @include pko-give-padding($padding-md-lg);
  }

  @include breakpoint($brake-lg) {
    @include pko-give-padding($padding-lg);
  }

  //@include breakpoint($brake-xl) {
  //  @include pko-give-padding($padding-xl);
  //}
}

%sidebar-paddington {

  padding-left: ($padding-xs) * 2;

  @include breakpoint($brake-sm) {
    padding-left: ($padding-sm) * 2;
  }

  @include breakpoint($brake-md) {
    padding-left: ($padding-md) * 2;
  }

  @include breakpoint($brake-md-lg) {
    padding-left: ($padding-md-lg) * 2;
  }

  @include breakpoint($brake-lg) {
    padding-left: ($padding-lg) * 2;
  }
}

%invert-sidebar-paddington {

  padding-right: ($padding-xs) * 2;

  @include breakpoint($brake-sm) {
    padding-right: ($padding-sm) * 2;
  }

  @include breakpoint($brake-md) {
    padding-right: ($padding-md) * 2;
  }

  @include breakpoint($brake-md-lg) {
    padding-right: ($padding-md-lg) * 2;
  }

  @include breakpoint($brake-lg) {
    padding-right: ($padding-lg) * 2;
  }
}

%break-from-sidebar-paddington {

  @include pko-give-margin(($padding-xs) * 2);

  @include breakpoint($brake-sm) {
    @include pko-give-margin(($padding-sm) * 2);
  }

  @include breakpoint($brake-md) {
    @include pko-give-margin(($padding-md) * 2);
  }

  @include breakpoint($brake-md-lg) {
    @include pko-give-margin(($padding-md-lg) * 2);
  }

  @include breakpoint($brake-lg) {
    @include pko-give-margin(($padding-lg) * 2);
  }
}

%break-from-paddington {

  @include pko-give-margin($padding-xs);

  @include breakpoint($brake-sm) {
    @include pko-give-margin($padding-sm);
  }

  @include breakpoint($brake-md) {
    @include pko-give-margin($padding-md);
  }

  @include breakpoint($brake-md-lg) {
    @include pko-give-margin($padding-md-lg);
  }

  @include breakpoint($brake-lg) {
    @include pko-give-margin($padding-lg);
  }

  //@include breakpoint($brake-xl) {
  //  @include pko-give-margin($padding-xl);
  //}
}

%wrap-paddington {
  @include pko-give-padding(15px);

  @include breakpoint($brake-sm) {
    @include pko-give-padding(18px);
  }

  @include breakpoint($brake-md) {
    @include pko-give-padding(20px);
  }

  //@include breakpoint($brake-1120) {
  //  @include pko-give-padding(20px);
  //}

  //@include breakpoint($brake-lg) {
  //  @include pko-give-padding(40px);
  //}

  @include breakpoint($brake-xl) {
    @include pko-give-padding(0);
  }
}


%break-from-wrap-paddington {

  @include pko-give-margin(15px);

  @include breakpoint($brake-sm) {
    @include pko-give-margin(18px);
  }

  @include breakpoint($brake-md) {
    @include pko-give-margin(20px);
  }

  //@include breakpoint($brake-1120) {
  //  @include pko-give-margin(20px);
  //}

  //@include breakpoint($brake-lg) {
  //  @include pko-give-margin(40px);
  //}

  @include breakpoint($brake-xl) {
    @include pko-give-margin(0);
  }
}

%icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icon-font-stack !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: $color-gray-third; // ?? todo - potem musimy nadpisywać

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%pko-clearfix {
  &::after {
    display: table;
    clear: both;
    content: '';
  }
}

%h1 {
  @include pko-text-size($font-size-h1-mobile, $line-height-h1-mobile, $font-size-h1, $line-height-h1);
}

%h2 {
  @include pko-text-size($font-size-h2-mobile, $line-height-h2-mobile, $font-size-h2, $line-height-h2);
}

%h3 {
  @include pko-text-size($font-size-h3-mobile, $line-height-h3-mobile, $font-size-h3, $line-height-h3);
}

%h4 {
  @include pko-text-size($font-size-h4-mobile, $line-height-h4-mobile, $font-size-h4, $line-height-h4);
}

%text-default-1 {
  @include pko-text-size($font-size-t1-mobile, $line-height-t1-mobile, $font-size-t1, $line-height-t1);
}

%text-default-2 {
  @include pko-text-size($font-size-t2-mobile, $line-height-t2-mobile, $font-size-t2, $line-height-t2);
}

%text-default-3 {
  @include pko-text-size($font-size-t3-mobile, $line-height-t3-mobile, $font-size-t3, $line-height-t3);
}

%text-rodo-normal {
  @include pko-text-size($font-size-t1-mobile, $line-height-t1-mobile, $font-size-t1, $line-height-t1);
}
%text-rodo-small {
  @include pko-text-size-no-break($font-size-t3-mobile, $line-height-t3-mobile);
}

%pko-icon-plus {
  position: relative;
  width: 18px;
  height: 18px;
  &::before {
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: '';
    background-color: $color-blue;
    border-radius: 0 2px;
    position: absolute;
  }
  &::after {
    width: 2px;
    height: 100%;
    background-color: $color-blue;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    border-radius: 2px 0;
  }
  @include breakpoint($brake-sm) {
    width: 21px;
    height: 21px;
    &::before {
      height: 3px;
    }
    &::after {
      width: 3px;
    }
  }
}

%pko-icon-minus {
  position: relative;
  width: 18px;
  height: 18px;
  &::before {
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    position: absolute;
    content: '';
    background-color: currentColor;
    border-radius: 0 2px;
  }
  &::after {
    width: 2px;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    background-color: currentColor;
    position: absolute;
    content: '';
    border-radius: 2px 0;
  }
  @include breakpoint($brake-sm) {
    width: 21px;
    height: 21px;
    &::before {
      height: 3px;
    }
    &::after {
      width: 3px;
    }
  }
}

%pko-icon-menu {
  width: 20px;
  height: 3px;
  top: 6px;
  left: 0;
  background-color: currentColor;
  position: absolute;

  &::before,
  &::after {
    width: 20px;
    height: 3px;
    position: absolute;
    left: 0;
    content: '';
    display: block;
    background-color: currentColor;
  }

  &::before {
    top: -6px;

  }

  &::after {
    bottom: -6px;
  }
}

%pko-icon-search {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid currentColor;
  position: relative;
  top: 0;
  left: 0;

  &::after {
    width: 10px;
    height: 3px;
    background-color: currentColor;
    transform: rotate(45deg);
    content: '';
    display: block;
    left: 9px;
    top: 12px;
    position: absolute;
  }
}

// todo wywalić - użyć czcionki
%pko-icon-arrow {
  position: relative;
  width: 13px;
  height: 7px;

  &::before,
  &::after {
    content: '';
    width: 10px;
    height: 2px;
    display: block;
    background: $color-blue;
    position: absolute;
    bottom: 0;
  }

  &::before {
    right: 50%;
    transform: rotate(45deg);
    margin-right: -2px;
  }

  &::after {
    left: 50%;
    transform: rotate(-45deg);
    margin-left: -2px;
  }
}

// todo wywalić - użyć czcionki
%pko-icon-arrow-gray {
  @extend %pko-icon-arrow;

  &::before,
  &::after {
    background: $color-gray-second;
  }
}

// todo wywalić - użyć czcionki
%pko-icon-arrow-left {
  width: 7px;
  height: 13px;

  &::before {
    right: 50%;
    transform: rotate(-135deg);
    margin-right: -5px;
  }

  &::after {
    left: 0;
    transform: rotate(135deg);
    margin-left: -2px;
    bottom: 50%;
  }
}

// todo wywalić - użyć czcionki
%pko-icon-arrow-right {
  width: 7px;
  height: 13px;

  &::before {
    right: 50%;
    transform: rotate(135deg);
    margin-right: -5px;
  }

  &::after {
    left: 0;
    transform: rotate(-135deg);
    margin-left: -2px;
    bottom: 50%;
  }
}

%pko-transparent-background {
  background: transparent url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
}

%pko-accordion-on-mobile {
  @include breakpoint($brake-sm-max) {
    &.site-content__module {
      padding-bottom: 0;
    }
  }
}

%pko-reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

%icon-infotip {
  display: inline-block;
  cursor: pointer;
  margin-left: 15px;

  &::before {
    position: absolute;
    font-size: 28px;
    margin-top: -18px;
    margin-left: -18px;
    transition: $trans-hover;
  }

  &:hover::before {
    color: $color-blue;
  }
}

%button-theme {
  @include pko-font-size($font-size-t3);
  border-radius: 8px 0;
  padding: 8px 20px;
  display: inline-block;

  @include breakpoint($brake-sm) {
    transition: background-color $trans-hover;
    @include pko-font-size($font-size-t2);
  }
}

%cta {
  @extend %button-theme;
  color: $color-white;
  background-color: $color-red;
  position: relative;
  overflow: hidden;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;

  &:hover,
  .no-touch &:hover {
    background: $color-blue;
    color: $color-white;
  }
  .no-touch &:hover * {
    color: $color-white;
  }

  &:focus,
  .no-touch &:focus {
    color: $color-white;
  }

  @include breakpoint($brake-sm) {
    @include pko-font-size($font-size-t2);
  }
}

%pko-owl-arrows {
  @include breakpoint($brake-sm) {
    .owl-prev,
    .owl-next {
      display: block;
      color: $color-blue;
      &.disabled {
        color: $color-gray-third;
      }
    }
    .owl-prev {
      left: 0;
    }
  }
}

%data-recommended {
  &::before {
    content: attr(data-recommended);
    display: block;
    bottom: 100%;
    left: 0;
    margin-bottom: 2px;
    color: $text-color;
    text-transform: none;
    font-weight: normal;
    @include pko-font-size($font-size-t2);
  }
}

%tfi-charts {
  .highcharts-legend-item rect {
    display: none;
  }

  .highcharts-legend-item path {
    display: none;
  }

  .chart-custom-line {
    width: 19px;
    height: 2px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: 4px;
  }
}

%pko-close-absolute {
  color: $icon-color;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 32px;
  cursor: pointer;
  transition: color $trans-hover;

  &::before {
    line-height: 32px;
    height: 32px;
    width: 32px;
    backface-visibility: visible;
  }

  @include pko-on-event() {
    color: $color-gray-main;
  }
}
