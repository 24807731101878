/*
* Styl 3 - Duze zdjecie i numerowana lista artykulow
*/

.bubbles {
  $root: &;

  &--8 {
    $style: &;

    margin-bottom: 25px;

    @include breakpoint($brake-sm) {
      margin-bottom: 45px;
    }

    &#{$root}--left {
      #{$root}__cover { 
        order: 2;

        @include breakpoint($brake-sm) {
          order: 1;
        }
      }

      #{$root}__content { 
        order: 1;

        @include breakpoint($brake-sm) {
          order: 2;
        }
      }
    }

    &#{$root}--right {
      #{$root}__cover { 
        order: 2;
      }

      #{$root}__content { 
        order: 1;
      }
    }

    #{$root}__inner {
      display: flex;
      flex-wrap: wrap;

      @include breakpoint($brake-sm) {
        flex-wrap: nowrap;
      }
    }

    #{$root}__title {
      margin-bottom: 40px;
      font-size: 24px;
      color: $color-black;
    }

    #{$root}__cover {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px -30px 0;
      
      @include breakpoint($brake-sm) {
        margin: 0;
        flex-basis: 50%;
      }
    }

    #{$root}__cover-image {
      width: 100%;
    }

    #{$root}__description {
      margin-bottom: 45px;
      color: $text-color;
    }

    #{$root}__content {
      flex: 1 1 100%;
      order: 1;

      @include breakpoint($brake-sm) {
        padding-left: 25px;
        padding-right: 25px;
        flex-basis: 50%;
        order: 2;
      }
    }

    #{$root}__description {
      margin-bottom: 45px;
      color: $text-color;
    }

    #{$root}__articles {
      //nothin
    }

    #{$root}__article {
      margin-top: 50px;

      &:first-child {
        margin-top: 0;
      }
    }

    #{$root}__article-image {
      width: 55px;
      height: 55px;
      margin-bottom: 15px;

      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    #{$root}__article-title {
      font-size: 19px;
      margin-bottom: 20px;
      color: $text-color-head;

      @include breakpoint($brake-sm) {
        font-size: 24px;
      }
    }

    #{$root}__article-description {
      font-size: 15px;
    }
  }
}