/* =================
   Moduł kalkulatora spłaty ratalnej
   ================= */
// 'reason'
// scss-lint:disable BemDepth
.calc {
  &--payment-installment {

    .calc__disclaimer {
      @extend %text-default-3;
      margin-top: 30px;
    }

    .calc__selections {
      @include breakpoint($brake-sm-max) {
        .input-text-label {
          display: block;
        }
      }
    }

    .calc__selections__list {
      @extend %pko-clearfix;
    }

    .calc__selections__list--header {
      display: none;
    }

    @include breakpoint($brake-sm) {
      .calc__selections__list--content .calc__selections__label {
        display: none;
      }

      .calc__selections__list--header {
        display: block;
        margin-bottom: 0;
      }
    }

    @include breakpoint($brake-xs-sm) {
      .calc__selections {
        margin-bottom: 20px;
      }
    }

    .calc__selections__list-item {
      vertical-align: top;
      @include breakpoint($brake-xs-sm) {
        width: 100%;
        margin-bottom: 10px;
      }
      @include breakpoint($brake-md) {
        width: 100%;
        margin-bottom: -2px;
      }
      @include breakpoint($brake-sm-max) {
        margin-bottom: 10px;
      }
    }

    @include breakpoint($brake-sm) {
      .calc__selections__list-item--1 {
        width: 33.333%;
      }

      .calc__selections__list-item--2 {
        width: 33.333%;
      }

      .calc__selections__list-item--3 {
        width: 33.333%;
      }
    }

    @include breakpoint($brake-md) {
      .calc__selections__list-item--1 {
        width: 33.333%;
      }

      .calc__selections__list-item--2 {
        width: 33.333%;
      }

      .calc__selections__list-item--3 {
        width: 33.333%;
      }
    }

    .datepicker-wrap {
      @include pko-font-size($font-size-t1);
      margin-top: 0;
      @include breakpoint($brake-sm) {
        margin-top: 15px;
      }
    }


    .select2 {
      max-width: 220px;
      @include breakpoint($brake-sm) {
        max-width: 9999px;
      }
    }

    .irs-controls {
      &--rata {
        margin-top: -95px;

        .irs-value {
          input {
            width: 70%;
          }
        }
      }
    }

    @include breakpoint($brake-sm-max) {
      .calc__sliders {
        .irs-controls {
          &--rata {
            bottom: 66px;

            .irs-value {
              input {
                width: 50%;
              }
            }
          }
        }
      }
    }

    @include breakpoint($brake-sm) {
      .calc__sliders {
        .irs-controls {
          min-width: 204px;
          position: relative;

          .irs-value {
            width: 184px;
          }

          &--rata {
            margin-top: 0;

            .irs-value {
              input {
                width: 70%;
              }
            }
          }
        }
      }

      form .calc__summary {
        .calc-summary-record {
          @include span(12);
          padding-bottom: 0;
          min-height: 55px;
        }
        .calc-summary-column {
          @include span(6);
        }
      }
    }

    @include breakpoint($brake-md) {
      .calc__sliders {
        margin-top: 30px;
        margin-bottom: 30px;
        &__list-item {
          margin-bottom: 18px;
        }
      }

      form .calc__summary {
        padding-top: 11px;
        padding-bottom: 20px;
        .calc-summary-record {
          @include span(12);
          padding-bottom: 4px;
          min-height: 0;
        }
      }

      .calc__header {
        margin-top: 45px;
        margin-bottom: 38px;
      }
    }


    .calc__summary {
      @extend %pko-clearfix;
      padding: 15px 0;
      border-top: 1px solid $color-gray-fifth;
      border-bottom: 1px solid $color-gray-fifth;
    }

    .calc-summary-row {
      margin: 0 -25px;
      @include pko-font-size($font-size-t1-mobile);
      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-t1);
      }
    }

    .calc-summary-record {
      @extend %pko-clearfix;
      padding: 15px 25px;

      @include breakpoint($brake-sm) {
        float: left;
        @include span(6);
      }

      &__name {
        @include span(8);
      }
      &__value {
        @include span(4);
        text-align: right;
        font-weight: 700;
      }
    }
  }
}

.calc-payment-schedule {
  width: 100%;
  min-width: 450px;
  th {
    width: 20%;
  }
  .summary {
    * {
      font-weight: 700;
    }
  }
}
// scss-lint:enable BemDepth
