@mixin prevent-jump-fix() {

  /* some ugly fixes that prevent page jump when menu expand */

  @include breakpoint(($brake-sm) and ($brake-md-max)) {
    .header--login,
    .header--search,
    .header--menu-open,
    .header--sticky {
      .header__sticky-paddington {
        //padding: 0 18px;
      }
    }
  }
  @include breakpoint($brake-md) {
    .header__inner {
      right: 17px;
      width: auto !important;
    }
    .header--login,
    .header--search,
    .header--menu-open,
    .header--sticky {

      .header__sticky-inner {
        max-width: 1242px;
        margin: 0 auto;
        //max-width: 1202px;
        //margin: 0 auto;
        //@include pko-give-padding(0);
        //padding-left:0 !important;
        //padding-right: 0 !important;
      }
    }
  }

  @include breakpoint(($brake-md) and (max-width: 1119px)) {
    .header--login,
    .header--search,
    .header--menu-open,
    .header--sticky {

      .header__sticky-inner {
        //fix 74638
        //@include pko-give-padding(5px);
        //dodatkowy padding na hamburgerze został tutaj usunięty
      }
    }
  }

  // to do nierozumiem tego, powstają style @media (min-width: 64em) and (min-width: 90em)
  @include breakpoint($brake-xl) {
    .header--login,
    .header--search,
    .header--menu-open,
    .header--sticky {

      .header__sticky-inner {
        //max-width: 1242px;
        //margin: 0 auto;
        /*
        @include pko-give-padding(15px);
        @include breakpoint($brake-sm) {
          @include pko-give-padding(18px);
        }
        @include breakpoint($brake-md) {
          @include pko-give-padding(20px);
        }
        @include breakpoint($brake-xl) {
          @include pko-give-padding(0);
        }*/
      }

    }
  }

}
