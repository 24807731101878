.person {

  $root: &;

  &__photo {
    display: block;
    margin: 0 auto 20px;
    max-height: 300px;
  }

  &__name {
    margin: 0 0 5px;
  }

  &__position {
    @extend %text-default-2;
    margin: 0;
    display: block; // #71457
  }

  &__info {
    @extend %text-default-2;
    margin-top: 18px;

    &--phone,
    &--mobile {
      display: inline-block;

      @include breakpoint($brake-md-lg) {
        display: none;
      }

      &-desktop {
        display: none;

        @include breakpoint($brake-md-lg) {
          display: inline-block;
        }
      }
    }

  }

  &__group {
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &-name {
      @extend %paddington;
      margin-bottom: 42px;
    }
  }

  &__list {
    $person-list: &;
    @extend %break-from-paddington;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;

    &.equal-rows {
      visibility: hidden;
    }

    &.rows-aligned {
      visibility: visible;
    }

    &-item {
      @extend %paddington;
      display: inline-block;
      width: 100%;
      margin-bottom: 26px;

      @include breakpoint($brake-sm) {
        @include span(4);
      }

      *:first-child {
        margin-top: 0;
      }

      &:nth-child(3n+1) {
        clear: left;
      }
    }

    &__photo {
      max-height: 120px;
      max-width: 100%;
    }

    &__name {
      margin: 0 0 2px;
    }

    &__link {
      @include pko-on-event() {
        #{$person-list}__name {
          color: $color-blue;
        }
      }
    }

  }

  @include breakpoint($brake-sm) {
    &__list {
      &-item {
        margin-bottom: 42px;
      }
    }
    &__group {
      margin-bottom: 45px;
      &-name {
        margin-bottom: 42px;
        margin-top: 35px;
      }
      &:last-child #{$root}__list {
        margin-bottom: -50px;
      }
    }
  }
}
