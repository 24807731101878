// .leasing .feature-section - zmiany dla leasingu i newsow na dole

.news {
  $root: &;
  $mod: '#{$root}--mobile-hide';

  &-list {
    .filters-wrapper-voivodeship {
      margin-bottom: 12px;

      .select2 {
        position: relative;
        top: -3px;

        @include breakpoint($brake-md) {
          position: relative;
          top: -1px;
        }
      }
    }

    //
    // scss-lint:disable IdSelector
    #category-with-visual & .site-content__module-header {
      margin-top: 0;
    }
    // scss-lint:enable IdSelector

    .voivodeship-label,
    .sort-label {
      margin-bottom: 8px;
      color: $color-black;
      //font-size: 21px;

      @include pko-font-size($font-size-h3-mobile);
      //@include pko-line-height($size1, $size2);
      @include breakpoint($brake-md-lg) {
        @include pko-font-size($font-size-h4);
        //@include pko-line-height($size3, $size4);
      }

      .touch & {
        display: block;
      }

      @include breakpoint($brake-sm) {
        display: inline;
      }
    }

    .site-content {
      &__additional {
        padding-top: 0;
        padding-bottom: $vertical-space-mobile;
        margin-top: -8px;
        @include breakpoint($brake-sm) {
          float: right;
          padding-bottom: $vertical-space-desktop;
          margin-top: 0;
        }

        &::after,
        &::before {
          display: none;
        }

      }

      &__sidebar {
        border-top: 1px solid $border-color;
        padding-top: $vertical-space-mobile;
        @include breakpoint($brake-sm) {
          border-top: none;
          padding-top: 0;
        }
      }

    }
  }

  // COMMENTED BECAUSE OF: Bug #63910
  //@include breakpoint($brake-sm) {
  //  display: inline;
  //}

  &__all {
    @extend %paddington;
    @include pko-font-size($font-size-t2-mobile);
    @include pko-line-height($font-size-t2-mobile, 31);
    float: right;
    span {
      display: none;
    }
  }

  &__main-link {
    color: $text-color;
    @include pko-on-event() {
      color: $text-color;
      #{$root}__title {
        color: $color-blue;
      }
    }
  }

  &__item {
    @extend %paddington;
    margin-bottom: $vertical-space-mobile;
    @include breakpoint($brake-sm) {
      margin-bottom: $vertical-space-desktop;
      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    @include breakpoint($brake-sm) {
      .leasing .feature-section & {
        margin-bottom: 0;
      }
    }

    &-image {
      @extend %pko-clearfix;
      width: 100%;
      img {
        width: 160px;
        height: 120px;
      }
      @include breakpoint($brake-sm) {
        float: left;
        width: auto;
      }
    }

    &--tfi:nth-child(2n+1) {
      clear: left;
    }
  }

  &--snippet {
    #{$root}__item {
      @include breakpoint($brake-sm) {
        @include span(6);
      }
    }
  }

  &__link {
    #{$mod} & {
      display: none;
    }
    @include breakpoint($brake-sm) {
      margin-top: 20px;
      &, #{$mod} & {
        display: inline-block;
      }
    }
  }

  &__title {
    transition: color $trans-hover linear;
    @include pko-font-size($font-size-t1);
    margin: 8px 0 -5px;

    @include pko-normalcase-in-uppercase;

    @include breakpoint($brake-sm) {
      margin: 5px 0 18px;
    }
    a {
      color: inherit;
    }

    &--tfi {
      @include pko-font-size($font-size-h4);
      margin-bottom: 4px;
    }

    .icon-umbrella {
      position: relative;
      top: 2px;
    }

    .icon-leaf {
      height: 1em;
      display: inline-block;
      vertical-align: text-top;
      margin-left: 1px;
      position: relative;
      top: 2px;
      padding: 1px;

      img {
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }
  }

  &__description {
    #{$mod} & {
      display: none;
    }
    @include breakpoint($brake-sm) {
      &, #{$mod} & {
        display: block;
      }
      p {
        margin: 0; // na pre-prod tresci sa bez <p>, w srodowiskach qa są z <p>
      }
    }
  }

  @include breakpoint($brake-sm) {
    padding-bottom: $vertical-space-desktop;
    margin-bottom: $vertical-space-desktop;

    .leasing .feature-section & {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &__all {
      @include pko-font-size($font-size-t2);
      @include pko-line-height($font-size-t2, 36);
      span {
        display: inline;
      }
    }

    &__title {
      @include pko-font-size($font-size-h4);
      &--tfi {
        @include pko-font-size($font-size-h3);
      }
    }
  }

  &-section {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &__category {
    &-title {
      @extend %paddington;
      margin: 13px 0 19px;

      @include breakpoint($brake-sm) {
        margin: 0 0 40px;
      }
    }
  }

  &__no-results {
    @include pko-font-size($font-size-t1);
    color: $color-gray-main;
    margin: 0;
  }

  //
  // scss-lint:disable IdSelector
  #category-with-visual &__header {
    &--no-margin-top {
      margin-top: 0;
    }
  }
  // scss-lint:enable IdSelector

  &--compact {
    h3 {
      margin-bottom: 5px;
      &.news__no-results {
        @extend %paddington;
      }
    }
    .news__link {
      margin-top: 5px;
    }
    .news__item {
      margin-bottom: 20px;

      &--button-container {
        display: inline-block;
        margin-top: 30px;
      }
    }
  }

  .leasing .feature-section & .breakline {
    display: none;
  }

  // Klasa dodawana w snippetcie Aktualności Redesign w CMS usuwająca padding-bottom, zwiększenie Specificity
  &.news--snippet-without-padding-bottom {
    padding-bottom: 0;
  }
}
