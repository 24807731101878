.static-map {
  &__wrap {
    position: relative;
    max-width: 682px;
    line-height: 0; // fix na rowna wysokosc obrazka i wrappera
    padding-top: 6%;
    @include breakpoint($brake-sm) {
      padding-top: 4%;
    }
    .text & img {
      margin-bottom: 0;
    }
  }
  &__poi {
    position: absolute;
    width: 5%;
    height: 9%;
  }
}
