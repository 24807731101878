.overlay {

  $root: &;

  @include pko-z-index('ajax-overlay-default');
  background-color: rgba($color-white, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;

  &:not(.spacial-modal) {
    cursor: not-allowed;
  }

  &.transparent {
    @extend %pko-transparent-background;
  }

  &.spacial-modal {
    @extend %pko-transparent-background;
    @include breakpoint($brake-sm-max) {
      background-color: rgba($color-white, 0.8);
    }
    //@include breakpoint($brake-sm) {
    //  cursor: pointer;
    //}
  }

  &.opaque {
    background-color: $color-white;
  }

  &.offset {
    height: 72%;
    top: 50%;
    transform: translateY(-50%);
  }

  &.fixed {
    position: fixed;
  }

  &.rodo-not-small-modal {
    @include pko-z-index(cookie);
  }

  &.top {
    @include pko-z-index('ajax-overlay-top');
  }

  .mod-G &,
  .mod-Z &,
  .modal--popup-blue & {
    @extend %pko-transparent-background;
  }

  &__icon {
    @include pko-font-size(60);

    color: $icon-color;
    position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;

    #{$root}.no-icon & {
      display: none;
    }

    &--20pc {
      top: 20%;
    }
    &--25pc {
      top: 25%;
    }
    &--30pc {
      top: 30%;
    }
    &--35pc {
      top: 35%;
    }
    &--40pc {
      top: 40%;
    }

    &--poi {
      top: 560px;
      @include breakpoint($brake-sm) {
        top: 640px;
      }
    }

    // no-icon oznacza zwykle brak ikony w szablonie
    #{$root}.animating:not(.no-icon) & {
      animation: rotate-loader 1.5s infinite linear;
    }

    .mod-G &,
    .mod-Z &,
    .modal--popup-blue & {
      color: $color-white;
    }

  }

  &--dark-background {
    background-color: rgba($color-black, 0.8);
  }

}

.overlay-wrap-with-header {
  .overlay {
    top: -50px;
  }
}
