.wifi {

  .header {
    min-height: 300px;
  }

  &__buttons {
    @extend %paddington;
    @extend %pko-clearfix;
    margin-bottom: 50px;
    margin-top: -20px;
  }

  &__button {
    width: 100%;
    border: 1px solid $color-gray-wifi;
    border-radius: 10px;
    height: 48px;
    margin-right: 5%;
    float: left;
    box-shadow: 0 1.5px rgba($color-black, 0.12);

    margin-bottom: 10px;
    max-width: 480px;

    @include breakpoint($brake-md) {
      width: 30%;
      margin-bottom: 0;
      max-width: none;
      height: 58px;
      &:last-child {
        margin-right: 0;
      }
    }

    &-link {
      display: block;
      @extend %pko-clearfix;

      &-text {
        @include pko-font-size(17);
        float: left;
        color: $text-color;
        line-height: 48px;
        margin: 0 13px;
        @include breakpoint($brake-md) {
          line-height: 58px;
        }
        @include breakpoint($brake-lg) {
          @include pko-font-size(20);
        }
      }

      &-logo {
        float: left;
        width: auto;
        height: 30px;
        margin-top: 8px;
        vertical-align: middle;
        @include breakpoint($brake-md) {
          margin-top: 10px;
          height: 35px;
        }
      }

      &-arrow {
        @extend %icomoon;
        float: right;
        color: $color-blue;
        transition: color $trans-hover;
        font-size: 40px;
        margin: 3px;
        @include pko-on-event() {
          color: $color-gray-main;
        }
        @include breakpoint($brake-md) {
          margin-top: 8px;
        }

        &::before {
          content: $icon-arrow-right-small;
        }
      }
    }
  }
}
