.calc-input {
  position: relative;

  &__header {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px;
    color: $color-black;
    text-align: center;
    @include pko-font-size(17);

    @include breakpoint($brake-md) {
      text-align: left;
      margin: 0 0 15px;
      padding: 0;
    }

    &--small {
      margin: 40px 0 12px;
      padding: 0;
      @include pko-font-size(14);

      @include breakpoint($brake-md) {
        @include pko-font-size(17);
        margin: 35px 0 15px;
      }
    }
  }

  &__input-wrapper {
    display: block;
    float: none;
    width: calc(100% - 25px);
    height: 55px;
    margin: 0;
    padding: 0;
    position: relative;
    border: 1px solid $color-gray-third;
    border-radius: 3px;
    color: $color-gray-main;
    -webkit-appearance: none;

    &:before {
      position: absolute;
      content: attr(data-unit);
      float: right;
      right: -25px;
      @include pko-font-size(22);
      line-height: 53px;
      color: $color-black;

      @include breakpoint($brake-md) {
        @include pko-font-size(24);
      }
    }
  }

  &__input {
    display: block;
    float: none;
    width: 100%;
    height: 53px;
    padding: 0;
    border: none;
    border-radius: 3px;
    margin: 0;
    background-color: $color-white;
    color: #000;
    @include pko-font-size(22);
    font-weight: 400;
    line-height: 53px;
    text-align: center;

    ::-ms-clear,
    &::-ms-clear {
      display: none;
    }

    @include breakpoint($brake-md) {
      height: 53px;
      @include pko-font-size(24);
      line-height: 53px;
    }
  }

  &__error {
    @extend .form__error;
    padding: 0;
  }
}
