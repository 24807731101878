.repayment-calculator {
  @extend %pko-accordion-on-mobile;
  margin-bottom: 0;

  $field-width: 170;

  &__row {
    @include span(12);

    @include breakpoint($brake-sm) {
      @include span(6);
    }
    @include breakpoint($brake-md) {
      @include span(4);
    }
  }

  &__sliders {
    clear: both;
    padding-top: 10px;
  }
  &__slider {
    clear: both;
    padding-top: 0;
    > .irs {
      clear: both;
    }
  }
  &__label {
    @include pko-font-size($font-size-t2-mobile);
    display: block;
    margin: 5px 0 -5px;
    padding-top: 5px;
    float: left;
    &--desktop {
      display: none;
    }
  }
  .irs-value {
    float: right;
    width: 100px;
    input {
      width: 75px;
    }
    &[data-unit^="mc"] {
      input {
        width: 50px;
      }
    }
  }
  .irs-block {
    float: right;
    margin: 2px -5px 0 2px;
  }

  @include breakpoint($brake-sm) {
    &__slider {
      padding-top: 10px;
      > .irs {
        clear: none;
        float: left;
        width: calc(100% - #{$field-width + 70}px);
      }
      .input-text {
        width: #{$field-width}px;
        margin-left: 10px;
      }
    }
    &__label {
      @include pko-font-size($font-size-t1);
      margin-top: 0;
      float: none;
      &--desktop {
        display: block;
      }
      &--mobile {
        display: none;
      }
    }
    .irs-value {
      margin-top: 3px;
      float: right;
      input {
        width: #{$field-width - 50}px;
        height: 31px;
        @include breakpoint($brake-sm) {
          height: 41px;
        }
      }
      &[data-unit^="mc"] {
        input {
          width: #{$field-width - 75}px;
        }
      }
    }
    .irs-block {
      float: none;
      margin: 10px 0 -10px 20px;
      display: inline-block;
    }
    .accordion--mobile-only-footer {
      margin-bottom: 0;
    }
    .accordion__body {
      padding-top: 0;
    }
  }
}
