.site-nav {
  $root: &;

  &__hamburger {
    @include pko-z-index('hamburger');
    top: 19px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    color: $icon-color;
    transition: $trans-hover;

    span {
      transition: $trans-hover;
      display: inline-block;
      @extend %pko-icon-menu;
      color: $link-color;

      &,
      &::before,
      &::after {
        background-color: currentColor;
      }
    }

    &::after {
      @include pko-line-height(15px, 17px);
      top: -2px;
      content: 'menu';
      padding-left: 28px;
      display: inline-block;
      position: relative;
    }

    @include breakpoint($brake-md) {
      display: none;
    }
  }

}
